import React, { FunctionComponent, ReactElement } from 'react'
import { ActivityIndicator, GestureResponderEvent, Platform, ViewStyle } from 'react-native'

import styled from 'styled-components/native'

import { colors } from '../../styles/colors'
import { BodyText, Size } from '../bodyText/BodyText'
import { PressableOpacity } from '../pressableOpacity/PressableOpacity'

/**
 * DEPRECATING! This button component contains the old button styles. Please use
 * PrimaryButon, SecondaryButton, TertiaryButton or TextButton for the new
 * "Look and Feel" styling
 */

export enum ButtonTypes {
  PRIMARY = 'Primary',
  TEXT = 'Text',
  SECONDARY_TEXT = 'Secondary Text',
  WARNING_TEXT = 'Warning Text',
  SECONDARY_LIGHT = 'Secondary Light',
  DEV = 'Dev',
  DEFAULT = 'Default',
}

type ContainerProps = {
  backgroundColor: string
}

export type ButtonProps = {
  onPress: (event: GestureResponderEvent) => void
  title: string | React.ReactNode
  buttonType?: ButtonTypes
  icon?: ReactElement | null
  testID?: string
  disabled?: boolean
  loading?: boolean
  loadingColor?: string
  accessibilityLabel?: string
  style?: ViewStyle
}

const getButtonStyles = (buttonType: ButtonTypes) => {
  switch (buttonType) {
    case ButtonTypes.PRIMARY:
      return {
        container: colors.teal5,
        text: colors.white,
        pressed: colors.teal6,
      }
    case ButtonTypes.TEXT:
      return {
        container: 'transparent',
        text: colors.navy5,
        pressed: 'transparent',
      }
    case ButtonTypes.SECONDARY_TEXT:
      return {
        container: 'transparent',
        text: colors.navy6,
        pressed: 'transparent',
      }
    case ButtonTypes.WARNING_TEXT:
      return {
        container: 'transparent',
        text: colors.red4,
        pressed: 'transparent',
      }
    case ButtonTypes.SECONDARY_LIGHT:
      return {
        container: colors.white,
        text: colors.navy6,
        pressed: colors.charcoal1,
      }
    case ButtonTypes.DEV:
      return {
        container: colors.devBlue,
        text: colors.white,
        pressed: colors.devDarkBlue,
      }
    default:
      return {
        container: colors.navy5,
        text: colors.white,
        pressed: colors.navy6,
      }
  }
}

const handleButtonState = (
  styles: { container: string; text: string; pressed: string },
  disabled?: boolean,
  loading?: boolean,
) => {
  if (loading) {
    return {
      container: colors.white,
      text: 'transparent',
      pressed: 'transparent',
    }
  } else if (disabled) {
    return {
      container: colors.charcoal3,
      text: colors.white,
      pressed: 'transparent',
    }
  }
  return styles
}

const Container = styled(PressableOpacity)<ContainerProps>`
  ${Platform.OS === 'web' && `cursor: pointer;`}
  height: 50px;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  flex-direction: row;
  background-color: ${({ backgroundColor }) => backgroundColor};
`

const IconContainer = styled.View`
  padding: 12px;
  top: 5px;
  height: 50px;
`

export const Button: FunctionComponent<ButtonProps> = ({
  onPress,
  title,
  icon,
  buttonType = ButtonTypes.DEFAULT,
  testID = 'Button',
  disabled,
  loading,
  accessibilityLabel,
  loadingColor = colors.black,
  style,
}) => {
  const styles = handleButtonState(getButtonStyles(buttonType), disabled, loading)

  return (
    <Container
      onPress={loading || disabled ? () => {} : onPress}
      pressedOpacity={1}
      pressedBackgroundColor={styles.pressed}
      backgroundColor={styles.container}
      testID={testID}
      disabled={disabled}
      accessibilityLabel={accessibilityLabel}
      accessibilityRole='button'
      accessible
      style={style}
    >
      {loading ? (
        <ActivityIndicator color={loadingColor} />
      ) : (
        <>
          {icon && <IconContainer>{icon}</IconContainer>}
          <BodyText text={title} color={styles.text} textAlign='center' size={Size.DEFAULT} maxFontSizeMultiplier={2} />
        </>
      )}
    </Container>
  )
}
