import React, { FunctionComponent, useContext } from 'react'
import { useIntl } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import {
  CARE_ADVISOR_DISPLAY_PHONE_NUMBER,
  registrationAndLoginDisabledMessage,
} from '@lyrahealth-inc/shared-app-logic'

import { AppContext, Link, Linking } from '../..'
import { BodyText } from '../../atoms/bodyText/BodyText'
import { BodyTextSize } from '../../styles/typeStyles'
import { Banner } from '../banner/Banner'

const StyledBanner = styled(Banner)({
  marginTop: 0,
})

export const RegistrationAndLoginDisabledBanner: FunctionComponent = () => {
  const { isRegistrationAndLoginDisabled } = useContext(AppContext)
  const { formatMessage } = useIntl()
  const { colors } = useTheme()
  const { warning } = colors.components.alertBanner
  return (
    <StyledBanner
      show={isRegistrationAndLoginDisabled ?? false}
      bannerContent={
        <BodyText
          textAlign='center'
          color={warning.text}
          size={BodyTextSize.LARGE}
          text={formatMessage(registrationAndLoginDisabledMessage, {
            link: () => (
              <Link
                text={CARE_ADVISOR_DISPLAY_PHONE_NUMBER}
                onPress={() => {
                  Linking.openURL(`tel:${CARE_ADVISOR_DISPLAY_PHONE_NUMBER}`)
                }}
                color={colors.textActive}
              />
            ),
            emailLink: (text: string) => (
              <Link
                text={text}
                onPress={() => {
                  Linking.openURL(`mailto:${text}`)
                }}
                color={colors.textActive}
              />
            ),
          })}
        />
      }
      backgroundColor={warning.background}
    />
  )
}
