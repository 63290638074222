import React from 'react'
import { useIntl } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { lightThemeColors } from '@lyrahealth-inc/shared-app-logic'

import { XIcon } from '../../atoms/icons/XIcon'
import { Image } from '../../atoms/image/Image'
import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { PrimaryButton } from '../../atoms/primaryButton/PrimaryButton'
import { Subhead } from '../../atoms/subhead/Subhead'
import { IS_WEB } from '../../constants'
import { SubheadSize } from '../../styles'
import { ThemeType, tID } from '../../utils'

interface GoalExampleViewProps {
  onCLosePress: () => void
}

const GoalExampleViewContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing, breakpoints } }) => ({
  padding: breakpoints.isMobileSized ? spacing['24px'] : 0,
}))

const Header = styled.View<{
  theme: ThemeType
}>(({ theme: { spacing } }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  paddingBottom: spacing['24px'],
  paddingTop: '0px',
  ...(IS_WEB && { cursor: 'default' }),
}))

const ButtonContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  paddingTop: spacing['32px'],
  flexDirection: 'row-reverse',
}))

const ImageContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  backgroundColor: lightThemeColors.backgroundSection,
  borderRadius: '16px',
  alignItems: 'center',
  padding: `${spacing['24px']} 0`,
}))

export const GoalExampleView = ({ onCLosePress }: GoalExampleViewProps) => {
  const { formatMessage } = useIntl()
  const { breakpoints, colors } = useTheme()

  return (
    <GoalExampleViewContainer>
      <Header>
        <Subhead
          size={breakpoints.isMobileSized ? SubheadSize.MEDIUM : SubheadSize.MEDIUM}
          text={formatMessage({
            defaultMessage: 'Client goals example',
            description: 'Client goals header title',
          })}
        />
        <PressableOpacity onPress={() => onCLosePress()}>
          <XIcon size={24} fillColor={colors.iconDefault} />
        </PressableOpacity>
      </Header>
      <ImageContainer>
        <Image
          style={{ width: 253, height: 534 }}
          source={require('../../assets/goalExample.png')}
          accessibilityIgnoresInvertColors
        />
      </ImageContainer>
      <ButtonContainer>
        <PrimaryButton
          onPress={() => onCLosePress()}
          testID={tID('GoalExampleView-closeButton')}
          text={formatMessage({
            defaultMessage: 'Close',
            description: 'Close button label',
          })}
        />
      </ButtonContainer>
    </GoalExampleViewContainer>
  )
}
