export const ELECTION_MICROSITE_URL = '/election-microsite'
export const NEW_YEAR_MICROSITE_URL = '/new-year-toolkit'

/** Post messages defined through the CMS. Add shared events here. Events sent directly from goodness should go in `MICROSITE_IFRAME_MESSAGES` */
export const CMS_MICROSITE_MESSAGE_KEY = {
  CLICK_SELF_CARE_CONTENT: 'clickSelfCareContent',
  OPEN_CARE_NAVIGATOR_INFO: 'open care navigator info',
} as const
export type CMS_MICROSITE_MESSAGE = (typeof CMS_MICROSITE_MESSAGE_KEY)[keyof typeof CMS_MICROSITE_MESSAGE_KEY]

export const RESOURCE_COORDINATOR_MICROSITE_MESSAGE_KEY = {
  ...CMS_MICROSITE_MESSAGE_KEY,
  DOCUMENT_OBJECT: 'Document Object',
  CLICK_SCHEDULE_RESOURCE_COORDINATOR_CALL: 'scheduleResourceCoordinatorCall',
} as const
export type RESOURCE_COORDINATOR_MICROSITE_MESSAGE =
  (typeof RESOURCE_COORDINATOR_MICROSITE_MESSAGE_KEY)[keyof typeof RESOURCE_COORDINATOR_MICROSITE_MESSAGE_KEY]

export const ELECTION_CMS_MICROSITE_MESSAGE_KEY = {
  ...CMS_MICROSITE_MESSAGE_KEY,
  CLICK_WORKSHOP_REGISTER_NOW: 'clickWorkshopRegisterNow',
  CLICK_ELECTIONS_BOOK_A_SESSION_TODAY: 'clickElectionsBookASessionToday',
} as const
export type ELECTION_CMS_MICROSITE_MESSAGE =
  (typeof ELECTION_CMS_MICROSITE_MESSAGE_KEY)[keyof typeof ELECTION_CMS_MICROSITE_MESSAGE_KEY]

export const COMPLEX_CARE_CMS_MICROSITE_MESSAGE_KEY = {
  ...CMS_MICROSITE_MESSAGE_KEY,
  NAVIGATE_TO_MEDS_BOOKING: 'navigateToMedsBooking',
  NAVIGATE_TO_RENEW_BOOKING: 'navigateToRenewBooking',
  SCHEDULE_COMPLEX_CARE_CALL: 'scheduleComplexCareCall',
  SCHEDULE_DBT_ASSESSMENT_CALL: 'scheduleDBTAssessmentCall',
  NAVIGATE_TO_SUBPAGE: 'navigateToSubpage',
  OPEN_LEARN_MORE_PANEL: 'openLearnMorePanel',
  CLOSE_LEARN_MORE_PANEL: 'closeLearnMorePanel',
  EXPAND_FAQ: 'expandFAQ',
} as const
export type COMPLEX_CARE_CMS_MICROSITE_MESSAGE =
  (typeof COMPLEX_CARE_CMS_MICROSITE_MESSAGE_KEY)[keyof typeof COMPLEX_CARE_CMS_MICROSITE_MESSAGE_KEY]

export const NEW_YEAR_CMS_MICROSITE_MESSAGE_KEY = {
  ...CMS_MICROSITE_MESSAGE_KEY,
  NAVIGATE_TO_FORKED_TRIAGE: 'navigateToForkedTriage',
  NAVIGATE_TO_WELLNESS_CHECK_IN: 'navigateToWellnessCheckIn',
  NAVIGATE_TO_ESSENTIALS: 'navigateToEssentials',
  REGISTER_WEBINAR: 'registerWebinar',
  NAVIGATE_TO_REGISTRATION: 'navigateToRegistration',
} as const
export type NEW_YEAR_CMS_MICROSITE_MESSAGE =
  (typeof NEW_YEAR_CMS_MICROSITE_MESSAGE_KEY)[keyof typeof NEW_YEAR_CMS_MICROSITE_MESSAGE_KEY]

export type CMSMessageData = {
  mixPanelData: {
    entrypoint?: string
    question?: string
    event?: string
    page?: string
  }
}
