import { useMemo } from 'react'

import { getSchemaTotalPages } from './useGetSchemaTotalPages'
import { Assignment } from '../../../models'
import { customContentAttributesPhase1, customContentAttributesPhase2 } from '../customContentAttributes'
import { countConditionalPages, getLastPageFromResponse } from '../utils'

/**
 * Hook to get total number of pages and number of completed pages in linked assignments
 */
export const useGetLinkedAssignmentsPages = ({
  selectedAssignmentName,
  linkedAssignments,
  isCareOnboardingPhase2Enabled,
}: useGetLinkedAssignmentsPagesParams): { totalPages: number; currentPage: number } => {
  return useMemo(() => {
    const customContentAttributes = isCareOnboardingPhase2Enabled
      ? customContentAttributesPhase2
      : customContentAttributesPhase1
    const linkedAssignmentsPages = linkedAssignments?.map((linkedAssignment) => {
      if (linkedAssignment.content.name === selectedAssignmentName) {
        return { totalPages: 0, currentPage: 0 }
      } else if (
        linkedAssignment.content.name &&
        customContentAttributes[linkedAssignment.content.name]?.hideProgressBar
      ) {
        return { totalPages: 0, currentPage: 0 }
      }
      const schema = linkedAssignment.content_meta_data.schema
      const uiSchema = linkedAssignment.content_meta_data.uiSchema
      const totalPages = getSchemaTotalPages({ schema, uiSchema })
      const conditionalPages = countConditionalPages(
        linkedAssignment.content_meta_data.schema ?? {},
        linkedAssignment.assignment_responses?.[0]?.response ?? {},
      )
      const actualTotalPages = totalPages - conditionalPages.totalCount
      const lastPageFromResponse = getLastPageFromResponse({
        response: linkedAssignment.assignment_responses?.[0]?.response ?? {},
        schema: linkedAssignment.content_meta_data.schema ?? {},
      })
      return {
        totalPages: actualTotalPages,
        currentPage: lastPageFromResponse,
      }
    })

    const pages = linkedAssignmentsPages?.reduce(
      (combinedPages, linkedAssignmentPage) => {
        return {
          totalPages: combinedPages.totalPages + linkedAssignmentPage.totalPages,
          currentPage: combinedPages.currentPage + linkedAssignmentPage.currentPage,
        }
      },
      {
        totalPages: 0,
        currentPage: 0,
      },
    )

    return (
      pages ?? {
        totalPages: 0,
        currentPage: 0,
      }
    )
  }, [selectedAssignmentName, linkedAssignments, isCareOnboardingPhase2Enabled])
}

type useGetLinkedAssignmentsPagesParams = {
  selectedAssignmentName?: string | undefined
  linkedAssignments?: Assignment[]
  isCareOnboardingPhase2Enabled?: boolean
}
