import { useCallback } from 'react'

import { DirectLinkIntent } from '../../common/constants/customerProgram'
import { FORKED_TRIAGE_EVENTS, S4C_PROPERTIES } from '../../common/constants/mixpanelConstants'
import { CARE_OPTION, TREATMENT_OPTIONS } from '../../common/constants/treatmentOptions'
import { DirectLinkSource, OnCareOptionModalOpenCloseProps } from '../constants'

export interface useGetCareOptionsEventHandlersArgs {
  shouldTrackDirectPathClickEvent: boolean
  directLinkSource?: DirectLinkSource | string
  navigationRoutes: {
    onSelfSearchPress: () => void
    onChildSearchPress: () => void
    onCoachingToolkitPress: () => void
    onParentingToolkitPress: () => void
    onMedsConsultPress: () => void
    onCouplesTherapyPress: () => void
    onFamilyTherapyPress: () => void
  }
  indicateIsGuardian: () => void
  trackPathwayOption: (
    eventName: FORKED_TRIAGE_EVENTS,
    props?: {
      [key: string]: string
    },
  ) => void
}

/** Used for getting event handlers needed for care option pages */
export const useGetCareOptionsEventHandlers = ({
  shouldTrackDirectPathClickEvent,
  directLinkSource = DirectLinkSource.FORKED_TRIAGE,
  navigationRoutes,
  indicateIsGuardian,
  trackPathwayOption,
}: useGetCareOptionsEventHandlersArgs) => {
  const handleSelfSearchPress = useCallback(() => {
    trackPathwayOption(FORKED_TRIAGE_EVENTS.CLICK_FIND_CARE_FOR_YOURSELF)
    navigationRoutes.onSelfSearchPress()
  }, [navigationRoutes, trackPathwayOption])

  const handleChildSearchPress = useCallback(() => {
    trackPathwayOption(FORKED_TRIAGE_EVENTS.CLICK_FIND_CARE_FOR_CHILD)
    indicateIsGuardian()
    navigationRoutes.onChildSearchPress()
  }, [indicateIsGuardian, navigationRoutes, trackPathwayOption])

  const handleCoachingToolkitPress = useCallback(() => {
    trackPathwayOption(FORKED_TRIAGE_EVENTS.CLICK_COACHING_FOR_YOURSELF)
    navigationRoutes.onCoachingToolkitPress()
  }, [navigationRoutes, trackPathwayOption])

  const handleParentingToolkitPress = useCallback(() => {
    trackPathwayOption(
      shouldTrackDirectPathClickEvent
        ? FORKED_TRIAGE_EVENTS.CLICK_DIRECT_PATH
        : FORKED_TRIAGE_EVENTS.CLICK_COACHING_FOR_PARENT,
      shouldTrackDirectPathClickEvent
        ? {
            [S4C_PROPERTIES.DIRECT_LINK_SOURCE]: directLinkSource,
            [S4C_PROPERTIES.DIRECT_LINK_INTENT]: DirectLinkIntent.PARENTING,
            [S4C_PROPERTIES.TREATMENT_OPTION]: TREATMENT_OPTIONS.COACHING,
          }
        : {},
    )
    indicateIsGuardian()
    navigationRoutes.onParentingToolkitPress()
  }, [directLinkSource, indicateIsGuardian, shouldTrackDirectPathClickEvent, navigationRoutes, trackPathwayOption])

  const handleMedsConsultPress = useCallback(() => {
    trackPathwayOption(
      shouldTrackDirectPathClickEvent
        ? FORKED_TRIAGE_EVENTS.CLICK_DIRECT_PATH
        : FORKED_TRIAGE_EVENTS.CLICK_MEDICATION_CONSULTATION_FOR_YOURSELF,
      shouldTrackDirectPathClickEvent
        ? {
            [S4C_PROPERTIES.DIRECT_LINK_SOURCE]: directLinkSource,
            [S4C_PROPERTIES.DIRECT_LINK_INTENT]: DirectLinkIntent.MEDS_CONSULT,
            [S4C_PROPERTIES.TREATMENT_OPTION]: TREATMENT_OPTIONS.MEDICATION_CONSULTATION,
          }
        : {},
    )
    navigationRoutes.onMedsConsultPress()
  }, [directLinkSource, shouldTrackDirectPathClickEvent, navigationRoutes, trackPathwayOption])

  const handleCouplesTherapyPress = useCallback(() => {
    trackPathwayOption(FORKED_TRIAGE_EVENTS.CLICK_DIRECT_PATH, {
      [S4C_PROPERTIES.DIRECT_LINK_SOURCE]: directLinkSource,
      [S4C_PROPERTIES.DIRECT_LINK_INTENT]: DirectLinkIntent.COUPLES_THERAPY,
      [S4C_PROPERTIES.TREATMENT_OPTION]: TREATMENT_OPTIONS.COUPLES_THERAPY,
    })
    navigationRoutes.onCouplesTherapyPress()
  }, [directLinkSource, navigationRoutes, trackPathwayOption])

  const handleFamilyTherapyPress = useCallback(() => {
    trackPathwayOption(
      shouldTrackDirectPathClickEvent
        ? FORKED_TRIAGE_EVENTS.CLICK_DIRECT_PATH
        : FORKED_TRIAGE_EVENTS.CLICK_FAMILY_THERAPY,
      shouldTrackDirectPathClickEvent
        ? {
            [S4C_PROPERTIES.DIRECT_LINK_SOURCE]: directLinkSource,
            [S4C_PROPERTIES.DIRECT_LINK_INTENT]: DirectLinkIntent.FAMILY_SUPPORT,
            [S4C_PROPERTIES.TREATMENT_OPTION]: TREATMENT_OPTIONS.FAMILY_THERAPY,
          }
        : {},
    )
    navigationRoutes.onFamilyTherapyPress()
  }, [directLinkSource, shouldTrackDirectPathClickEvent, navigationRoutes, trackPathwayOption])

  const trackOnCareOptionModalOpen = (props: OnCareOptionModalOpenCloseProps) => {
    const { treatmentOption } = props
    if (shouldTrackDirectPathClickEvent) {
      switch (treatmentOption) {
        case CARE_OPTION.FAMILY_THERAPY:
          trackPathwayOption(FORKED_TRIAGE_EVENTS.CLICK_FAMILY_THERAPY)
          break
        case CARE_OPTION.MEDICATION_CONSULTATION:
          trackPathwayOption(FORKED_TRIAGE_EVENTS.CLICK_MEDICATION_CONSULTATION_FOR_YOURSELF)
          break
        case CARE_OPTION.PARENT_COACHING:
          trackPathwayOption(FORKED_TRIAGE_EVENTS.CLICK_COACHING_FOR_PARENT)
          break
      }
    }
  }

  const trackOnCareOptionModalClose = (props: OnCareOptionModalOpenCloseProps) => {
    const { treatmentOption } = props
    switch (treatmentOption) {
      case CARE_OPTION.FAMILY_THERAPY:
        trackPathwayOption(FORKED_TRIAGE_EVENTS.CLOSE_FAMILY_THERAPY)
        break
      case CARE_OPTION.MEDICATION_CONSULTATION:
        trackPathwayOption(FORKED_TRIAGE_EVENTS.CLOSE_MEDICATION_CONSULTATION_FOR_YOURSELF)
        break
      case CARE_OPTION.PARENT_COACHING:
        trackPathwayOption(FORKED_TRIAGE_EVENTS.CLOSE_COACHING_FOR_PARENT)
        break
    }
  }
  return {
    handleSelfSearchPress,
    handleChildSearchPress,
    handleCoachingToolkitPress,
    handleParentingToolkitPress,
    handleMedsConsultPress,
    handleCouplesTherapyPress,
    handleFamilyTherapyPress,
    trackOnCareOptionModalOpen,
    trackOnCareOptionModalClose,
  }
}
