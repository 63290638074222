import { useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'

import { SearchRequestData } from '../../../models'
import { SpeculativeFilterObject } from '../../wellnessToolkit/types'
import { getSpeculativeSearchDefaultCoachingSearchParams } from '../utils'

export interface useRunSpeculativeSearchArgs {
  searchRequestData?: Partial<SearchRequestData>
  isUserLoggedIn: boolean
  lyraId: string
  userDOB: string | undefined
  userCountryName: string | undefined
  isInternationalUser: boolean
  postSpeculativeSearch: (lyraId: string, searchData: SearchRequestData) => Promise<AxiosResponse<boolean>>
  isSpeculativeFilterEnabled: boolean
  filters?: SpeculativeFilterObject
}

/**
 * Check if there's an available supply of providers given a specific search
 *
 * @param searchRequestData If undefined, no speculative search will be made
 * @returns {boolean} hasAvailableSupply
 * @returns {boolean} isLoading
 */
export const useRunSpeculativeSearch = ({
  searchRequestData,
  isUserLoggedIn,
  lyraId,
  userDOB,
  userCountryName,
  isInternationalUser,
  postSpeculativeSearch,
  isSpeculativeFilterEnabled,
  filters,
}: useRunSpeculativeSearchArgs): { hasAvailableSupply: boolean; isLoading: boolean } => {
  const [hasAvailableSupply, setHasAvailableSupply] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(Boolean(isUserLoggedIn && searchRequestData))

  useEffect(() => {
    async function fetchData() {
      const defaultSearchParams = getSpeculativeSearchDefaultCoachingSearchParams({
        isInternationalUser,
        userDOB,
        userCountryName,
        isSpeculativeFilterEnabled,
      })

      const searchData: SearchRequestData = {
        ...defaultSearchParams,
        ...searchRequestData,
        ...(isSpeculativeFilterEnabled && filters),
      }

      try {
        const results = await postSpeculativeSearch(lyraId, searchData)
        setHasAvailableSupply(results.data)
      } catch (e) {
        console.error(e)
      } finally {
        setIsLoading(false)
      }
    }
    if (isUserLoggedIn && searchRequestData) {
      fetchData()
    }
  }, [
    lyraId,
    userDOB,
    isInternationalUser,
    userCountryName,
    postSpeculativeSearch,
    isUserLoggedIn,
    searchRequestData,
    isSpeculativeFilterEnabled,
    filters,
  ])

  return { hasAvailableSupply, isLoading }
}
