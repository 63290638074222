import React, { useCallback, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { compose } from 'redux'
import styled from 'styled-components'
import { useTheme } from 'styled-components/native'

import {
  CARE_ADVISOR_TRUNK_PHONE_NUMBER,
  REGISTRATION_ENTRY_POINT_KEY,
  useLyraIntl,
} from '@lyrahealth-inc/shared-app-logic'
import { VerifyEmail, VerifyEmailState } from '@lyrahealth-inc/ui-core-crossplatform'

import withRedirectForDepartingCustomersOnLoad from '../../../common/components/error/redirect/withRedirectForDepartingCustomersOnLoad'
import { ERROR_CODES } from '../../../common/constants/appConstants'
import { REGISTERED } from '../../../common/constants/errorConstants'
import {
  postEmailVerification,
  postFamilyHubEmailVerification,
  postParentLedTeenEmailVerification,
} from '../../../common/http/data/verifyEmail'
import { handleOpenEmailPress } from '../../../common/utils/accountSetupUtils'
import { getCustomerPhone, getIsCustomerInternational } from '../../../data/customer/customerSelectors'
import { trackEventWithObj } from '../../../data/mixpanel'
import { getSelectedEssentialsTopicIds, getSelectedPathwayOption } from '../../../data/register/registerSelectors'
import { PAGE_ROUTES } from '../../onboard/data/page-navigation/location-actions'
import withRouter from '../../routing/withRouter'
import { dispatchMixpanelForEmailResend, dispatchMixpanelForPageLoad } from '../../verifyEmail/data/verifyEmailActions'

const { UNPROCESSABLE_ENTITY: USER_ALREADY_VERIFIED_STATUS_CODE } = ERROR_CODES

export type VerifyEmailWellnessCheckInContainerProps = {
  registeredEmail?: string
  emailStateError?: string
  isPreview?: boolean
}

export type VerifyEmailContainerProps = ConnectedProps<typeof connector> & VerifyEmailWellnessCheckInContainerProps

const VerifyEmailContainer: React.FC<VerifyEmailContainerProps> = ({
  isCustomerInternational,
  customerPhone,
  selectedPathwayOption,
  dispatchMixpanelForPageLoad,
  dispatchMixpanelForEmailResend,
  selectedEssentialsTopicIds,
  registeredEmail = '',
  emailStateError = '',
  isPreview = false,
}) => {
  const navigate = useNavigate()
  const { breakpoints } = useTheme()
  const { pathname } = useLocation()
  const { activeLanguage } = useLyraIntl()
  const [searchParams] = useSearchParams()
  const [showGenericErrorMessage, setShowGenericErrorMessage] = useState(false)

  const token = searchParams.get('token')
  const email = registeredEmail || searchParams.get('email')
  const error = emailStateError || searchParams.get('error')
  const appointmentId = searchParams.get('apptId')
  const entryPoint = searchParams.get(REGISTRATION_ENTRY_POINT_KEY)

  const isTeen: boolean = searchParams.get('isTeen') === 'true' ? true : false
  const isFamilyHub: boolean = entryPoint === 'family_hub'
  const isFamilyHubTeen: boolean = isFamilyHub && isTeen
  const isFamilyHubAdult: boolean = isFamilyHub && !isTeen
  const isWellnessCheckIn: boolean = pathname.includes(PAGE_ROUTES.WELLNESS_CHECK_IN.DEFAULT)

  // TODO: Once we start sending parent led teen as the entryPoint, we can explicitly check for it here
  const isParentLedTeen: boolean = isTeen && !isFamilyHub

  let verifyEmailState: VerifyEmailState = VerifyEmailState.SUCCESS
  if (error) {
    if (error === REGISTERED) {
      verifyEmailState = VerifyEmailState.ERROR_REGISTERED
    } else {
      verifyEmailState = VerifyEmailState.ERROR_INVALID
    }
  }

  const requestEmailVerification = useCallback(async () => {
    const sendEmail = async () => {
      const username = email && encodeURIComponent(email)

      if (isParentLedTeen) {
        await postParentLedTeenEmailVerification({
          childEmail: username ?? '',
          token: token ?? '',
          appointmentId: appointmentId ?? '',
        })
      } else if (isFamilyHubTeen || isFamilyHubAdult) {
        await postFamilyHubEmailVerification({
          token: token ?? '',
          email: username ?? '',
          isTeen: isTeen,
        })
      } else {
        await postEmailVerification({
          username,
          language: encodeURIComponent(activeLanguage),
          ...(selectedPathwayOption && { pathwayOption: encodeURIComponent(selectedPathwayOption) }),
          ...(selectedEssentialsTopicIds && { essentialsTopicIds: encodeURIComponent(selectedEssentialsTopicIds) }),
        })
      }
    }

    const onSendEmailFailure = async (error: any) => {
      if (error.response?.status === USER_ALREADY_VERIFIED_STATUS_CODE) {
        navigate(`${pathname}?error=registered`)
      }
    }

    try {
      await sendEmail()
    } catch (error) {
      onSendEmailFailure(error)
      setShowGenericErrorMessage(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLanguage, email, navigate, pathname])

  useEffect(() => {
    dispatchMixpanelForPageLoad({ isParentLedTeen, isFamilyHubAdult, isFamilyHubTeen, isWellnessCheckIn })
  }, [dispatchMixpanelForPageLoad, isParentLedTeen, isFamilyHubAdult, isFamilyHubTeen, isWellnessCheckIn])

  useEffect(() => {
    // Automatically resend the email once this screen appears and user came from an invalid link
    if (verifyEmailState === VerifyEmailState.ERROR_INVALID) {
      requestEmailVerification()
    }
  }, [requestEmailVerification, verifyEmailState])

  const onResendEmail = () => {
    setShowGenericErrorMessage(false)
    requestEmailVerification()
    dispatchMixpanelForEmailResend({ isParentLedTeen, isFamilyHubAdult, isFamilyHubTeen, isWellnessCheckIn })
  }

  const handleCallUsPress = () => {
    navigate('/care-team-phone-numbers')
  }

  const handleSignInPress = () => {
    navigate('/login')
  }

  const VerifyEmailContainer = styled.div({
    marginTop: breakpoints.isMinWidthTablet ? 0 : 32,
  })

  return (
    <VerifyEmailContainer>
      <VerifyEmail
        email={email as string}
        verifyEmailState={verifyEmailState}
        onCallUsPressed={handleCallUsPress}
        onSignInPressed={handleSignInPress}
        onResendEmailPressed={onResendEmail}
        onOpenEmailPressed={handleOpenEmailPress}
        showGenericErrorMessage={showGenericErrorMessage}
        isCustomerInternational={isCustomerInternational}
        customerPhone={customerPhone}
        isPreview={isPreview}
      />
    </VerifyEmailContainer>
  )
}

const mapStateToProps = ($$state: Map<string, any>) => {
  return {
    isCustomerInternational: getIsCustomerInternational($$state),
    customerPhone: getCustomerPhone($$state) ?? CARE_ADVISOR_TRUNK_PHONE_NUMBER,
    selectedPathwayOption: getSelectedPathwayOption($$state),
    selectedEssentialsTopicIds: getSelectedEssentialsTopicIds($$state),
  }
}

const mapDispatchToProps = {
  dispatchMixpanelForEmailResend,
  dispatchMixpanelForPageLoad,
  trackEventWithObj,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export default compose(withRedirectForDepartingCustomersOnLoad, withRouter, connector)(VerifyEmailContainer)
