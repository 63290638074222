import { COMMON_MIXPANEL_PROPERTIES } from '@lyrahealth-inc/shared-app-logic'

import { TrackEventWithObjAction } from '../../data/mixpanel/types'

export function rerouteFromMicrosite({
  user,
  navigate,
  trackEventWithObj,
  mixpanelEvent,
  mixpanelEntryPoint,
  mixpanelPage,
}: {
  user: $TSFixMe
  navigate: $TSFixMe
  trackEventWithObj?: TrackEventWithObjAction
  mixpanelEvent?: string
  mixpanelEntryPoint?: string
  mixpanelPage?: string
}) {
  if (!user?.id) {
    if (trackEventWithObj && mixpanelEvent) {
      trackEventWithObj({
        event: mixpanelEvent,
        ...(mixpanelEntryPoint && { entrypoint: mixpanelEntryPoint }),
        ...(mixpanelPage && { [COMMON_MIXPANEL_PROPERTIES.PAGE]: mixpanelPage }),
      })
    }
    navigate('/register/connect', { state: { ...(mixpanelEntryPoint && { mixpanelEntryPoint }) } })
  } else {
    navigate('/secure/onboard/about')
  }
}
