import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { COMMON_MIXPANEL_PROPERTIES } from '@lyrahealth-inc/shared-app-logic'

import { routeToPageMap } from '../constants/mixpanelConstants'

export const useDefaultMixpanelPage = () => {
  const location = useLocation()
  return useMemo(() => {
    const currentRoute = location.pathname
    const page = routeToPageMap[currentRoute]
    if (page) {
      return { [COMMON_MIXPANEL_PROPERTIES.PAGE]: page }
    }
    return {}
  }, [location.pathname])
}
