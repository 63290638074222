import React, { FunctionComponent, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ImageSourcePropType, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'

import { noop } from 'lodash-es'
import { CSSObject } from 'styled-components'
import styled, { useTheme } from 'styled-components/native'

import {
  EmailClient,
  formatCustomerPhoneNumber,
  getEmailAddressClientFromString,
} from '@lyrahealth-inc/shared-app-logic'

import { ActionCard, InlineErrorBanner, IS_WEB } from '../..'
import gmailIcon from '../../assets/gmail_icon.png'
import openEmailIllustration from '../../assets/open_email_illustration.png'
import outlookIcon from '../../assets/outlook_icon.png'
import paperplaneIllustration from '../../assets/paperplane_illustration.png'
import yahooIcon from '../../assets/yahoo_icon.png'
import { PressableOpacity, PrimaryButton } from '../../atoms'
import { BodyText, Size } from '../../atoms/bodyText/BodyText'
import { ArrowIcon, ArrowIconDirection } from '../../atoms/icons/ArrowIcon'
import { CheckEmailAnimation } from '../../atoms/illustrations/CheckEmailAnimation'
import { Link } from '../../atoms/link/Link'
import { Subhead, Size as SubheadSize } from '../../atoms/subhead/Subhead'
import { ThemeType } from '../../utils/themes/ThemeProvider'
import { tID } from '../../utils/utils'

export enum VerifyEmailState {
  SUCCESS = 'SUCCESS',
  ERROR_INVALID = 'ERROR_INVALID', // the token has expired and user needs to get a new email with new token
  ERROR_REGISTERED = 'ERROR_REGISTERED', // the user has already registered
  RESET_PASSWORD = 'RESET_PASSWORD', // Forgot password flow
  UPDATE_USER_INFO_INVALID = 'UPDATE_USER_INFO_INVALID',
}
export interface VerifyEmailProps {
  email: string
  verifyEmailState: VerifyEmailState
  onCallUsPressed?: () => void
  onResendEmailPressed: () => void
  onSignInPressed?: () => void
  onBackPressed?: () => void
  onOpenEmailPressed: (email?: string) => void
  resendLoading?: boolean
  showGenericErrorMessage?: boolean
  contentContainerStyle?: CSSObject
  isCustomerInternational?: boolean
  customerPhone?: string
  isPreview?: boolean
}

const BackgroundContainer = styled(SafeAreaView)<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.breakpoints.isMinWidthTablet ? 'none' : theme.colors.backgroundPrimary,
  height: '100%',
  marginBottom: theme.breakpoints.isMobileSized ? theme.spacing['24px'] : theme.spacing['32px'],
}))

const ContentContainer = styled.View<{ theme: ThemeType; contentContainerStyle?: CSSObject; isPreview?: boolean }>(
  ({ theme, contentContainerStyle, isPreview }) => ({
    backgroundColor: theme.colors.backgroundPrimary,
    paddingHorizontal: theme.spacing['16px'],
    justifyContent: 'center',
    alignSelf: 'center',
    ...(theme.breakpoints.isMinWidthTablet && {
      width: '720px',
      borderRadius: '8px',
      padding: '48px',
      marginTop: '72px',
      marginBottom: isPreview ? '0px' : '300px',
    }),
    ...(theme.breakpoints.isMobileSized &&
      isPreview && {
        marginBottom: '128px',
      }),
    ...contentContainerStyle,
  }),
)

const ArrowContainer = styled(PressableOpacity)<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['24px'],
  marginBottom: theme.spacing['16px'],
  paddingHorizontal: theme.spacing['16px'],
}))

const ImageContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['32px'],
  alignItems: 'center',
}))

const BodyTextContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['16px'],
}))

const ButtonGroupContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['16px'],
}))

const CtaContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['32px'],
  alignSelf: 'stretch',
}))

/**
 * Screen that tells you to confirm your email registration
 */
export const VerifyEmail: FunctionComponent<VerifyEmailProps> = ({
  email,
  verifyEmailState,
  onBackPressed,
  onCallUsPressed = noop,
  onResendEmailPressed,
  onSignInPressed,
  onOpenEmailPressed,
  resendLoading,
  showGenericErrorMessage,
  contentContainerStyle,
  isCustomerInternational = true,
  customerPhone,
  isPreview = false,
}) => {
  const { breakpoints, colors } = useTheme()
  const { formatMessage } = useIntl()
  const [isResendLinkPressed, setIsResendLinkPressed] = useState(false)

  const handleResendEmailPressed = () => {
    setIsResendLinkPressed(true)
    onResendEmailPressed()
  }
  const titles = {
    [VerifyEmailState.SUCCESS]: formatMessage({
      defaultMessage: 'We just sent you a confirmation link',
      description: 'Header text for verify email page when confirmation link was successfully sent',
    }),
    [VerifyEmailState.ERROR_INVALID]: formatMessage({
      defaultMessage: 'That link is expired, but we just sent you a new one',
      description: 'Header text for verify email page when confirmation link is expired',
    }),
    [VerifyEmailState.ERROR_REGISTERED]: formatMessage({
      defaultMessage: 'You have already registered! Please sign in to access your account.',
      description: 'Header of registration verification page prompting the user to sign in with their existing account',
    }),
    [VerifyEmailState.RESET_PASSWORD]: formatMessage({
      defaultMessage: 'Please check your email',
      description:
        'Description text prompting users to check the email they have listed for a link to reset their password',
    }),
    [VerifyEmailState.UPDATE_USER_INFO_INVALID]: formatMessage({
      defaultMessage: 'Sorry, this link is no longer active. Click resend for a new link.',
      description: 'Error message when the link user clicked to verify their account has expired',
    }),
  }

  const emailClients = {
    [EmailClient.GMAIL]: {
      title: formatMessage({
        defaultMessage: 'Open in Gmail',
        description: 'Open in Gmail CTA',
      }),
      imageSource: gmailIcon as ImageSourcePropType,
    },
    [EmailClient.YAHOO]: {
      title: formatMessage({
        defaultMessage: 'Open in Yahoo! Mail',
        description: 'Open in Yahoo Mail CTA',
      }),
      imageSource: yahooIcon as ImageSourcePropType,
    },
    [EmailClient.OUTLOOK]: {
      title: formatMessage({
        defaultMessage: 'Open in Outlook',
        description: 'Open in Outlook CTA',
      }),
      imageSource: outlookIcon as ImageSourcePropType,
    },
  }

  const renderEmailClientButton = (email: string) => {
    if (!IS_WEB) {
      return (
        <ActionCard
          id={tID(`VerifyEmail-openEmail-cta`)}
          title={formatMessage({
            defaultMessage: 'Open email',
            description: 'Open email',
          })}
          onPress={onOpenEmailPressed}
          ImageSource={openEmailIllustration as ImageSourcePropType}
        />
      )
    } else {
      const emailClient = getEmailAddressClientFromString(email)
      return (
        emailClient &&
        emailClients[emailClient] && (
          <ActionCard
            id={tID(`VerifyEmail-openEmail-cta`)}
            title={emailClients[emailClient].title}
            onPress={() => {
              onOpenEmailPressed(email)
            }}
            ImageSource={emailClients[emailClient].imageSource}
          />
        )
      )
    }
  }

  const confirmEmailMessage = (
    <FormattedMessage
      defaultMessage={`Check your inbox at {email} for a confirmation link. This link expires in 24 hours.`}
      description='Body text for verify email page'
      values={{
        email,
      }}
    />
  )

  const resetPasswordMessage = (
    <FormattedMessage
      defaultMessage='If a Lyra account exists for {hasEmail, select, true {{email}} other {the submitted address}}, we sent you an email with a link to reset your password. If you don’t see the email, please check your spam folder.'
      description='Description text that informs the user that an email with the needed link was sent to the email provided and to check their spam folder'
      values={{
        hasEmail: !!email,
        email: email,
      }}
    />
  )

  const hideImage = verifyEmailState === VerifyEmailState.ERROR_REGISTERED
  const showBodyTextAndResendLink = verifyEmailState !== VerifyEmailState.ERROR_REGISTERED
  const showSignInButton = verifyEmailState === VerifyEmailState.ERROR_REGISTERED && onSignInPressed

  let bodyText
  switch (verifyEmailState) {
    case VerifyEmailState.SUCCESS:
      bodyText = confirmEmailMessage
      break
    case VerifyEmailState.ERROR_INVALID:
      bodyText = confirmEmailMessage
      break
    case VerifyEmailState.RESET_PASSWORD:
      bodyText = resetPasswordMessage
      break
  }

  return (
    <BackgroundContainer>
      {onBackPressed && breakpoints.isMobileSized && (
        <ArrowContainer onPress={onBackPressed} testID={tID('backButton')}>
          <ArrowIcon fillColor={colors.iconDefault} direction={ArrowIconDirection.LEFT} />
        </ArrowContainer>
      )}
      <ContentContainer testID={tID('VerifyEmail')} contentContainerStyle={contentContainerStyle} isPreview={isPreview}>
        {!hideImage && (
          <ImageContainer>
            <CheckEmailAnimation />
          </ImageContainer>
        )}
        <Subhead text={titles[verifyEmailState]} size={SubheadSize.LARGE} />

        {showBodyTextAndResendLink && (
          <>
            <BodyTextContainer>
              <BodyText text={bodyText} size={Size.DEFAULT} color={colors.textSecondary} />
            </BodyTextContainer>
            {showGenericErrorMessage && (
              <InlineErrorBanner
                text={formatMessage({
                  defaultMessage: 'Sorry, something went wrong. Please try again.',
                  description: 'Generic error banner text for email verification.',
                })}
              />
            )}

            <ButtonGroupContainer>
              {renderEmailClientButton(email.toLowerCase())}
              <ActionCard
                id={tID(`ResendEmail-link`)}
                title={
                  isResendLinkPressed
                    ? formatMessage({
                        defaultMessage: 'Your link is on the way',
                        description: 'Button text when resend email link was pressed',
                      })
                    : formatMessage({
                        defaultMessage: 'Resend link',
                        description: 'Button text to resend the link',
                      })
                }
                onPress={handleResendEmailPressed}
                ImageSource={paperplaneIllustration as ImageSourcePropType}
                disabled={isResendLinkPressed}
                withChervon={!isResendLinkPressed}
                disabledBackgroundColor={isResendLinkPressed ? colors.backgroundElement : ''}
              />
            </ButtonGroupContainer>
            <BodyText
              text={
                IS_WEB && !isCustomerInternational && verifyEmailState === VerifyEmailState.ERROR_INVALID ? (
                  <FormattedMessage
                    defaultMessage='The email might be in your spam folder. Need help? Call us at {phone}.'
                    description='Text informing the user to check if email is sent to their spam folder and to contact Lyra Health directly if they do not receive an email'
                    values={{
                      phone: formatCustomerPhoneNumber(customerPhone),
                    }}
                  />
                ) : (
                  <FormattedMessage
                    defaultMessage={`The email might be in your spam folder. Need help? <phoneNumbersLink>Contact us</phoneNumbersLink>.`}
                    description='Text informing the user to check if email is sent to their spam folder and to contact Lyra Health directly if they do not receive an email'
                    values={{
                      phoneNumbersLink: (chunks: string) => (
                        <View testID={tID('VerifyEmail-callUs-link')}>
                          <Link text={chunks} onPress={onCallUsPressed} underline size={Size.DEFAULT} />
                        </View>
                      ),
                    }}
                  />
                )
              }
              size={Size.DEFAULT}
              color={colors.textSecondary}
            />
          </>
        )}

        {showSignInButton && (
          <CtaContainer>
            <PrimaryButton
              testID={'SignIn-Button'}
              text={
                <FormattedMessage
                  defaultMessage='Sign in'
                  description='Button text shown to users of the app trying to sign in'
                />
              }
              onPress={onSignInPressed}
              accessibilityLabel={formatMessage({
                defaultMessage: 'Sign in',
                description: 'button text sign in',
              })}
              {...(breakpoints.isMobileSized && { fullWidth: true })}
            />
          </CtaContainer>
        )}
      </ContentContainer>
    </BackgroundContainer>
  )
}
