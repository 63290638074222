import { defineMessage } from 'react-intl'

import {
  SOCIAL_CARE_OPTIONS_MSG,
  WellnessCheckInDomain,
  WellnessCheckInFacetGroup,
  WellnessCheckInValency,
} from './constants'
import {
  CheckboxButtonType,
  FieldSchema,
  FormMetadata,
  RadioButtonType,
  UiMetadata,
} from '../../models/formMetadata/FormMetadata'
import { extendMetadata } from '../assignments/utils'

export const WELLNESS_CHECK_IN_DOMAIN_TO_PAGE_NUMBER_OFFSET = 2
export const WELLNESS_CHECK_IN_NUM_DOMAINS = Object.keys(WellnessCheckInDomain).length
export const WELLNESS_CHECK_IN_SOCIAL_CARE_PAGE_NUMBER =
  WELLNESS_CHECK_IN_NUM_DOMAINS + WELLNESS_CHECK_IN_DOMAIN_TO_PAGE_NUMBER_OFFSET

export const getWellnessCheckInMetadata = (isMobileSized = false, socialCareEnabled = false): FormMetadata => {
  const getFieldSchema = ({ domain }: { domain: WellnessCheckInDomain }): FieldSchema => ({
    mobilePage: Object.values(WellnessCheckInDomain).indexOf(domain) + WELLNESS_CHECK_IN_DOMAIN_TO_PAGE_NUMBER_OFFSET,
    type: 'number',
    enum: [1, 2, 3, 4, 5, 6],
    buttonType: RadioButtonType.CIRCULAR_WITH_NUMBER,
    minLabel: defineMessage({
      defaultMessage: 'Strongly disagree',
      description:
        'Label that appears under radio buttons of a range of numbers 1 through 6, where 1 indicates strongly disagree',
    }),
    maxLabel: defineMessage({
      defaultMessage: 'Strongly agree',
      description:
        'Label that appears under radio buttons of a range of numbers 1 through 6, where 6 indicates strongly agree',
    }),
  })

  const getFieldUiSchema = (args: { isLastQuestionInDomain?: boolean } = {}): UiMetadata => {
    const { isLastQuestionInDomain } = args
    return {
      'ui:widget': 'radio',
      'ui:options': {
        baseInputStyle: {
          marginBottom: isMobileSized ? (isLastQuestionInDomain ? 80 : 64) : isLastQuestionInDomain ? 48 : 72,
        },
        customTextStyle: { fontSize: 18, fontWeight: 500 },
        labelContainerStyle: { marginBottom: 16 },
      },
    }
  }

  const getEngineRuleConditions = ({
    domain,
    path,
    valency,
  }: {
    domain: WellnessCheckInDomain
    path: string
    valency: WellnessCheckInValency
  }) => {
    const disagreeCondition = {
      all: [
        {
          fact: domain,
          operator: 'greaterThanInclusive',
          value: 1,
          path,
        },
        {
          fact: domain,
          operator: 'lessThanInclusive',
          value: 3,
          path,
        },
      ],
    }
    const agreeCondition = {
      fact: domain,
      operator: 'greaterThanInclusive',
      value: 4,
      path,
    }
    return {
      answeredCondition: {
        fact: domain,
        operator: 'greaterThanInclusive',
        value: 1,
        path,
      },
      lowWellbeingCondition: valency === WellnessCheckInValency.Low ? agreeCondition : disagreeCondition,
      highWellbeingCondition: valency === WellnessCheckInValency.Low ? disagreeCondition : agreeCondition,
    }
  }

  const socialCareMetadata: FormMetadata = {
    schema: {
      type: 'object',
      properties: {
        socialCareSupport: {
          name: 'socialCareSupport',
          type: 'string',
          title: defineMessage({
            defaultMessage: 'Would you also like support with any of these topics?',
            description:
              'Question that appears above a list of checkbox options for user to select whether they need support with social care',
          }),
          mobilePage: WELLNESS_CHECK_IN_SOCIAL_CARE_PAGE_NUMBER,
          enum: Object.keys(SOCIAL_CARE_OPTIONS_MSG),
          enumNames: Object.values(SOCIAL_CARE_OPTIONS_MSG),
          noneOfTheAboveCheckboxId: Object.keys(SOCIAL_CARE_OPTIONS_MSG)[3],
          buttonType: CheckboxButtonType.CHECKBOX_TEXT_OUTLINE,
        },
      },
    },
    uiSchema: {
      'ui:order': ['socialCareSupport'],
      socialCareSupport: {
        'ui:widget': 'checkboxGroup',
        'ui:options': {
          inputContainerStyle: { borderRadius: 32, paddingHorizontal: 24, paddingVertical: 16 },
          customTextStyle: { fontSize: 18, fontWeight: 500 },
          labelContainerStyle: { marginBottom: 16 },
        },
      },
    },
  }

  const wellnessCheckInSchema: FieldSchema = {
    type: 'object',
    properties: {
      howAreYouFeelingToday: {
        mobilePage: 1,
        name: 'howAreYouFeelingToday',
        minimum: 0,
        maximum: 4,
        type: 'integer',
        default: 2,
        minLabel: defineMessage({
          defaultMessage: 'I’m feeling bad',
          description:
            'Label that appears under the left side of a slider input for the user to indicate their current mood',
        }),
        maxLabel: defineMessage({
          defaultMessage: 'I’m feeling great',
          description:
            'Label that appears under the right side of a slider input for the user to indicate their current mood',
        }),
      },
      iFeltDownOrDepressed: {
        name: `${WellnessCheckInDomain.MoodAndEmotions}.${WellnessCheckInFacetGroup.Mood}.depressionAndHopelessness.iFeltDownOrDepressed`,
        title: defineMessage({
          defaultMessage: 'I felt down or depressed',
          description:
            'Text above input field where user can indicate how much they agree or disagree with the statement',
        }),
        valency: WellnessCheckInValency.Low,
        engineRuleConditions: getEngineRuleConditions({
          domain: WellnessCheckInDomain.MoodAndEmotions,
          path: `$.${WellnessCheckInFacetGroup.Mood}.depressionAndHopelessness.iFeltDownOrDepressed`,
          valency: WellnessCheckInValency.Low,
        }),
        ...getFieldSchema({ domain: WellnessCheckInDomain.MoodAndEmotions }),
      },
      iFeltAnxiousOrStressed: {
        name: `${WellnessCheckInDomain.MoodAndEmotions}.${WellnessCheckInFacetGroup.Mood}.anxietyWorryAndStress.iFeltAnxiousOrStressed`,
        title: defineMessage({
          defaultMessage: 'I felt anxious or stressed',
          description:
            'Text above input field where user can indicate how much they agree or disagree with the statement',
        }),
        valency: WellnessCheckInValency.Low,
        engineRuleConditions: getEngineRuleConditions({
          domain: WellnessCheckInDomain.MoodAndEmotions,
          path: `$.${WellnessCheckInFacetGroup.Mood}.anxietyWorryAndStress.iFeltAnxiousOrStressed`,
          valency: WellnessCheckInValency.Low,
        }),
        ...getFieldSchema({ domain: WellnessCheckInDomain.MoodAndEmotions }),
      },
      iWorriedAboutThingsICouldNotControl: {
        name: `${WellnessCheckInDomain.MoodAndEmotions}.${WellnessCheckInFacetGroup.Mood}.anxietyWorryAndStress.iWorriedAboutThingsICouldNotControl`,
        title: defineMessage({
          defaultMessage: 'I worried about things I could not control',
          description:
            'Text above input field where user can indicate how much they agree or disagree with the statement',
        }),
        valency: WellnessCheckInValency.Low,
        engineRuleConditions: getEngineRuleConditions({
          domain: WellnessCheckInDomain.MoodAndEmotions,
          path: `$.${WellnessCheckInFacetGroup.Mood}.anxietyWorryAndStress.iWorriedAboutThingsICouldNotControl`,
          valency: WellnessCheckInValency.Low,
        }),
        ...getFieldSchema({ domain: WellnessCheckInDomain.MoodAndEmotions }),
      },
      iLookedForwardToEachNewDay: {
        name: `${WellnessCheckInDomain.MoodAndEmotions}.${WellnessCheckInFacetGroup.Emotions}.joyfulAndVital.iLookedForwardToEachNewDay`,
        title: defineMessage({
          defaultMessage: 'I looked forward to each new day',
          description:
            'Text above input field where user can indicate how much they agree or disagree with the statement',
        }),
        valency: WellnessCheckInValency.High,
        engineRuleConditions: getEngineRuleConditions({
          domain: WellnessCheckInDomain.MoodAndEmotions,
          path: `$.${WellnessCheckInFacetGroup.Emotions}.joyfulAndVital.iLookedForwardToEachNewDay`,
          valency: WellnessCheckInValency.High,
        }),
        ...getFieldSchema({ domain: WellnessCheckInDomain.MoodAndEmotions }),
      },
      iExperiencedMoodSwings: {
        name: `${WellnessCheckInDomain.MoodAndEmotions}.${WellnessCheckInFacetGroup.Emotions}.emotionalStability.iExperiencedMoodSwings`,
        title: defineMessage({
          defaultMessage: 'I experienced mood swings',
          description:
            'Text above input field where user can indicate how much they agree or disagree with the statement',
        }),
        valency: WellnessCheckInValency.Low,
        engineRuleConditions: getEngineRuleConditions({
          domain: WellnessCheckInDomain.MoodAndEmotions,
          path: `$.${WellnessCheckInFacetGroup.Emotions}.emotionalStability.iExperiencedMoodSwings`,
          valency: WellnessCheckInValency.Low,
        }),
        ...getFieldSchema({ domain: WellnessCheckInDomain.MoodAndEmotions }),
      },
      iGotDiscourageWhenThingsDidntGoMyWay: {
        name: `${WellnessCheckInDomain.ResilienceAndCoping}.${WellnessCheckInFacetGroup.Resilience}.meetsChallengesWithPositiveOutlook.iGotDiscourageWhenThingsDidntGoMyWay`,
        title: defineMessage({
          defaultMessage: "I got discouraged when things didn't go my way",
          description:
            'Text above input field where user can indicate how much they agree or disagree with the statement',
        }),
        valency: WellnessCheckInValency.Low,
        engineRuleConditions: getEngineRuleConditions({
          domain: WellnessCheckInDomain.ResilienceAndCoping,
          path: `$.${WellnessCheckInFacetGroup.Resilience}.meetsChallengesWithPositiveOutlook.iGotDiscourageWhenThingsDidntGoMyWay`,
          valency: WellnessCheckInValency.Low,
        }),
        ...getFieldSchema({ domain: WellnessCheckInDomain.ResilienceAndCoping }),
      },
      iFeltLikeIWasOnMyOwnWhenFacingChallenges: {
        name: `${WellnessCheckInDomain.ResilienceAndCoping}.${WellnessCheckInFacetGroup.Resilience}.feelsSupported.iFeltLikeIWasOnMyOwnWhenFacingChallenges`,
        title: defineMessage({
          defaultMessage: 'I felt like I was on my own when facing challenges',
          description:
            'Text above input field where user can indicate how much they agree or disagree with the statement',
        }),
        valency: WellnessCheckInValency.Low,
        engineRuleConditions: getEngineRuleConditions({
          domain: WellnessCheckInDomain.ResilienceAndCoping,
          path: `$.${WellnessCheckInFacetGroup.Resilience}.feelsSupported.iFeltLikeIWasOnMyOwnWhenFacingChallenges`,
          valency: WellnessCheckInValency.Low,
        }),
        ...getFieldSchema({ domain: WellnessCheckInDomain.ResilienceAndCoping }),
      },
      iOftenAvoidedAProblemBecauseIDidntWantToDealWithIt: {
        name: `${WellnessCheckInDomain.ResilienceAndCoping}.${WellnessCheckInFacetGroup.Coping}.escapingToCope.iOftenAvoidedAProblemBecauseIDidntWantToDealWithIt`,
        title: defineMessage({
          defaultMessage: "I often avoided a problem because I didn't want to deal with it",
          description:
            'Text above input field where user can indicate how much they agree or disagree with the statement',
        }),
        valency: WellnessCheckInValency.Low,
        engineRuleConditions: getEngineRuleConditions({
          domain: WellnessCheckInDomain.ResilienceAndCoping,
          path: `$.${WellnessCheckInFacetGroup.Coping}.escapingToCope.iOftenAvoidedAProblemBecauseIDidntWantToDealWithIt`,
          valency: WellnessCheckInValency.Low,
        }),
        ...getFieldSchema({ domain: WellnessCheckInDomain.ResilienceAndCoping }),
      },
      itWasHardToMakeTimeToBeAGoodFamilyMemberEmployeeAndFriend: {
        name: `${WellnessCheckInDomain.RolesAndRelationships}.${WellnessCheckInFacetGroup.Relationships}.feelsPulledManyDirectionsAndOverloaded.itWasHardToMakeTimeToBeAGoodFamilyMemberEmployeeAndFriend`,
        title: defineMessage({
          defaultMessage: 'It was hard to make time to be a good family member, employee, and friend',
          description:
            'Text above input field where user can indicate how much they agree or disagree with the statement',
        }),
        valency: WellnessCheckInValency.Low,
        engineRuleConditions: getEngineRuleConditions({
          domain: WellnessCheckInDomain.RolesAndRelationships,
          path: `$.${WellnessCheckInFacetGroup.Relationships}.feelsPulledManyDirectionsAndOverloaded.itWasHardToMakeTimeToBeAGoodFamilyMemberEmployeeAndFriend`,
          valency: WellnessCheckInValency.Low,
        }),
        ...getFieldSchema({ domain: WellnessCheckInDomain.RolesAndRelationships }),
      },
      iWasOverwhelmedWithWhatIHadToAccomplishInADay: {
        name: `${WellnessCheckInDomain.RolesAndRelationships}.${WellnessCheckInFacetGroup.Relationships}.feelsPulledManyDirectionsAndOverloaded.iWasOverwhelmedWithWhatIHadToAccomplishInADay`,
        title: defineMessage({
          defaultMessage: 'I was overwhelmed with what I had to accomplish in a day',
          description:
            'Text above input field where user can indicate how much they agree or disagree with the statement',
        }),
        valency: WellnessCheckInValency.Low,
        engineRuleConditions: getEngineRuleConditions({
          domain: WellnessCheckInDomain.RolesAndRelationships,
          path: `$.${WellnessCheckInFacetGroup.Relationships}.feelsPulledManyDirectionsAndOverloaded.iWasOverwhelmedWithWhatIHadToAccomplishInADay`,
          valency: WellnessCheckInValency.Low,
        }),
        ...getFieldSchema({ domain: WellnessCheckInDomain.RolesAndRelationships }),
      },
      myRelationshipsAtWorkWereSatisfying: {
        name: `${WellnessCheckInDomain.RolesAndRelationships}.${WellnessCheckInFacetGroup.Relationships}.workRelationshipsFulfillingSatisfying.myRelationshipsAtWorkWereSatisfying`,
        title: defineMessage({
          defaultMessage: 'My relationships at work were satisfying',
          description:
            'Text above input field where user can indicate how much they agree or disagree with the statement',
        }),
        valency: WellnessCheckInValency.High,
        engineRuleConditions: getEngineRuleConditions({
          domain: WellnessCheckInDomain.RolesAndRelationships,
          path: `$.${WellnessCheckInFacetGroup.Relationships}.workRelationshipsFulfillingSatisfying.myRelationshipsAtWorkWereSatisfying`,
          valency: WellnessCheckInValency.High,
        }),
        ...getFieldSchema({ domain: WellnessCheckInDomain.RolesAndRelationships }),
      },
      iFeltFulfilledInMyCloseRelationships: {
        name: `${WellnessCheckInDomain.RolesAndRelationships}.${WellnessCheckInFacetGroup.Relationships}.closeRelationshipsFulfillingSatisfying.iFeltFulfilledInMyCloseRelationships`,
        title: defineMessage({
          defaultMessage: 'I felt fulfilled in my close relationships',
          description:
            'Text above input field where user can indicate how much they agree or disagree with the statement',
        }),
        valency: WellnessCheckInValency.High,
        engineRuleConditions: getEngineRuleConditions({
          domain: WellnessCheckInDomain.RolesAndRelationships,
          path: `$.${WellnessCheckInFacetGroup.Relationships}.closeRelationshipsFulfillingSatisfying.iFeltFulfilledInMyCloseRelationships`,
          valency: WellnessCheckInValency.High,
        }),
        ...getFieldSchema({ domain: WellnessCheckInDomain.RolesAndRelationships }),
      },
      iDidntGetEnoughPhysicalActivityOrExercise: {
        name: `${WellnessCheckInDomain.PhysicalHealthAndActivity}.${WellnessCheckInFacetGroup.PhysicalActivity}.isPhysicallyActive.iDidntGetEnoughPhysicalActivityOrExercise`,
        title: defineMessage({
          defaultMessage: "I didn't get enough physical activity or exercise",
          description:
            'Text above input field where user can indicate how much they agree or disagree with the statement',
        }),
        valency: WellnessCheckInValency.Low,
        engineRuleConditions: getEngineRuleConditions({
          domain: WellnessCheckInDomain.PhysicalHealthAndActivity,
          path: `$.${WellnessCheckInFacetGroup.PhysicalActivity}.isPhysicallyActive.iDidntGetEnoughPhysicalActivityOrExercise`,
          valency: WellnessCheckInValency.Low,
        }),
        ...getFieldSchema({ domain: WellnessCheckInDomain.PhysicalHealthAndActivity }),
      },
      iOftenFeltRestedWhenIWokeUp: {
        name: `${WellnessCheckInDomain.PhysicalHealthAndActivity}.${WellnessCheckInFacetGroup.Sleep}.experiencesRestfulSleep.iOftenFeltRestedWhenIWokeUp`,
        title: defineMessage({
          defaultMessage: 'I often felt rested when I woke up',
          description:
            'Text above input field where user can indicate how much they agree or disagree with the statement',
        }),
        valency: WellnessCheckInValency.High,
        engineRuleConditions: getEngineRuleConditions({
          domain: WellnessCheckInDomain.PhysicalHealthAndActivity,
          path: `$.${WellnessCheckInFacetGroup.Sleep}.experiencesRestfulSleep.iOftenFeltRestedWhenIWokeUp`,
          valency: WellnessCheckInValency.High,
        }),
        ...getFieldSchema({ domain: WellnessCheckInDomain.PhysicalHealthAndActivity }),
      },
      iFrequentlyHadAnUpsetStomachHeadachesOrPainInMyBackArmsLegsOrJoints: {
        name: `${WellnessCheckInDomain.PhysicalHealthAndActivity}.${WellnessCheckInFacetGroup.Pain}.somaticComplaints.iFrequentlyHadAnUpsetStomachHeadachesOrPainInMyBackArmsLegsOrJoints`,
        title: defineMessage({
          defaultMessage: 'I frequently had an upset stomach, headaches, or pain in my back, arms, legs, or joints',
          description:
            'Text above input field where user can indicate how much they agree or disagree with the statement',
        }),
        valency: WellnessCheckInValency.Low,
        engineRuleConditions: getEngineRuleConditions({
          domain: WellnessCheckInDomain.PhysicalHealthAndActivity,
          path: `$.${WellnessCheckInFacetGroup.Pain}.somaticComplaints.iFrequentlyHadAnUpsetStomachHeadachesOrPainInMyBackArmsLegsOrJoints`,
          valency: WellnessCheckInValency.Low,
        }),
        ...getFieldSchema({ domain: WellnessCheckInDomain.PhysicalHealthAndActivity }),
      },
      iFoundItDifficultToFindTimeToRelaxOrDoThingsIEnjoy: {
        name: `${WellnessCheckInDomain.PhysicalHealthAndActivity}.${WellnessCheckInFacetGroup.PhysicalActivity}.selfCareActivities.iFoundItDifficultToFindTimeToRelaxOrDoThingsIEnjoy`,
        title: defineMessage({
          defaultMessage: 'I found it difficult to find time to relax or do things I enjoy',
          description:
            'Text above input field where user can indicate how much they agree or disagree with the statement',
        }),
        valency: WellnessCheckInValency.Low,
        engineRuleConditions: getEngineRuleConditions({
          domain: WellnessCheckInDomain.PhysicalHealthAndActivity,
          path: `$.${WellnessCheckInFacetGroup.PhysicalActivity}.selfCareActivities.iFoundItDifficultToFindTimeToRelaxOrDoThingsIEnjoy`,
          valency: WellnessCheckInValency.Low,
        }),
        ...getFieldSchema({ domain: WellnessCheckInDomain.PhysicalHealthAndActivity }),
      },
      iFeltLikeMyHouseholdBudgetWasTight: {
        name: `${WellnessCheckInDomain.FinancesAndWork}.${WellnessCheckInFacetGroup.Finance}.householdBudgetAndFinancialPlanning.iFeltLikeMyHouseholdBudgetWasTight`,
        title: defineMessage({
          defaultMessage: 'I felt like my household budget was tight',
          description:
            'Text above input field where user can indicate how much they agree or disagree with the statement',
        }),
        valency: WellnessCheckInValency.Low,
        engineRuleConditions: getEngineRuleConditions({
          domain: WellnessCheckInDomain.FinancesAndWork,
          path: `$.${WellnessCheckInFacetGroup.Finance}.householdBudgetAndFinancialPlanning.iFeltLikeMyHouseholdBudgetWasTight`,
          valency: WellnessCheckInValency.Low,
        }),
        ...getFieldSchema({ domain: WellnessCheckInDomain.FinancesAndWork }),
      },
      iFeltLikeICouldntCatchUpFinancially: {
        name: `${WellnessCheckInDomain.FinancesAndWork}.${WellnessCheckInFacetGroup.Finance}.planningForFinancialFuture.iFeltLikeICouldntCatchUpFinancially`,
        title: defineMessage({
          defaultMessage: "I felt like I couldn't catch up financially",
          description:
            'Text above input field where user can indicate how much they agree or disagree with the statement',
        }),
        valency: WellnessCheckInValency.Low,
        engineRuleConditions: getEngineRuleConditions({
          domain: WellnessCheckInDomain.FinancesAndWork,
          path: `$.${WellnessCheckInFacetGroup.Finance}.planningForFinancialFuture.iFeltLikeICouldntCatchUpFinancially`,
          valency: WellnessCheckInValency.Low,
        }),
        ...getFieldSchema({ domain: WellnessCheckInDomain.FinancesAndWork }),
      },
      iEnjoyedGoingToWorkOnMostDays: {
        name: `${WellnessCheckInDomain.FinancesAndWork}.${WellnessCheckInFacetGroup.Work}.jobIsFulfillingAndEnjoyable.iEnjoyedGoingToWorkOnMostDays`,
        title: defineMessage({
          defaultMessage: 'I enjoyed going to work on most days',
          description:
            'Text above input field where user can indicate how much they agree or disagree with the statement',
        }),
        valency: WellnessCheckInValency.High,
        engineRuleConditions: getEngineRuleConditions({
          domain: WellnessCheckInDomain.FinancesAndWork,
          path: `$.${WellnessCheckInFacetGroup.Work}.jobIsFulfillingAndEnjoyable.iEnjoyedGoingToWorkOnMostDays`,
          valency: WellnessCheckInValency.High,
        }),
        ...getFieldSchema({ domain: WellnessCheckInDomain.FinancesAndWork }),
      },
      iWasEmotionallyExhaustedInRelationToMyWorkRoles: {
        name: `${WellnessCheckInDomain.FinancesAndWork}.${WellnessCheckInFacetGroup.Work}.burnout.iWasEmotionallyExhaustedInRelationToMyWorkRoles`,
        title: defineMessage({
          defaultMessage: 'I was emotionally exhausted in relation to my work roles',
          description:
            'Text above input field where user can indicate how much they agree or disagree with the statement',
        }),
        valency: WellnessCheckInValency.Low,
        engineRuleConditions: getEngineRuleConditions({
          domain: WellnessCheckInDomain.FinancesAndWork,
          path: `$.${WellnessCheckInFacetGroup.Work}.burnout.iWasEmotionallyExhaustedInRelationToMyWorkRoles`,
          valency: WellnessCheckInValency.Low,
        }),
        ...getFieldSchema({ domain: WellnessCheckInDomain.FinancesAndWork }),
      },
      iTriedNewThings: {
        name: `${WellnessCheckInDomain.SpiritualityAndExploration}.${WellnessCheckInFacetGroup.NewExperiences}.openToNewExperiences.iTriedNewThings`,
        title: defineMessage({
          defaultMessage: 'I tried new things',
          description:
            'Text above input field where user can indicate how much they agree or disagree with the statement',
        }),
        valency: WellnessCheckInValency.High,
        engineRuleConditions: getEngineRuleConditions({
          domain: WellnessCheckInDomain.SpiritualityAndExploration,
          path: `$.${WellnessCheckInFacetGroup.NewExperiences}.openToNewExperiences.iTriedNewThings`,
          valency: WellnessCheckInValency.High,
        }),
        ...getFieldSchema({ domain: WellnessCheckInDomain.SpiritualityAndExploration }),
      },
      mySpiritualNeedsWereMet: {
        name: `${WellnessCheckInDomain.SpiritualityAndExploration}.${WellnessCheckInFacetGroup.Spiritual}.spiritualNeedsMet.mySpiritualNeedsWereMet`,
        title: defineMessage({
          defaultMessage: 'My spiritual needs were met',
          description:
            'Text above input field where user can indicate how much they agree or disagree with the statement',
        }),
        valency: WellnessCheckInValency.High,
        engineRuleConditions: getEngineRuleConditions({
          domain: WellnessCheckInDomain.SpiritualityAndExploration,
          path: `$.${WellnessCheckInFacetGroup.Spiritual}.spiritualNeedsMet.mySpiritualNeedsWereMet`,
          valency: WellnessCheckInValency.High,
        }),
        ...getFieldSchema({ domain: WellnessCheckInDomain.SpiritualityAndExploration }),
      },
      iSpentMeaningfulTimeOutsideInNature: {
        name: `${WellnessCheckInDomain.SpiritualityAndExploration}.${WellnessCheckInFacetGroup.Nature}.experiencesNature.iSpentMeaningfulTimeOutsideInNature`,
        title: defineMessage({
          defaultMessage: 'I spent meaningful time outside in nature',
          description:
            'Text above input field where user can indicate how much they agree or disagree with the statement',
        }),
        valency: WellnessCheckInValency.High,
        engineRuleConditions: getEngineRuleConditions({
          domain: WellnessCheckInDomain.SpiritualityAndExploration,
          path: `$.${WellnessCheckInFacetGroup.Nature}.experiencesNature.iSpentMeaningfulTimeOutsideInNature`,
          valency: WellnessCheckInValency.High,
        }),
        ...getFieldSchema({ domain: WellnessCheckInDomain.SpiritualityAndExploration }),
      },
    },
  }

  const wellnessCheckInMetadata: FormMetadata = {
    schema: wellnessCheckInSchema,
    uiSchema: {
      'ui:order': [
        'howAreYouFeelingToday',
        'iFeltDownOrDepressed',
        'iFeltAnxiousOrStressed',
        'iWorriedAboutThingsICouldNotControl',
        'iLookedForwardToEachNewDay',
        'iExperiencedMoodSwings',
        'iGotDiscourageWhenThingsDidntGoMyWay',
        'iFeltLikeIWasOnMyOwnWhenFacingChallenges',
        'iOftenAvoidedAProblemBecauseIDidntWantToDealWithIt',
        'itWasHardToMakeTimeToBeAGoodFamilyMemberEmployeeAndFriend',
        'iWasOverwhelmedWithWhatIHadToAccomplishInADay',
        'myRelationshipsAtWorkWereSatisfying',
        'iFeltFulfilledInMyCloseRelationships',
        'iDidntGetEnoughPhysicalActivityOrExercise',
        'iOftenFeltRestedWhenIWokeUp',
        'iFrequentlyHadAnUpsetStomachHeadachesOrPainInMyBackArmsLegsOrJoints',
        'iFoundItDifficultToFindTimeToRelaxOrDoThingsIEnjoy',
        'iFeltLikeMyHouseholdBudgetWasTight',
        'iFeltLikeICouldntCatchUpFinancially',
        'iEnjoyedGoingToWorkOnMostDays',
        'iWasEmotionallyExhaustedInRelationToMyWorkRoles',
        'iTriedNewThings',
        'mySpiritualNeedsWereMet',
        'iSpentMeaningfulTimeOutsideInNature',
      ],
      howAreYouFeelingToday: { 'ui:widget': 'multicolorSlider' },
      iFeltDownOrDepressed: getFieldUiSchema(),
      iFeltAnxiousOrStressed: getFieldUiSchema(),
      iWorriedAboutThingsICouldNotControl: getFieldUiSchema(),
      iLookedForwardToEachNewDay: getFieldUiSchema(),
      iExperiencedMoodSwings: getFieldUiSchema({ isLastQuestionInDomain: true }),
      iGotDiscourageWhenThingsDidntGoMyWay: getFieldUiSchema(),
      iFeltLikeIWasOnMyOwnWhenFacingChallenges: getFieldUiSchema(),
      iOftenAvoidedAProblemBecauseIDidntWantToDealWithIt: getFieldUiSchema({ isLastQuestionInDomain: true }),
      itWasHardToMakeTimeToBeAGoodFamilyMemberEmployeeAndFriend: getFieldUiSchema(),
      iWasOverwhelmedWithWhatIHadToAccomplishInADay: getFieldUiSchema(),
      myRelationshipsAtWorkWereSatisfying: getFieldUiSchema(),
      iFeltFulfilledInMyCloseRelationships: getFieldUiSchema({ isLastQuestionInDomain: true }),
      iDidntGetEnoughPhysicalActivityOrExercise: getFieldUiSchema(),
      iOftenFeltRestedWhenIWokeUp: getFieldUiSchema(),
      iFrequentlyHadAnUpsetStomachHeadachesOrPainInMyBackArmsLegsOrJoints: getFieldUiSchema(),
      iFoundItDifficultToFindTimeToRelaxOrDoThingsIEnjoy: getFieldUiSchema({ isLastQuestionInDomain: true }),
      iFeltLikeMyHouseholdBudgetWasTight: getFieldUiSchema(),
      iFeltLikeICouldntCatchUpFinancially: getFieldUiSchema(),
      iEnjoyedGoingToWorkOnMostDays: getFieldUiSchema(),
      iWasEmotionallyExhaustedInRelationToMyWorkRoles: getFieldUiSchema({ isLastQuestionInDomain: true }),
      iTriedNewThings: getFieldUiSchema(),
      mySpiritualNeedsWereMet: getFieldUiSchema(),
      iSpentMeaningfulTimeOutsideInNature: getFieldUiSchema({ isLastQuestionInDomain: true }),
    },
  }

  return socialCareEnabled ? extendMetadata(socialCareMetadata, wellnessCheckInMetadata) : wellnessCheckInMetadata
}

export const WELLNESS_CHECK_IN_INITIAL_VALUES: Dict & { socialCareSupport?: Array<string> } = {
  howAreYouFeelingToday: 2,
  [WellnessCheckInDomain.MoodAndEmotions]: {
    [WellnessCheckInFacetGroup.Mood]: {
      depressionAndHopelessness: { iFeltDownOrDepressed: 0 },
      anxietyWorryAndStress: { iFeltAnxiousOrStressed: 0, iWorriedAboutThingsICouldNotControl: 0 },
    },
    [WellnessCheckInFacetGroup.Emotions]: {
      joyfulAndVital: { iLookedForwardToEachNewDay: 0 },
      emotionalStability: { iExperiencedMoodSwings: 0 },
    },
  },
  [WellnessCheckInDomain.ResilienceAndCoping]: {
    [WellnessCheckInFacetGroup.Resilience]: {
      meetsChallengesWithPositiveOutlook: {
        iGotDiscourageWhenThingsDidntGoMyWay: 0,
      },
      feelsSupported: {
        iFeltLikeIWasOnMyOwnWhenFacingChallenges: 0,
      },
    },
    [WellnessCheckInFacetGroup.Coping]: {
      escapingToCope: {
        iOftenAvoidedAProblemBecauseIDidntWantToDealWithIt: 0,
      },
    },
  },
  [WellnessCheckInDomain.RolesAndRelationships]: {
    [WellnessCheckInFacetGroup.Relationships]: {
      feelsPulledManyDirectionsAndOverloaded: {
        itWasHardToMakeTimeToBeAGoodFamilyMemberEmployeeAndFriend: 0,
        iWasOverwhelmedWithWhatIHadToAccomplishInADay: 0,
      },
      workRelationshipsFulfillingSatisfying: {
        myRelationshipsAtWorkWereSatisfying: 0,
      },
      closeRelationshipsFulfillingSatisfying: {
        iFeltFulfilledInMyCloseRelationships: 0,
      },
    },
  },
  [WellnessCheckInDomain.PhysicalHealthAndActivity]: {
    [WellnessCheckInFacetGroup.PhysicalActivity]: {
      isPhysicallyActive: {
        iDidntGetEnoughPhysicalActivityOrExercise: 0,
      },
      selfCareActivities: {
        iFoundItDifficultToFindTimeToRelaxOrDoThingsIEnjoy: 0,
      },
    },
    [WellnessCheckInFacetGroup.Sleep]: {
      experiencesRestfulSleep: {
        iOftenFeltRestedWhenIWokeUp: 0,
      },
    },
    [WellnessCheckInFacetGroup.Pain]: {
      somaticComplaints: {
        iFrequentlyHadAnUpsetStomachHeadachesOrPainInMyBackArmsLegsOrJoints: 0,
      },
    },
  },
  [WellnessCheckInDomain.FinancesAndWork]: {
    [WellnessCheckInFacetGroup.Finance]: {
      householdBudgetAndFinancialPlanning: {
        iFeltLikeMyHouseholdBudgetWasTight: 0,
      },
      planningForFinancialFuture: {
        iFeltLikeICouldntCatchUpFinancially: 0,
      },
    },
    [WellnessCheckInFacetGroup.Work]: {
      jobIsFulfillingAndEnjoyable: {
        iEnjoyedGoingToWorkOnMostDays: 0,
      },
      burnout: {
        iWasEmotionallyExhaustedInRelationToMyWorkRoles: 0,
      },
    },
  },
  [WellnessCheckInDomain.SpiritualityAndExploration]: {
    [WellnessCheckInFacetGroup.NewExperiences]: {
      openToNewExperiences: {
        iTriedNewThings: 0,
      },
    },
    [WellnessCheckInFacetGroup.Spiritual]: {
      spiritualNeedsMet: {
        mySpiritualNeedsWereMet: 0,
      },
    },
    [WellnessCheckInFacetGroup.Nature]: {
      experiencesNature: {
        iSpentMeaningfulTimeOutsideInNature: 0,
      },
    },
  },
  socialCareSupport: [],
}
