import { useCallback } from 'react'

import {
  getSessionLimitProgramCategoryByTreatmentOption,
  ProgramCategory,
  TREATMENT_OPTIONS_TYPE,
} from '@lyrahealth-inc/shared-app-logic'

export type GetIsEAPSessionCountExhaustedOptions = {
  // treatment option can be expectedly undefined if treatment option has not been selected yet during triage
  treatmentOption?: TREATMENT_OPTIONS_TYPE | undefined | null
}

export function useGetIsEAPSessionCountExhaustedShared(
  isProgramLevelSessionLimitEnabled: boolean,
  isTotalEAPSessionCountExhausted: boolean,
  getIsEAPSessionCountExhaustedByProgram: (programCategory: ProgramCategory | undefined) => boolean,
) {
  return useCallback(
    (options?: GetIsEAPSessionCountExhaustedOptions) => {
      const { treatmentOption } = options ?? {}
      if (treatmentOption == null || !isProgramLevelSessionLimitEnabled) {
        // if treatment option is unknown or program-level session limit is disabled,
        // return whether overall session limit is exhausted
        return isTotalEAPSessionCountExhausted
      } else {
        const programCategory = getSessionLimitProgramCategoryByTreatmentOption(treatmentOption)
        const isEAPSessionCountExhaustedByProgram = getIsEAPSessionCountExhaustedByProgram(programCategory)
        return isEAPSessionCountExhaustedByProgram
      }
    },
    [isProgramLevelSessionLimitEnabled, isTotalEAPSessionCountExhausted, getIsEAPSessionCountExhaustedByProgram],
  )
}
