import React from 'react'

import styled from 'styled-components/native'

import { BodyText, Size } from '../../atoms/bodyText/BodyText'
import { BodyTextSize, TextAlign } from '../../styles/typeStyles'
import { tID } from '../../utils/utils'

export interface InlineBannerProps {
  text: React.ReactNode | string
  backgroundColor: string
  textColor: string
  testId?: string
  icon?: React.ReactNode
  trailingLink?: React.ReactElement
  marginBottom?: string
  marginTop?: string
  textSize?: BodyTextSize
  borderRadius?: string
  padding?: string
  textAlign?: TextAlign
}
const Container = styled.View<{
  backgroundColor: string
  marginBottom?: string
  marginTop?: string
  borderRadius?: string
  padding?: string
}>(({ backgroundColor, marginBottom, marginTop, borderRadius, padding }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: marginBottom || '24px',
  marginTop,
  borderRadius: borderRadius || '16px',
  backgroundColor: backgroundColor,
  padding: padding || '12px',
}))

const IconContainer = styled.View({
  alignSelf: 'flex-start',
  width: 24,
  height: 24,
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: 4,
})

const TextContainer = styled.View({
  flex: 1,
})

export const InlineBanner: React.FC<InlineBannerProps> = ({
  text,
  testId,
  backgroundColor,
  textColor,
  trailingLink,
  icon,
  marginBottom,
  marginTop,
  textSize = Size.DEFAULT,
  borderRadius,
  padding,
  textAlign,
}) => (
  <Container
    backgroundColor={backgroundColor}
    marginBottom={marginBottom}
    marginTop={marginTop}
    testID={tID(testId)}
    borderRadius={borderRadius}
    padding={padding}
  >
    {icon && <IconContainer>{icon}</IconContainer>}
    <TextContainer>
      <BodyText size={textSize} color={textColor} wrap text={text} textAlign={textAlign}>
        {trailingLink && <>&nbsp;{trailingLink}</>}
      </BodyText>
    </TextContainer>
  </Container>
)
