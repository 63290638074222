import React, { FunctionComponent, useState } from 'react'
import { FormattedMessage, MessageDescriptor } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import { BodyText, ChevronIconDirection, ChevronV2Icon, PressableOpacity, Subhead } from '../../../atoms'
import { BodyTextSize, getFloatingStyles, SubheadSize } from '../../../styles'
import { tID } from '../../../utils/utils'
import { getPathwayTypes, PATHWAY_ILLUSTRATIONS } from '../../utils'

const PathwayTypes = getPathwayTypes({ isMobileView: false })
export interface PathwayCardProps {
  title: MessageDescriptor
  illustration: PATHWAY_ILLUSTRATIONS
  onPress: () => void
  isMicrositeLookAndFeelUpdateEnabled?: boolean
}

const PathwayCardContainer = styled(PressableOpacity)<{ pressed: boolean }>(({ theme, pressed }) => ({
  alignItems: 'center',
  border: '1px',
  borderStyle: 'solid',
  borderColor: theme.colors.borderDefault,
  flexDirection: 'column',
  backgroundColor: theme.colors.backgroundPrimary,
  flex: 1,
  ...(theme.breakpoints.isMinWidthDesktop && {
    width: '308px',
    padding: `${theme.spacing['48px']} ${theme.spacing['32px']}`,
    borderRadius: '32px',
  }),
  ...(theme.breakpoints.isMinWidthLaptop &&
    !theme.breakpoints.isMinWidthDesktop && {
      width: '232px',
      padding: `${theme.spacing['24px']}  ${theme.spacing['16px']}`,
      borderRadius: '32px',
    }),
  ...(theme.breakpoints.isMobileSized && {
    width: theme.breakpoints.isMinWidthMobileXs ? '156px' : '144px',
    borderRadius: '16px',
    padding: theme.spacing['16px'],
  }),
  ...(theme.breakpoints.isMinWidthTablet &&
    !theme.breakpoints.isMinWidthLaptop && {
      width: '174px',
      borderRadius: '16px',
      padding: theme.spacing['16px'],
    }),
  ...(pressed && {
    backgroundColor: theme.colors.backgroundSecondary,
  }),
}))

const PathwayCardContainerV2 = styled(PressableOpacity)<{
  pressed: boolean
}>(({ theme, pressed }) => ({
  alignItems: 'center',
  border: '1px',
  borderStyle: 'solid',
  borderColor: theme.colors.borderDefault,
  flexDirection: 'column',
  backgroundColor: theme.colors.backgroundPrimary,
  flex: 1,
  ...(theme.breakpoints.isMinWidthDesktopL && {
    width: '255px',
    padding: `${theme.spacing['24px']} ${theme.spacing['16px']}`,
    borderRadius: '32px',
  }),
  ...(theme.breakpoints.isMobileSized && {
    width: theme.breakpoints.isMinWidthMobileXs ? '156px' : '144px',
    borderRadius: '16px',
    padding: theme.spacing['16px'],
  }),
  ...(theme.breakpoints.isMinWidthTablet &&
    !theme.breakpoints.isMinWidthDesktopL && {
      width: '227px',
      borderRadius: '16px',
      padding: theme.spacing['24px'],
    }),
  ...(pressed && {
    backgroundColor: theme.colors.backgroundSecondary,
  }),
  justifyContent: 'space-around',
}))

const SubHeadContainer = styled(Subhead)<{
  isMicrositeLookAndFeelUpdateEnabled?: boolean
}>(({ theme, isMicrositeLookAndFeelUpdateEnabled }) => ({
  marginTop: isMicrositeLookAndFeelUpdateEnabled ? theme.spacing['16px'] : theme.spacing['24px'],
}))

const BodyTextContainer = styled(BodyText)(({ theme }) => ({
  marginTop: theme.spacing['16px'],
  minWidth: '124px',
}))

const ComponentContainer = styled.View<{ size: number }>(({ size }) => ({
  width: `${size}px`,
  height: `${size}px`,
  alignItems: 'center',
  justifyContent: 'center',
}))

export const PathwayCard: FunctionComponent<PathwayCardProps> = ({
  title,
  illustration,
  onPress,
  isMicrositeLookAndFeelUpdateEnabled,
}) => {
  const { Component, testID } = PathwayTypes[illustration]
  const { colors, breakpoints } = useTheme()
  const { isMobileSized, isMinWidthDesktop, isMinWidthDesktopL, isMinWidthLaptop, isMinWidthTablet } = breakpoints
  const [hovered, setHovered] = useState(false)
  const [pressed, setPressed] = useState(false)
  const isTabletSized = isMinWidthTablet && !isMinWidthLaptop

  let size = 64
  if (!isMobileSized) {
    if (isMicrositeLookAndFeelUpdateEnabled) {
      size = isTabletSized || isMinWidthDesktopL ? 128 : 108
    } else {
      size = isTabletSized ? 80 : isMinWidthDesktop ? 158 : 128
    }
  }

  const floatingStyles = getFloatingStyles({
    interactive: true,
    hovered,
    pressed,
    initialHiddenBoxShadow: isMicrositeLookAndFeelUpdateEnabled,
  })

  const Container = isMicrositeLookAndFeelUpdateEnabled ? PathwayCardContainerV2 : PathwayCardContainer

  return (
    <Container
      pressed={pressed}
      testID={tID(`PathwayCard-${testID}`)}
      // Shadows on web only work with StyleSheet.create. Using it with styled-components/native throws an error and breaks the app
      style={floatingStyles.floatingCard}
      onPress={() => {
        onPress()
      }}
      onPressIn={() => {
        setPressed(true)
      }}
      onPressOut={() => {
        setPressed(false)
      }}
      pressedOpacity={1}
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}
    >
      <ComponentContainer size={size}>
        <Component play={hovered} size={size} />
      </ComponentContainer>
      {isMicrositeLookAndFeelUpdateEnabled ? (
        <SubHeadContainer
          isMicrositeLookAndFeelUpdateEnabled={isMicrositeLookAndFeelUpdateEnabled}
          size={isMinWidthDesktopL ? SubheadSize.MEDIUM : SubheadSize.SMALL}
          textAlign='center'
          color={colors.textSecondary}
          text={
            <>
              <FormattedMessage {...title} />{' '}
              <ChevronV2Icon
                fillColor={colors.iconDefault}
                size={isMinWidthDesktopL ? 16 : 14}
                direction={ChevronIconDirection.RIGHT}
              />
            </>
          }
        />
      ) : (
        <>
          {isMobileSized || isTabletSized ? (
            <BodyTextContainer
              size={isTabletSized ? BodyTextSize.DEFAULT : BodyTextSize.SMALL}
              textAlign='center'
              color={colors.textSecondary}
              text={<FormattedMessage {...title} />}
            />
          ) : (
            <SubHeadContainer
              textAlign='center'
              size={isMinWidthDesktopL ? SubheadSize.MEDIUM : SubheadSize.SMALL}
              color={colors.textSecondary}
              text={<FormattedMessage {...title} />}
            />
          )}
        </>
      )}
    </Container>
  )
}
