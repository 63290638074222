import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'

import { SET_CUSTOMER_GOALS } from './goalsActions'

const $$initialState = fromJS({
  goals: {},
})

const goalsDataReducer = ($$state: $TSFixMe, action: $TSFixMe) => {
  if (action.data.length > 0) {
    const episode_id = action.data[0].episode_id
    $$state = $$state.setIn(['goals', episode_id], action.data)
  }
  return $$state
}

export default createReducer($$initialState, {
  [SET_CUSTOMER_GOALS]: goalsDataReducer,
})
