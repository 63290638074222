import availabilitiesCoaching from './availabilitiesCoaching'
import availabilitiesTherapy from './availabilitiesTherapy'
import genders from './gender'
import lgbtqia from './lgbtqia'
import meetingSettingCoaching from './meetingSettingCoaching'
import meetingSettingTherapy from './meetingSettingTherapy'
import raceEthnicityCoaching from './raceEthnicityCoaching'
import raceEthnicityTherapy from './raceEthnicityTherapy'
import religion from './religion'
import veterans from './veteran'

// The active set of preferences we currently surface across all MP workflows
export const activeMemberPreferenceListOptions = [
  genders,
  raceEthnicityCoaching,
  raceEthnicityTherapy,
  lgbtqia,
  religion,
  veterans,
  availabilitiesCoaching,
  availabilitiesTherapy,
  meetingSettingTherapy,
  meetingSettingCoaching,
]
