import {
  COMMON_MIXPANEL_PROPERTIES,
  REGISTRATION_ENTRY_POINTS,
  REGISTRATION_EVENTS,
} from '@lyrahealth-inc/shared-app-logic'

import * as MixpanelActions from '../../../data/mixpanel/index'

export const dispatchMixpanelForEmailResend = (entryPoints?: {
  isParentLedTeen?: boolean
  isFamilyHubTeen?: boolean
  isFamilyHubAdult?: boolean
  isAutoEnrollment?: boolean
  isWellnessCheckIn?: boolean
}) => {
  let entryPoint: string

  if (entryPoints) {
    const { isParentLedTeen, isFamilyHubTeen, isFamilyHubAdult, isAutoEnrollment, isWellnessCheckIn } = entryPoints

    if (isParentLedTeen) {
      entryPoint = REGISTRATION_ENTRY_POINTS.PARENT_LED_LCT_TEEN_BOOKING
    } else if (isFamilyHubTeen) {
      entryPoint = REGISTRATION_ENTRY_POINTS.FAMILY_HUB_TEEN_INVITATION
    } else if (isFamilyHubAdult) {
      entryPoint = REGISTRATION_ENTRY_POINTS.FAMILY_HUB_ADULT_INVITATION
    } else if (isAutoEnrollment) {
      entryPoint = REGISTRATION_ENTRY_POINTS.AUTO_ENROLLMENT_REGISTRATION
    } else if (isWellnessCheckIn) {
      entryPoint = REGISTRATION_ENTRY_POINTS.WELLNESS_CHECK_IN
    }
  }

  return (dispatch: $TSFixMe) => {
    dispatch(
      MixpanelActions.trackEventWithObj({
        event: REGISTRATION_EVENTS.RESEND_EMAIL_VERIFICATION,
        ...(entryPoint && {
          [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: entryPoint,
        }),
      }),
    )
  }
}

export const dispatchMixpanelForPageLoad = (entryPoints?: {
  isParentLedTeen?: boolean
  isFamilyHubTeen?: boolean
  isFamilyHubAdult?: boolean
  isAutoEnrollment?: boolean
  isWellnessCheckIn?: boolean
}) => {
  let entryPoint: string

  if (entryPoints) {
    const { isParentLedTeen, isFamilyHubTeen, isFamilyHubAdult, isAutoEnrollment, isWellnessCheckIn } = entryPoints

    if (isParentLedTeen) {
      entryPoint = REGISTRATION_ENTRY_POINTS.PARENT_LED_LCT_TEEN_BOOKING
    } else if (isFamilyHubTeen) {
      entryPoint = REGISTRATION_ENTRY_POINTS.FAMILY_HUB_TEEN_INVITATION
    } else if (isFamilyHubAdult) {
      entryPoint = REGISTRATION_ENTRY_POINTS.FAMILY_HUB_ADULT_INVITATION
    } else if (isAutoEnrollment) {
      entryPoint = REGISTRATION_ENTRY_POINTS.AUTO_ENROLLMENT_REGISTRATION
    } else if (isWellnessCheckIn) {
      entryPoint = REGISTRATION_ENTRY_POINTS.WELLNESS_CHECK_IN
    }
  }

  return (dispatch: $TSFixMe) => {
    dispatch(
      MixpanelActions.trackEventWithObj({
        event: REGISTRATION_EVENTS.VIEW_EMAIL_VERIFICATION_PAGE,
        ...(entryPoint && {
          [COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT]: entryPoint,
        }),
      }),
    )
  }
}
