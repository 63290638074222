// @intl project:microsite

import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'

import classnames from 'classnames'

import { getLegalNoticeLink, useLyraIntl } from '@lyrahealth-inc/shared-app-logic'

import { CoverageNotice } from './CoverageNotice'
import styles from './footer.module.scss'
import { getShouldShowCookieSettings } from '../../../data/appGlobals/appGlobalsSelectors'
import {
  getIsCustomerCareTeam,
  getLegalNotice,
  getShouldDisplayCareTeamContactInfo,
} from '../../../data/customer/customerSelectors'
import { getIsUserInternational, getUser } from '../../../data/user/userSelectors'
import { CMS_MICROSITE_ROUTES } from '../../../features/onboard/data/page-navigation/location-actions'
import { isPathInAutoEnrollmentFlow } from '../../../features/register/data/registerUtils'
import { useProviderRequestPage } from '../../hooks/useProviderRequestPage'
import { useShouldHideFloatingFooter } from '../../hooks/useShouldHideFloatingFooter'
import useUpdatedSchedulingPage from '../../hooks/useUpdatedSchedulingPage'
import { hasRole } from '../../utils/userUtils'
import { CoverageTermsContent } from '../modals/legalNotices/CoverageTermsContent'

type Props = {
  isUserSignedIn: boolean
  legalNotice: React.ReactNode
  displayingFloatingFooter: boolean
  isUserInternational: boolean
  shouldShowCookieSettings: boolean
  shouldUnsetBackgroundStyle?: boolean
  isCareTeamPage?: boolean
}

const LegalFooter = ({
  isUserSignedIn,
  legalNotice,
  displayingFloatingFooter,
  isUserInternational,
  shouldShowCookieSettings,
  shouldUnsetBackgroundStyle,
  isCareTeamPage,
}: Props) => {
  const { activeLanguage } = useLyraIntl()
  const { pathname } = useLocation()
  const isFloatingFooterHidden = useShouldHideFloatingFooter()
  const isOnUpdatedBookingPage = useUpdatedSchedulingPage(pathname)
  const isProviderRequestPage = useProviderRequestPage(pathname)
  const isOnCmsMicrosite = CMS_MICROSITE_ROUTES.some((path) => pathname.startsWith(path))
  const isPathInWellnessCheckIn = pathname.startsWith('/secure/wellness-check-in/results')

  const shouldHideFooter = isProviderRequestPage || isPathInAutoEnrollmentFlow(pathname) || isPathInWellnessCheckIn

  if (shouldHideFooter) {
    return null
  }
  return (
    <div
      className={classnames(styles.footer, {
        [styles['more-bottom']]: displayingFloatingFooter && !isFloatingFooterHidden,
        [styles.bookingPage]: isOnUpdatedBookingPage,
        [styles.unsetBackgroundStyle]: shouldUnsetBackgroundStyle,
        [styles.cmsMicrosite]: isOnCmsMicrosite,
      })}
      data-test-id='legal-footer'
    >
      <div
        className={classnames(styles.inner, {
          [styles.carePage]: isCareTeamPage,
          [styles.cmsMicrositeInner]: isOnCmsMicrosite,
        })}
      >
        {isUserSignedIn ? (
          []
        ) : (
          /* eslint-disable formatjs/no-literal-string-in-jsx */
          <div className={classnames(styles['copyright-contact'])}>
            &copy;{new Date().getFullYear()}&nbsp;Lyra&nbsp;Health,&nbsp;INC
            <i>&nbsp;&bull;&nbsp;</i>
            270 EAST LN, BURLINGAME, CA 94010
          </div>
          /* eslint-enable formatjs/no-literal-string-in-jsx */
        )}
        <ul className={classnames(styles['footer-links'])}>
          <li>
            <CoverageTermsContent legalLanguage={isUserSignedIn ? legalNotice : <CoverageNotice signedOut={true} />} />
          </li>
          {!isUserInternational && (
            <li>
              <a href={getLegalNoticeLink(activeLanguage, 'HIPAA_NOTICE')} target='_blank' rel='noreferrer'>
                <FormattedMessage
                  defaultMessage='HIPAA Notice'
                  description='A short link to the HIPAA Notice, shown in page footer'
                />
              </a>
            </li>
          )}
          <li>
            <a href={getLegalNoticeLink(activeLanguage, 'PRIVACY_POLICY')} target='_blank' rel='noreferrer'>
              <FormattedMessage
                defaultMessage='Privacy Policy'
                description='A short link to the Privacy Policy, shown in page footer'
              />
            </a>
          </li>
          <li>
            <a href={getLegalNoticeLink(activeLanguage, 'TERMS_OF_USE')} target='_blank' rel='noreferrer'>
              <FormattedMessage
                defaultMessage='Terms of Use'
                description='A short link to the Terms of Use, shown in page footer'
              />
            </a>
          </li>
          <li>
            <a href='https://www.lyrahealth.com/accessibility/' target='_blank' rel='noreferrer'>
              <FormattedMessage
                defaultMessage='Accessibility'
                description='A short link to Accessibility info, shown in page footer'
              />
            </a>
          </li>
          {shouldShowCookieSettings && (
            <li>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className='ot-sdk-show-settings'>
                {/* Hardcoded class name from OneTrust */}
                <FormattedMessage
                  defaultMessage='Cookie Settings'
                  description="A link to customize which cookies are tracked on the user's computer"
                />
              </a>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

const mapStateToProps = (state: $TSFixMe) => {
  return {
    isUserSignedIn: hasRole(getUser(state), 'ROLE_usr'),
    legalNotice: getLegalNotice(state),
    displayingFloatingFooter: getShouldDisplayCareTeamContactInfo(state),
    isUserInternational: getIsUserInternational(state),
    shouldShowCookieSettings: getShouldShowCookieSettings(state),
    isCareTeamPage: getIsCustomerCareTeam(state),
  }
}

export default connect(mapStateToProps)(LegalFooter)
