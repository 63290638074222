import { IntlShape } from 'react-intl'

import { addDays, isBefore, max } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { CountryCode, parsePhoneNumberFromString as parsePhoneNumber } from 'libphonenumber-js'
import {
  cloneDeep,
  get,
  includes,
  isArray,
  isBoolean,
  isEmpty,
  isEqual,
  isFunction,
  isNil,
  isNumber,
  isString,
  merge,
  set,
} from 'lodash-es'
import moment from 'moment-timezone'

import { customContentAttributesPhase1, customContentAttributesPhase2 } from './customContentAttributes'
import {
  ActivityResponseData,
  AssessmentStages,
  AssignmentStatuses,
  AssignmentStatusesLabel,
  VideoLesson,
} from './types'
import {
  ActivityGroupType,
  Appointment,
  Assignment,
  AssignmentGroup,
  AssignmentResponse,
  ClientListClientObject,
  Condition,
  EpisodeContents,
  FieldSchema,
  FieldValue,
  FormMetadata,
  LessonContent,
  NoteMetadata,
  Provider,
  Response,
  SchemaProperties,
  UiMetadata,
} from '../../models'
import { getAppointmentDateTimeObject } from '../appointments/utils'
import {
  characterLimit,
  hasValue,
  isBooleanTrue,
  isPositiveInteger,
  requiredField as isRequired,
  isValid5DigitZipCode,
  isValidAge,
  isValidAgeCustomErrors,
  isValidCustomerCode,
  isValidEmail,
  isValidFirstName,
  validInternationalPhoneNumber as isValidInternationalPhoneNumber,
  isValidLastName,
  isValidPassword,
  isValidPastDate,
  isValidPastYear,
  validPhoneNumber as isValidPhoneNumber,
  maxValue,
  rangeHasValidValue,
} from '../common/utils/validationUtils'
import { getProviderSessionCounts } from '../providers/utils'

export function countConditionalPages(schema: FieldSchema, answers: ActivityResponseData | Dict) {
  const fields = schema?.properties as FieldSchema

  // RunTime: O(fields.length)
  const filteredSchema = Object.keys(fields).reduce((acc: any, key) => {
    const field = fields[key]
    if (field?.condition?.fullPageIsConditional) {
      acc.push(field)
    }
    return acc
  }, [])

  const result = { totalCount: 0 }

  // Prevent anymore computations
  if (!filteredSchema.length) return result

  let totalCount = 0

  // RunTime: O(answers.length)
  // Initialize result object with answers keys
  for (const key in answers) {
    if (answers.hasOwnProperty(key)) {
      result[key] = 0
    }
  }

  // RunTime: O(filteredSchema.length)
  // Accumulate counts
  for (let i = 0; i < filteredSchema.length; i++) {
    const item = filteredSchema[i]
    const { parentField, parentValue } = item.condition

    if (answers.hasOwnProperty(parentField)) {
      const value = answers[parentField]
      if (!parentValue.includes(value)) {
        result[parentField]++
        totalCount++
      }
    }
  }

  result.totalCount = totalCount
  return result
}

/**
 * Determines if the activity/lesson is in the current activities.
 * Note: assessments/lessons with session_period 0s
 * do not follow session flow but have to be put in the past activities
 * when they are completed.
 * @param sessionPeriod Indicates what session period the activity has been assigned on
 * @param status Indicates the status of the activity
 * @param sessionCount Indicates the current session number for the client
 * @return boolean
 */

export const isActivityCurrentPeriod = (sessionPeriod: number, status: string, sessionCount: number) =>
  (sessionPeriod === 0 && status !== AssignmentStatuses.completed) ||
  (sessionPeriod === 0 && sessionCount === 1) ||
  sessionPeriod === sessionCount

/**
 * @param userTimeZone
 * @param postCareAssessment
 * @returns if the post assessment is not completed in two weeks return true
 */

export const isPostCareAssessmentExpired = (userTimeZone: string, postCareAssessment: Assignment) => {
  if (postCareAssessment?.status !== AssignmentStatuses.completed) {
    const todaysDate = new Date()
    const createdDate = new Date(postCareAssessment.create_date)
    const dateOfExpiration = addDays(zonedTimeToUtc(createdDate, userTimeZone), 14)
    return isBefore(dateOfExpiration, todaysDate)
  }
  return false
}

/**
 * @param formatMessage
 * @param videos
 * @returns returns the title of cta in the lesson overview
 */

export const getCTATitle = (formatMessage: IntlShape['formatMessage'], videos: VideoLesson[]) => {
  if (videos.filter((video) => video.completionStatus === 'new').length !== videos.length) {
    return formatMessage({
      defaultMessage: 'Continue',
      description: 'title of the button to continue video lesson',
    })
  }
  return formatMessage({
    defaultMessage: 'Start lesson',
    description: 'title of the button to start video lesson',
  })
}

/**
 *
 * @param videos
 * @returns returns the first index of incomplete videos
 */

export const getFirstIndexOfIncompleteVideo = (videos: VideoLesson[]) =>
  videos.findIndex((video: VideoLesson) => video.completionStatus !== 'completed')

/**
 *
 * @param videos
 * @returns returns to see if review activity is available. would return true if every video is completed
 */

export const getReviewAvailability = (videos: VideoLesson[]): boolean =>
  videos.filter((video) => video.completionStatus === 'completed').length === videos.length

export const isAssessmentSummaryStage = (assignment: Assignment): boolean => {
  return (
    assignment?.content?.group == 'assessment' &&
    [
      AssessmentStages.initial,
      AssessmentStages.ongoing,
      AssessmentStages.pre_session,
      AssessmentStages.post_session,
    ].includes((assignment?.content_meta_data?.stage || '') as AssessmentStages)
  )
}

/*
  return response with preferred name
*/
export const isWithPreferredName = (
  isPreferredNameEnabled: boolean,
  preferredFirstName: string | null | undefined,
  preferredLastName: string | null | undefined,
  response?: Response,
  title?: string,
): Response | undefined => {
  if (isPreferredNameEnabled && title?.toLocaleLowerCase().includes('intake')) {
    if (isEmpty(response) && !preferredFirstName && !preferredLastName) return
    const updatedResponse = { ...(response || {}) }

    if (preferredFirstName) {
      updatedResponse.newPreferredFirstName = preferredFirstName
    } else {
      delete updatedResponse.newPreferredFirstName
    }

    if (preferredLastName) {
      updatedResponse.newPreferredLastName = preferredLastName
    } else {
      delete updatedResponse.newPreferredLastName
    }

    return updatedResponse
  }

  return response
}

// get the non-introductory thumbnail if available
export const getThumbnail = (LessonContent: LessonContent[]): string | undefined => {
  if (
    LessonContent &&
    (LessonContent[0]?.name?.includes('introTo') || LessonContent[0]?.name?.includes('introductionTo')) &&
    LessonContent[1]?.meta_data?.thumbnail
  ) {
    return LessonContent[1].meta_data.thumbnail
  }
  return LessonContent[0]?.meta_data?.thumbnail
}

/**
 * Determine if a field should be displayed based on the value of its parent field
 */
export const isFieldDisplayed = ({
  condition,
  formValues,
  hidden,
  formFields,
  name = '',
}: IsFieldDisplayedParams): boolean => {
  if (hidden) {
    return false
  }
  if (isNil(condition)) {
    return true
  }
  if (condition.conditionType && condition.items) {
    switch (condition.conditionType) {
      case 'OR':
        return condition.items?.some((condition) => isFieldDisplayed({ condition, formValues, formFields, name }))
      case 'AND':
        return condition.items?.every((condition) => isFieldDisplayed({ condition, formValues, formFields, name }))
      case 'SUM':
        /* If the sum of values for the selected fields is greater than or equal to the threshold value
         * we will not display the field else it will be displayed
         */
        if (condition?.operator === 'LTE') {
          return condition.threshold
            ? condition.items?.reduce((prevValue, currentValue) => {
                const fieldValue = currentValue?.parentField && get(formValues, currentValue.parentField)
                if (isNumber(fieldValue)) {
                  return prevValue + fieldValue
                }
                return Number.MAX_SAFE_INTEGER
              }, 0) <= condition.threshold
            : false
        } else {
          return condition.threshold
            ? condition.items?.reduce((prevValue, currentValue) => {
                const fieldValue = currentValue?.parentField && get(formValues, currentValue.parentField)
                if (isNumber(fieldValue)) {
                  return prevValue + fieldValue
                }
                return Number.MIN_SAFE_INTEGER
              }, 0) >= condition.threshold
            : false
        }
    }
  }

  const parentFieldCondition =
    formFields && condition.parentField
      ? getFieldSchema({ name: condition.parentField, fields: formFields })?.condition
      : undefined
  const parentFieldParentFields = parentFieldCondition?.items
    ? parentFieldCondition.items.map((condition) => condition.parentField)
    : [parentFieldCondition?.parentField]
  const areFieldsInterDependent = parentFieldParentFields.includes(name)
  if (
    condition?.checkVisibility !== false &&
    !areFieldsInterDependent &&
    !isFieldDisplayed({ condition: parentFieldCondition, formValues, formFields, name: condition.parentField ?? '' })
  ) {
    return false
  }
  if (!isNil(condition.parentRegex) && !isNil(condition.parentValue)) {
    throw new Error('You cannot specify both "parentRegex" and "parentValue". it needs to be one or the other.')
  }
  const fieldValue = condition.parentField && get(formValues, condition.parentField)

  if (condition.parentRegex) {
    if (!isString(fieldValue)) {
      return false
    }
    const regex = new RegExp(condition.parentRegex.pattern, condition.parentRegex.flag)
    return regex.test(fieldValue)
  }
  if (isArray(condition.parentValue)) {
    const match = condition.match ?? 'oneOf'
    switch (match) {
      case 'oneOf':
        return condition.parentValue.some((val) => {
          if (isString(fieldValue) || isNumber(fieldValue) || isBoolean(fieldValue)) {
            return fieldValue === val
          } else if (isArray(fieldValue)) {
            // @ts-expect-error TS(2345): Argument of type 'FieldValue' is not assignable to... Remove this comment to see the full error message
            return fieldValue.includes(val)
          } else {
            return false
          }
        })
      case 'isNot':
        return (
          condition.parentValue.findIndex((val) => {
            if (isString(fieldValue) || isNumber(fieldValue) || isBoolean(fieldValue)) {
              return fieldValue === val
            } else if (isArray(fieldValue)) {
              // @ts-expect-error TS(2345): Argument of type 'FieldValue' is not assignable to... Remove this comment to see the full error message
              return fieldValue.includes(val)
            } else {
              return false
            }
          }) === -1
        )
      case 'exact':
        return isEqual(fieldValue, condition.parentValue)
    }
  } else {
    return isEqual(fieldValue, condition.parentValue)
  }
}

export const isHiddenByPagination = ({
  mobilePage,
  currentPage,
  singlePageContent,
  totalPages,
}: IsHiddenByPaginationParams) => {
  if (isNumber(currentPage) && isNumber(totalPages) && !singlePageContent) {
    if (!isNil(mobilePage)) {
      return mobilePage > currentPage
    } else {
      // If mobile page is undefined then place it on a new page at the end of the activity
      return currentPage < totalPages
    }
  }
  return false
}

export const getVisibleFields = ({ uiSchema, schema, values }: GetVisibleFieldsParams) => {
  return uiSchema?.['ui:order']?.filter((fieldName: string) => {
    const field = schema?.properties?.[fieldName]
    return isFieldDisplayed({
      condition: field?.condition,
      formValues: values || {},
      formFields: schema?.properties,
      name: field?.name,
    })
  })
}

export const validationRules = {
  isRequired,
  characterLimit,
  isValidPastDate,
  isValidPhoneNumber,
  isValidInternationalPhoneNumber,
  isValidEmail,
  isPositiveInteger,
  isValidPastYear,
  hasValue,
  maxValue,
  isValidAge,
  isValidAgeCustomErrors,
  isValid5DigitZipCode,
  isValidFirstName,
  isValidLastName,
  isBooleanTrue,
  isValidPassword,
  isValidCustomerCode,
  rangeHasValidValue,
}

/**
 * Validate form and filter out undisplayed fields
 */
export const validateForm = ({
  fields,
  values,
  errors = {},
  pagination = false,
  currentPage = 0,
  totalPages,
  singlePageContent,
  formatMessage,
  disableIsRequiredFormValidation,
}: ValidateFormParams): Dict => {
  if (!fields) {
    return errors
  }
  Object.entries(fields).forEach(([, { name, items, condition, validation, mobilePage, show, customErrorMessage }]) => {
    if (items) {
      const fields = items.reduce(
        (obj: FieldSchema, item: Dict) => ({
          [item.name as string]: { ...item, mobilePage, show: show || item.show },
          ...obj,
        }),
        {},
      )
      return validateForm({ fields, values, errors, currentPage, pagination, totalPages, formatMessage })
    }

    const hiddenByPagination = pagination
      ? isHiddenByPagination({ mobilePage, currentPage, singlePageContent, totalPages })
      : false
    const hiddenByShowProp = show === false
    if (
      !validation ||
      !isFieldDisplayed({
        condition,
        formValues: values,
        formFields: fields,
        hidden: hiddenByPagination || hiddenByShowProp,
        name,
      })
    ) {
      return
    }
    return Object.entries(validation).forEach(([key, validationValue]) => {
      if ((disableIsRequiredFormValidation ? key !== 'isRequired' : true) && isFunction(validationRules[key])) {
        errors = merge(
          errors,
          validationRules[key](get(values, name), name, validationValue, formatMessage, customErrorMessage),
        )
      }
    })
  })
  return errors
}

/**
 * Transforms nested objects into a flat object with dot notation.
 * For example: { foo: { bar: 1 }} would become { 'foo.bar': 1}
 */
export const flattenObject = (obj = {}, prefix = ''): Dict => {
  obj = obj ?? {}
  return Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? prefix + '.' : ''
    if (typeof obj[k] === 'object' && !Array.isArray(obj[k])) {
      Object.assign(acc, flattenObject(obj[k], pre + k))
    } else {
      acc[pre + k] = obj[k]
    }
    return acc
  }, {})
}

/**
 *  Get a specific field's metada from its name
 */
export const getFieldSchema = ({ name, fields, fieldSchema = [] }: GetFieldSchemaParams): FieldSchema | undefined => {
  Object.entries(fields).forEach(([fieldName, schema]) => {
    if (fieldName === name) {
      fieldSchema.push(schema)
    } else if (schema.items) {
      // Transform `items` array to an object
      const subFields = schema.items.reduce(
        (obj: Dict, item: FieldSchema) => ({ [item.name as string]: item, ...obj }),
        {},
      ) as SchemaProperties
      getFieldSchema({ name, fields: subFields, fieldSchema })
    }
  })
  return fieldSchema[0]
}

/**
 * Given a note get all section headers
 */
export const getSectionHeaders = (
  note: NoteMetadata,
  fields: SchemaProperties,
  noteValues: {},
  headers: Partial<FieldSchema>[] = [],
) => {
  const uiSchemaOrder = note?.meta_data?.uiSchema?.['ui:order'] as unknown as string[]

  uiSchemaOrder &&
    uiSchemaOrder.forEach((field) => {
      if (fields[field]?.sectionHeader) {
        const fieldSchema = getFieldSchema({ name: field, fields })
        if (isFieldDisplayed({ condition: fieldSchema?.condition, formValues: { ...noteValues } })) {
          return headers.push({ ...fields[field], name: field })
        } else {
          return
        }
      } else if (fields[field]?.items) {
        // Transform `items` array to an object
        const subFields = fields[field]?.items?.reduce((obj, item) => ({ [item.name as string]: item, ...obj }), {})
        return subFields ? getSectionHeaders(note, subFields as SchemaProperties, noteValues, headers) : []
      } else {
        return []
      }
    })
  return headers
}

/**
 * Given a note get all AI enabled fields
 */
export const getAIEnabledFields = (
  note: NoteMetadata,
  fields: SchemaProperties,
  noteValues: {},
  aiEnabledFields: Partial<FieldSchema>[] = [],
) => {
  const uiSchemaOrder = note?.meta_data?.uiSchema?.['ui:order'] as unknown as string[]

  uiSchemaOrder &&
    uiSchemaOrder.forEach((field) => {
      if (!fields[field]?.sectionHeader && fields[field]?.aiPromptEnabled) {
        const fieldSchema = getFieldSchema({ name: field, fields })
        if (isFieldDisplayed({ condition: fieldSchema?.condition, formValues: { ...noteValues } })) {
          return aiEnabledFields.push({ ...fields[field], name: field })
        } else {
          return
        }
      } else {
        return []
      }
    })
  return aiEnabledFields
}

/**
 *  Extend metadata
 */
export const extendMetadata = (content1: FormMetadata, content2: FormMetadata) => {
  const content = cloneDeep(content1)
  content.schema.properties = {
    ...content1.schema.properties,
    ...content2.schema.properties,
  }
  content.uiSchema = { ...content1.uiSchema, ...content2.uiSchema }
  content.uiSchema['ui:order'] = [...(content1.uiSchema['ui:order'] ?? []), ...(content2.uiSchema['ui:order'] ?? [])]

  content.initialValues = { ...(content1.initialValues ?? {}), ...(content2.initialValues ?? {}) }
  return content
}

/**
 *  Return a new values object with not visible fields filtered out
 */
export const removeHiddenFields = ({
  values,
  fields,
  externalValues,
}: RemoveHiddenFieldsParams): { [key: string]: FieldValue } => {
  if (fields) {
    const fieldNames = Object.keys(flattenObject(values))
    fieldNames.forEach((fieldName) => {
      const fieldSchema = getFieldSchema({ name: fieldName, fields })
      if (fieldSchema) {
        if (!isFieldDisplayed({ condition: fieldSchema.condition, formValues: { ...values, ...externalValues } })) {
          set(values, fieldName, undefined)
        }
      }
    })
  }
  return values
}

export const convertToInternationalFormat = (fieldValue: string, countryCode: [string]) => {
  if (!isEmpty(fieldValue) && !isEmpty(countryCode)) {
    const libPhoneNumber = parsePhoneNumber(fieldValue as string, countryCode[0] as CountryCode)
    return libPhoneNumber?.number ?? fieldValue
  }
  return fieldValue
}

export const convertToNationalFormat = (storedValue: string, countryCode: [string]) => {
  if (!isEmpty(storedValue) && typeof storedValue === 'string' && !isEmpty(countryCode)) {
    const libPhoneNumber = parsePhoneNumber(storedValue as string, countryCode[0] as CountryCode)
    return libPhoneNumber?.nationalNumber ?? storedValue
  }
  return storedValue
}

export const getInternationallyFormattedNumber = (phoneNumber: string, clientCountry: string | undefined) => {
  if (!isEmpty(phoneNumber) && typeof phoneNumber === 'string') {
    return parsePhoneNumber(phoneNumber as string, clientCountry as CountryCode)?.formatInternational() || ''
  }
  return ''
}

export const parseFunctions = {
  convertToInternationalFormat,
}

export const formatFunctions = {
  convertToNationalFormat,
}

/**
 *  Return the highest page number from response data
 */
export const getLastPageFromResponse = ({ response, schema }: getLastPageFromResponseParams): number => {
  if (isNil(response) || isNil(schema)) {
    return 0
  }
  const properties = schema.properties
  if (isEmpty(response) || !properties) {
    return 0
  }
  const pageNumbers = Object.keys(response).map((fieldName) => {
    const fieldSchema = getFieldSchema({ name: fieldName, fields: properties })
    if (!isNil(fieldSchema?.mobilePage)) {
      return fieldSchema?.mobilePage
    } else {
      // If fieldSchema does not have mobile page check to see if it's parent does
      const parentArrayKey = Object.keys(properties).find((propertyName) =>
        properties[propertyName]?.items?.some((item?: FieldSchema) => item?.name === fieldSchema?.name),
      )
      return parentArrayKey ? properties[parentArrayKey]?.mobilePage : 0
    }
  })
  const filterPageNumbers = pageNumbers.filter((element) => element !== undefined) as number[]
  return Math.max(...filterPageNumbers)
}

/**
 * Helper function to determine if the To-Do badge should be shown for current assignments
 */
export const shouldShowToDo = ({ group, hasCompletedResponse, status }: shouldShowToDoParams) => {
  switch (group) {
    case AssignmentGroup.ASSESSMENT:
      return !includes([AssignmentStatuses.completed, AssignmentStatuses.draft, AssignmentStatuses.missed], status)
    case AssignmentGroup.EXERCISE:
      return !hasCompletedResponse
    default:
      return status !== AssignmentStatuses.completed
  }
}

export const isAssignmentHidden = ({
  currentAssignment,
  assignments,
  isCareOnboardingPhase2Enabled,
}: {
  currentAssignment?: Assignment
  assignments?: Assignment[]
  isCareOnboardingPhase2Enabled?: boolean
}): boolean => {
  if (isNil(currentAssignment) || isNil(assignments)) {
    return false
  }

  const customContentAttributes = isCareOnboardingPhase2Enabled
    ? customContentAttributesPhase2
    : customContentAttributesPhase1

  const shouldBeHidden = customContentAttributes[currentAssignment.content.name]?.hideInClientActivities

  if (shouldBeHidden) {
    const shownParentName = customContentAttributes[currentAssignment.content.name]?.shownParentActivity
    const shownParentActivity = assignments.find((assignment) => {
      return (
        assignment.content.name === shownParentName &&
        assignment.episode_id === currentAssignment.episode_id &&
        assignment.status !== AssignmentStatuses.missed
      )
    })
    return !isNil(shownParentActivity)
  }

  return false
}

export const isAssessmentCurrent = ({
  assessment,
  assignments,
  isActiveEpisode,
  currentAppointment,
  isCurrentPeriod,
}: isAssessmentCurrentParams) => {
  // All new, draft or in-progress assessments are current
  if (
    !includes(
      [AssignmentStatuses.completed, AssignmentStatuses.missed],
      getCombinedAssignmentStatus({ assignment: assessment, assignments }),
    )
  ) {
    return true
    // All assessments associated with an inactive episode or past session period are past
  } else if (!isActiveEpisode || !isCurrentPeriod) {
    return false
  } else if (currentAppointment) {
    const updateDate = moment.utc(assessment.update_date)
    const submitDate = moment.utc(assessment.assignment_responses?.[0]?.submit_date)
    const sessionStartDate = getAppointmentDateTimeObject(currentAppointment)
    // If it's after the appointment date then assessment is past, otherwise it's current
    // Use submitDate for completed assessments and update for non-completed
    if (assessment.status === 'completed') {
      return submitDate.isSameOrAfter(sessionStartDate)
    } else {
      // Note: this may not be needed any more because missed assessments are hidden
      return updateDate.isSameOrAfter(sessionStartDate)
    }
  } else {
    // Fall back to current
    return true
  }
}

export const assignmentHasNonCompletedProviderDraft = (assignment?: Assignment | EpisodeContents) => {
  if (assignment?.id) {
    return (
      assignmentOnlyHasProviderDraft(assignment as Assignment) &&
      (assignment as Assignment)?.assignment_responses?.[0]?.status !== AssignmentStatuses.completed
    )
  } else {
    return false
  }
}

export const assignmentHasProviderDraft = (assignment?: Assignment) => {
  return assignment?.assignment_responses?.some((response: AssignmentResponse) => {
    return response.provider_started
  })
}

// get the first provider draft response
export const getProviderDraftResponse = (assignment?: Assignment) => {
  return assignment?.assignment_responses?.find((response: AssignmentResponse) => {
    return response.provider_started
  })
}

export const assignmentOnlyHasProviderDraft = (assignment?: Assignment) => {
  return assignment?.assignment_responses?.length === 1 && assignmentHasProviderDraft(assignment)
}

export const assignmentHasCompletedResponse = (assignment?: Assignment) => {
  return assignment?.assignment_responses?.some((response: AssignmentResponse) => {
    return response.status === 'completed'
  })
}

export const removeFutureSessionExercisesFromActivities = (assignments: Assignment[], providers: Provider[]) => {
  return assignments.filter((assignment) => {
    const { episode_id, provider_id, session_period } = assignment
    const sessionPeriod = session_period === 0 ? session_period + 1 : session_period
    const currentEpisodeSessionPeriod = getProviderSessionCounts(provider_id, episode_id, providers)
    const isFutureSessionExercise =
      assignment.content.group === AssignmentGroup.EXERCISE && sessionPeriod > currentEpisodeSessionPeriod

    return isFutureSessionExercise ? false : true
  })
}

// get if the pre/postrequisite assignment should display when going through the assignments. Only either pre or post requisite should be passed
export const getShownLinkedAssignment = ({
  clickedAssignment,
  assignments,
  isCareOnboardingPhase2Enabled,
}: {
  clickedAssignment?: Assignment
  assignments?: Assignment[]
  isCareOnboardingPhase2Enabled?: boolean
}) => {
  if (isNil(clickedAssignment)) {
    return
  }
  if (isNil(assignments)) {
    return clickedAssignment
  }

  const linkedAssignments = getLinkedAssignments({
    assignment: clickedAssignment,
    assignments,
    isCareOnboardingPhase2Enabled,
  })

  const firstOpenAssignment = linkedAssignments.find(
    (assignment) =>
      ![AssignmentStatuses.completed, AssignmentStatuses.missed].includes(
        assignment.status as string as AssignmentStatuses,
      ),
  )

  if (firstOpenAssignment) {
    return firstOpenAssignment
  }

  const firstCompletedAssignment = linkedAssignments.find(
    (assignment) => assignment.status === AssignmentStatuses.completed,
  )

  if (firstCompletedAssignment) {
    return firstCompletedAssignment
  }

  return clickedAssignment
}

export const getClientPreferredNameIfExists = (client: ClientListClientObject | null) => {
  return client?.preferred_first_name || client?.preferred_last_name
    ? (client?.preferred_first_name ?? client?.first_name) + ' ' + (client?.preferred_last_name ?? client?.last_name)
    : ' '
}

export const getPreAndPostRequisites = ({
  selectedAssignment,
  assignments,
  isCareOnboardingPhase2Enabled = false,
}: {
  selectedAssignment?: Assignment | null
  assignments?: Assignment[]
  isCareOnboardingPhase2Enabled?: boolean
}) => {
  const preAndPostRequisites: { prerequisite: Assignment | undefined; postrequisite: Assignment | undefined } = {
    prerequisite: undefined,
    postrequisite: undefined,
  }
  if (isNil(selectedAssignment) || !assignments) {
    return preAndPostRequisites
  }

  const selectedAssignmentName = selectedAssignment.content.name
  const customContentAttributes = isCareOnboardingPhase2Enabled
    ? customContentAttributesPhase2
    : customContentAttributesPhase1

  if (selectedAssignmentName in customContentAttributes) {
    const prerequisiteName = customContentAttributes[selectedAssignmentName]?.prerequisite
    if (prerequisiteName) {
      const preRequisiteAssignment = assignments.find((assignment) => {
        return (
          assignment.content.name === prerequisiteName &&
          assignment.episode_id === selectedAssignment.episode_id &&
          assignment.status !== AssignmentStatuses.missed
        )
      })
      preAndPostRequisites.prerequisite = preRequisiteAssignment
    }

    const postrequisiteName = customContentAttributes[selectedAssignmentName]?.postrequisite
    if (postrequisiteName) {
      const postRequisiteAssignment = assignments.find((assignment) => {
        return (
          assignment.content.name === postrequisiteName &&
          assignment.episode_id === selectedAssignment.episode_id &&
          assignment.status !== AssignmentStatuses.missed
        )
      })
      preAndPostRequisites.postrequisite = postRequisiteAssignment
    }
  }
  return preAndPostRequisites
}

export const getAssignmentStatusLabel = ({
  formatMessage,
  status,
  simplifyStatus = false,
}: {
  formatMessage?: IntlShape['formatMessage']
  status?: AssignmentStatuses
  simplifyStatus?: boolean
}): string => {
  if (isNil(status)) {
    return ''
  }

  if (simplifyStatus && formatMessage) {
    switch (status) {
      case AssignmentStatuses.new:
        return formatMessage({
          defaultMessage: 'Not started',
          description: 'The assignment has not yet been started',
        })
      case AssignmentStatuses.in_progress:
      case AssignmentStatuses.draft:
      case AssignmentStatuses.provider_draft:
        return formatMessage({
          defaultMessage: 'In progress',
          description: 'The assignment has been started and is in progress',
        })
      case AssignmentStatuses.completed:
        return formatMessage({
          defaultMessage: 'Completed',
          description: 'The assignment has been completed',
        })
      case AssignmentStatuses.missed:
        return formatMessage({
          defaultMessage: 'Missed',
          description: 'The assignment was missed and there is no entry for it',
        })
      default:
        return ''
    }
  }
  return AssignmentStatusesLabel[status] ?? ''
}

export const getLinkedAssignments = ({
  assignment,
  assignments,
  isCareOnboardingPhase2Enabled,
}: {
  assignment?: Assignment | null
  assignments: Assignment[]
  isCareOnboardingPhase2Enabled?: boolean
}): Assignment[] => {
  if (isNil(assignment)) {
    return []
  }
  const linkedStatusAssignments = isCareOnboardingPhase2Enabled
    ? customContentAttributesPhase2[assignment.content.name]?.linkedStatusAssignments
    : customContentAttributesPhase1[assignment.content.name]?.linkedStatusAssignments

  if (isNil(linkedStatusAssignments)) {
    return [assignment]
  }
  const linkedAssignments: Assignment[] = []
  linkedStatusAssignments?.forEach((assignmentName: string) => {
    if (assignmentName === assignment.content.name) {
      linkedAssignments.push(assignment)
    } else {
      const linkedAssignment = assignments?.find(
        (asgmt) =>
          asgmt.content.name === assignmentName &&
          asgmt.episode_id === assignment.episode_id &&
          asgmt.status !== AssignmentStatuses.missed,
      )
      if (linkedAssignment) {
        linkedAssignments.push(linkedAssignment)
      }
    }
  })

  return linkedAssignments
}

export const getCombinedAssignmentCompletionDate = ({
  assignment,
  assignments,
  isCareOnboardingPhase2Enabled,
}: {
  assignment?: Assignment
  assignments?: Assignment[]
  isCareOnboardingPhase2Enabled?: boolean
}): any => {
  if (isNil(assignment)) {
    return
  }

  const customContentAttributes = isCareOnboardingPhase2Enabled
    ? customContentAttributesPhase2
    : customContentAttributesPhase1

  if (!(assignment.content.name in customContentAttributes)) {
    return assignment?.assignment_responses?.[0]?.submit_date
  }

  if (isNil(assignments)) {
    const submitDate = assignment?.assignment_responses?.[0]?.submit_date
    if (submitDate) {
      return new Date(submitDate)
    }
    return
  }

  const completionDates: Date[] = []
  const linkedAssignments = getLinkedAssignments({ assignment, assignments, isCareOnboardingPhase2Enabled })
  linkedAssignments.forEach((assignment: Assignment) => {
    const submitDate = assignment?.assignment_responses?.[0]?.submit_date
    if (submitDate) {
      completionDates.push(new Date(submitDate))
    }
  })
  return completionDates.length ? max(completionDates) : undefined
}

export const getCombinedAssignmentStatus = ({
  assignment,
  assignments,
  isCareOnboardingPhase2Enabled,
}: {
  assignment?: Assignment
  assignments?: Assignment[]
  isCareOnboardingPhase2Enabled?: boolean
}): any => {
  if (isNil(assignment)) {
    return
  }

  const customContentAttributes = isCareOnboardingPhase2Enabled
    ? customContentAttributesPhase2
    : customContentAttributesPhase1

  if (!(assignment.content.name in customContentAttributes)) {
    return assignment?.assignment_responses?.[0]?.status ?? assignment.status
  }

  if (isNil(assignments)) {
    return (assignment?.assignment_responses?.[0]?.status ?? assignment.status) as string as AssignmentStatuses
  }

  const completionStatuses: AssignmentStatuses[] = []

  const linkedAssignments = getLinkedAssignments({ assignment, assignments, isCareOnboardingPhase2Enabled })
  linkedAssignments.forEach((assignment: Assignment) => {
    completionStatuses.push(
      (assignment?.assignment_responses?.[0]?.status ?? assignment.status) as string as AssignmentStatuses,
    )
  })

  if (completionStatuses.length === 1) {
    return completionStatuses[0]
  }

  if (
    completionStatuses.some((status) =>
      [AssignmentStatuses.in_progress, AssignmentStatuses.draft, AssignmentStatuses.provider_draft].includes(status),
    )
  ) {
    return AssignmentStatuses.in_progress
  }

  if (completionStatuses.every((status) => [AssignmentStatuses.new].includes(status))) {
    return AssignmentStatuses.new
  }

  if (
    completionStatuses.some((status) => [AssignmentStatuses.new].includes(status)) &&
    completionStatuses.some((status) => ![AssignmentStatuses.new].includes(status))
  ) {
    return AssignmentStatuses.in_progress
  }

  if (completionStatuses.some((status) => [AssignmentStatuses.completed].includes(status))) {
    return AssignmentStatuses.completed
  }

  if (completionStatuses.every((status) => [AssignmentStatuses.missed].includes(status))) {
    return AssignmentStatuses.missed
  }

  return AssignmentStatuses.in_progress
}

type IsFieldDisplayedParams = {
  condition?: Condition
  formValues: { [key: string]: FieldValue }
  hidden?: boolean
  formFields?:
    | {
        [key: string]: FieldSchema
      }
    | FieldSchema
    | undefined
  name?: string
}

type IsHiddenByPaginationParams = {
  mobilePage?: number
  currentPage?: number
  totalPages?: number
  singlePageContent?: boolean
}

type GetVisibleFieldsParams = {
  uiSchema?: UiMetadata
  schema?: FieldSchema
  values?: { [key: string]: FieldValue }
}

type ValidateFormParams = {
  fields:
    | {
        [key: string]: FieldSchema
      }
    | FieldSchema
    | undefined
  values: { [key: string]: FieldValue }
  errors?: Dict
  currentPage?: number
  totalPages?: number
  singlePageContent?: boolean
  pagination?: boolean
  formatMessage?: IntlShape['formatMessage']
  disableIsRequiredFormValidation?: boolean
}

type GetFieldSchemaParams = {
  name: string
  fields:
    | {
        [key: string]: FieldSchema
      }
    | FieldSchema
  fieldSchema?: FieldSchema[]
}

type RemoveHiddenFieldsParams = {
  values: { [key: string]: FieldValue }
  fields?: SchemaProperties
  externalValues?: { [key: string]: FieldValue }
}

type getLastPageFromResponseParams = {
  response?: Dict
  schema?: FieldSchema
}

type shouldShowToDoParams = {
  group: ActivityGroupType
  hasCompletedResponse?: boolean
  isCareOnboardingPhase2Enabled?: boolean
  status: string
}

type isAssessmentCurrentParams = {
  assignments?: Assignment[]
  assessment: Assignment
  isActiveEpisode: boolean
  currentAppointment: Appointment | null
  isCurrentPeriod: boolean
}
