import React, { FunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Dimensions, View } from 'react-native'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import {
  CustomerName,
  EXCLUDE_MICROSITE_NOTIFY_ON_LAUNCH_CTA,
  getLegalNoticeLink,
  PROGRAM_MEMBERSHIP_TYPES,
  useLyraIntl,
} from '@lyrahealth-inc/shared-app-logic'

import {
  FAQ_EMPLOYEE_COST_MESSAGES,
  FAQ_EMPLOYEE_MESSAGES,
  FAQ_EMPLOYEE_MESSAGES_BEFORE_LAUNCH,
  FAQ_MEMBER_MESSAGES,
  FAQ_MEMBER_MESSAGES_BEFORE_LAUNCH,
  FAQ_MESSAGES_DEFAULT,
  FAQ_QUESTIONS,
} from './messages'
import { SoftSunriseMobileBG, SoftSunriseWithDogBGBody, SoftSunriseWithDogBGFooter } from '../../../assets/microsite'
import { BodyText, Headline, Link, PrimaryButton, Subhead } from '../../../atoms'
import { Accordion, AccordionGroup, FAQSection } from '../../../molecules'
import { BodyTextSize, HeadlineSize, SubheadSize } from '../../../styles'
import { openUrl, ThemeType, tID } from '../../../utils'

const CUSTOM_COST_MESSAGE_CUSTOMERS: string[] = [CustomerName.STARBUCKS]

const CtaCopyContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  paddingTop: '124px',
  ...(theme.breakpoints.isMinWidthTablet && { justifyContent: 'center', alignItems: 'center' }),
}))

const CtaBodyText = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['16px'],
  marginBottom: theme.spacing['48px'],
  ...(theme.breakpoints.isMinWidthTablet && { textAlign: 'center', maxWidth: '781px' }),
}))

const FAQsCollapsibleContentContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  ...(theme.breakpoints.isMobileSized && { flexDirection: 'column', paddingBottom: theme.spacing['32px'] }),
  ...(theme.breakpoints.isMinWidthTablet && {
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: '200px',
    width: '100%',
  }),
}))

const CollapsibleElementContainer = styled.View<{ theme: ThemeType; hasRightMargin?: boolean }>(
  ({ theme, hasRightMargin }) => ({
    ...(theme.breakpoints.isMinWidthTablet && {
      flex: 1,
      height: '100%',
      marginRight: hasRightMargin && theme.breakpoints.isMinWidthLaptop ? theme.spacing['64px'] : theme.spacing['32px'],
      maxWidth: '598px',
    }),
    ...(theme.breakpoints.isMobileSized && { marginBottom: theme.spacing['48px'] }),
  }),
)

const DesktopSizedCreateAccountContainer = styled.ImageBackground<{ theme: ThemeType; backgroundHeight?: number }>(
  ({ theme, backgroundHeight }) => ({
    alignItems: 'center',
    width: '100%',
    ...(backgroundHeight && { height: backgroundHeight }),
    ...(theme.breakpoints.isMinWidthDesktop && {
      paddingTop: theme.breakpoints.isMinWidthDesktopL ? theme.spacing['56px'] : theme.spacing['32px'],
    }),
  }),
)

const BottomCTAButtonContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['24px'],
  ...(!theme.breakpoints.isMinWidthDesktop && {
    paddingBottom: '124px',
  }),
}))

const AccordionItem = styled(Accordion)<{ theme: ThemeType }>(({ theme }) => ({
  borderBottomWidth: '1px',
  borderColor: theme.colors.borderDefault,
  paddingTop: '21px',
  paddingBottom: '21px',
}))

const DesktopSizedCtaAndCollapsibleFAQsContainer = styled.ImageBackground({
  width: '100%',
  paddingLeft: '124px',
  paddingRight: '124px',
})

export interface CustomerCostProperties {
  isCustomerEAPExclusive: boolean
  isCustomerHPIExclusive: boolean
  supportsDependents: boolean
  customerName: string
  customerDisplayName: string
  numberOfSessions?: string
  displayVisitsPerIssuePerYear?: number
}

export interface PathwaysFAQSectionProps {
  membershipType: PROGRAM_MEMBERSHIP_TYPES
  beforeLaunch: boolean
  hasMembershipInCustomer: boolean
  customerCostProperties: CustomerCostProperties
  isCostMessagingEnabled: boolean
  isRegistrationAndLoginDisabled?: boolean
  startDate?: string
  rerouteFromMicrosite?: () => void
  onReachOutToCareNavTeamLinkPress?: () => void
  onQuestionPress?: (title: string) => void
  onNotifyMeOnLaunchPress?: () => void
}

export const PathwaysFAQSection: FunctionComponent<PathwaysFAQSectionProps> = ({
  membershipType,
  beforeLaunch,
  hasMembershipInCustomer,
  startDate,
  customerCostProperties,
  isCostMessagingEnabled,
  isRegistrationAndLoginDisabled,
  rerouteFromMicrosite = noop,
  onReachOutToCareNavTeamLinkPress,
  onQuestionPress,
  onNotifyMeOnLaunchPress = noop,
}) => {
  const { formatDate, formatMessage } = useIntl()

  const { activeLanguage } = useLyraIntl()

  const { colors, breakpoints } = useTheme()

  const desktopSizedBackgroundFooterAspectRatio = 3016 / 599

  // We will explicitly set background height ONLY for the footer portion of the larger image (it's more intricate) so it's aspect ratio can be preserved.
  // The smaller background image's height is fine to be auto set as it does not have any noticeable distortion to the image when stretched.
  const desktopSizedBackgroundHeightFooter = Math.round(
    Dimensions.get('window').width * (1 / desktopSizedBackgroundFooterAspectRatio),
  )

  const backgroundContainerProps = {
    source: breakpoints.isMinWidthDesktop ? SoftSunriseWithDogBGBody : SoftSunriseMobileBG,
    resizeMode: 'stretch',
    testID: breakpoints.isMinWidthDesktop
      ? tID('FAQSection-pathwaysWebBackground')
      : tID('FAQSection-pathwaysMobileBackground'),
    width: '100%',
  }

  const {
    isCustomerEAPExclusive,
    isCustomerHPIExclusive,
    supportsDependents,
    customerName,
    customerDisplayName,
    numberOfSessions,
    displayVisitsPerIssuePerYear,
  } = customerCostProperties

  const faqEmployeeMessages = beforeLaunch
    ? { ...FAQ_EMPLOYEE_MESSAGES, ...FAQ_EMPLOYEE_MESSAGES_BEFORE_LAUNCH }
    : FAQ_EMPLOYEE_MESSAGES

  const faqMemberMessages = beforeLaunch
    ? { ...FAQ_MEMBER_MESSAGES, ...FAQ_MEMBER_MESSAGES_BEFORE_LAUNCH }
    : FAQ_MEMBER_MESSAGES

  const getEmployeeCostMessage = () => {
    if (customerName === CustomerName.ATT_UNION) {
      return FAQ_EMPLOYEE_COST_MESSAGES.ATT_BARGAINED
    }

    if (CUSTOM_COST_MESSAGE_CUSTOMERS.includes(customerName)) {
      return supportsDependents
        ? FAQ_EMPLOYEE_COST_MESSAGES.CUSTOM_CUSTOMER_WITH_DEPENDENTS
        : FAQ_EMPLOYEE_COST_MESSAGES.CUSTOM_CUSTOMER_WITHOUT_DEPENDENTS
    }

    if (isCustomerEAPExclusive) {
      return supportsDependents
        ? FAQ_EMPLOYEE_COST_MESSAGES.EAP_ONLY_WITH_DEPENDENTS
        : FAQ_EMPLOYEE_COST_MESSAGES.EAP_ONLY_WITHOUT_DEPENDENTS
    }

    if (isCustomerHPIExclusive) {
      return supportsDependents
        ? FAQ_EMPLOYEE_COST_MESSAGES.HPI_ONLY_WITH_DEPENDENTS
        : FAQ_EMPLOYEE_COST_MESSAGES.HPI_ONLY_WITHOUT_DEPENDENTS
    }

    return supportsDependents
      ? FAQ_EMPLOYEE_COST_MESSAGES.EAP_HPI_WITH_DEPENDENTS
      : FAQ_EMPLOYEE_COST_MESSAGES.EAP_HPI_WITHOUT_DEPENDENTS
  }

  const FAQ_ANSWERS = {
    IS_THE_INFORMATION_I_SHARE_CONFIDENTIAL: {
      [PROGRAM_MEMBERSHIP_TYPES.EMPLOYEE]: formatMessage(faqEmployeeMessages.IS_THE_INFORMATION_I_SHARE_CONFIDENTIAL, {
        link1: (text: string) => (
          <Link
            text={text}
            onPress={() => {
              openUrl(getLegalNoticeLink(activeLanguage, 'PRIVACY_POLICY'))
            }}
          />
        ),
        link2: (text: string) => (
          <Link
            text={text}
            onPress={() => {
              openUrl(getLegalNoticeLink(activeLanguage, 'HIPAA_NOTICE'))
            }}
          />
        ),
      }),
      [PROGRAM_MEMBERSHIP_TYPES.MEMBER]: formatMessage(faqMemberMessages.IS_THE_INFORMATION_I_SHARE_CONFIDENTIAL, {
        link1: (text: string) => (
          <Link
            text={text}
            onPress={() => {
              openUrl(getLegalNoticeLink(activeLanguage, 'PRIVACY_POLICY'))
            }}
          />
        ),
        link2: (text: string) => (
          <Link
            text={text}
            onPress={() => {
              openUrl(getLegalNoticeLink(activeLanguage, 'HIPAA_NOTICE'))
            }}
          />
        ),
      }),
    },
    HOW_MUCH_DOES_LYRA_COST: {
      [PROGRAM_MEMBERSHIP_TYPES.EMPLOYEE]: isCostMessagingEnabled
        ? formatMessage(getEmployeeCostMessage(), {
            customerName: customerDisplayName,
            sessionCount: (displayVisitsPerIssuePerYear ?? numberOfSessions) || 0,
            bold: (chunks: string) => (
              <BodyText bold size={BodyTextSize.DEFAULT} color={colors.textPrimary} text={chunks} />
            ),
          })
        : formatMessage(faqEmployeeMessages.HOW_MUCH_DOES_LYRA_COST),
      [PROGRAM_MEMBERSHIP_TYPES.MEMBER]: formatMessage(faqMemberMessages.HOW_MUCH_DOES_LYRA_COST),
    },
    WHAT_TYPE_OF_SERVICES_DOES_LYRA_OFFER: {
      [PROGRAM_MEMBERSHIP_TYPES.EMPLOYEE]: formatMessage(faqEmployeeMessages.WHAT_TYPE_OF_SERVICES_DOES_LYRA_OFFER),
      [PROGRAM_MEMBERSHIP_TYPES.MEMBER]: formatMessage(faqMemberMessages.WHAT_TYPE_OF_SERVICES_DOES_LYRA_OFFER),
    },
    HOW_DO_I_GET_STARTED: {
      [PROGRAM_MEMBERSHIP_TYPES.EMPLOYEE]: formatMessage(faqEmployeeMessages.HOW_DO_I_GET_STARTED),
      [PROGRAM_MEMBERSHIP_TYPES.MEMBER]: formatMessage(faqMemberMessages.HOW_DO_I_GET_STARTED),
    },
    HOW_LONG_DOES_IT_TAKE_TO_GET_STARTED: {
      [PROGRAM_MEMBERSHIP_TYPES.EMPLOYEE]: formatMessage(faqEmployeeMessages.HOW_LONG_DOES_IT_TAKE_TO_GET_STARTED),
      [PROGRAM_MEMBERSHIP_TYPES.MEMBER]: formatMessage(faqMemberMessages.HOW_LONG_DOES_IT_TAKE_TO_GET_STARTED),
    },
    HOW_WILL_I_MEET_WITH_MY_PROVIDER: {
      [PROGRAM_MEMBERSHIP_TYPES.EMPLOYEE]: (
        <>
          <BodyText>
            {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
            <FormattedMessage {...faqEmployeeMessages.HOW_WILL_I_MEET_WITH_MY_PROVIDER_FIRST_PARAGRAPH} />{' '}
          </BodyText>
          <BodyText>
            <FormattedMessage
              {...faqEmployeeMessages.HOW_WILL_I_MEET_WITH_MY_PROVIDER_SECOND_PARAGRAPH}
              values={{
                link: (text: string) => (
                  <Link
                    text={text}
                    onPress={() => {
                      onReachOutToCareNavTeamLinkPress && onReachOutToCareNavTeamLinkPress()
                    }}
                  />
                ),
              }}
            />
          </BodyText>
        </>
      ),
      [PROGRAM_MEMBERSHIP_TYPES.MEMBER]: formatMessage(faqMemberMessages.HOW_WILL_I_MEET_WITH_MY_PROVIDER),
    },
    DOES_LYRA_HAVE_PROVIDERS_WHO_SHARE_MY_SOCIAL_CULTURAL_IDENTITIES: formatMessage(
      FAQ_MESSAGES_DEFAULT.DOES_LYRA_HAVE_PROVIDERS_WHO_SHARE_MY_SOCIAL_CULTURAL_IDENTITIES,
    ),
    DOES_LYRA_HAVE_PROVIDERS_THAT_SPECIALIZE_IN_MY_SPECIFIC_NEEDS: formatMessage(
      FAQ_MESSAGES_DEFAULT.DOES_LYRA_HAVE_PROVIDERS_THAT_SPECIALIZE_IN_MY_SPECIFIC_NEEDS,
    ),
  }

  const aboutLyraFAQsDefault = [
    {
      question: FAQ_QUESTIONS.HOW_IS_THE_INFORMATION_I_SHARE_CONFIDENTIAL,
      answer: FAQ_ANSWERS.IS_THE_INFORMATION_I_SHARE_CONFIDENTIAL[membershipType],
    },
    {
      question: FAQ_QUESTIONS.HOW_MUCH_DOES_LYRA_COST,
      answer: FAQ_ANSWERS.HOW_MUCH_DOES_LYRA_COST[membershipType],
    },
    {
      question: FAQ_QUESTIONS.WHAT_TYPE_OF_SERVICES_DOES_LYRA_OFFER,
      answer: FAQ_ANSWERS.WHAT_TYPE_OF_SERVICES_DOES_LYRA_OFFER[membershipType],
    },
    {
      question: FAQ_QUESTIONS.DOES_LYRA_HAVE_PROVIDERS_WHO_SHARE_MY_SOCIAL_CULTURAL_IDENTITIES,
      answer: FAQ_ANSWERS.DOES_LYRA_HAVE_PROVIDERS_WHO_SHARE_MY_SOCIAL_CULTURAL_IDENTITIES,
    },
    {
      question: FAQ_QUESTIONS.DOES_LYRA_HAVE_PROVIDERS_THAT_SPECIALIZE_IN_MY_SPECIFIC_NEEDS,
      answer: FAQ_ANSWERS.DOES_LYRA_HAVE_PROVIDERS_THAT_SPECIALIZE_IN_MY_SPECIFIC_NEEDS,
    },
  ]

  const FAQs = [
    {
      title: (
        <FormattedMessage
          defaultMessage='About Lyra'
          description='Microsite FAQ section header 1, questions about the Lyra Health benefit'
        />
      ),
      questions: aboutLyraFAQsDefault,
    },
    {
      title: (
        <FormattedMessage
          defaultMessage='How does it work?'
          description='Microsite FAQ section header 2, questions about getting care with Lyra Health'
        />
      ),
      questions: [
        {
          question: FAQ_QUESTIONS.HOW_DO_I_GET_STARTED,
          answer: FAQ_ANSWERS.HOW_DO_I_GET_STARTED[membershipType],
        },
        {
          question: FAQ_QUESTIONS.HOW_LONG_DOES_IT_TAKE_TO_GET_STARTED,
          answer: FAQ_ANSWERS.HOW_LONG_DOES_IT_TAKE_TO_GET_STARTED[membershipType],
        },
        {
          question: FAQ_QUESTIONS.HOW_WILL_I_MEET_WITH_MY_PROVIDER,
          answer: FAQ_ANSWERS.HOW_WILL_I_MEET_WITH_MY_PROVIDER[membershipType],
        },
      ],
    },
  ]

  const stillHaveQuestionsText = (
    <FormattedMessage
      defaultMessage='Still have questions?'
      description='Header text, about how to get answers to employer-specific questions, and about contacting the care navigator team'
    />
  )
  const ctaHeader = breakpoints.isMobileSized ? (
    <Subhead size={SubheadSize.LARGE} text={stillHaveQuestionsText} />
  ) : (
    <Headline size={HeadlineSize.SMALL} text={stillHaveQuestionsText} />
  )

  const ctaSection = beforeLaunch ? (
    <View>
      <CtaCopyContainer>
        {ctaHeader}
        <CtaBodyText>
          <FormattedMessage
            defaultMessage='Get answers to common questions before the Lyra benefit launches then come back {launchDate} to register and learn more.'
            description='The client can get employer specific details once their benefit launches'
            values={{
              launchDate: formatDate(startDate, {
                month: 'long',
                day: 'numeric',
                year: 'numeric',
                timeZone: 'UTC',
              }),
            }}
          />
        </CtaBodyText>
      </CtaCopyContainer>
    </View>
  ) : (
    <View>
      <CtaCopyContainer>
        {ctaHeader}
        <CtaBodyText>
          {hasMembershipInCustomer ? (
            <FormattedMessage
              defaultMessage='Here are just a few of our frequently asked questions. For more specific details about your personalized Lyra experience, sign into your account.'
              description='The client can get employer specific details by signing into their account, under the section (Still have questions?)'
            />
          ) : (
            <FormattedMessage
              defaultMessage='Here are just a few of our frequently asked questions. You can find more information specific to your employer’s benefits plan by signing into your free Lyra account.'
              description='The client can get employer specific details by signing into their account, under the section (Still have questions?)'
            />
          )}
        </CtaBodyText>
      </CtaCopyContainer>
    </View>
  )

  const pathwaysFaqsCollapsibleContent = (
    <FAQsCollapsibleContentContainer>
      {FAQs.map(({ title, questions }, index) => (
        <CollapsibleElementContainer key={index} hasRightMargin={FAQs.length - 1 !== index}>
          <Subhead size={SubheadSize.LARGE} text={title} />
          <View>
            <AccordionGroup
              accordions={questions.map((faq, index) => (
                <AccordionItem
                  key={index}
                  title={formatMessage(faq.question)}
                  content={<BodyText color={colors.textSecondary} text={faq.answer} />}
                  backgroundColor={colors.backgroundTransparent}
                  onAccordionPress={() => {
                    onQuestionPress &&
                      onQuestionPress((faq.question.defaultMessage[0] as unknown as { value: string })?.value)
                  }}
                  chevronSize={breakpoints.isMobileSized ? 24 : 32}
                />
              ))}
              allowMultiExpand={!breakpoints.isMobileSized}
            />
          </View>
        </CollapsibleElementContainer>
      ))}
    </FAQsCollapsibleContentContainer>
  )

  const BottomCTAWrapper = breakpoints.isMinWidthDesktop ? DesktopSizedCreateAccountContainer : React.Fragment
  const bottomCTAContainerWrapperProps = breakpoints.isMinWidthDesktop
    ? {
        resizeMode: 'stretch',
        source: SoftSunriseWithDogBGFooter,
        backgroundHeight: breakpoints.isMinWidthDesktop ? desktopSizedBackgroundHeightFooter : undefined,
        testID: 'BottomCTAContainer',
      }
    : {}

  const readyToGetStartedText = beforeLaunch ? (
    <FormattedMessage
      defaultMessage='Launching {launchDate}'
      description='Header above the notify me on launch button'
      values={{
        launchDate: formatDate(startDate, {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
          timeZone: 'UTC',
        }),
      }}
    />
  ) : (
    <FormattedMessage defaultMessage='Ready to get started?' description='Header above the create account button' />
  )

  const bottomCTASectionHeader = breakpoints.isMobileSized ? (
    <Subhead size={SubheadSize.LARGE} text={readyToGetStartedText} />
  ) : (
    <Headline size={HeadlineSize.MEDIUM} text={readyToGetStartedText} />
  )

  const bottomCTASection = isRegistrationAndLoginDisabled ? (
    <></>
  ) : (
    <BottomCTAWrapper {...bottomCTAContainerWrapperProps}>
      <View testID={tID('FAQSection-bottomCTASectionHeader')}>{bottomCTASectionHeader}</View>
      <BottomCTAButtonContainer>
        {!EXCLUDE_MICROSITE_NOTIFY_ON_LAUNCH_CTA.includes(customerName) && (
          <PrimaryButton
            text={
              beforeLaunch ? (
                <FormattedMessage
                  defaultMessage='Notify me on launch'
                  description='button text for notifying on launch'
                />
              ) : (
                <FormattedMessage defaultMessage='Sign up' description='button text for creating a new account' />
              )
            }
            onPress={() => {
              if (beforeLaunch) {
                onNotifyMeOnLaunchPress()
              } else {
                rerouteFromMicrosite()
              }
            }}
            testID={tID(`FAQSection-${beforeLaunch ? 'notifyMeOnLaunchButton' : 'createAccountButton'}`)}
          />
        )}
      </BottomCTAButtonContainer>
    </BottomCTAWrapper>
  )

  const CtaAndFAQsWrapper = breakpoints.isMinWidthDesktop ? DesktopSizedCtaAndCollapsibleFAQsContainer : React.Fragment
  const ctaAndFAQsWrapperProps = breakpoints.isMinWidthDesktop
    ? {
        resizeMode: 'stretch',
        source: SoftSunriseWithDogBGBody,
      }
    : {}

  const ctaAndFAQsSection = (
    <CtaAndFAQsWrapper {...ctaAndFAQsWrapperProps}>
      {ctaSection}
      {pathwaysFaqsCollapsibleContent}
    </CtaAndFAQsWrapper>
  )
  return (
    <FAQSection
      ctaAndCollapsibleFAQsSection={ctaAndFAQsSection}
      bottomCTA={bottomCTASection}
      backgroundContainerProps={backgroundContainerProps}
      hasBackgroundImage
    />
  )
}
