import { NEW_YEAR_WEBINAR_LINK } from '../CMSMicrosite/constants'

export enum PathwayOptions {
  SELF_SEARCH = 'self-search',
  CHILD_SEARCH = 'child-search',
  COACHING_TOOLKIT = 'coaching-toolkit',
  PARENTING_TOOLKIT = 'parenting-toolkit',
  ESSENTIALS = 'essentials',
  MEDICATION_CONSULTATION = 'medication-consultation',
  COUPLES_THERAPY = 'couples-therapy',
  FAMILY_THERAPY = 'family-therapy',
  WELLNESS_CHECK_IN = 'wellness-check-in',
  /** Entry point from the new year toolkit (/members) */
  NEW_YEAR_WEBINAR = 'new-year-webinar',
}

/** If the pathway option is available on forked triage. Maps pathway option to boolean. Pathway option `key` is required in this map */
export const IS_PATHWAY_OPTION_ON_FORKED_TRIAGE: { [key in PathwayOptions]: boolean } = {
  [PathwayOptions.SELF_SEARCH]: true,
  [PathwayOptions.CHILD_SEARCH]: true,
  [PathwayOptions.COACHING_TOOLKIT]: true,
  [PathwayOptions.PARENTING_TOOLKIT]: true,
  [PathwayOptions.ESSENTIALS]: false,
  [PathwayOptions.MEDICATION_CONSULTATION]: true,
  [PathwayOptions.COUPLES_THERAPY]: true,
  [PathwayOptions.FAMILY_THERAPY]: true,
  [PathwayOptions.WELLNESS_CHECK_IN]: false,
  [PathwayOptions.NEW_YEAR_WEBINAR]: false,
}

export enum PathwaysRedirectUrls {
  SELF_TRIAGE = '/secure/onboard/about',
  CHILD_TRIAGE = '/secure/onboard/about-child',
  COACHING_TOOLKIT = '/secure/wellness/toolkit/purpose',
  PARENTING_TOOLKIT = '/secure/wellness/toolkit/parent',
  ESSENTIALS = '/secure/index/essentials',
  MEDICATION_CONSULTATION = '/secure/onboard/match-location?directPath=true&clientele=Individual&treatment=MedicationManagement&partner=BlendedCare&offering=Default&directLinkIntent=DIRECT_LINK_MEDS_CONSULT',
  WELLNESS_CHECK_IN = '/secure/wellness-check-in/results',
  COUPLES_THERAPY = `/secure/onboard/match-location?directPath=true&clientele=Couples&treatment=Therapy&partner=DirectAccess&offering=Default&directLinkSource=microsite_forked_triage&directLinkIntent=DIRECT_LINK_INTENT_COUPLES`,
  FAMILY_THERAPY = `/pathways/care-options?selectedPathwayOption=${PathwayOptions.FAMILY_THERAPY}`,
  PARENTING_TOOLKIT_V2 = `/pathways/care-options?selectedPathwayOption=${PathwayOptions.PARENTING_TOOLKIT}`,
  MEDICATION_CONSULTATION_V2 = `/pathways/care-options?selectedPathwayOption=${PathwayOptions.MEDICATION_CONSULTATION}`,
  NEW_YEAR_WEBINAR = NEW_YEAR_WEBINAR_LINK,
}

const BasePathwayOptionToRedirectUrlMapping = {
  [PathwayOptions.SELF_SEARCH]: PathwaysRedirectUrls.SELF_TRIAGE,
  [PathwayOptions.CHILD_SEARCH]: PathwaysRedirectUrls.CHILD_TRIAGE,
  [PathwayOptions.COACHING_TOOLKIT]: PathwaysRedirectUrls.COACHING_TOOLKIT,
  [PathwayOptions.ESSENTIALS]: PathwaysRedirectUrls.ESSENTIALS,
  [PathwayOptions.FAMILY_THERAPY]: PathwaysRedirectUrls.FAMILY_THERAPY,
  [PathwayOptions.COUPLES_THERAPY]: PathwaysRedirectUrls.COUPLES_THERAPY,
  [PathwayOptions.WELLNESS_CHECK_IN]: PathwaysRedirectUrls.WELLNESS_CHECK_IN,
  [PathwayOptions.NEW_YEAR_WEBINAR]: PathwaysRedirectUrls.NEW_YEAR_WEBINAR,
}

export const PathwayOptionToRedirectUrlMapping = {
  ...BasePathwayOptionToRedirectUrlMapping,
  [PathwayOptions.PARENTING_TOOLKIT]: PathwaysRedirectUrls.PARENTING_TOOLKIT,
  [PathwayOptions.MEDICATION_CONSULTATION]: PathwaysRedirectUrls.MEDICATION_CONSULTATION,
}

export const PathwayOptionToRedirectUrlMappingV2 = {
  ...BasePathwayOptionToRedirectUrlMapping,
  [PathwayOptions.PARENTING_TOOLKIT]: PathwaysRedirectUrls.PARENTING_TOOLKIT_V2,
  [PathwayOptions.MEDICATION_CONSULTATION]: PathwaysRedirectUrls.MEDICATION_CONSULTATION_V2,
}

/**
 * Care options that should be available to teens (ie teens should be directed to the care option once logged into the app)
 */
export const TEEN_ENABLED_PATHWAYS: PathwayOptions[] = [
  PathwayOptions.SELF_SEARCH,
  PathwayOptions.ESSENTIALS,
  PathwayOptions.WELLNESS_CHECK_IN,
]

export const PATHWAY_QUERY_PARAM = 'pathwayOption'
