import { careConsentFormError } from '../common/constants/messages'

export type ContentAttributes = {
  bypassReview?: boolean
  customActivityIntroduction?: string
  customErrorMessage?: { defaultMessage: string; description: string }
  group?: string
  hideInClientActivities?: boolean
  hideInstructionsIfHasPrereq?: boolean
  hideProgressBar?: boolean
  initialPageIfHasPrereq?: number
  linkedAssignmentTitle?: string
  linkedStatusAssignments?: string[]
  prerequisite?: string
  postrequisite?: string
  shownParentActivity?: string
  singlePageContent?: boolean
}

export const customContentAttributesPhase1: {
  [key: string]: ContentAttributes
} = {
  audCareConsent: {
    bypassReview: true,
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    linkedStatusAssignments: ['audCareConsent', 'audClientIntake'],
    postrequisite: 'audClientIntake',
    singlePageContent: true,
    shownParentActivity: 'audClientIntake',
  },
  audClientIntake: {
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['audCareConsent', 'audClientIntake'],
    prerequisite: 'audCareConsent',
  },
  bccCareConsent: {
    bypassReview: true,
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    linkedStatusAssignments: ['bccCareConsent', 'bccClientIntake'],
    postrequisite: 'bccClientIntake',
    singlePageContent: true,
    shownParentActivity: 'bccClientIntake',
  },
  bccClientIntake: {
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['bccCareConsent', 'bccClientIntake'],
    prerequisite: 'bccCareConsent',
  },
  bcmCareConsent: {
    bypassReview: true,
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    linkedStatusAssignments: ['bcmCareConsent', 'bcmClientIntake'],
    postrequisite: 'bcmClientIntake',
    singlePageContent: true,
    shownParentActivity: 'bcmClientIntake',
  },
  bcmClientIntake: {
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['bcmCareConsent', 'bcmClientIntake'],
    prerequisite: 'bcmCareConsent',
  },
  bctCareConsent: {
    bypassReview: true,
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    linkedStatusAssignments: ['bctCareConsent', 'bctClientIntake'],
    postrequisite: 'bctClientIntake',
    singlePageContent: true,
    shownParentActivity: 'bctClientIntake',
  },
  bctClientIntake: {
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['bctCareConsent', 'bctClientIntake'],
    prerequisite: 'bctCareConsent',
  },
  cleCareConsent: {
    bypassReview: true,
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    linkedStatusAssignments: ['cleCareConsent', 'cleClientIntake'],
    postrequisite: 'cleClientIntake',
    singlePageContent: true,
    shownParentActivity: 'cleClientIntake',
  },
  cleClientIntake: {
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['cleCareConsent', 'cleClientIntake'],
    prerequisite: 'cleCareConsent',
  },
  lcttCareConsent: {
    bypassReview: true,
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    linkedStatusAssignments: ['lcttCareConsent', 'lcttClientIntake'],
    postrequisite: 'lcttClientIntake',
    singlePageContent: true,
    shownParentActivity: 'lcttClientIntake',
  },
  lcttClientIntake: {
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['lcttCareConsent', 'lcttClientIntake'],
    prerequisite: 'lcttCareConsent',
  },
  sscCareConsent: {
    bypassReview: true,
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    linkedStatusAssignments: ['sscCareConsent', 'sscClientIntake'],
    postrequisite: 'sscClientIntake',
    singlePageContent: true,
    shownParentActivity: 'sscClientIntake',
  },
  sscClientIntake: {
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['sscCareConsent', 'sscClientIntake'],
    prerequisite: 'sscCareConsent',
  },
}

export const customContentAttributesPhase2: {
  [key: string]: ContentAttributes
} = {
  audCareConsent: {
    bypassReview: true,
    customActivityIntroduction: 'CareOnboardingHeader',
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideProgressBar: true,
    linkedStatusAssignments: ['audCareConsent', 'audClientIntake', 'audInitialAssessmentMetadata'],
    postrequisite: 'audClientIntake',
    singlePageContent: true,
    shownParentActivity: 'audClientIntake',
  },
  audClientIntake: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['audCareConsent', 'audClientIntake', 'audInitialAssessmentMetadata'],
    prerequisite: 'audCareConsent',
    postrequisite: 'audInitialAssessmentMetadata',
  },
  audInitialAssessmentMetadata: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideInClientActivities: true,
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['audCareConsent', 'audClientIntake', 'audInitialAssessmentMetadata'],
    prerequisite: 'audClientIntake',
    postrequisite: 'baseYourTherapyWithLyra',
    shownParentActivity: 'audClientIntake',
  },
  baseYourCoachingWithLyra: {
    group: 'lessonPreviewMaximized',
  },
  baseYourTherapyWithLyra: {
    group: 'lessonPreviewMaximized',
  },
  bccCareConsent: {
    bypassReview: true,
    customActivityIntroduction: 'CareOnboardingHeader',
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideProgressBar: true,
    linkedStatusAssignments: ['bccCareConsent', 'bccClientIntake', 'bccBaselineMetadata'],
    postrequisite: 'bccClientIntake',
    singlePageContent: true,
    shownParentActivity: 'bccClientIntake',
  },
  bccClientIntake: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['bccCareConsent', 'bccClientIntake', 'bccBaselineMetadata'],
    prerequisite: 'bccCareConsent',
    postrequisite: 'bccBaselineMetadata',
  },
  bccBaselineMetadata: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideInClientActivities: true,
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['bccCareConsent', 'bccClientIntake', 'bccBaselineMetadata'],
    prerequisite: 'bccClientIntake',
    postrequisite: 'baseYourCoachingWithLyra',
    shownParentActivity: 'bccClientIntake',
  },
  bcmCareConsent: {
    bypassReview: true,
    customActivityIntroduction: 'CareOnboardingHeader',
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    linkedAssignmentTitle: 'Client Intake',
    hideProgressBar: true,
    linkedStatusAssignments: ['bcmCareConsent', 'bcmClientIntake', 'baseBaselineExtendedMetadata'],
    postrequisite: 'bcmClientIntake',
    singlePageContent: true,
    shownParentActivity: 'bcmClientIntake',
  },
  bcmClientIntake: {
    bypassReview: true,
    linkedAssignmentTitle: 'Client Intake',
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['bcmCareConsent', 'bcmClientIntake', 'baseBaselineExtendedMetadata'],
    prerequisite: 'bcmCareConsent',
    postrequisite: 'baseBaselineExtendedMetadata',
  },
  baseBaselineExtendedMetadata: {
    bypassReview: true,
    linkedAssignmentTitle: 'Client Intake',
    hideInClientActivities: true,
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['bcmCareConsent', 'bcmClientIntake', 'baseBaselineExtendedMetadata'],
    prerequisite: 'bcmClientIntake',
    shownParentActivity: 'bcmClientIntake',
  },
  bctCareConsent: {
    bypassReview: true,
    customActivityIntroduction: 'CareOnboardingHeader',
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideProgressBar: true,
    linkedStatusAssignments: ['bctCareConsent', 'bctClientIntake', 'baseInitialAssessmentMetadata'],
    postrequisite: 'bctClientIntake',
    singlePageContent: true,
    shownParentActivity: 'bctClientIntake',
  },
  bctClientIntake: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['bctCareConsent', 'bctClientIntake', 'baseInitialAssessmentMetadata'],
    prerequisite: 'bctCareConsent',
    postrequisite: 'baseInitialAssessmentMetadata',
  },
  baseInitialAssessmentMetadata: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideInClientActivities: true,
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['bctCareConsent', 'bctClientIntake', 'baseInitialAssessmentMetadata'],
    prerequisite: 'bctClientIntake',
    postrequisite: 'baseYourTherapyWithLyra',
    shownParentActivity: 'bctClientIntake',
  },
  cleCareConsent: {
    bypassReview: true,
    customActivityIntroduction: 'CareOnboardingHeader',
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    linkedAssignmentTitle: 'Client Intake',
    hideProgressBar: true,
    linkedStatusAssignments: ['cleCareConsent', 'cleClientIntake', 'cleInitialAssessment'],
    postrequisite: 'cleClientIntake',
    singlePageContent: true,
    shownParentActivity: 'cleClientIntake',
  },
  cleClientIntake: {
    bypassReview: true,
    linkedAssignmentTitle: 'Client Intake',
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['cleCareConsent', 'cleClientIntake', 'cleInitialAssessment'],
    prerequisite: 'cleCareConsent',
    postrequisite: 'cleInitialAssessment',
  },
  cleInitialAssessment: {
    bypassReview: true,
    linkedAssignmentTitle: 'Client Intake',
    hideInClientActivities: true,
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['cleCareConsent', 'cleClientIntake', 'cleInitialAssessment'],
    prerequisite: 'cleClientIntake',
    postrequisite: 'baseYourTherapyWithLyra',
    shownParentActivity: 'cleClientIntake',
  },
  lcttCareConsent: {
    bypassReview: true,
    customActivityIntroduction: 'CareOnboardingHeader',
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideProgressBar: true,
    linkedStatusAssignments: ['lcttCareConsent', 'lcttClientIntake', 'lcttInitialAssessment'],
    postrequisite: 'lcttClientIntake',
    singlePageContent: true,
    shownParentActivity: 'lcttClientIntake',
  },
  lcttClientIntake: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['lcttCareConsent', 'lcttClientIntake', 'lcttInitialAssessment'],
    prerequisite: 'lcttCareConsent',
    postrequisite: 'lcttInitialAssessment',
  },
  lcttInitialAssessment: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideInClientActivities: true,
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['lcttCareConsent', 'lcttClientIntake', 'lcttInitialAssessment'],
    prerequisite: 'lcttClientIntake',
    postrequisite: 'baseYourTherapyWithLyra',
    shownParentActivity: 'lcttClientIntake',
  },
  sscCareConsent: {
    bypassReview: true,
    customActivityIntroduction: 'CareOnboardingHeader',
    customErrorMessage: careConsentFormError,
    hideInClientActivities: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideProgressBar: true,
    linkedStatusAssignments: ['sscCareConsent', 'sscClientIntake', 'sscBaselineMetadata'],
    postrequisite: 'sscClientIntake',
    singlePageContent: true,
    shownParentActivity: 'sscClientIntake',
  },
  sscClientIntake: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['sscCareConsent', 'sscClientIntake', 'sscBaselineMetadata'],
    prerequisite: 'sscCareConsent',
    postrequisite: 'sscBaselineMetadata',
  },
  sscBaselineMetadata: {
    bypassReview: true,
    linkedAssignmentTitle: 'Care Onboarding',
    hideInClientActivities: true,
    hideInstructionsIfHasPrereq: true,
    initialPageIfHasPrereq: 1,
    linkedStatusAssignments: ['sscCareConsent', 'sscClientIntake', 'sscBaselineMetadata'],
    prerequisite: 'sscClientIntake',
    postrequisite: 'baseYourTherapyWithLyra',
    shownParentActivity: 'sscClientIntake',
  },
}
