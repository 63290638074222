import React from 'react'

import providerCalendarIllustrationUrl from '../../assets/providerCalendar.png'
import { Image } from '../image/Image'

interface ProviderCalendarIllustrationProps {
  width?: number
  height?: number
}

export function ProviderCalendarIllustration({
  width = 100,
  height = 100,
  ...props
}: ProviderCalendarIllustrationProps) {
  return (
    <Image
      source={providerCalendarIllustrationUrl}
      style={{ width, height }}
      contentFit='contain'
      {...props}
      accessibilityIgnoresInvertColors
    />
  )
}
