import axios from 'axios'
import { AnyAction, Dispatch } from 'redux'

import { updateStoredProviderResultsSurveyValues } from '../../common/utils/pendoUtils'
import mixpanel from '../../data/mixpanel/mixpanel-browser'
import { appRouter_DO_NOT_USE as appRouter } from '../../features/routing/legacyRouterShim'

export const RESET_STORE = 'RESET_STORE'
export const SET_IS_SESSION_TIMED_OUT = 'SET_IS_SESSION_TIMED_OUT'
export const RESET_HEALTHPLAN = 'RESET_HEALTHPLAN'
export const RESET_TRIAGE = 'RESET_TRIAGE'

export const resetStore = () => ({
  type: RESET_STORE,
})

export const resetHealthPlan = () => ({
  type: RESET_HEALTHPLAN,
})

export const resetTriage = () => ({
  type: RESET_TRIAGE,
})

export const setIsSessionTimedOut = (value: boolean) => ({
  type: SET_IS_SESSION_TIMED_OUT,
  value,
})

export const sessionExpiredLogout = () => {
  return (dispatch: Dispatch<AnyAction>) => {
    axios
      .get('/logout?expired=true')
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        mixpanel.reset()
        pendo?.clearSession?.()
        updateStoredProviderResultsSurveyValues()
        dispatch(resetStore())
        appRouter.navigate('/login')
      })
  }
}
