import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import {
  COMMON_MIXPANEL_PAGE,
  COMMON_MIXPANEL_PROPERTIES,
  getNewYearMicrositeUrl,
  NEW_YEAR_CMS_MICROSITE_MESSAGE,
  NEW_YEAR_CMS_MICROSITE_MESSAGE_KEY,
  NEW_YEAR_UTM_CAMPAIGN,
  NEW_YEAR_WEBINAR_LINK,
  PathwayOptions,
  useFlags,
  useLyraIntl,
  WELLNESS_TOOLKIT_EVENTS_PROPERTIES,
} from '@lyrahealth-inc/shared-app-logic'

import CMSMicrositeIframe from '../../common/components/CMSMicrositeIFrame/CMSMicrositeIFrame'
import LegalFooter from '../../common/components/footer/LegalFooter'
import { useTrackViewPage } from '../../common/hooks/useTrackViewPage'
import {
  getEssentialsDisabled,
  getHideDependentField,
  getIsCoachingEnabled,
  getIsCustomerHPIExclusive,
  getIsWorkhubDisabled,
  getIsWorkhubEventsDisabled,
  getNumberOfSessions,
} from '../../data/customer/customerSelectors'
import mixpanel from '../../data/mixpanel/mixpanel-browser'
import { useAppDispatch } from '../../data/storeConfiguration/store'
import { getIsUserInternational, getIsUserLoggedIn, getUserDisplayLanguage } from '../../data/user/userSelectors'
import { PAGE_ROUTES } from '../onboard/data/page-navigation/location-actions'
import { clearSelectedPathwayOption, setSelectedPathwayOption } from '../register/data/registerActions'
import RegistrationEmailCaptureContainer from '../register/setupAccount/RegistrationEmailCaptureContainer'

const NewYearMicrosite: FunctionComponent = () => {
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { activeLanguage } = useLyraIntl()
  const { isNewYearToolkitEnabled } = useFlags()
  const [searchParams, setSearchParams] = useSearchParams()
  const initialParams = useRef(searchParams)
  const initialSetSearchParams = useRef(setSearchParams) // setSearchParams setter changes when updating searchParams. Use ref to prevent unexpected side effects
  const [isShowingRegistrationModal, setIsShowingRegistrationModal] = useState(false)
  const [checkedRedirect, setCheckedRedirect] = useState(false)
  const addedUtmParams = useRef(false)

  const isInternational = useSelector(getIsUserInternational)
  const isEssentialsDisabled = useSelector(getEssentialsDisabled)
  const isLoggedIn = useSelector(getIsUserLoggedIn)
  const isWorkhubDisabled = useSelector(getIsWorkhubDisabled)
  const isWorkhubEventsDisabled = useSelector(getIsWorkhubEventsDisabled)
  const numSessions = useSelector(getNumberOfSessions)
  const isHPIExclusive = useSelector(getIsCustomerHPIExclusive)
  const isCoachingEnabled = useSelector(getIsCoachingEnabled)
  const userDisplayLanguage = useSelector(getUserDisplayLanguage)
  const hideDependentField = useSelector(getHideDependentField)

  const displayLanguage = isLoggedIn ? userDisplayLanguage : activeLanguage
  const micrositeUrl = getNewYearMicrositeUrl(isWorkhubDisabled, isWorkhubEventsDisabled, displayLanguage)

  const mixpanelEventProps = useMemo(() => {
    return { [WELLNESS_TOOLKIT_EVENTS_PROPERTIES.TOOLKIT_TOPIC]: 'new year' }
  }, [])
  const utmParams: { [key: string]: string } = useMemo(() => {
    const utmSource = initialParams.current.get('utm_source') || document.referrer
    const utmMedium = initialParams.current.get('utm_medium')
    return {
      utm_campaign: initialParams.current.get('utm_campaign') || NEW_YEAR_UTM_CAMPAIGN,
      ...(utmMedium && { utm_medium: utmMedium }),
      ...(utmSource && { utm_source: utmSource }),
    }
  }, [])
  const registrationEntryPointState = useMemo(() => {
    return {
      state: { registrationEntryPoint: COMMON_MIXPANEL_PAGE.NEW_YEAR_TOOLKIT },
    }
  }, [])

  const coverageHeroTextDefault = formatMessage(
    {
      defaultMessage:
        'With Lyra, you get {sessionCount} free therapy or coaching sessions, plus access to hundreds of on-demand resources.',
      description: 'Text summarizing lyra services',
    },
    { sessionCount: numSessions },
  )
  const coverageHeroTextHpiOnly = formatMessage({
    defaultMessage: 'Meet with top-tier providers, plus get access to hundreds of on-demand resources.',
    description: 'Text summarizing lyra services',
  })
  const coverageSectionDescriptionDefault = formatMessage(
    {
      defaultMessage:
        'You get {sessionCount} free therapy or coaching sessions, plus access to hundreds of on-demand resources.',
      description: 'Text summarizing lyra services',
    },
    { sessionCount: numSessions },
  )
  const coverageSectionHeaderDefault = formatMessage({
    defaultMessage: 'High-quality care at no cost to you',
    description: 'Text summarizing lyra services',
  })
  const coverageSectionHeaderHpiOnly = formatMessage({
    defaultMessage: 'High-quality care',
    description: 'Text summarizing lyra services',
  })
  const messagesHpi = {
    coverageHeroText: coverageHeroTextHpiOnly,
    coverageSectionHeader: coverageSectionHeaderHpiOnly,
    coverageSectionDescription: coverageHeroTextHpiOnly,
  }
  const messagesDefault = {
    coverageHeroText: coverageHeroTextDefault,
    coverageSectionHeader: coverageSectionHeaderDefault,
    coverageSectionDescription: coverageSectionDescriptionDefault,
  }
  const customMessages = isHPIExclusive ? messagesHpi : messagesDefault

  useTrackViewPage(
    COMMON_MIXPANEL_PAGE.MARKETING_TOOLKIT,
    {
      [COMMON_MIXPANEL_PROPERTIES.PAGE]: COMMON_MIXPANEL_PAGE.NEW_YEAR_TOOLKIT,
      ...utmParams,
      ...mixpanelEventProps,
      language: displayLanguage,
      pageLanguage: displayLanguage?.startsWith('es') ? 'spanish' : 'english',
    },
    undefined,
    true,
  )

  useEffect(() => {
    if (
      !isNewYearToolkitEnabled ||
      isEssentialsDisabled ||
      isInternational ||
      !isCoachingEnabled ||
      hideDependentField
    ) {
      navigate('/')
      return
    }
    setCheckedRedirect(true)
  }, [hideDependentField, isCoachingEnabled, isEssentialsDisabled, isInternational, isNewYearToolkitEnabled, navigate])

  useEffect(() => {
    if (!checkedRedirect || addedUtmParams.current) {
      return
    }
    addedUtmParams.current = true
    // Set default params to be tracked by mixpanel
    const newParams = new URLSearchParams(initialParams.current)
    for (const key in utmParams) {
      const value = utmParams[key]
      if (value && !initialParams.current.has(key)) {
        newParams.set(key, value)
      }
    }
    initialSetSearchParams.current(newParams)
    // Mixpanel doesn't always detect the new params so we should manually set it
    mixpanel.register(utmParams)
  }, [utmParams, checkedRedirect])

  const documentTitle = formatMessage({
    defaultMessage: 'Start the Year Off Right',
    description: 'Browser tab title text when visiting the new year page',
  })

  const goToCareOptions = useCallback(() => {
    navigate(PAGE_ROUTES.PATHWAYS.FORKED_TRIAGE)
  }, [navigate])

  const goToWellnessCheckIn = useCallback(() => {
    navigate(PAGE_ROUTES.WELLNESS_CHECK_IN.DEFAULT)
  }, [navigate])

  const goToEssentials = useCallback(() => {
    if (isLoggedIn) {
      navigate(PAGE_ROUTES.ESSENTIALS)
    } else {
      navigate(PAGE_ROUTES.PATHWAYS.ESSENTIALS_HOMEPAGE_WIDGET, registrationEntryPointState)
      dispatch(setSelectedPathwayOption(PathwayOptions.ESSENTIALS))
    }
  }, [dispatch, isLoggedIn, navigate, registrationEntryPointState])

  const registerWebinar = useCallback(() => {
    if (isLoggedIn) {
      navigate(NEW_YEAR_WEBINAR_LINK)
    } else {
      // Since modal opens on the toolkit, 'page' defaults new year toolkit but 'entryPoint' needs to be set
      const newParams = new URLSearchParams(searchParams)
      newParams.set(COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT, COMMON_MIXPANEL_PAGE.NEW_YEAR_TOOLKIT)
      setSearchParams(newParams)

      setIsShowingRegistrationModal(true)
      dispatch(setSelectedPathwayOption(PathwayOptions.NEW_YEAR_WEBINAR))
    }
  }, [dispatch, isLoggedIn, navigate, searchParams, setSearchParams])

  const goToRegistration = useCallback(() => {
    if (!isLoggedIn) {
      navigate('/register/connect', registrationEntryPointState)
    } else {
      goToCareOptions()
    }
  }, [goToCareOptions, isLoggedIn, navigate, registrationEntryPointState])

  const callbacks: { [key in NEW_YEAR_CMS_MICROSITE_MESSAGE]?: () => void } = useMemo(() => {
    return {
      [NEW_YEAR_CMS_MICROSITE_MESSAGE_KEY.NAVIGATE_TO_FORKED_TRIAGE]: goToCareOptions,
      [NEW_YEAR_CMS_MICROSITE_MESSAGE_KEY.NAVIGATE_TO_WELLNESS_CHECK_IN]: goToWellnessCheckIn,
      [NEW_YEAR_CMS_MICROSITE_MESSAGE_KEY.NAVIGATE_TO_ESSENTIALS]: goToEssentials,
      [NEW_YEAR_CMS_MICROSITE_MESSAGE_KEY.REGISTER_WEBINAR]: registerWebinar,
      [NEW_YEAR_CMS_MICROSITE_MESSAGE_KEY.NAVIGATE_TO_REGISTRATION]: goToRegistration,
    }
  }, [goToCareOptions, goToEssentials, goToRegistration, goToWellnessCheckIn, registerWebinar])

  const onCloseRegistrationModal = () => {
    const newParams = new URLSearchParams(searchParams)
    newParams.delete(COMMON_MIXPANEL_PROPERTIES.ENTRY_POINT)
    setSearchParams(newParams)

    setIsShowingRegistrationModal(false)
    dispatch(clearSelectedPathwayOption())
  }

  return (
    <>
      <CMSMicrositeIframe
        testId='NewYearPage'
        iFrameTitle={formatMessage({
          defaultMessage: 'New Year Resources',
          description: 'iFrame title for new year page',
        })}
        micrositeUrl={micrositeUrl}
        documentTitle={documentTitle}
        messageHandlers={callbacks}
        customMessages={customMessages}
        eventProps={mixpanelEventProps}
      />
      <LegalFooter />
      {isShowingRegistrationModal && (
        <RegistrationEmailCaptureContainer
          isModalView
          enableModalClose
          onCloseRegistrationModal={onCloseRegistrationModal}
          registrationEntryPoint={COMMON_MIXPANEL_PAGE.NEW_YEAR_TOOLKIT}
        />
      )}
    </>
  )
}

export default NewYearMicrosite
