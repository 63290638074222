import { isNil } from 'lodash-es'

import { axiosInstance } from '../../../../common/http/axiosInstance'
import { CareAdvisersDropdownOptions } from '../../../../data/graphql-queries/provider-profile-graphql'

const ListViewFragment = `
fragment listView on providerProfileGraphqlType{
  contactMethods
  contactEmail
  videoOnly
  contactPhone
  nearestAddress {
    city_state_zip
    street1
  }
  npi
  lyraId
  lyraType
  credentials
  name
  websites
  accessType
  nearestDistance
  nearestTravelTime
  isPreferred
  preference
  disqualified
  isBlocked
  onsite
  status
  statusText
  surface
  isNotAvailable
  isApprovalNeeded
  isCalendarConnected
  genders
  activeSuicidality
  neutralSuicidality
  yearsOfExperience
  isDisplayAsVideo
  videoChild
  videoCouples
  videoIndividual
  activeLicense
  license_info
  statedSensitivities
  capacity
  conditions
  languages
  treatments
  providerEthnicity
  racialStressPreferred
  rates
  hasCalendarAvailability
  providerTransgender
  providerSexualOrientation
  religions
}
`

const ListSearch = ({ type = 'navigatorProviders', queryString }: $TSFixMe) => {
  if (queryString === '') {
    return `query ListSearch {${type}{...listView}} ${ListViewFragment}`
  }
  return `query ListSearch {
  ${type}(${queryString}){
  	...listView
  }
}

${ListViewFragment}
`
}

// @ts-expect-error TS(7006): Parameter 'args' implicitly has an 'any' type.
const createQueryString = (args) => {
  const queryArgs = []
  for (const paramName in args) {
    if (!isNil(args[paramName])) {
      queryArgs.push(`${paramName}: "${args[paramName]}"`)
    }
  }
  return queryArgs.join(', ')
}

export default class MatcherSearchQueries {
  // @ts-expect-error TS(7006): Parameter 'args' implicitly has an 'any' type.
  static navigatorProvidersQuery(args) {
    const query = ListSearch({ type: 'navigatorProviders', queryString: createQueryString(args) })
    const data = {
      query,
      variables: { var: 'hi' },
      operationName: 'ListSearch',
    }
    const url = '/v1/graphql'
    return axiosInstance.post(url, data, { timeout: 60000 }).then(({ data }) => data)
  }

  // @ts-expect-error TS(7006): Parameter 'args' implicitly has an 'any' type.
  static directAccessProvidersQuery(args) {
    const query = ListSearch({ type: 'directAccessProviders', queryString: createQueryString(args) })
    const data = {
      query,
      variables: { var: 'hi' },
      operationName: 'ListSearch',
    }
    const url = '/v1/graphql'
    return axiosInstance.post(url, data, { timeout: 60000 }).then(({ data }) => data)
  }

  static getCareAdvisersDropdownOptions() {
    const queryName = 'CareAdviserDropdownQuery'
    const query = `query ${queryName} {${CareAdvisersDropdownOptions}}`

    const data = {
      query,
      variables: { var: 'hi' },
      operationName: queryName,
    }
    const url = '/v1/graphql'
    return axiosInstance.post(url, data, { timeout: 60000 }).then(({ data }) => data)
  }

  static getDropdownEnums() {
    const queryName = 'GetDropdownEnums'
    const query = `
            query ${queryName}{
  statusEnum: statusEnum{
    id
    text
  }

  careAdvisers: careAdvisers(dropdownType: "all"){
    id
    text
  }

  ownerDropdown:careAdvisers(dropdownType: "owner"){
    id
    text
  }

  approverDropdown:careAdvisers(dropdownType: "approver"){
    id
    text
  }

  ccnDropdown:careAdvisers(dropdownType: "ccn"){
    id
    text
  }
}
`
    const data = {
      query,
      variables: { var: 'hi' },
      operationName: queryName,
    }
    const url = '/v1/graphql'
    return axiosInstance.post(url, data, { timeout: 60000 }).then(({ data }) => data)
  }

  // @ts-expect-error TS(7006): Parameter 'args' implicitly has an 'any' type.
  static vettingProvidersQuery(args) {
    const query = ListSearch({ type: 'vettingProviders', queryString: createQueryString(args) })

    const data = {
      query,
      variables: { var: 'hi' },
      operationName: 'ListSearch',
    }
    const url = '/v1/graphql'

    return axiosInstance.post(url, data, { timeout: 60000 }).then(({ data }) => data)
  }

  static getStatusEnum() {
    const queryName = 'GetStatusEnum'
    const query = `
            query ${queryName}{
  options: statusEnum{
    id
    text
  }
}
`
    const data = {
      query,
      variables: { var: 'hi' },
      operationName: queryName,
    }
    const url = '/v1/graphql'
    return axiosInstance.post(url, data, { timeout: 60000 }).then(({ data }) => data)
  }
}
