import { DirectLinkIntent, PROGRAM_TAXONOMY } from './customerProgram'
import { DirectPathBookingApplinkParams } from '../../searchForCare/constants'

export const COACHING_PARAMS: DirectPathBookingApplinkParams = {
  directPath: 'true',
  clientele: PROGRAM_TAXONOMY.clientele.individual,
  treatment: PROGRAM_TAXONOMY.treatment.coaching,
  partner: PROGRAM_TAXONOMY.partner.blendedCare,
  offering: PROGRAM_TAXONOMY.offering.default,
}

export const PARENTING_PARAMS: DirectPathBookingApplinkParams = {
  directPath: 'true',
  clientele: PROGRAM_TAXONOMY.clientele.individual,
  treatment: PROGRAM_TAXONOMY.treatment.coaching,
  partner: PROGRAM_TAXONOMY.partner.blendedCare,
  offering: PROGRAM_TAXONOMY.offering.parenting,
  directLinkIntent: DirectLinkIntent.PARENTING,
}

export const MEDS_PARAMS = {
  clientele: PROGRAM_TAXONOMY.clientele.individual,
  offering: PROGRAM_TAXONOMY.offering.default,
  partner: PROGRAM_TAXONOMY.partner.blendedCare,
  treatment: PROGRAM_TAXONOMY.treatment.medicationManagement,
}

export const OUTPATIENT_MEDS_PARAMS: DirectPathBookingApplinkParams = {
  ...MEDS_PARAMS,
  directLinkIntent: DirectLinkIntent.OUTPATIENT_MEDS,
}

export const AUD_PARAMS: DirectPathBookingApplinkParams = {
  clientele: PROGRAM_TAXONOMY.clientele.individual,
  offering: PROGRAM_TAXONOMY.offering.alcoholUseDisorder,
  partner: PROGRAM_TAXONOMY.partner.blendedCare,
  treatment: PROGRAM_TAXONOMY.treatment.therapy,
  directLinkIntent: DirectLinkIntent.AUD,
}

export const COUPLES_PARAMS: DirectPathBookingApplinkParams = {
  directPath: 'true',
  clientele: PROGRAM_TAXONOMY.clientele.couples,
  treatment: PROGRAM_TAXONOMY.treatment.therapy,
  partner: PROGRAM_TAXONOMY.partner.directAccess,
  offering: PROGRAM_TAXONOMY.offering.default,
}

export const FAMILY_PARAMS: DirectPathBookingApplinkParams = {
  directPath: 'true',
  clientele: PROGRAM_TAXONOMY.clientele.family,
  treatment: PROGRAM_TAXONOMY.treatment.therapy,
  partner: PROGRAM_TAXONOMY.partner.directAccess,
  offering: PROGRAM_TAXONOMY.offering.default,
}
