import React from 'react'
import { useIntl } from 'react-intl'

import { CARE_ADVISOR_DISPLAY_PHONE_NUMBER, useFlags } from '@lyrahealth-inc/shared-app-logic'
import { ContentAlignment, NotificationBanner, NotificationLevel } from '@lyrahealth-inc/ui-core'
import { BodyText, BodyTextSize, colors, tID } from '@lyrahealth-inc/ui-core-crossplatform'

import styles from './banners.module.scss'

export const MaintenanceAlertBanner: React.FC = () => {
  const { formatMessage } = useIntl()
  const { isMaintenanceModeEnabled } = useFlags()
  return isMaintenanceModeEnabled ? (
    <NotificationBanner
      // @ts-expect-error TS(2769): No overload matches this call.
      level={NotificationLevel.WARNING}
      data-test-id={tID('MaintenanceAlertBanner')}
      className={styles['alert-banner']}
      contentAlignment={ContentAlignment.CENTER}
      notifications={[
        <div key='n1' className={styles['top-banner']}>
          <BodyText
            color={colors.white}
            size={BodyTextSize.LARGE}
            text={formatMessage(
              {
                defaultMessage:
                  'We are performing a scheduled maintenance. If you experience any issues and require immediate assistance please call {supportNumber} or email care@lyrahealth.com.',
                description:
                  'Text in a banner informing the user the site is currently under an expected maintenance and to call if they need help',
              },
              {
                supportNumber: CARE_ADVISOR_DISPLAY_PHONE_NUMBER,
              },
            )}
          />
        </div>,
      ]}
    />
  ) : null
}
