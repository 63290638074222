import { fromJS } from 'immutable'

import { BANNERS } from '../micrositeAndElsewhereTopBannerConstants'

export const amazonkids = () => {
  return fromJS({
    micrositeAndElsewhereTopBanner: {
      banners: [BANNERS.REGISTRATION_AND_LOGIN_DISABLED],
    },
  })
}
