import React, { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { View } from 'react-native'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import styled, { useTheme } from 'styled-components/native'

import {
  COMMON_MIXPANEL_EVENTS,
  COMMON_MIXPANEL_PROPERTIES,
  PathwayOptions,
  WellbeingResponse,
  WELLNESS_CHECK_IN_EVENTS,
  WELLNESS_CHECK_IN_PAGES,
} from '@lyrahealth-inc/shared-app-logic'
import { LoadingIndicator } from '@lyrahealth-inc/ui-core'
import {
  BodyText,
  CheckMarkIconV2,
  Subhead,
  SubheadSize,
  ThemeType,
  tID,
  WellnessCheckInCopyrightFooter,
  WellnessCheckInResults,
} from '@lyrahealth-inc/ui-core-crossplatform'

import {
  WELLNESS_CHECK_IN_PAGE_TYPE,
  WellnessCheckInHeader,
} from '../../common/components/header/WellnessCheckInHeader'
import { LOCAL_STORAGE_KEY } from '../../common/constants/appConstants'
import { useGetLegalFooterProps } from '../../common/hooks/useGetLegalFooterProps'
import { useShouldShowWellnessCheckIn } from '../../common/hooks/useShouldShowWellnessCheckIn'
import { logToSumoLogic } from '../../common/utils/userUtils'
import { trackEventWithObj } from '../../data/mixpanel'
import { useAppDispatch } from '../../data/storeConfiguration/store'
import { clearWellnessCheckInEntryPoint } from '../../data/wellnessCheckIn/wellnessCheckInActions'
import { getWellnessCheckInEntryPoint } from '../../data/wellnessCheckIn/wellnessCheckInSelectors'
import { setSelectedPathwayOption } from '../register/data/registerActions'
import RegistrationEmailCaptureContainer from '../register/setupAccount/RegistrationEmailCaptureContainer'
import VerifyEmailContainer, {
  VerifyEmailWellnessCheckInContainerProps,
} from '../register/setupAccount/VerifyEmailContainer'

const WellnessCheckInHeaderContainer = styled(Subhead)<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.breakpoints.isMobileSized ? theme.spacing['24px'] : theme.spacing['24px'],
  marginBottom: theme.breakpoints.isMobileSized ? theme.spacing['16px'] : theme.spacing['24px'],
}))

const WellnessCheckInContainer = styled(View)<{ theme: ThemeType }>(({ theme }) => ({
  margin: `0 auto ${theme.spacing['32px']} auto`,
}))

const WellnessCheckInText = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  color: theme.colors.textSecondary,
  marginLeft: theme.spacing['12px'],
}))

const WellnessCheckInTitleText = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['8px'],
}))

const WellnessCheckInTextContainer = styled(View)<{ theme: ThemeType }>(({ theme }) => ({
  flexDirection: 'row',
  marginBottom: theme.spacing['8px'],
}))

const WellnessCheckInPreviewContainer: FunctionComponent = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const wellnessCheckInEntryPoint = useSelector(getWellnessCheckInEntryPoint)
  const { colors, breakpoints } = useTheme()
  const { formatMessage } = useIntl()
  const [wellbeingResponse, setWellbeingResponse] = useState<WellbeingResponse | null | undefined>(null)
  const [verifyEmailProps, setVerifyEmailProps] = useState<VerifyEmailWellnessCheckInContainerProps | null>(null)
  const showWellnessCheckIn = useShouldShowWellnessCheckIn()
  const isLoading = wellbeingResponse === null
  const legalFooterProps = useGetLegalFooterProps()

  useEffect(() => {
    dispatch(setSelectedPathwayOption(PathwayOptions.WELLNESS_CHECK_IN))
  }, [dispatch])

  const onSubmit = (email: string, error?: string) => {
    setVerifyEmailProps({
      registeredEmail: email,
      emailStateError: error,
    })
  }

  const BENEFIT_MESSAGES = [
    formatMessage({
      defaultMessage: 'Mental wellness sessions',
      description: 'Wellness check in registration mental wellness sessions description',
    }),
    formatMessage({
      defaultMessage: 'Meditations, articles & more',
      description: 'Wellness check in registration meditation description',
    }),
    formatMessage({
      defaultMessage: '24/7 Care Navigator support',
      description: 'Wellness check in registration care support description',
    }),
  ]

  const verifyEmailHeaderElements = (
    <View testID={tID('WellnessCheckInRegistrationHeader')}>
      <WellnessCheckInHeaderContainer
        size={breakpoints.isMobileSized ? SubheadSize.MEDIUM : SubheadSize.LARGE}
        text={formatMessage({
          defaultMessage: 'Sign up to see your full results',
          description: 'A title above the registration form for wellness check in',
        })}
        textAlign={'center'}
        testID={`${name}-title`}
      />
      <WellnessCheckInContainer>
        <WellnessCheckInTitleText
          text={formatMessage({
            defaultMessage: 'Your Lyra health account also includes:',
            description: 'Wellness check in registration title',
          })}
        />
        {BENEFIT_MESSAGES.map((benefitMessage, index) => {
          return (
            <WellnessCheckInTextContainer key={index}>
              <CheckMarkIconV2 fillColor={colors.iconActive} width={'24'} height={'24'} />
              <WellnessCheckInText text={benefitMessage} color={colors.textPrimary} />
            </WellnessCheckInTextContainer>
          )
        })}
      </WellnessCheckInContainer>
    </View>
  )

  const registrationContainer = verifyEmailProps ? (
    // @ts-expect-error TS(2604): FIXME! JSX element type 'RegisterVerifyEmail' does not have any construct or call signatures.
    <VerifyEmailContainer {...verifyEmailProps} isPreview />
  ) : (
    <RegistrationEmailCaptureContainer
      isPreview
      previewSubmitCallback={onSubmit}
      previewComponents={verifyEmailHeaderElements}
    />
  )

  const registrationWithFooter = (
    <>
      {registrationContainer}
      <WellnessCheckInCopyrightFooter
        {...legalFooterProps}
        wellnessCheckInFooterBackgroundColor={colors.backgroundPrimary}
      />
    </>
  )

  const onEmptyStatePress = () => {
    dispatch(
      trackEventWithObj({
        event: WELLNESS_CHECK_IN_EVENTS.CLICK_EMPTY_STATE_START_AGAIN,
        [COMMON_MIXPANEL_PROPERTIES.ACTION]: COMMON_MIXPANEL_EVENTS.CLICK,
      }),
    )
    dispatch(clearWellnessCheckInEntryPoint())
    navigate('/wellness-check-in', { state: { mixpanelEntryPoint: wellnessCheckInEntryPoint } })
  }

  useEffect(() => {
    if (!showWellnessCheckIn) {
      return
    }

    const wellnessCheckInStorageValue = localStorage.getItem(LOCAL_STORAGE_KEY.WELLNESS_CHECK_IN)
    if (isLoading) {
      if (wellnessCheckInStorageValue) {
        try {
          const latestWellbeingResponseWithForm = JSON.parse(wellnessCheckInStorageValue)
          setWellbeingResponse(latestWellbeingResponseWithForm?.wellbeingResponse)
        } catch (error) {
          const errorMsg = `Unable to parse wellness check in data from storage in preview: ${wellnessCheckInStorageValue}`
          console.error(errorMsg, error)
          logToSumoLogic('wellnessCheckInPreview', undefined, {
            message: errorMsg,
            type: 'error',
          })
          setWellbeingResponse(undefined)
        }
      } else {
        const errorMsg = 'Unable to find wellness check in from storage in preview'
        console.error(errorMsg)
        logToSumoLogic('wellnessCheckInPreview', undefined, {
          message: errorMsg,
          type: 'error',
        })
        setWellbeingResponse(undefined)
      }
    }
  }, [isLoading, showWellnessCheckIn, wellbeingResponse])

  const handleOnLoad = useCallback(() => {
    dispatch(
      trackEventWithObj({
        event: `view ${WELLNESS_CHECK_IN_PAGES.PREVIEW} page`,
        [COMMON_MIXPANEL_PROPERTIES.ACTION]: COMMON_MIXPANEL_EVENTS.PAGE_LOAD,
      }),
    )
  }, [dispatch])

  const handleOnEmptyStateLoad = useCallback(() => {
    dispatch(
      trackEventWithObj({
        event: `view ${WELLNESS_CHECK_IN_PAGES.EMPTY_STATE_RESULTS}`,
        [COMMON_MIXPANEL_PROPERTIES.ACTION]: COMMON_MIXPANEL_EVENTS.PAGE_LOAD,
      }),
    )
  }, [dispatch])

  if (!showWellnessCheckIn) {
    return <></>
  }

  if (isLoading) {
    return <LoadingIndicator />
  }

  return (
    <>
      <WellnessCheckInHeader pageType={WELLNESS_CHECK_IN_PAGE_TYPE.Preview} />
      <WellnessCheckInResults
        wellbeingResponse={wellbeingResponse}
        isSocialNeedEnabled={false}
        onEmptyStatePress={onEmptyStatePress}
        isPreview
        previewComponents={registrationWithFooter}
        footerComponents={<WellnessCheckInCopyrightFooter {...legalFooterProps} />}
        onLoad={handleOnLoad}
        onEmptyStateLoad={handleOnEmptyStateLoad}
      />
    </>
  )
}

export default WellnessCheckInPreviewContainer
