import React, { forwardRef, Fragment, ReactNode, useContext, useState } from 'react'
import { createPortal } from 'react-dom'
import { Dimensions, LayoutChangeEvent, ScrollView, View } from 'react-native'
import { Portal } from 'react-native-portalize'

import BottomSheetGorhom, {
  BottomSheetBackdrop,
  BottomSheetBackdropProps,
  BottomSheetScrollView,
} from '@gorhom/bottom-sheet'
import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { CloseIcon } from '../../atoms/icons/CloseIcon'
import { PressableOpacity } from '../../atoms/pressableOpacity/PressableOpacity'
import { IS_WEB } from '../../constants'
import { AppContext } from '../../context'
import { tID } from '../../utils'

export type BottomSheetProps = {
  children: ReactNode
  snapPoints?: (string | number)[]
  onCloseEnd?: () => void
  showCloseIcon?: boolean
  /** On some context, portalized bottom sheet does not work on web. turn off if so */
  withPortal?: boolean
  /** Height of the bottom sheet is based on the content height. `snapPoints` are ignored. */
  useDynamicHeight?: boolean
  /** Use to attach the bottom sheet to the document.body on web */
  useWebPortal?: boolean
  /** Show panel handle. Hidden by default */
  showHandle?: boolean
  /** Index of snapPoints where the bottom sheet should initially be positioned. Defaults to 0 (open) */
  index?: number
  /** If content scrolls, we have to use `BottomSheetScrollView` which handles scrolling and bottom sheet gestures */
  enableScroll?: boolean
  /** some bottom sheets have textarea and when user clicks into it, the dynamic height is required, but sometimes it needs a default */
  customBottomSheetHeight?: number | null
  /** set to true if bottom sheet should stay open regardless of scroll down, backdrop press, etc. */
  disableClose?: boolean
  /** Use to show content that overflows the bottom sheet (example: AppPromoBottomSheet) */
  overflowVisible?: boolean
  backgroundColor?: string
  removeBorderRadius?: boolean
  /** Set to false prevent bounce when users scrolls the modal */
  enableContentPanningGesture?: boolean
}

const CloseIconContainer = styled(PressableOpacity)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing['24px'],
  right: theme.spacing['24px'],
  zIndex: 1,
}))

const ContentContainer = styled.View<{ overflowVisible: boolean }>(({ overflowVisible }) => ({
  overflow: overflowVisible ? 'visible' : 'hidden',
}))

// This is needed by web so that the snap point can be set
// based on the height of the bottom sheet rather than in
// relation to the scroll position on the page.
const FixedWebContainer = styled.View({
  position: 'fixed',
  top: '0px',
  right: '0px',
  bottom: '0px',
  left: '0px',
  ...(IS_WEB && { pointerEvents: 'none' }),
})

export const BottomSheet = forwardRef(
  (
    {
      children,
      snapPoints = ['100%'],
      showHandle = false,
      index = 0,
      onCloseEnd = noop,
      showCloseIcon = false,
      withPortal = true,
      useDynamicHeight = false,
      useWebPortal = false,
      enableScroll = false,
      customBottomSheetHeight = null,
      disableClose = false,
      overflowVisible = false,
      backgroundColor,
      removeBorderRadius = false,
      enableContentPanningGesture = true,
    }: BottomSheetProps,
    ref: any,
  ) => {
    const value = useContext(AppContext)
    const Container = IS_WEB ? FixedWebContainer : Fragment
    const { setModalStatusOpen } = useContext(AppContext)
    const DEFAULT_BOTTOM_SHEET_HEIGHT = 375
    const [dynamicSnapPoint, setDynamicSnapPoint] = useState<number | null>(
      customBottomSheetHeight ?? DEFAULT_BOTTOM_SHEET_HEIGHT,
    )
    const windowHeight = Dimensions.get('window').height
    const ScrollContainer =
      enableScroll || (dynamicSnapPoint && dynamicSnapPoint > windowHeight) ? BottomSheetScrollView : Fragment
    const { colors } = useTheme()

    const handleStyle = showHandle
      ? {
          width: 40,
          backgroundColor: colors.dividerSecondary,
        }
      : { height: 0 }

    let snapPointsProcessed = snapPoints.filter((p) => p !== 0)
    snapPointsProcessed =
      useDynamicHeight && dynamicSnapPoint
        ? [Math.min(windowHeight, dynamicSnapPoint)]
        : snapPointsProcessed.length
        ? snapPointsProcessed
        : ['100%']

    const onClose = () => {
      setModalStatusOpen && setModalStatusOpen(false)
      onCloseEnd && onCloseEnd()
    }

    const closeSheet = () => {
      ref?.current?.close()

      // Sometimes the sheet can be dismissed really quickly before it fully opens and onChange does not trigger. Timeout is set to allow the animation to run.
      setTimeout(() => {
        onClose()
      }, 500)
    }

    const handleBottomSheetPositionChange = (index: number) => {
      if (index === -1) {
        onClose()
      } else {
        setModalStatusOpen && setModalStatusOpen(true)
      }
    }

    const handleOnLayout = (e: LayoutChangeEvent) => {
      if (dynamicSnapPoint && e.nativeEvent.layout.height > dynamicSnapPoint) {
        setDynamicSnapPoint(e.nativeEvent.layout.height)
      }
    }

    const renderContent = () => (
      <ContentContainer
        as={enableScroll ? ScrollView : View}
        testID={tID('BottomSheet-content')}
        onLayout={handleOnLayout}
        overflowVisible={overflowVisible}
      >
        <ScrollContainer>
          {showCloseIcon && (
            <CloseIconContainer testID={tID('BottomSheet-closeButton')} onPress={closeSheet}>
              <CloseIcon size={14} />
            </CloseIconContainer>
          )}
          {children}
        </ScrollContainer>
      </ContentContainer>
    )

    const backdropComponent = (props: BottomSheetBackdropProps) => (
      <BottomSheetBackdrop
        {...props}
        disappearsOnIndex={-1}
        appearsOnIndex={1}
        opacity={2} // opacity gets divided by 2?
        style={{ backgroundColor: colors.backgroundScrim }}
        pressBehavior={disableClose ? 'none' : 'close'}
      />
    )

    const renderBottomSheet = () => (
      <Container>
        <AppContext.Provider value={value}>
          <BottomSheetGorhom
            ref={ref}
            snapPoints={snapPointsProcessed}
            index={index}
            backdropComponent={backdropComponent}
            handleIndicatorStyle={handleStyle}
            handleStyle={!showHandle && { padding: 0 }}
            enablePanDownToClose={!disableClose}
            enableContentPanningGesture={enableContentPanningGesture}
            onChange={handleBottomSheetPositionChange}
            backgroundStyle={{
              backgroundColor: backgroundColor ?? colors.backgroundPrimary,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderTopLeftRadius: removeBorderRadius ? 0 : 16,
              borderTopRightRadius: removeBorderRadius ? 0 : 16,
            }}
            detached={overflowVisible}
          >
            {renderContent()}
          </BottomSheetGorhom>
        </AppContext.Provider>
      </Container>
    )

    return withPortal ? (
      IS_WEB && useWebPortal ? (
        createPortal(renderBottomSheet(), document.body)
      ) : (
        <Portal>{renderBottomSheet()}</Portal>
      )
    ) : (
      renderBottomSheet()
    )
  },
)
