import React, { FunctionComponent } from 'react'
import Svg, { G, Path } from 'react-native-svg'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'

export const GearIcon: FunctionComponent<GearIconProps> = ({ size = 24, color, isFilled = true }) => {
  const { colors } = useTheme()

  return (
    <Svg width={size} height={size} viewBox='0 0 24 24' fill='none' testID={tID('GearIcon')}>
      {isFilled ? (
        <Path
          fillRule='evenodd'
          d='M19.329 12c0 .32-.025.63-.061.926l2.04 1.568a.452.452 0 0 1 .122.605l-1.955 3.29c-.122.213-.378.297-.598.213l-2.43-.95c-.502.369-1.051.69-1.65.927l-.366 2.517c-.025.238-.232.404-.477.404h-3.908a.493.493 0 0 1-.489-.404l-.366-2.517a7.209 7.209 0 0 1-1.65-.927l-2.43.95a.503.503 0 0 1-.599-.213l-1.954-3.29a.482.482 0 0 1 .122-.605l2.065-1.568A5.807 5.807 0 0 1 4.67 12c0-.309.037-.63.086-.926L2.693 9.506a.452.452 0 0 1-.123-.605l1.955-3.29c.122-.213.378-.297.598-.213l2.43.95c.502-.369 1.051-.69 1.65-.927l.366-2.517a.471.471 0 0 1 .477-.404h3.908c.245 0 .452.166.489.404l.366 2.517a7.209 7.209 0 0 1 1.65.926l2.43-.95c.22-.07.476 0 .599.214l1.954 3.29a.482.482 0 0 1-.122.605l-2.064 1.568c.048.297.073.605.073.926ZM8.437 12A3.573 3.573 0 0 0 12 15.563 3.573 3.573 0 0 0 15.563 12 3.573 3.573 0 0 0 12 8.437 3.573 3.573 0 0 0 8.437 12Z'
          clipRule='evenodd'
          fill={color ?? colors.iconActive}
        />
      ) : (
        <G fill={color ?? colors.iconActive}>
          <Path d='M13.5 21.8H9.9c-.3 0-.6-.2-.7-.6l-.5-1.8c-.7-.3-1.3-.6-1.9-1.1l-1.8.5c-.3.1-.7 0-.9-.3l-1.8-3.1c-.2-.3-.1-.7.1-.9l1.3-1.3c-.1-.4-.1-.7-.1-1.1 0-.4 0-.7.1-1.1L2.5 9.6c-.3-.2-.3-.6-.1-.9l1.8-3.1c.1-.3.5-.5.8-.4l1.8.5c.6-.4 1.2-.8 1.9-1.1l.5-1.8c.1-.3.4-.6.7-.6h3.6c.3 0 .6.2.7.6l.5 1.8c.7.3 1.3.6 1.9 1.1l1.8-.5c.3-.1.7 0 .9.3L21 8.7c.2.3.1.7-.1.9l-1.3 1.3c.1.4.1.7.1 1.1 0 .4 0 .7-.1 1.1l1.3 1.3c.2.2.3.6.1.9l-1.8 3.1c-.2.3-.5.4-.9.3l-1.8-.5c-.6.5-1.2.8-1.9 1.1l-.5 1.8c0 .4-.3.7-.6.7Zm-3-1.6h2.4l.4-1.6c.1-.2.2-.4.5-.5.8-.3 1.5-.7 2.1-1.2.2-.2.5-.2.7-.2l1.6.5 1.2-2.1-1.2-1.2c-.2-.2-.3-.4-.2-.7.1-.4.1-.8.1-1.2 0-.4 0-.8-.1-1.2 0-.2 0-.5.2-.7l1.2-1.2-1.2-2.1-1.6.5c-.2.1-.5 0-.7-.2-.6-.5-1.3-1-2.1-1.2-.2-.1-.4-.3-.5-.5l-.4-1.6h-2.4l-.4 1.6c-.1.2-.3.4-.5.5-.8.3-1.5.7-2.1 1.2-.2.2-.5.3-.7.2l-1.6-.5-1.3 2.1 1.2 1.2c.2.2.3.4.2.7-.1.4-.1.8-.1 1.2 0 .4 0 .8.1 1.2 0 .2 0 .5-.2.7l-1.2 1.2 1.2 2.1 1.6-.5c.2-.1.5 0 .7.2.6.5 1.3 1 2.1 1.2.2.1.4.3.5.5l.5 1.6Z' />
          <Path d='M11.7 15.6C9.7 15.6 8 14 8 12s1.6-3.6 3.6-3.6 3.6 1.6 3.6 3.6-1.5 3.6-3.5 3.6Zm0-5.7c-1.2 0-2.1 1-2.1 2.1s1 2.1 2.1 2.1 2.1-1 2.1-2.1-.9-2.1-2.1-2.1Z' />
        </G>
      )}
    </Svg>
  )
}

type GearIconProps = {
  size?: string | number
  color?: string
  isFilled?: boolean
}
