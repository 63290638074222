import React from 'react'
import { createBrowserRouter, createRoutesFromElements, Navigate, Outlet, parsePath, Route } from 'react-router-dom'

import * as Sentry from '@sentry/react'

import { HostWrapper } from '@lyrahealth-inc/ui-core-crossplatform'

import { NavigateListener, UNSAFE_listenBeforeNavigate, UNSAFE_patchRouterNavigate } from './patchRouterNavigate'
import { PreviousRouteProvider } from './PreviousRouteProvider'
import usePathChanged from './usePathChanged'
import { MainAppLayout } from '../../common/components/appLayout/MainAppLayout'
import RouterErrorBoundaryWrapper from '../../common/components/error/ErrorBoundaryWrapper/RouterErrorBoundaryWrapper'
import FloatingFooter from '../../common/components/footer/FloatingFooter'
import InactivityWarningScreen from '../../common/components/inactivityWarningScreen/InactivityWarningScreen'
import { STICKY_QUERY_PARAMS } from '../../common/constants/appConstants'
import { preserveSearchParams } from '../../common/utils/routingUtils'
import ElectionMicrosite from '../electionMicrosite/ElectionMicrosite'
import { LyraWebIntlProvider } from '../intl/LyraWebIntlProvider'
import CustomerSpecificInstanceRedirectionContainer from '../microsite/components/customerSpecificInstanceRedirection/CustomerSpecificInstanceRedirectionContainer'
import { MicrositeRoutes } from '../microsite/micrositeRoutes'
import NewYearMicrosite from '../newYearMicrosite/NewYearMicrosite'
import { PAGE_ROUTES } from '../onboard/data/page-navigation/location-actions'
import { EssentialsHomepageWidgetContainer } from '../pathways/EssentialsHomepageWidgetContainer'
import ForkedTriage from '../pathways/pageWrappers/ForkedTriage'
import AppleRegistrationContainer from '../register/AppleRegistrationContainer'
import GoogleRegistrationContainer from '../register/GoogleRegistrationContainer'
import SelfCareWellnessTopicContainer from '../selfCareWellnessTopic/SelfCareWellnessTopicContainer'
import { UnsubscribeContainer } from '../unsubscribe/UnsubscribeContainer'
import WellnessCheckInFormContainer from '../wellnessCheckIn/WellnessCheckInFormContainer'
import WellnessCheckInOverviewContainer from '../wellnessCheckIn/WellnessCheckInOverviewContainer'
import WellnessCheckInPreviewContainer from '../wellnessCheckIn/WellnessCheckInPreviewContainer'

const RegisterRoutes = React.lazy(() => import(/* webpackChunkName: "Register" */ '../register/registerRoutes'))
const MobileRoutes = React.lazy(() => import(/* webpackChunkName: "Mobile" */ './mobileRoutes'))
const SecureRoutes = React.lazy(() => import(/* webpackChunkName: "Secure" */ './secureRoutes'))

const ContactUs = React.lazy(() => import(/* webpackChunkName: "ContactUs" */ '../chatLive/ContactUs'))
const FaqView = React.lazy(() => import(/* webpackChunkName: "FaqView" */ '../faq/FaqView'))
const ForgotPasswordContainer = React.lazy(
  () => import(/* webpackChunkName: "ForgotPassword" */ '../forgotPasswordContainer/ForgotPasswordContainer'),
)
const ResetPasswordContainer = React.lazy(
  () => import(/* webpackChunkName: "ResetPassword" */ '../resetPasswordContainer/ResetPasswordContainer'),
)
const ChangeEmailConfirmationContainer = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ChangeEmailConfirmation" */ '../editProfile/changeEmail/ChangeEmailConfirmationContainer'
    ),
)

const Login = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Login" */
      /* webpackPrefetch: true  */
      '../login/Login'
    ),
)
const SatisfactionSurvey = React.lazy(
  () => import(/* webpackChunkName: "SatisfactionSurvey" */ '../satisfactionSurvey/SatisfactionSurvey'),
)
const SatisfactionSurveyConfirmation = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SatisfactionSurveyConfirmation" */ '../satisfactionSurvey/SatisfactionSurveyConfirmation'
    ),
)
const VerifyAttestation = React.lazy(
  () => import(/* webpackChunkName: "VerifyAttestation" */ '../verifyAttestation/VerifyAttestation'),
)
const WorkLife = React.lazy(() => import(/* webpackChunkName: "WorkLife" */ '../workLife/WorkLife'))

const RouterPathChanged = () => {
  usePathChanged()
  return <Outlet />
}

const PreviousRoute = () => {
  return (
    <PreviousRouteProvider>
      <Outlet />
    </PreviousRouteProvider>
  )
}

const createRootRoutes = () => {
  const mainAppRouteContainer = (
    <HostWrapper>
      <MainAppLayout>
        <Outlet />
        <InactivityWarningScreen />
      </MainAppLayout>
    </HostWrapper>
  )

  const mainAppIntlContainer = (
    <LyraWebIntlProvider>
      <Outlet />
    </LyraWebIntlProvider>
  )

  const mainAppRoutes = (
    <Route element={mainAppRouteContainer}>
      <Route
        element={
          <>
            <Outlet />
            <FloatingFooter />
          </>
        }
      >
        <Route path='unsubscribe' element={<UnsubscribeContainer />} />
        <Route path='satisfaction' element={<SatisfactionSurvey />} />
        <Route path='satisfaction/confirmation' element={<SatisfactionSurveyConfirmation />} />
        <Route
          element={
            <>
              <Outlet />
              <CustomerSpecificInstanceRedirectionContainer />
            </>
          }
        >
          <Route path='login' element={<Login />} />
          <Route path='forgot-password' element={<ForgotPasswordContainer />} />
        </Route>
        <Route path='reset-password' element={<ResetPasswordContainer />} />
        <Route path='reset-pwd' element={<ResetPasswordContainer />} />
        <Route path='faq' element={<FaqView />} />
        <Route path='worklife' element={<WorkLife />} />
        <Route path='verify-attestation' element={<VerifyAttestation />} />
        <Route path='confirm-change-email' element={<ChangeEmailConfirmationContainer />} />
      </Route>
      <Route path='register/*' element={<RegisterRoutes />} />
      <Route path='secure/*' element={<SecureRoutes />} />
      <Route path='pathways'>
        <Route path='care-options' element={<ForkedTriage />} />
        <Route path='self-care-options' element={<SelfCareWellnessTopicContainer />} />
        <Route path='essentials-homepage-widget' element={<EssentialsHomepageWidgetContainer />} />
      </Route>
      <Route path='election' element={<ElectionMicrosite />} />
      <Route path='members' element={<NewYearMicrosite />} />
      <Route path='wellness-check-in' element={<WellnessCheckInOverviewContainer />} />
      <Route path='wellness-check-in/preview' element={<WellnessCheckInPreviewContainer />} />
      <Route path='wellness-check-in/:pageIndex' element={<WellnessCheckInFormContainer />} />
      <Route path='*!survey' element={<Navigate to='login' replace />} />
    </Route>
  )

  const rootRoutes = (
    <>
      <Route element={<RouterErrorBoundaryWrapper />}>
        <Route element={<RouterPathChanged />}>
          <Route element={<PreviousRoute />}>
            {/* /logout redirects to /home */}
            <Route path='home' element={<Navigate to='/' />} />
            {MicrositeRoutes}
            <Route element={mainAppIntlContainer}>
              {mainAppRoutes}
              {/* Google SSO button embedded in an iframe */}
              <Route path={PAGE_ROUTES.SSO_REGISTRATION_GOOGLE} element={<GoogleRegistrationContainer />} />
              {/* Apple SSO button embedded in an iframe */}
              <Route path={PAGE_ROUTES.SSO_REGISTRATION_APPLE} element={<AppleRegistrationContainer />} />
              <Route path='contact-us' element={<ContactUs />} />
              {/* Mobile */}
              <Route path='lyraweb-mobile/*' element={<MobileRoutes />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </>
  )

  return rootRoutes
}

// Some monkey business to listen to router navigations
// Preserves app-wide query parameters in the URL across route changes
const preserveSearchParamsListener: NavigateListener = (navigateArgs, { location }) => {
  const [toArg] = navigateArgs
  if (typeof toArg === 'number') {
    return
  }
  let to
  if (typeof toArg === 'string') {
    to = parsePath(toArg)
  } else {
    to = { ...toArg }
  }
  if (location.search) {
    to.search = preserveSearchParams(STICKY_QUERY_PARAMS, location.search, to.search)
    navigateArgs[0] = to
  }
}

export function createRouter() {
  const routes = createRoutesFromElements(createRootRoutes())
  const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)
  const router = sentryCreateBrowserRouter(routes)

  UNSAFE_patchRouterNavigate(router)
  UNSAFE_listenBeforeNavigate(router, preserveSearchParamsListener)

  return router
}
