import { useMemo } from 'react'

import { CustomerPropertiesMap } from '../../../models'
import {
  CoachingToolkitAvailabilityInfo,
  useForkedTriageAvailability,
} from '../../common/hooks/useForkedTriageAvailability'
import { IS_PATHWAY_OPTION_ON_FORKED_TRIAGE, TEEN_ENABLED_PATHWAYS } from '../../pathways/constants'

export const useShouldShowForkedTriageEntrypoint = ({
  isUserTeen,
  customerPropertiesMap,
  coachingToolkitAvailability,
}: {
  isUserTeen: boolean
  customerPropertiesMap?: CustomerPropertiesMap
  coachingToolkitAvailability?: CoachingToolkitAvailabilityInfo
}) => {
  // if forkedTriageAvailable is true then there are 3 or more forked triage options available
  const { forkedTriageAvailable } = useForkedTriageAvailability({
    customerPropertiesMap,
    coachingToolkitAvailability,
  })
  return useMemo(() => {
    // Essentials is a pathway option that does NOT have an entrypoint in Forked Triage
    return isUserTeen
      ? TEEN_ENABLED_PATHWAYS.filter((option) => IS_PATHWAY_OPTION_ON_FORKED_TRIAGE[option]).length > 1
      : forkedTriageAvailable
  }, [forkedTriageAvailable, isUserTeen])
}
