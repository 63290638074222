import React, { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { View } from 'react-native'

import { noop } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { BodyText, PressableOpacity, PrimaryButton, Subhead, XIcon } from '../../atoms'
import * as Icons from '../../atoms/icons/index'
import * as Illustrations from '../../atoms/illustrations/index'
import { SubheadSize } from '../../styles'
import { ColumnView, RowView } from '../../templates'
import { ThemeType } from '../../utils/themes/ThemeProvider'
import { tID } from '../../utils/utils'
import { Modal } from '../modal/Modal'

export interface ProgramDetailsContent {
  headerIllustration: string
  header: string
  description: string
  programPoints: { icon: string; description: string | React.ReactNode }[]
  centerProgramPoints?: boolean
  ctaText?: string
  isModalOpenInitially?: boolean
  closeModalIfOpen?: boolean
  onModalOpen?: () => void
  /** On user dismissing the modal. Skipped if the cta is pressed */
  onModalClose?: () => void
}

export interface ProgramDetailsModalProps {
  testId: string
  modalTrigger: React.ReactNode
  onCtaPress?: () => void
  programContent: ProgramDetailsContent
}

const StyledModal = styled(Modal)<{ theme: ThemeType }>(({ theme }) => ({
  ...(!theme.breakpoints.isMobileSized && { width: '624px' }),
}))

const ContentsContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  alignItems: 'center',
  ...(theme.breakpoints.isMobileSized && {
    padding: `${theme.spacing['16px']} ${theme.spacing['16px']} ${theme.spacing['32px']} ${theme.spacing['16px']}`,
    maxHeight: '100vh',
    overflow: 'auto',
  }),
}))

const CloseButton = styled(PressableOpacity)<{ theme: ThemeType }>(({ theme }) => ({
  alignSelf: 'flex-end',
  position: 'absolute',
  top: 0,
  right: 0,
  ...(theme.breakpoints.isMobileSized && {
    padding: theme.spacing['12px'],
  }),
}))

const HeaderStyled = styled(Subhead)<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.breakpoints.isMobileSized ? theme.spacing['16px'] : theme.spacing['24px'],
}))

const DescriptionContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['16px'],
}))

const ProgramPointsContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['24px'],
  width: '100%',
}))

const ProgramPointsListContainer = styled(ColumnView)<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['12px'],
  gap: theme.spacing['24px'],
}))

const ProgramPointLabel = styled(View)<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: theme.spacing['24px'],
  flex: 1,
}))

const ButtonContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['24px'],
  width: '100%',
}))

const ButtonStyled = styled(PrimaryButton)<{ theme: ThemeType }>(({ theme }) => ({
  paddingHorizontal: 40,
  ...(!theme.breakpoints.isMobileSized && {
    alignSelf: 'flex-end',
  }),
}))

const Row = styled(RowView)<{ centerProgramPoints?: boolean }>(({ centerProgramPoints }) => ({
  ...(centerProgramPoints && {
    alignItems: 'center',
  }),
}))

export const ProgramDetailsModal: FunctionComponent<ProgramDetailsModalProps> = ({
  modalTrigger,
  onCtaPress = noop,
  programContent,
  testId,
}) => {
  const [isModalOpen, setModalState] = useState(programContent?.isModalOpenInitially ?? false)
  const { breakpoints, colors } = useTheme() as ThemeType
  const { formatMessage } = useIntl()
  const { isMobileSized } = breakpoints
  const IllustrationComponent = (Illustrations as { [key: string]: any })[programContent.headerIllustration]
  const illustrationSize = breakpoints.isMobileSized ? 96 : 124
  const ctaPressed = useRef(false)

  useEffect(() => {
    const closeModalIfOpen = programContent?.closeModalIfOpen
    if (closeModalIfOpen && isModalOpen) {
      setModalState(false)
    }
  }, [isModalOpen, programContent?.closeModalIfOpen])

  const openModal = () => {
    programContent.onModalOpen && programContent.onModalOpen()
    setModalState(true)
  }

  const closeModal = () => {
    if (!ctaPressed.current) {
      programContent.onModalClose && programContent.onModalClose()
    }
    setModalState(false)
  }

  const modalContents = (
    <ContentsContainer testID={tID(`${testId}-modal`)}>
      <CloseButton onPress={closeModal} testID={tID(`${testId}-close`)}>
        <XIcon size={24} />
      </CloseButton>
      <IllustrationComponent
        size={illustrationSize}
        width={illustrationSize}
        backgroundFill={colors.backgroundHighlightPeriwinkle}
      />
      <HeaderStyled text={programContent.header} size={SubheadSize.MEDIUM} />
      <DescriptionContainer>
        <BodyText text={programContent.description} />
      </DescriptionContainer>
      <ProgramPointsContainer>
        <BodyText
          text={formatMessage({
            defaultMessage: 'How it works',
            description: 'Header of section which explains how parent coaching works',
          })}
        />
        <ProgramPointsListContainer>
          {programContent.programPoints.map((item, index) => {
            const IconComponent = (Icons as { [key: string]: any })[item.icon]
            return (
              <Row key={index} centerProgramPoints={programContent?.centerProgramPoints}>
                <IconComponent size={24} fillColor={colors.iconActive} />
                <ProgramPointLabel>
                  <BodyText text={item.description} />
                </ProgramPointLabel>
              </Row>
            )
          })}
        </ProgramPointsListContainer>
      </ProgramPointsContainer>
      <ButtonContainer>
        <ButtonStyled
          testID={tID(`${testId}-cta`)}
          text={programContent.ctaText}
          onPress={() => {
            ctaPressed.current = true
            closeModal()
            onCtaPress()
          }}
          fullWidth={isMobileSized}
        />
      </ButtonContainer>
    </ContentsContainer>
  )

  return (
    <View>
      <PressableOpacity testID={tID(`${testId}`)} onPress={openModal}>
        {modalTrigger}
      </PressableOpacity>

      <StyledModal
        visible={isModalOpen}
        modalContents={modalContents}
        onRequestClose={closeModal}
        onCloseEnd={closeModal}
        closeOnScrim
        showCloseIcon
        useDynamicHeightForBottomSheet
      />
    </View>
  )
}
