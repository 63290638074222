import { fromJS } from 'immutable'

import { amazonkids } from './customers/amazonkids'
import { amex } from './customers/amex'
import { commonspirit } from './customers/commonspirit'
import { defaultCustomer } from './customers/default'
import { dell } from './customers/dell'
import { eyassist } from './customers/eyassist'
import { fidelity } from './customers/fidelity'
import { gap } from './customers/gap'
import { google } from './customers/google'
import { netflix } from './customers/netflix'
import { newyorkfed } from './customers/newyorkfed'
import { nike } from './customers/nike'
import { organon } from './customers/organon'
import { roche } from './customers/roche'
import { skadden } from './customers/skadden'
import { starbucks } from './customers/starbucks'
import { thehartford } from './customers/thehartford'
import { virginiamason } from './customers/viriginiamason'

/**
 * A list of customers who require custom copy.
 * Format:
 * * key = name of customer
 * * * copy = imported copy belonging to said customer
 * * * template = copy to merge directly on top of "default" copy (optional)
 *
 * By default, everyone gets the "default" copy to start.
 * The copy specified in the "template" key, if it exists, is then merged on top of the default copy.
 * Finally, the copy specified in "copy" is merged in on top of that.
 */
const customers = fromJS({
  defaultCustomer: {
    copy: defaultCustomer,
  },
  amazonkids: {
    copy: amazonkids,
  },
  amex: {
    copy: amex,
  },
  commonspirit: {
    copy: commonspirit,
  },
  dell: {
    copy: dell,
  },
  eyassist: {
    copy: eyassist,
  },
  fidelity: {
    copy: fidelity,
  },
  gap: {
    copy: gap,
  },
  google: {
    copy: google,
  },
  netflix: {
    copy: netflix,
  },
  newyorkfed: {
    copy: newyorkfed,
  },
  nike: {
    copy: nike,
  },
  organon: {
    copy: organon,
  },
  roche: {
    copy: roche,
  },
  skadden: {
    copy: skadden,
  },
  starbucks: {
    copy: starbucks,
  },
  thehartford: {
    copy: thehartford,
  },
  virginiamason: {
    copy: virginiamason,
  },
})

export default customers
