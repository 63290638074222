import { Map } from 'immutable'
import { createSelector } from 'reselect'

import { getDevFlags, LDFlags } from '@lyrahealth-inc/shared-app-logic'

export const getFeatureFlagState = (state: Map<string, any>) => state?.get('featureFlag')

export const getFeatureFlags = createSelector([getFeatureFlagState], (featureFlagState) => ({
  ...(featureFlagState.get('flags').toJS() as LDFlags),
  ...getDevFlags(),
}))

export const getIsParentInitiatedChildPreferencesEnabled = createSelector(
  getFeatureFlags,
  (featureFlagState) => featureFlagState?.isParentInitiatedChildPreferencesEnabled,
)

export const getAdultTriageDisabled = createSelector(
  getFeatureFlags,
  (featureFlagState) => featureFlagState?.adultTriageDisabled,
)
