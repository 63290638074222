import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useWindowSize } from '@lyrahealth-inc/shared-app-logic'
import { useGetIsMobileWebBrowser } from '@lyrahealth-inc/ui-core-crossplatform'

import { getLwMobileEnabled } from '../../data/customer/customerSelectors'
import { PAGE_ROUTES } from '../../features/onboard/data/page-navigation/location-actions'
import { MOBILE_PROMO_DISMISSED } from '../constants/appConstants'

export interface useShowAppPromoArgs {
  promoBottomSheetEnabled?: boolean
}

export const useShowAppPromo = (
  { promoBottomSheetEnabled }: useShowAppPromoArgs = { promoBottomSheetEnabled: false },
) => {
  const isMobileBrowser = useGetIsMobileWebBrowser()
  const windowSize = useWindowSize()
  const isLwMobileAppEnabled = useSelector(getLwMobileEnabled)
  const [showAppPromoBanner, setShowAppPromoBanner] = useState(false)
  const [showAppPromoBottomSheet, setShowAppPromoBottomSheet] = useState(false)
  const { pathname } = useLocation()
  const isWellnessCheckInFlow = pathname.includes(PAGE_ROUTES.WELLNESS_CHECK_IN.DEFAULT)

  useEffect(() => {
    if (!isWellnessCheckInFlow && isMobileBrowser && isLwMobileAppEnabled) {
      const promoBottomSheetDismissed = window.localStorage.getItem(MOBILE_PROMO_DISMISSED) === 'true'
      setShowAppPromoBanner(!promoBottomSheetEnabled || (promoBottomSheetEnabled && promoBottomSheetDismissed))
      if (promoBottomSheetEnabled && !promoBottomSheetDismissed) {
        const timeout = setTimeout(() => {
          setShowAppPromoBottomSheet(true)
        }, 2000)
        return () => clearTimeout(timeout)
      }
    } else {
      setShowAppPromoBottomSheet(false)
      setShowAppPromoBanner(false)
    }
    return
  }, [
    isMobileBrowser,
    isLwMobileAppEnabled,
    promoBottomSheetEnabled,
    setShowAppPromoBanner,
    setShowAppPromoBottomSheet,
    windowSize,
    isWellnessCheckInFlow,
  ])

  return {
    showAppPromoBanner,
    setShowAppPromoBanner,
    showAppPromoBottomSheet,
    setShowAppPromoBottomSheet,
  }
}
