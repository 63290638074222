import { ThemeColors } from '@lyrahealth-inc/shared-app-logic'

import { Size as TextSize } from '../bodyText/BodyText'

/**
 * This file contains all the styling for our button components.
 * All low level keys should be valid ViewStyles.
 * Sizes, states and focus properties overwrite base values.
 */

export const getButtonStyles = (tokenColors: ThemeColors) => ({
  primary: {
    default: {
      base: {
        backgroundColor: tokenColors.primaryButtonBackgroundDefault,
        color: tokenColors.primaryButtonTextActive,
        minWidth: 100,
      },
      sizes: {
        default: {
          height: 48,
          paddingHorizontal: 24,
        },
        small: {
          height: 40,
          paddingHorizontal: 16,
        },
        large: {
          paddingHorizontal: 36,
          height: 48,
        },
      },
      states: {
        hovered: {
          backgroundColor: tokenColors.primaryButtonBackgroundHover,
        },
        pressed: {
          backgroundColor: tokenColors.primaryButtonBackgroundPressed,
        },
        inactive: {
          backgroundColor: tokenColors.primaryButtonBackgroundInactive,
          color: tokenColors.primaryButtonTextInactive,
        },
        loading: {
          backgroundColor: tokenColors.primaryButtonBackgroundLoading,
        },
      },
      focused: {
        backgroundColor: tokenColors.primaryButtonBackgroundFocus,
      },
    },
    periwinkle: {
      base: {
        backgroundColor: tokenColors.primaryButtonPeriwinkleBackgroundDefault,
        color: tokenColors.primaryButtonTextActive,
        minWidth: 100,
      },
      sizes: {
        default: {
          height: 48,
          paddingHorizontal: 23,
        },
        small: {
          height: 40,
          paddingHorizontal: 15,
        },
        unsized: {},
      },
      states: {
        hovered: {
          backgroundColor: tokenColors.primaryButtonPeriwinkleBackgroundHover,
        },
        pressed: {
          backgroundColor: tokenColors.primaryButtonPeriwinkleBackgroundPressed,
        },
        inactive: {
          color: tokenColors.secondaryButtonTextInactive,
          backgroundColor: tokenColors.primaryButtonPeriwinkleBackgroundInactive,
          border: `1.5px solid ${tokenColors.secondaryButtonTextInactive}`,
        },
        loading: {
          backgroundColor: tokenColors.primaryButtonPeriwinkleBackgroundLoading,
        },
      },
      focused: {
        backgroundColor: tokenColors.primaryButtonPeriwinkleBackgroundFocus,
      },
    },
  },
  secondary: {
    default: {
      base: {
        color: tokenColors.secondaryButtonTextActive,
        borderWidth: 1.5,
        borderColor: tokenColors.secondaryButtonOutlineDefault,
        minWidth: 100,
      },
      sizes: {
        default: {
          height: 48,
          paddingHorizontal: 23,
        },
        small: {
          height: 40,
          paddingHorizontal: 15,
        },
        unsized: {},
      },
      states: {
        hovered: {
          backgroundColor: tokenColors.secondaryButtonBackgroundHover,
        },
        pressed: {
          backgroundColor: tokenColors.secondaryButtonBackgroundPressed,
        },
        inactive: {
          color: tokenColors.secondaryButtonTextInactive,
          backgroundColor: tokenColors.secondaryButtonBackgroundInactive,
          border: `1.5px solid ${tokenColors.secondaryButtonOutlineInactive}`,
        },
        loading: {
          backgroundColor: tokenColors.secondaryButtonBackgroundPressed,
        },
        selected: {
          backgroundColor: tokenColors.primaryButtonBackgroundDefault,
          color: tokenColors.primaryButtonTextActive,
        },
      },
      focused: {
        backgroundColor: tokenColors.secondaryButtonBackgroundFocus,
      },
    },
    inverse: {
      base: {
        backgroundColor: tokenColors.secondaryButtonInverseBackgroundDefault,
        color: tokenColors.secondaryButtonInverseTextActive,
        borderWidth: 1.5,
        borderColor: tokenColors.secondaryButtonInverseOutlineDefault,
        minWidth: 100,
      },
      sizes: {
        default: {
          height: 48,
          paddingHorizontal: 23,
        },
        small: {
          height: 40,
          paddingHorizontal: 15,
        },
      },
      states: {
        hovered: {
          backgroundColor: tokenColors.secondaryButtonInverseBackgroundHover,
        },
        pressed: {
          backgroundColor: tokenColors.secondaryButtonInverseBackgroundPressed,
          borderColor: tokenColors.secondaryButtonInverseOutlinePressed,
        },
        inactive: {
          color: tokenColors.secondaryButtonInverseTextInactive,
          border: `1.5px solid ${tokenColors.secondaryButtonInverseOutlineInactive}`,
        },
        loading: {
          backgroundColor: tokenColors.secondaryButtonInverseBackgroundPressed,
          borderColor: tokenColors.secondaryButtonInverseOutlinePressed,
        },
      },
      focused: {
        backgroundColor: tokenColors.secondaryButtonInverseBackgroundFocus,
      },
    },
    black: {
      base: {
        color: tokenColors.secondaryButtonBlackTextActive,
        borderWidth: 1.5,
        borderColor: tokenColors.secondaryButtonBlackOutlineDefault,
        backgroundColor: tokenColors.secondaryButtonBlackBackgroundDefault,
        minWidth: 100,
      },
      sizes: {
        default: {
          height: 48,
          paddingHorizontal: 23,
        },
      },
      states: {
        hovered: {},
        pressed: {},
        inactive: {
          color: tokenColors.secondaryButtonTextInactive,
          backgroundColor: tokenColors.secondaryButtonBackgroundInactive,
          borderColor: tokenColors.secondaryButtonBackgroundInactive,
        },
        loading: {},
      },
      focused: {
        backgroundColor: tokenColors.secondaryButtonBackgroundFocus,
      },
    },
    periwinkle: {
      base: {
        color: tokenColors.secondaryButtonPeriwinkleTextActive,
        border: `1.5px solid ${tokenColors.secondaryButtonPeriwinkleOutlineDefault}`,
        minWidth: 100,
      },
      sizes: {
        default: {
          height: 48,
          paddingHorizontal: 23,
        },
        small: {
          height: 40,
          paddingHorizontal: 15,
        },
        unsized: {},
      },
      states: {
        hovered: {
          backgroundColor: tokenColors.secondaryButtonPeriwinkleBackgroundHover,
        },
        pressed: {
          backgroundColor: tokenColors.secondaryButtonPeriwinkleBackgroundPressed,
        },
        inactive: {
          color: tokenColors.secondaryButtonTextInactive,
          backgroundColor: tokenColors.secondaryButtonBackgroundInactive,
          border: `1.5px solid ${tokenColors.secondaryButtonOutlineInactive}`,
        },
        loading: {
          backgroundColor: tokenColors.secondaryButtonPeriwinkleBackgroundLoading,
        },
      },
      focused: {
        backgroundColor: tokenColors.secondaryButtonPeriwinkleBackgroundFocus,
      },
    },
  },
  tertiary: {
    default: {
      base: {
        backgroundColor: tokenColors.tertiaryButtonBackgroundDefault,
        color: tokenColors.tertiaryButtonTextActive,
        minWidth: 100,
      },
      sizes: {
        default: {
          height: 48,
          paddingHorizontal: 24,
        },
        small: {
          height: 40,
          paddingHorizontal: 16,
        },
      },
      states: {
        hovered: {
          backgroundColor: tokenColors.tertiaryButtonBackgroundHover,
        },
        pressed: {
          backgroundColor: tokenColors.tertiaryButtonBackgroundPressed,
        },
        inactive: {
          backgroundColor: tokenColors.tertiaryButtonBackgroundInactive,
          color: tokenColors.tertiaryButtonTextInactive,
        },
        loading: {
          backgroundColor: tokenColors.tertiaryButtonBackgroundPressed,
        },
      },
      focused: {
        backgroundColor: tokenColors.tertiaryButtonBackgroundFocus,
      },
    },
  },
  text: {
    default: {
      base: {
        color: tokenColors.linkDefault,
      },
      sizes: {
        default: {},
      },
      states: {
        hovered: {
          textDecorationLine: 'underline',
          backgroundColor: 'transparent',
        },
        pressed: {
          color: tokenColors.linkHover,
          textDecorationLine: 'underline',
        },
        inactive: {
          color: tokenColors.linkInactive,
          backgroundColor: tokenColors.backgroundTransparent,
        },
        loading: {},
      },
      focused: {
        backgroundColor: tokenColors.backgroundFocus,
        textDecorationLine: 'underline',
      },
    },
  },
  wordCloud: {
    default: {
      base: {
        color: tokenColors.wordCloudButtonTextActive,
        borderWidth: 1,
        borderColor: tokenColors.wordCloudButtonOutlineDefault,
        backgroundColor: tokenColors.wordCloudButtonBackgroundDefault,
      },
      sizes: {
        default: {
          height: 48,
          paddingHorizontal: 23,
        },
        small: {
          height: 45,
          paddingHorizontal: 20,
          textSize: TextSize.SMALL,
        },
        unsized: {},
      },
      states: {
        hovered: {
          borderColor: tokenColors.wordCloudButtonOutlineHover,
        },
        pressed: {
          color: tokenColors.wordCloudButtonTextPressed,
          borderColor: tokenColors.wordCloudButtonOutlinePressed,
        },
        inactive: {
          color: tokenColors.wordCloudButtonTextInactive,
          borderColor: tokenColors.wordCloudButtonOutlineInactive,
        },
        selected: {
          color: tokenColors.wordCloudButtonTextSelected,
          backgroundColor: tokenColors.wordCloudButtonBackgroundSelected,
          borderColor: tokenColors.wordCloudButtonOutlineSelected,
        },
        'selected-inactive': {
          color: tokenColors.wordCloudButtonTextInactive,
          borderColor: tokenColors.wordCloudButtonOutlineInactive,
          backgroundColor: tokenColors.wordCloudButtonBackgroundSelected,
        },
      },
    },
    periwinkle: {
      base: {
        color: tokenColors.wordCloudButtonTextActive,
        borderWidth: 1,
        borderColor: tokenColors.wordCloudButtonOutlineDefault,
        backgroundColor: tokenColors.wordCloudButtonBackgroundDefault,
      },
      sizes: {
        default: {
          height: 48,
          paddingHorizontal: 23,
        },
        small: {
          height: 45,
          paddingHorizontal: 20,
          textSize: TextSize.SMALL,
        },
      },
      states: {
        hovered: {
          borderColor: tokenColors.wordCloudPeriwinkleButtonOutlineHover,
        },
        pressed: {
          color: tokenColors.wordCloudPeriwinkleButtonTextPressed,
          borderColor: tokenColors.wordCloudPeriwinkleButtonOutlinePressed,
        },
        inactive: {
          color: tokenColors.secondaryButtonTextInactive,
          borderColor: tokenColors.secondaryButtonBackgroundInactive,
        },
        selected: {
          color: tokenColors.wordCloudPeriwinkleButtonTextSelected,
          backgroundColor: tokenColors.wordCloudPeriwinkleButtonBackgroundSelected,
          borderColor: tokenColors.wordCloudPeriwinkleButtonOutlineSelected,
        },
        'selected-inactive': {
          color: tokenColors.wordCloudButtonTextInactive,
          borderColor: tokenColors.wordCloudButtonOutlineInactive,
          backgroundColor: tokenColors.wordCloudButtonBackgroundSelected,
        },
      },
      focused: {
        backgroundColor: tokenColors.secondaryButtonPeriwinkleBackgroundFocus,
      },
    },
  },
  icon: {
    default: {
      base: {
        backgroundColor: tokenColors.backgroundTransparent,
        color: tokenColors.tertiaryButtonTextActive,
      },
      sizes: {
        default: {
          padding: 14,
        },
        small: {
          padding: 3,
        },
      },
      states: {
        hovered: {
          backgroundColor: tokenColors.tertiaryButtonBackgroundHover,
        },
        pressed: {
          backgroundColor: tokenColors.tertiaryButtonBackgroundPressed,
        },
        inactive: {
          backgroundColor: tokenColors.tertiaryButtonBackgroundInactive,
        },
        selected: {
          backgroundColor: tokenColors.tertiaryButtonBackgroundHover,
        },
      },
      focused: {
        backgroundColor: tokenColors.tertiaryButtonBackgroundFocus,
      },
    },
    inverse: {
      base: {
        backgroundColor: tokenColors.secondaryButtonInverseBackgroundDefault,
        color: tokenColors.secondaryButtonInverseTextActive,
        borderWidth: 1.5,
        borderColor: tokenColors.secondaryButtonInverseOutlineDefault,
      },
      sizes: {
        default: {
          padding: 14,
        },
        small: {
          padding: 3,
        },
      },
      states: {
        hovered: {
          backgroundColor: tokenColors.secondaryButtonInverseBackgroundHover,
        },
        pressed: {
          backgroundColor: tokenColors.secondaryButtonInverseBackgroundPressed,
          borderColor: tokenColors.secondaryButtonInverseOutlinePressed,
        },
        inactive: {
          color: tokenColors.secondaryButtonInverseTextInactive,
          border: `1.5px solid ${tokenColors.secondaryButtonInverseOutlineInactive}`,
        },
        loading: {
          backgroundColor: tokenColors.secondaryButtonInverseBackgroundPressed,
          borderColor: tokenColors.secondaryButtonInverseOutlinePressed,
        },
      },
      focused: {
        backgroundColor: tokenColors.secondaryButtonBackgroundFocus,
      },
    },
    unstyled: {
      base: {
        height: 24,
        width: 24,
      },
      sizes: {
        default: {},
      },
      states: {},
    },
  },
  tertiary_icon: {
    default: {
      base: {
        color: tokenColors.tertiaryButtonTextActive,
      },
      sizes: {
        default: {
          padding: 14,
        },
        small: {
          padding: 3,
        },
      },
      states: {
        hovered: {
          backgroundColor: tokenColors.tertiaryButtonBackgroundHover,
        },
        pressed: {
          backgroundColor: tokenColors.tertiaryButtonBackgroundPressed,
        },
        inactive: {
          backgroundColor: tokenColors.tertiaryButtonBackgroundInactive,
        },
        loading: {
          backgroundColor: tokenColors.tertiaryButtonBackgroundPressed,
        },
      },
      focused: {
        backgroundColor: tokenColors.tertiaryButtonBackgroundFocus,
      },
    },
  },
  secondary_tertiary_icon: {
    default: {
      base: {
        color: tokenColors.secondaryTertiaryButtonTextActive,
        backgroundColor: tokenColors.secondaryTertiaryButtonBackgroundDefault,
      },
      sizes: {
        default: {
          padding: 14,
        },
        small: {
          padding: 3,
        },
      },
      states: {
        hovered: {
          backgroundColor: tokenColors.secondaryTertiaryButtonBackgroundHover,
        },
        pressed: {
          backgroundColor: tokenColors.secondaryTertiaryButtonBackgroundHover,
        },
        inactive: {
          color: tokenColors.secondaryTertiaryButtonTextInactive,
          backgroundColor: tokenColors.secondaryTertiaryButtonBackgroundInactive,
        },
      },
      focused: {
        color: tokenColors.secondaryTertiaryButtonTextActive,
        backgroundColor: tokenColors.secondaryTertiaryButtonBackgroundFocus,
      },
    },
  },
  tertiary_header: {
    default: {
      base: {
        color: tokenColors.tertiaryButtonTextActive,
      },
      sizes: {
        default: {
          paddingTop: 8,
          paddingRight: 16,
          paddingBottom: 8,
          paddingLeft: 16,
        },
      },
      states: {
        hovered: {
          backgroundColor: tokenColors.tertiaryButtonBackgroundHover,
        },
        pressed: {
          backgroundColor: tokenColors.tertiaryButtonBackgroundPressed,
        },
        inactive: {
          color: tokenColors.tertiaryButtonTextInactive,
        },
        loading: {
          backgroundColor: tokenColors.tertiaryButtonBackgroundPressed,
        },
        selected: {
          backgroundColor: tokenColors.tertiaryButtonBackgroundPressed,
        },
      },
      focused: {
        backgroundColor: tokenColors.tertiaryButtonBackgroundFocus,
      },
    },
  },
})
