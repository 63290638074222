import { isFuture } from 'date-fns'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { isEmpty } from 'lodash-es'
import moment from 'moment-timezone'

import { INITIAL_DAYS_OF_AVAILABILITY_SHOWN } from './constants'
import { getDateFromAppointment } from './getDateFromAppointment'
import {
  Address,
  Appointment,
  APPOINTMENT_STATUS,
  AvailabilityDate,
  CalendarCardAppointment,
  MEETING_FORMATS,
  Provider,
  ProviderInfo,
  SESSION_TYPES,
  sessionSupportedProviderTypes,
} from '../../models'
import { S4C_PROPERTIES } from '../common/constants/mixpanelConstants'
import {
  TREATMENT_OPTIONS_FROM_FRONT_END,
  TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND,
} from '../common/constants/treatmentOptions'
import { getISO8601DateWithTimezone } from '../common/utils/dateUtils'

// checks a list or providers and gets all the available supported appointments for the user
export const getSupportedAppointments = (providers: Provider[]): Appointment[] => {
  const appts = providers
    .map((provider: Provider) => provider.appointments)
    .flat()
    .filter((appt: Appointment) => appointmentIsSupported(appt))
  return appts
}

/**
 * @deprecated use `getDateFromAppointment`
 */
export const getDateTimeObject = ({
  startDate = '',
  startTime = '',
  timeZone = '',
}: {
  startDate?: string
  startTime?: string
  timeZone?: string
}): moment.Moment => {
  return moment.tz(`${startDate} ${startTime}`, timeZone)
}

/**
 * @deprecated use `getDateFromAppointment`
 */
export const getAppointmentDateTimeObject = ({ startDate, startTime, timeZone }: Appointment): moment.Moment => {
  return getDateTimeObject({ startDate, startTime, timeZone })
}

// check is the appointment meetingFormat is supported, appointment status is present + future, and provider supports this session type
export const appointmentIsSupported = (appt: Appointment): boolean => {
  return (
    ['live_messaging', 'video'].includes(appt.meetingFormat) &&
    ['newAppt', 'rescheduled'].includes(appt.appointmentStatus) &&
    (appt.provider == null ||
      sessionSupportedProviderTypes.some(
        (providerType) => providerType.lyraType === appt.provider?.lyra_type.toLowerCase(),
      ))
  )
}

// 'now' or: 59:99
export const calculateTimeLeft = ({ startTime }: { startTime: moment.Moment }) => {
  const duration = moment.duration(startTime.diff(moment()))
  const startsInFormatted =
    duration.asMilliseconds() <= 0 ? 'now' : moment.utc(duration.asMilliseconds()).format('mm:ss')
  return startsInFormatted
}

// get the text that shows in the 'Join' button
export const getJoinButtonText = (sessionType: SESSION_TYPES, startsIn: string, isMobile: boolean) => {
  let joinText = ''
  if (isMobile) return 'Join'
  if (sessionType === SESSION_TYPES.MESSAGE) {
    joinText = startsIn === 'now' ? 'Join (In progress)' : startsIn === '' ? 'Join' : `Join (Starts in ${startsIn})`
  } else {
    // is a video session
    joinText = startsIn === 'now' ? 'Start Session' : startsIn === '' ? 'Join' : `Join (Starts in ${startsIn})`
  }
  return joinText
}

/**
 * Helper to get the current appointment
 * @param {*} providerId string
 * @param {*} episodeId string
 * @param {*} appointments Providers
 * @param {*} sessionCount number
 * @returns appointment | null
 */
export const getAppointmentFromSessionNumber = (
  providerId: string,
  episodeId: string | null,
  appointments: Appointment[] | undefined,
  sessionCount: number,
): Appointment | null => {
  const providerEpisodeAppointments = appointments?.filter(
    (appointment) => appointment.lyraProviderId === providerId && appointment.episodeId === episodeId,
  )
  const appointment = providerEpisodeAppointments?.find((appointment) => appointment.sessionNumber === sessionCount)
  return appointment || null
}

export const isExpeditedBookingAppointment = (appointment: Appointment) => {
  if (appointment && appointment?.createDate && appointment?.startDate && appointment?.startTime) {
    // createDate comes in like this: 2023-04-13T16:18:26.000+00:00
    const cleanedCreateDate = appointment.createDate.replace(/\..*/, '')
    const createDateTimeParts = cleanedCreateDate.split('T')
    const createDateTime = getDateTimeObject({
      startDate: createDateTimeParts[0],
      startTime: createDateTimeParts[1],
      timeZone: 'UTC',
    })
    const startDateTime = getDateTimeObject({
      startDate: appointment.startDate,
      startTime: appointment.startTime,
      timeZone: appointment.timeZone,
    })
    const dayInMilliseconds = 24 * 60 * 60 * 1000
    return startDateTime.valueOf() - createDateTime.valueOf() < dayInMilliseconds
  }
  return false
}

export enum AppointmentClass {
  INITIAL = 'initial',
  RECURRING = 'recurring',
}

export const sortAppointments = (appointments: Appointment[]) =>
  appointments?.sort(
    (a, b) =>
      getDate({ date: a.startDate, time: a.startTime, timeZone: a.timeZone }).getTime() -
      getDate({ date: b.startDate, time: b.startTime, timeZone: b.timeZone }).getTime(),
  )

export const getAppointmentClass = (appointments: Appointment[]): AppointmentClass =>
  appointments.some((appointment) => appointment.appointmentStatus === 'completed')
    ? AppointmentClass.RECURRING
    : AppointmentClass.INITIAL

const getDate = ({ date, time, timeZone }: { date: string; time: string; timeZone: string }) =>
  zonedTimeToUtc(`${date}T${time}`, timeZone)

export const getSessionNumber = (dateTime: string, appointments: Appointment[]) => {
  const sortedAppointments = sortAppointments(appointments)

  let sessionNumber = 1
  for (const appointment of sortedAppointments) {
    const appointmentDate = getDate({
      date: appointment.startDate,
      time: appointment.startTime,
      timeZone: appointment.timeZone,
    })
    if (new Date(dateTime).getTime() < appointmentDate.getTime()) {
      return sessionNumber
    }

    sessionNumber++
  }

  return sessionNumber
}

export const getCurrentAppointment = (appointments: Appointment[]): Appointment | undefined => {
  const sortedAppointments = sortAppointments(appointments)

  return sortedAppointments?.find((appointment: CalendarCardAppointment) => {
    const sessionStart = getDateFromAppointment(appointment)
    return isFuture(sessionStart) && appointment.appointmentStatus !== APPOINTMENT_STATUS.CANCELED
  })
}

export const getDisplayAddress = ({
  address,
  isOnsiteProvider,
  provider,
}: {
  address?: Address | undefined
  isOnsiteProvider?: boolean
  provider?: ProviderInfo | undefined
}) => {
  const appointmentAddress = isOnsiteProvider && !isEmpty(address) ? address : provider?.nearestAddress
  const addressLine1 = `${appointmentAddress?.street1?.trim()}`.concat(
    appointmentAddress?.street2?.trim() ? `, ${appointmentAddress?.street2?.trim()}` : '',
  )
  const addressLine2 = `${appointmentAddress?.city}, ${appointmentAddress?.state} ${
    appointmentAddress?.zipcode ?? appointmentAddress?.zip
  }`

  return addressLine1 + ', ' + addressLine2
}

export const isFollowUpBCMorCLEAppointment = (appointment: Appointment) => {
  return (
    appointment.sessionNumber > 1 &&
    [
      TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND.BLENDED_CARE_MEDS as string,
      TREATMENT_OPTIONS_FROM_FRONT_END.CLINICAL_LEAVE_EVALUATION as string,
    ].includes(appointment.treatmentType)
  )
}

/**
 * Helper function to check if the appointment is of type meeting format
 * @param appt Appointment
 * @param meetingFormat MEETING_FORMATS
 */
export const isApptMeetingFormat = (appt: Appointment | null, meetingFormat: MEETING_FORMATS): boolean => {
  return appt ? appt.meetingFormat === meetingFormat : false
}

export const getAppointmentRange = (
  startDate?: string,
  startTime?: string,
  timeZone?: string,
  duration?: number,
): { fromDate: Date; toDate: Date } => {
  const appointmentStart = getDateTimeObject(
    {
      startDate,
      startTime,
      timeZone,
    } ?? {},
  )
  const appointmentInterval: number = duration ?? 60
  const appointmentEnd = appointmentStart.clone().add(appointmentInterval, 'minutes')
  return { fromDate: new Date(appointmentStart.format()), toDate: new Date(appointmentEnd.format()) }
}

export const getDisplayedTimeslots = ({
  dates,
  daysOfAvailability,
}: {
  dates: Array<AvailabilityDate>
  daysOfAvailability: number
}) => {
  return dates.slice(0, daysOfAvailability).map((dateData) => {
    const { date, times } = dateData
    return {
      date,
      times: times.filter((dateTime) => Boolean(dateTime)),
    }
  })
}

export const createUpdatedScheduleTrackingData = ({
  dates,
  timeZone,
  daysOfAvailabilityShown = INITIAL_DAYS_OF_AVAILABILITY_SHOWN,
  matchingScheduleSlotsString,
  shouldShowAvailabilityPreference,
}: {
  // In UTC
  dates?: Array<AvailabilityDate>
  timeZone: string
  daysOfAvailabilityShown?: number
  matchingScheduleSlotsString?: string[] | undefined
  shouldShowAvailabilityPreference?: boolean
}) => {
  if (!dates) {
    return {}
  }
  const calendarSlotsShown = getDisplayedTimeslots({
    dates,
    daysOfAvailability: daysOfAvailabilityShown,
  })
    .flatMap((timeslots) => timeslots.times)
    .map((dateTime) => {
      if (!dateTime) return
      return getISO8601DateWithTimezone({ date: utcToZonedTime(dateTime, timeZone), timeZone })
    })

  const matchingScheduleSlotsInClientTimezone = matchingScheduleSlotsString?.map((scheduleSlot) => {
    return getISO8601DateWithTimezone({
      date: utcToZonedTime(scheduleSlot, timeZone),
      timeZone,
    })
  })

  return {
    [S4C_PROPERTIES.CALENDAR_SLOTS_SHOWN]: calendarSlotsShown,
    [S4C_PROPERTIES.NUM_CALENDAR_SLOTS]: calendarSlotsShown?.length,
    ...(shouldShowAvailabilityPreference && {
      [S4C_PROPERTIES.MATCHED_CALENDAR_SLOTS_SHOWN]: matchingScheduleSlotsInClientTimezone,
    }),
  }
}
