import { RegistrationAndLoginDisabledBanner } from '@lyrahealth-inc/ui-core-crossplatform'

import RacialStressBanner from '../../features/microsite/components/Banners/RacialStressBanner'

export const BANNERS = {
  RACE: {
    component: RacialStressBanner as $TSFixMe,
    paths: ['/secure/index/search-care', '/secure/onboard'],
  },
  REGISTRATION_AND_LOGIN_DISABLED: {
    component: RegistrationAndLoginDisabledBanner,
    paths: [],
  },
}
