export const ProviderProfileFullDetail = `
fragment ProviderProfileFullDetail on providerProfileGraphqlType {
  ccnOwner{
     id
     text
  }
  approver{
     id
     text
  }
  owner: recruitmentOwner{
     id
     text
  }
   status
   statusText
  addresses {
    full_address
    street1
    street2
    city
    state
    zipcode
    phones
    }
  modalities
  demographics
  conditions
  treatments 
  education {
    school
    year
  }
  summary
  video
  videoCouples
  videoChild
  videoIndividual
  videoInterested
  isDisplayAsVideo
  activeSuicidality
  neutralSuicidality
  schedule
  eveningWeekdays
  statements
  treatmentModalities
  npi
  fullName
  displayName
  firstName
  middleName
  lastName
  activeLicense
  license_info
  lyraType
  acceptingPatients
  genders
  specialty
  credentials
  languages
  insurance
  profiles
  sanctions
  teletherapy
  isRecommended
  isLyra
  isBlocked
  surface
  matchScore
  relevance
  statement
  negativeConditions
  certifications
  statedSensitivities
  clinicalTraining
  prescriber
  accessType
  onsite
  lyraId
    emails
  #isPreferred
  #nearestDistance
  #nearestTravelTime
  #videoOnly
  sensitivities
  ageDemographics
    name
  nearestAddress {
    city_state_zip
    street1
  }
  contactMethods
  phone
  intakeRepresentativePhone
  contactPhone
  email
  intakeRepresentativeEmail
  contactEmail
  religions
  ethnicities
  providerEthnicity
  disqualified
  yearsOfExperience
  ebtPercentile
  transdiagnosticTherapyPercentile
  relationshipsAndFamilyTherapyPercentile
  addictionTherapyPercentile 
  adhdMedsPercentile 
  alcoholismMedsPercentile 
  anxietyMedsPercentile 
  bipolarMedsPercentile
  depressionMedsPercentile
  phobiaMedsPercentile
  psychosisMedsPercentile
  substanceDependenceMedsPercentile
  clinicName
  websites
  isApprovalNeeded
  isNotAvailable
  isCalendarConnected
  capacity
  racialStressPreferred
  rates
}
`

export const ProviderEditableData = `
    fragment ProviderEditableData on providerProfileGraphqlType {
  addresses {
    street1
    street2
    city
    state
    zipcode
    phones
    location{
        lat
        lon
    }
  }
  ccnOwner{
    id
    text
  }
  recruitmentOwner{
    id
    text
  }
  approver{
    id
    text
  }
  status
  isBlocked
  displayName
  #firstName
  #lastName
  websites
  emails
  }
`

export const CareAdvisersDropdownOptions = ` 
  careAdvisers: careAdvisers{
    id
    text
  }`
