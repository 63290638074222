import { useContext } from 'react'

import { PreviousRouteContext } from '../../features/routing/PreviousRouteProvider'

/**
 * @returns url pathname of the route user was on previously.
 * - If null, the previous route is not ready. URL has just changed and previous route is currently being set.
 * - If undefined, there is no previous route.
 */
export const usePreviousRoute = () => {
  return useContext(PreviousRouteContext).previousRoute
}
