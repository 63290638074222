export const DEV_DISPLAY_LANGUAGES =
  typeof __DEV__ !== 'undefined' && __DEV__ ? (['en-XA', 'en-XB'] as const) : ([] as const)

export const DEV_LANGUAGE_NAMES = Object.freeze(
  typeof __DEV__ !== 'undefined' && __DEV__
    ? {
        'en-XA': { name: '(DEV) English (A)' },
        'en-XB': { name: '(DEV) English (B)' },
        'xx-LS': { name: '(DEV) English (Long)' },
        'xx-AC': { name: '(DEV) English (All Caps)' },
      }
    : {},
)

// Languages that when selected take the user to the Bukwild microsites
export const RIGHT_TO_LEFT_LANGUAGES = Object.freeze({
  ar: { name: 'العربية', micrositePath: 'arabic' }, // Arabic
  'he-IL': { name: 'עברית', micrositePath: 'hebrew' }, // Hebrew
  'ur-PK': { name: 'اردو', micrositePath: 'urdu' }, // Urdu
})

/**
 * All supported display languages
 *
 * Keys are IETF BCP 47 language tag.
 *
 * `name`: A name for display purposes in its own language.
 */
export const LYRA_DISPLAY_LANGUAGES = Object.freeze({
  'bg-BG': { name: 'български' }, // Bulgarian
  'cs-CZ': { name: 'Čeština' }, // Czech
  'da-DK': { name: 'Dansk' }, // Danish
  'de-DE': { name: 'Deutsch' }, // German
  'el-GR': { name: 'Ελληνικά' }, // Greek
  'en-US': { name: 'English' }, // English
  'en-GB': { name: 'English (UK)' }, // English (UK)
  'es-419': { name: 'Español' }, // Spanish (Latin America)
  'es-ES': { name: 'Español (España)' }, // Spanish (Spain)
  fil: { name: 'Filipino' }, // Filipino
  'fi-FI': { name: 'Suomi' }, // Finnish
  'fr-CA': { name: 'Français (Canada)' }, // French (Canada)
  'fr-FR': { name: 'Français (France)' }, // French (France)
  'hi-IN': { name: 'हिन्दी' }, // Hindi
  'hu-HU': { name: 'Magyar' }, // Hungarian
  'id-ID': { name: 'Bahasa Indonesia' }, // Indonesian
  'it-IT': { name: 'Italiano' }, // Italian
  'ja-JP': { name: '日本語' }, // Japanese
  'ko-KR': { name: '한국어' }, // Korean
  'ms-MY': { name: 'Bahasa Melayu' }, // Malay
  'nb-NO': { name: 'Norsk (bokmål)' }, // Norwegian
  'nl-NL': { name: 'Nederlands' }, // Dutch
  'pl-PL': { name: 'Polski' }, // Polish
  'pt-BR': { name: 'Português (Brasil)' }, // Portuguese (Brazil)
  'pt-PT': { name: 'Português (Portugal)' }, // Portuguese (Portugal)
  'ro-RO': { name: 'Română' }, // Romanian
  'sv-SE': { name: 'Svenska' }, // Swedish
  'th-TH': { name: 'ภาษาไทย' }, // Thai
  'tr-TR': { name: 'Türkçe' }, // Turkish
  'vi-VN': { name: 'Tiếng Việt' }, // Vietnamese
  'zh-CN': { name: '中文(简体)' }, // Simplified Chinese (China)
  'zh-HK': { name: '中文(香港)' }, // Traditional Chinese (Hong Kong)
  'zh-TW': { name: '中文(台灣)' }, // Traditional Chinese (Taiwan)
  ...RIGHT_TO_LEFT_LANGUAGES,
  ...DEV_LANGUAGE_NAMES,
})
export const DEFAULT_LANGUAGE = 'en-US'

/**
 * Languages enabled when feature flag is not in use
 *
 * Currently includes all languages up to the Jan 2024 intl release
 */
export const LANGUAGES_ENABLED = [
  'bg-BG',
  'cs-CZ',
  'de-DE',
  'en-US',
  'es-419',
  'es-ES',
  'fil',
  'fr-CA',
  'fr-FR',
  'hi-IN',
  'hu-HU',
  'id-ID',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'ms-MY',
  'nl-NL',
  'pl-PL',
  'pt-BR',
  'pt-PT',
  'ro-RO',
  'th-TH',
  'tr-TR',
  'vi-VN',
  'zh-CN',
  'zh-HK',
  'zh-TW',
] as const

/**
 * Type contains all known Lyra language tags including dev languages
 */
export type LyraDisplayLanguageTags = keyof typeof LYRA_DISPLAY_LANGUAGES

export const LANG_QUERY_PARAM = 'lang'
