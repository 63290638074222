import { NEW_YEAR_MICROSITE_URL } from './types'
import { getGoodnessBaseUrl } from '../common/utils/commonUtils'

export const getNewYearMicrositeUrl = (
  isWorkhubDisabled: boolean,
  isWorkhubEventsDisabled: boolean,
  displayLanguage: string,
) => {
  const micrositeUrl = `${getGoodnessBaseUrl(displayLanguage)}${NEW_YEAR_MICROSITE_URL}`
  const isWorkhubDisabledParam = isWorkhubDisabled ? 'x-disabled-work-hub=true' : ''
  const isWorkhubEventsDisabledParam = isWorkhubEventsDisabled ? 'x-disabled-work-hub-events=true' : ''
  const params = [isWorkhubDisabledParam, isWorkhubEventsDisabledParam].filter((item) => item).join('&')
  return `${micrositeUrl}${params ? `?${params}` : ''}`
}
