import React, { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'

import { useTheme } from 'styled-components/native'

import { InputLabelBadgeTypes } from '@lyrahealth-inc/shared-app-logic'

import { TextBadge } from '../icons/TextBadge'

export type InputLabelBadgeProps = {
  badgeType: InputLabelBadgeTypes
}

type InputLabelBadgeConfig = {
  VISIBLE_TO_CLIENT: { [key: string]: string }
}

export const InputLabelBadge: FunctionComponent<InputLabelBadgeProps> = ({ badgeType }) => {
  const { colors } = useTheme()
  const { formatMessage } = useIntl()

  const INPUT_LABEL_BADGES = {
    VISIBLE_TO_CLIENT: {
      backgroundColor: colors.components.alertBanner.warning.background,
      color: colors.textWarning,
      text: formatMessage({
        defaultMessage: 'Visible To Client',
        description: 'Form label text indicating that the inputted value will be seen by the client.',
      }),
    },
  }

  const { backgroundColor, color, text } = INPUT_LABEL_BADGES[badgeType as keyof InputLabelBadgeConfig]

  return <TextBadge autoWidth backgroundColor={backgroundColor} bold color={color} text={text.toLocaleUpperCase()} />
}
