import { defineMessage } from 'react-intl'

import { OPTIONS_TYPE, PreferenceMetadataConfig, RENDER_CONDITION_TARGETS } from './types'
import { MEMBER_PREFERENCE_LABELS } from '../../searchForCare/messages'
import { MEMBER_PREFERENCE_IDS, S4C_PROGRAM_OPTION_FLOW } from '../constants'

const availabilitiesPreferenceTherapy: PreferenceMetadataConfig = {
  name: 'availabilities',
  title: MEMBER_PREFERENCE_LABELS.AVAILABILITY,
  options: [
    {
      text: MEMBER_PREFERENCE_LABELS.flexible,
      id: MEMBER_PREFERENCE_IDS.FLEXIBLE,
      defaultValue: [MEMBER_PREFERENCE_IDS.FLEXIBLE],
      shouldRenderSubtitle: () => false,
    },
    {
      text: MEMBER_PREFERENCE_LABELS.specifyDateTime,
      id: MEMBER_PREFERENCE_IDS.SPECIFY_DATE_TIME,
      defaultValue: [],
      shouldRenderSubtitle: (val) => Array.isArray(val) && val.length !== 0,
    },
  ],
  optionType: OPTIONS_TYPE.RADIO,
  conditionalChildComponent: {
    componentName: 'AvailabilityCalendar',
    optionId: MEMBER_PREFERENCE_IDS.SPECIFY_DATE_TIME,
  },
  icon: 'CalendarOpenSpotsIllustration',
  promptText: defineMessage({
    defaultMessage: 'Select when you’d prefer to meet for your first 50 min appointment:',
    description:
      'Text prompting the user to determine their preferred date and time with a provider for their first 50 minute appointment',
  }),
  visibleFor: [
    S4C_PROGRAM_OPTION_FLOW.THERAPY,
    S4C_PROGRAM_OPTION_FLOW.SELF_INITIATED_TEENS,
    S4C_PROGRAM_OPTION_FLOW.PARENT_INITIATED_CHILD,
    S4C_PROGRAM_OPTION_FLOW.PARENT_INITIATED_TEEN,
  ],
  renderConditions: [
    {
      target: RENDER_CONDITION_TARGETS.FEATURE_FLAG,
      fn: (condition) => condition === true,
    },
  ],
}

export default availabilitiesPreferenceTherapy
