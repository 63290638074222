import React, { FunctionComponent, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { View } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import {
  WellbeingResponseResults,
  WellnessCheckInDomain,
  WellnessCheckInResultsDomainProps,
} from '@lyrahealth-inc/shared-app-logic'
import {
  ChevronIcon,
  ChevronIconDirection,
  PressableOpacity,
  Subhead,
  SubheadSize,
  ThemeType,
  tID,
  WellnessCheckInResultsDomainCardScore,
} from '@lyrahealth-inc/ui-core-crossplatform'

import {
  getDomainDisplayName,
  getWellnessCheckInEssentialsContents,
  isWellbeingResponseInvalid,
  translateWellnessCheckInNumberScoreToDomainScore,
} from '../../utils/wellnessCheckInResults/utils'
import { WellnessCheckInResultsDomainCardScorePreview } from '../wellnessCheckInResultsDomainCardScorePreview/WellnessCheckInResultsDomainCardScorePreview'
import { WellnessCheckInResultsDomainContent } from '../wellnessCheckInResultsDomainContent/WellnessCheckInResultsDomainContent'

const DomainContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  margin: `${theme.spacing['24px']} 0 ${theme.spacing['64px']} 0`,
  padding: `0 ${theme.spacing['16px']} 0 ${theme.spacing['16px']}`,
  width: '100%',
}))

const DomainScoreContainer = styled(PressableOpacity)<{ theme: ThemeType }>(({ theme }) => ({
  padding: `${theme.spacing['24px']} 0 ${theme.spacing['24px']} 0`,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const ContentTitleContainer = styled(Subhead)<{ theme: ThemeType }>(({ theme }) => ({
  marginBottom: theme.spacing['4px'],
}))

const DomainContentContainer = styled.View(() => ({
  marginBottom: '28px',
}))

const HRLine = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  flexGrow: 1,
  height: 1,
  backgroundColor: theme.colors.borderDefault,
}))

export const WellnessCheckInResultsMobileViewDomain: FunctionComponent<WellnessCheckInResultsDomainProps> = ({
  wellbeingResponse,
  onArticlePress,
  isPreview,
  onDomainPress,
  onLoad,
}) => {
  const { colors } = useTheme() as ThemeType
  const responseKeys = Object.keys(wellbeingResponse)
  const [visibleDomains, setVisibleDomains] = useState<Set<string>>(
    new Set(responseKeys.length > 0 ? [responseKeys[0]] : []),
  )

  const updateDomains = (domain: string) => {
    const tempVisibleDomains = new Set(visibleDomains)
    if (visibleDomains.has(domain)) {
      tempVisibleDomains.delete(domain)
    } else {
      tempVisibleDomains.add(domain)
    }

    setVisibleDomains(tempVisibleDomains)
  }

  const getDomainEssentialsContents = (results: WellbeingResponseResults) => {
    const contents = getWellnessCheckInEssentialsContents(results)
    return contents.map((content, index) => (
      <DomainContentContainer key={index}>
        <WellnessCheckInResultsDomainContent
          title={content.title}
          body={content.body}
          articleContents={content.articleContents}
          onArticlePress={onArticlePress}
        />
      </DomainContentContainer>
    ))
  }

  useEffect(() => {
    onLoad()
  }, [onLoad])

  return (
    <DomainContainer testID={tID(`WellnessCheckInResultsMobileViewDomain`)}>
      {responseKeys.length > 0 &&
        responseKeys.map((domain, index) => {
          return isWellbeingResponseInvalid(
            domain as WellnessCheckInDomain,
            wellbeingResponse[domain as WellnessCheckInDomain].response,
          ) ? (
            <></>
          ) : (
            <View key={index}>
              <DomainScoreContainer
                testID={tID(`WellnessCheckInResultsMobileViewDomain-${domain}`)}
                onPress={
                  isPreview
                    ? undefined
                    : () => {
                        onDomainPress && onDomainPress(domain as WellnessCheckInDomain)
                        updateDomains(domain)
                      }
                }
              >
                <View>
                  <ContentTitleContainer
                    text={<FormattedMessage {...getDomainDisplayName(domain as WellnessCheckInDomain)} />}
                    size={SubheadSize.SMALL}
                  />
                  {isPreview && index > 0 ? (
                    <WellnessCheckInResultsDomainCardScorePreview />
                  ) : (
                    <WellnessCheckInResultsDomainCardScore
                      score={translateWellnessCheckInNumberScoreToDomainScore(
                        wellbeingResponse[domain as WellnessCheckInDomain].score,
                      )}
                    />
                  )}
                </View>
                <ChevronIcon
                  direction={visibleDomains.has(domain) ? ChevronIconDirection.UP : ChevronIconDirection.DOWN}
                  fillColor={colors.iconDefault}
                  size={24}
                />
              </DomainScoreContainer>
              {visibleDomains.has(domain) &&
                getDomainEssentialsContents(wellbeingResponse[domain as WellnessCheckInDomain].response)}
              {index != responseKeys.length - 1 && !visibleDomains.has(domain) && <HRLine />}
            </View>
          )
        })}
    </DomainContainer>
  )
}
