import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'

import BottomSheetGorhom from '@gorhom/bottom-sheet'

import {
  COMMON_MIXPANEL_EVENTS,
  COMMON_MIXPANEL_PROPERTIES,
  DirectDedicatedCareNavigatorInfoValues,
  getHelpEmailAddress,
  MIXPANEL_CARE_NAVIGATOR_BUTTON_TEXT,
  MIXPANEL_CARE_NAVIGATOR_OPTIONS,
  S4C_EVENTS,
  S4C_PROPERTIES,
  useFlags,
} from '@lyrahealth-inc/shared-app-logic'
import {
  ContactCareNavigatorConsolidatedPage as ContactCareNavigatorConsolidatedPageComponent,
  Layout,
  Linking,
  mailTo,
} from '@lyrahealth-inc/ui-core-crossplatform'

import { getCurrentCountryName } from '../../data/currentCountry/currentCountrySelectors'
import { getCustomerName } from '../../data/customer/customerSelectors'
import { MIXPANEL_EVENTS, MIXPANEL_PAGE } from '../../data/mixpanel'
import { TrackEventWithObjAction } from '../../data/mixpanel/types'
import { convertRouteToMixpanelPage } from '../../data/mixpanel/utils'
import { getUserCountryCode, getUserDisplayLanguage } from '../../data/user/userSelectors'
import { initializeICASChat, openICASChat } from '../utils/ICASChatUtils'

/**
 * Handle button handlers and styling in ContactCareNavigatorConsolidatedPage component for LW
 */
export const useCareNavigatorHeaderEntryPoint = ({
  isUserInternational,
  isMobileSized,
  trackEventWithObj,
  customerPhoneNumber,
  userId,
  name,
  email,
  salesforceId,
  launchDate,
  isLyrian,
  getDirectDedicatedCareNavigatorLink,
  userDirectDedicatedCareNavigatorLink,
  defaultDirectCareNavigatorBookingLink,
  goToCareNavigatorSchedulingPage,
  showLiveChat,
  userDedicatedCareNavigatorInfo,
  showMinimalCTAText = false,
}: {
  isUserInternational: boolean
  isMobileSized: boolean
  trackEventWithObj: TrackEventWithObjAction
  customerPhoneNumber: string | undefined
  userId: string
  name: string
  email: string
  salesforceId: string
  launchDate: string | undefined
  isLyrian: boolean
  getDirectDedicatedCareNavigatorLink: (userId: string) => Promise<string>
  userDirectDedicatedCareNavigatorLink: string
  defaultDirectCareNavigatorBookingLink: string | undefined
  showLiveChat: boolean
  userDedicatedCareNavigatorInfo: DirectDedicatedCareNavigatorInfoValues
  showMinimalCTAText?: boolean
  goToCareNavigatorSchedulingPage: ({
    defaultDirectCareNavigatorBookingLink,
    directDedicatedCareNavigatorLink,
    name,
    email,
    salesforceId,
    launchDate,
    isLyrian,
  }: {
    defaultDirectCareNavigatorBookingLink: string | undefined
    directDedicatedCareNavigatorLink: string
    name: string
    email: string
    salesforceId: string
    launchDate: string | undefined
    isLyrian: boolean
  }) => void
}) => {
  const navigate = useNavigate()
  const [isShowingBottomSheet, setIsShowingBottomSheet] = useState(false)
  const bottomSheetRef = useRef<BottomSheetGorhom>(null)
  const snapHeight = useRef(Layout.window.height).current * 0.9
  const [startChat, setStartChat] = useState(false)
  const { isDedicatedCareNavigatorEnabled } = useFlags()
  const { pathname } = useLocation()

  const customerLanguage = useSelector(getUserDisplayLanguage)
  const countryIsoCode = useSelector(getUserCountryCode)
  const customerName = useSelector(getCustomerName)
  const countryName = useSelector(getCurrentCountryName)

  useEffect(() => {
    // Only log the page load if the bottom sheet is shown since this component is shared between the full page and modal views
    // The full page views have their own mixpanel events
    if (isShowingBottomSheet) {
      trackEventWithObj({
        event: S4C_EVENTS.VIEW_GENERAL_CONTACT_CN_TEAM,
        [COMMON_MIXPANEL_PROPERTIES.ACTION]: COMMON_MIXPANEL_EVENTS.PAGE_LOAD,
        [S4C_PROPERTIES.CN_entrypoint]: convertRouteToMixpanelPage(pathname),
        [COMMON_MIXPANEL_PROPERTIES.BUTTON_TEXT]: MIXPANEL_CARE_NAVIGATOR_BUTTON_TEXT(isUserInternational),
        [COMMON_MIXPANEL_PROPERTIES.COUNTRY_FLAG]: isUserInternational ? 'International' : 'Domestic',
        [S4C_PROPERTIES.CONTACT_OPTIONS]: MIXPANEL_CARE_NAVIGATOR_OPTIONS({
          isInternationalUser: isUserInternational,
          isLyrian,
          isSidePanel: true,
        }),
      })
    }
  }, [isShowingBottomSheet, isUserInternational, pathname, trackEventWithObj, isLyrian])

  useEffect(() => {
    if (startChat) {
      setStartChat(false)
      setIsShowingBottomSheet(false)
    }
  }, [startChat])

  useEffect(() => {
    if (isUserInternational) {
      initializeICASChat({ customerLanguage, countryIsoCode, testMode: true })
    }
  }, [countryIsoCode, customerLanguage, isUserInternational])

  const openBottomSheet = useCallback(() => {
    bottomSheetRef.current?.expand()
  }, [])
  const closeBottomSheet = useCallback(() => {
    bottomSheetRef?.current?.close()
    setIsShowingBottomSheet(false)
  }, [setIsShowingBottomSheet])
  const snapPoints = isMobileSized ? [snapHeight] : [452]

  const onPressFAQ = () => {
    window.open(`${window.location.protocol}//${window.location.host}/faq`, '_blank')
  }

  const onPressEmail = () => {
    trackEventWithObj({
      event: MIXPANEL_EVENTS.CLICK_EMAIL_CARE_NAVIGATOR,
      [COMMON_MIXPANEL_PROPERTIES.ACTION]: COMMON_MIXPANEL_EVENTS.CLICK,
      [COMMON_MIXPANEL_PROPERTIES.PAGE]: MIXPANEL_PAGE.GENERAL_CN_CONTACT_PAGE,
      [COMMON_MIXPANEL_PROPERTIES.COUNTRY_FLAG]: isUserInternational ? 'International' : 'Domestic',
    })
    mailTo(getHelpEmailAddress({ isInternationalUser: isUserInternational }))
  }

  const onPressLiveChat = () => {
    trackEventWithObj({
      event: MIXPANEL_EVENTS.CHAT_LIVE,
      [COMMON_MIXPANEL_PROPERTIES.ACTION]: COMMON_MIXPANEL_EVENTS.CLICK,
      [COMMON_MIXPANEL_PROPERTIES.PAGE]: MIXPANEL_PAGE.GENERAL_CN_CONTACT_PAGE,
      [COMMON_MIXPANEL_PROPERTIES.COUNTRY_FLAG]: isUserInternational ? 'International' : 'Domestic',
    })
    if (isUserInternational) {
      openICASChat({ customerName, countryName })
    } else {
      setStartChat(!startChat)
    }
  }

  const onPressPhoneCall = () => {
    trackEventWithObj({
      event: MIXPANEL_EVENTS.CLICK_CARE_NAVIGATOR_PHONE_NUMBER,
      [COMMON_MIXPANEL_PROPERTIES.ACTION]: COMMON_MIXPANEL_EVENTS.CLICK,
      [COMMON_MIXPANEL_PROPERTIES.PAGE]: MIXPANEL_PAGE.GENERAL_CN_CONTACT_PAGE,
      [COMMON_MIXPANEL_PROPERTIES.COUNTRY_FLAG]: isUserInternational ? 'International' : 'Domestic',
    })
    if (isUserInternational) {
      return navigate('/care-team-phone-numbers')
    } else {
      return Linking.openURL(`tel:${customerPhoneNumber}`)
    }
  }

  const onPressScheduleCall = async () => {
    trackEventWithObj({
      event: COMMON_MIXPANEL_EVENTS.CLICK_SCHEDULE_A_CN_CALL,
      [COMMON_MIXPANEL_PROPERTIES.ACTION]: COMMON_MIXPANEL_EVENTS.CLICK,
      [COMMON_MIXPANEL_PROPERTIES.PAGE]: MIXPANEL_PAGE.GENERAL_CN_CONTACT_PAGE,
      [COMMON_MIXPANEL_PROPERTIES.COUNTRY_FLAG]: isUserInternational ? 'International' : 'Domestic',
    })
    const getCareNavigatorLink = isDedicatedCareNavigatorEnabled
      ? Promise.resolve(userDedicatedCareNavigatorInfo.ScheduleOnceURL)
      : getDirectDedicatedCareNavigatorLink(userId).catch((error: string) => {
          console.error('Failed to get the user’s dedicated care navigator link', error)
          return userDirectDedicatedCareNavigatorLink
        })

    getCareNavigatorLink.then((directDedicatedCareNavigatorLink: string) => {
      goToCareNavigatorSchedulingPage({
        defaultDirectCareNavigatorBookingLink,
        directDedicatedCareNavigatorLink,
        name,
        email,
        salesforceId,
        launchDate,
        isLyrian,
      })
    })
  }

  const CareNavigatorConsolidatedPage = (
    <ContactCareNavigatorConsolidatedPageComponent
      customerPhoneNumber={customerPhoneNumber}
      isLyrianFlow={isLyrian}
      isInternational={isUserInternational}
      onPressEmail={onPressEmail}
      onPressFAQ={onPressFAQ}
      onPressPhoneCall={onPressPhoneCall}
      onPressScheduleCall={onPressScheduleCall}
      onPressLiveChat={onPressLiveChat}
      onExit={closeBottomSheet}
      useSidePanelStyle={true}
      showLiveChat={showLiveChat}
      careNavigatorHeadshot={userDedicatedCareNavigatorInfo?.headShotURL}
      careNavigatorLicense={userDedicatedCareNavigatorInfo?.clinicalLicenses}
      careNavigatorName={userDedicatedCareNavigatorInfo?.firstName}
      showMinimalCTAText={showMinimalCTAText}
    />
  )

  return {
    isShowingBottomSheet,
    setIsShowingBottomSheet,
    snapPoints,
    bottomSheetRef,
    openBottomSheet,
    closeBottomSheet,
    careNavigatorConsolidatedBottomSheet: CareNavigatorConsolidatedPage,
    startChat,
  }
}
