import React, { FunctionComponent } from 'react'
import { View } from 'react-native'
import { useLocation } from 'react-router'

import { useLyraIntl } from '@lyrahealth-inc/shared-app-logic'
import { EssentialsHomepageWidget } from '@lyrahealth-inc/ui-core-crossplatform'

import RegistrationEmailCaptureContainer from '../register/setupAccount/RegistrationEmailCaptureContainer'

export const EssentialsHomepageWidgetContainer: FunctionComponent = () => {
  const { activeLanguage } = useLyraIntl()
  const { state } = useLocation()

  return (
    <View>
      <EssentialsHomepageWidget language={activeLanguage} />
      <RegistrationEmailCaptureContainer
        isModalView
        showModalDelay={2000}
        registrationEntryPoint={state?.registrationEntryPoint}
      />
    </View>
  )
}
