import { useMemo } from 'react'

import { includes, isNil } from 'lodash-es'

import { FieldSchema, UiMetadata } from '../../../models'

export const getSchemaTotalPages = ({ schema, uiSchema }: { schema?: FieldSchema; uiSchema?: UiMetadata }): number => {
  // When using the wildcard property in ui:order we should place all fields on page 1
  // therefore the total page number should only be 1
  const uiOrder = uiSchema?.['ui:order']
  if (includes(uiOrder, '*')) return 1
  const fields = schema?.properties
  if (fields) {
    // We want to filter out hidden fields
    const visibleFieldKeys = Object.keys(fields).filter((key) => fields[key].show !== false)
    const pageValues = visibleFieldKeys.map((key) => fields[key].mobilePage)
    const filteredPageValues = pageValues.filter((value) => !isNil(value)) as number[]
    const largestPageNumber = Math.max(...filteredPageValues)
    // If no mobilePage values are found set total to 1
    if (largestPageNumber === -Infinity || isNaN(largestPageNumber)) {
      return 1
    }
    // If not all schema fields have a mobilePage property increase total by one.
    // These un-numbered pages will exist on a new page at the end of the activity.
    return pageValues.length > filteredPageValues.length ? largestPageNumber + 1 : largestPageNumber
  }
  return 0
}

/**
 * Hook to get total number of pages given a schema
 */
export const useGetSchemaTotalPages = ({ schema, uiSchema }: useGetSchemaTotalPagesParams): number => {
  return useMemo(() => {
    return getSchemaTotalPages({ schema, uiSchema })
  }, [schema, uiSchema])
}

type useGetSchemaTotalPagesParams = {
  schema?: FieldSchema
  uiSchema?: UiMetadata
}
