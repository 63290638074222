import React from 'react'
import { MessageDescriptor } from 'react-intl'

import {
  CostMessagingCoverageType,
  CostMessagingModalContent,
  CustomEligibilityMetadata,
  CustomerRedirectionProps,
  EligibilityType,
  PATHWAYS_TYPE,
  TREATMENT_OPTIONS_TYPE,
  TREATMENT_RECOMMENDATION_TIER,
} from '../../features'
import { LocationMatcherDisplayFormats } from '../searchForCare/SearchForCare'
import { SessionLimitByProgram } from '../session/SessionUsage'
import { CustomerPropertiesMap, YearProgramCoverage } from '../user/User'
export interface CustomerInfo {
  label: string
  value: string
  internal_customer: boolean
  lyra_code_enabled: boolean | string
  lyra_code_required: boolean | string
  customer_session_type: SessionType[]
  session_count_starts_on: string
  blended_care_meds_enabled: string
  program_coverage_breakdown: {
    [key: string]: YearProgramCoverage
  }
  hide_dependent_field: boolean | string
  check_adult_dependent_eligibility_using_dependent_info: boolean | string
  check_child_dependent_eligibility_using_dependent_info: boolean | string
  customer_aliases?: string[]
  display_visits_per_issue_per_year?: number
  eap_eligibility_check_enabled?: string
  sessions_limit_by_program?: SessionLimitByProgram
}

interface SessionType {
  value: string
  label: string
}

export enum CUSTOMER_CONFIGURATION {
  EAP_ONLY = 'EAP only',
  EAP_PLUS_HPI = 'EAP plus HPI',
  HPI_ONLY = 'HPI only',
}

// export type CustomerTreatmentsOptionLocation = typeof TREATMENT_RECOMMENDATION_TIER & typeof SeeMoreLocation
enum SeeMoreLocation {
  SEE_MORE = 'seeMore',
}
// export const CustomerTreatmentsOptionLocation = { ...TREATMENT_RECOMMENDATION_TIER, ...SeeMoreLocation }
export type CustomerTreatmentsOptionLocation = TREATMENT_RECOMMENDATION_TIER | SeeMoreLocation
export interface CustomerTreatmentOption {
  option: TREATMENT_OPTIONS_TYPE
  config: {
    customCopy?: {
      title: string | JSX.Element
      description: string | JSX.Element
      buttonText: string | JSX.Element
      url: string
    }
    locationsDropdown?: {
      options: { label: string; value: string }[]
      label: MessageDescriptor
      placeholder: MessageDescriptor
      ctaClickHandler: (dropdownValue: string) => void
    }
    footnote?: string | JSX.Element
    key?: string // name of treatment option, e.g. "CROSSOVER_HEALTH", used for filtering/excluding treatment options
    minAge?: number
  }
  /** Location -> Position where the treatment option should appear relative to the options in `treatmentOptions.tsx` */
  optionRanking: {
    [key in CustomerTreatmentsOptionLocation]?: number
  }
}

// number: the required count for type of character
export interface PasswordPolicy {
  minUpperCase: number
  minLowerCase: number
  minNumber: number
  minLength: number
}

// boolean: if the requirement is met
export interface PasswordPolicyMet {
  minUpperCase: boolean
  minLowerCase: boolean
  minNumber: boolean
  minLength: boolean
}

export const DEFAULT_PASSWORD_POLICY: PasswordPolicy = {
  minUpperCase: 1,
  minLowerCase: 1,
  minNumber: 1,
  minLength: 8,
}

interface WorkLifeIdentifiers {
  id: string
  dataTestId: string
}

export interface WorkLifeServicesShape {
  identifiers: WorkLifeIdentifiers
  title: string
  illustrationType?: string
  img?: React.ReactNode
  paragraph?: string | (({ customerName }: { customerName: string }) => string) | React.ReactNode
  bullets: Array<string | React.ReactNode>
  CTA?: string
  PATH?: string
}

export interface CustomerWithoutMeds {
  legalNotice: React.ReactNode
  servicesNotCovered: string
}

interface FAQQuestion {
  question: string
  answer: React.ReactNode
}

export interface CustomerSubpopulationOption {
  label: string | MessageDescriptor
  description: string | MessageDescriptor
}

export interface CustomerSubpopulations {
  header: string | MessageDescriptor
  subHeader?: string | MessageDescriptor
  options: Array<CustomerSubpopulationOption>
}

export interface CustomerRedirection {
  header: MessageDescriptor
  checkboxLabel: MessageDescriptor
  redirectionText: MessageDescriptor
  redirectionLinkPress: () => void
  shouldShowBanner: boolean
}

export interface SMSConsentText {
  primaryConsentText: React.ReactNode
  secondaryConsentText: React.ReactNode
}

// These properties have been copied over from the default customer copy in the exact order it appears in LW
export interface CustomerCopy {
  /* Flag to indicate whether or not we want to display the session limit to users as part of a treatment option.
   * True means the user will see the string "Your sessions are covered, up to x sessions" where x is the value of
   * the customer property `numberOfSessions` for that given customer.
   * False means the user will be the string "Your session costs are covered".
   *
   * In most cases, this will be true, but for our older "default" customers, this should start as false since they
   * effectively have "unlimited" sessions.
   */
  displaySessionLimit?: boolean
  /* The "default" list of FAQ a customer will have.
   * Each item in this list should represent an FAQ item and can either be a string or an object.
   * Specifying a string means the question and answer pair corresponds to one that can be found
   * in the QuestionGenerator class from FaqView.js.
   * Specifying an object means that the question differs in some fashion - i.e. question text or answer text.
   * Object attributes:
   ** question: The text that should be displayed for the question in the FAQ. Required.
   ** answer: The text that should be displayed for the answer in the FAQ. Required if `key` is not present.
   ** key: A string representing the question from QuestionGenerator that this item should correspond to. Specifying
   *       this attribute means that the answer should be pulled from QuestionGenerator, but the `answer` attribute,
   *       if given, will override this. The key is useful to identify questions that are contextually the same
   *       across customers even when the question and answers differ. Optional.
   *
   * In reality, every customer has little idiosyncrasies about their FAQ, so the following list will rarely be the
   * displayed FAQ. Please look at other customer copy files for more examples of FAQ lists.
   */
  faqQuestions?: (string | FAQQuestion)[]
  showEligibilityQuestionForInternationalRegistration?: boolean
  generateEligibilityQuestionTitle?: () => MessageDescriptor | string
  generateEligibilityDependentTitle?: () => MessageDescriptor | string
  generateRegistrationFormSubHeaderText?: () => MessageDescriptor | string
  generateConfirmEligibilityFormSubHeaderText?: () => MessageDescriptor | string
  generateConfirmEligibilityFormDefaultError?: (isHealthPlanDirect?: boolean) => React.ReactNode
  eligibilityQuestionSubtitle?: MessageDescriptor | null
  accountLockedText?: MessageDescriptor
  dependentButtonText?: MessageDescriptor
  legalNotice?: React.ReactNode
  // TODO: In LW this is called locationMatcher - Replace when all customers have been moved over
  locationFinder?: {
    onsiteFormat: LocationMatcherDisplayFormats
    onsiteDescription: React.ReactNode
  }
  modalityQuestionnaireText?: {
    modalityLabelAddendums: {
      video: MessageDescriptor | string
    }
  }
  passwordResetFailedText?: MessageDescriptor
  servicesNotCovered?: string | React.ReactNode
  servicesCovered?: string
  shouldDisplayCareTeamContactInfo?: boolean
  shouldDisplayBCTProviderBenefits?: boolean
  smsConsent?: SMSConsentText
  eligibilityMetadata?: CustomEligibilityMetadata
  /* Worklife services that a customer can access, assuming full eligibility.
   * Not all customers have access to all services, but this is determined on component render, so we provide all
   * possible service copies to the customer without taking actual offered services into account.
   *
   * Customers can customize their service copy by passing in an array of different services (which will be displayed
   * in the order of items in the array). They can also generate entirely new copy for a given service so long as the
   * ID of the service object maps to a valid worklife service identifier used in the worklife customer property.
   *
   * e.g. This would constitute a valid custom service:
   * { identifiers: WORKLIFE_SERVICE_IDS.IDENTITY_THEFT, title: 'Legal Services (for U.S. employees only)', ... }
   * This would not constitute a valid custom service because 'legalServices' is not a valid worklife service ID:
   * { identifiers: { id: 'legalServices' }, title: 'Legal Services (for U.S. employees only)', ... }
   */
  workLifeServices?: Array<WorkLifeServicesShape>
  hideWorkLifeServicesForInternationalUser?: boolean // do not show WLS for international users
  treatmentOptions?: Array<CustomerTreatmentOption>
  redirectionCheckbox?: React.ReactNode
  subpopulations?: CustomerSubpopulations
  subpopulations_lctTeens?: CustomerSubpopulations
  customerRedirection?: (customerRedirectionProps: CustomerRedirectionProps) => CustomerRedirection
  generateEmployeeEligibilityError?: (isConfirmEligibility?: boolean, isUnexpectedError?: boolean) => React.ReactNode
  generateEligibilityCheckSubmissionErrors?: (
    eligibilityType?: EligibilityType,
    checkAdultDependentEligibilityUsingDependentInfo?: boolean,
  ) => {
    [fieldName: string]: MessageDescriptor
  }
  customerName?: string
  shouldUseCustomChildCare?: (childDOB: string) => boolean
  highAlertEmergencyInfo?: React.ReactNode
  costMessagingModalText?: { [key in CostMessagingCoverageType]: CostMessagingModalContent[] }
  customerDisplayName?: string
}

export interface CustomerCopyParams extends CustomerPropertiesMap {
  customerName?: string
  eligibilityType?: string
  customerDisplayName?: string
}

export type GetCustomerCopyFunc = (params: CustomerCopyParams) => CustomerCopy

export type CustomerCopyShape = {
  copy: GetCustomerCopyFunc
  template?: CustomerWithoutMeds | GetCustomerCopyFunc
}

export type CustomerConstantsMap = Record<string, CustomerCopyShape>

export enum MicrositeConfigSectionNames {
  HERO = 'hero',
  MOBILE_APP_PROMO_BANNER = 'mobileAppPromoBanner',
  OFFERINGS_CAROUSEL = 'offeringsCarousel',
  MOBILE_APP_PROMO = 'mobileAppPromo',
  SELF_CARE = 'selfCare',
  TESTIMONIALS = 'testimonials',
  LYRA_MEMBERSHIP = 'lyraMembership',
  CARE_NAVIGATOR = 'careNavigator',
  FAQ = 'faq',
}

export interface MicrositeConfig {
  [MicrositeConfigSectionNames.HERO]: {
    pathwaysType: PATHWAYS_TYPE | undefined
  }
  [MicrositeConfigSectionNames.MOBILE_APP_PROMO_BANNER]: {
    hideSection: boolean
  }
  [MicrositeConfigSectionNames.OFFERINGS_CAROUSEL]: {
    hideSection: boolean
    hideMeetWithExpert: boolean
    hideWellnessResources: boolean
    hideAllOfferings: boolean
  }
  [MicrositeConfigSectionNames.MOBILE_APP_PROMO]: {
    hideSection: boolean
  }
  [MicrositeConfigSectionNames.SELF_CARE]: {
    hideSection: boolean
  }
  [MicrositeConfigSectionNames.TESTIMONIALS]: {
    hideSection: boolean
  }
  [MicrositeConfigSectionNames.LYRA_MEMBERSHIP]: { hideSection: boolean; hideNumberOfSessions: boolean }
  [MicrositeConfigSectionNames.CARE_NAVIGATOR]: {
    hideSection: boolean
  }
  [MicrositeConfigSectionNames.FAQ]: {
    hideSection: boolean
  }
}

export const DEFAULT_MICROSITE_CONFIG: MicrositeConfig = {
  [MicrositeConfigSectionNames.HERO]: {
    pathwaysType: undefined,
  },
  [MicrositeConfigSectionNames.MOBILE_APP_PROMO_BANNER]: {
    hideSection: false,
  },
  [MicrositeConfigSectionNames.OFFERINGS_CAROUSEL]: {
    hideSection: false,
    hideMeetWithExpert: false,
    hideWellnessResources: false,
    hideAllOfferings: false,
  },
  [MicrositeConfigSectionNames.MOBILE_APP_PROMO]: {
    hideSection: false,
  },
  [MicrositeConfigSectionNames.LYRA_MEMBERSHIP]: { hideSection: false, hideNumberOfSessions: false },
  [MicrositeConfigSectionNames.SELF_CARE]: {
    hideSection: false,
  },
  [MicrositeConfigSectionNames.TESTIMONIALS]: {
    hideSection: false,
  },
  [MicrositeConfigSectionNames.CARE_NAVIGATOR]: {
    hideSection: false,
  },
  [MicrositeConfigSectionNames.FAQ]: {
    hideSection: false,
  },
}
