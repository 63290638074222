import { matchPath } from 'react-router'

import { COMMON_MIXPANEL_PAGE, COMMON_SEARCH_FOR_CARE_SCREENS } from '@lyrahealth-inc/shared-app-logic'

import { routeToPageMap } from '../../common/constants/mixpanelConstants'

export function convertRouteToMixpanelPage(
  route: string,
  hasChildren = false,
  returnRouteIfNotFound = true,
): COMMON_SEARCH_FOR_CARE_SCREENS | COMMON_MIXPANEL_PAGE | string | undefined {
  // The about-child route can either be the child selection screen or the child information screen
  // depending on the number of children under this user.
  if (matchPath('secure/onboard/about-child', route)) {
    return hasChildren
      ? COMMON_SEARCH_FOR_CARE_SCREENS.CHILD_SELECTION
      : COMMON_SEARCH_FOR_CARE_SCREENS.CHILD_INFORMATION
  }

  for (const path of Object.keys(routeToPageMap)) {
    if (matchPath(path, route)) {
      return routeToPageMap[path]
    }
  }
  // if there are no matching pathes from the list above, just return the original route for logging
  return returnRouteIfNotFound ? route : undefined
}
