import { DEFAULT_LANGUAGE } from '../intl/constants'

export const getShouldDisplayProviderResultsSurvey = ({
  shouldSeePendoGuides, // feature flag
  showProviderResultsSurvey, // This is determined by the date the user last saw the survey. This date is retrieved from local storage for web and persistent store for mobile.
  isUserInternational, // The survey is limited to the US
  displayLanguage, // The survey is limited to those who have their display language set to en-US
}: {
  shouldSeePendoGuides?: boolean
  showProviderResultsSurvey?: boolean
  isUserInternational?: boolean
  displayLanguage?: string
}) => {
  return Boolean(
    shouldSeePendoGuides && showProviderResultsSurvey && !isUserInternational && displayLanguage === DEFAULT_LANGUAGE,
  )
}
