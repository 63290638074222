import { useMemo } from 'react'

import { format, utcToZonedTime } from 'date-fns-tz'

import { AvailabilityDate } from '../../../models/calendar/CalendarV2'

/**
 * Parse matching availability slots to separate them into an array of objects containing matching date
 * with availability times and return filtered dates from provider availabilities
 */
export const useParseMatchingAvailabilities = ({
  matchingAvailabilities,
  timeZone,
}: {
  matchingAvailabilities: string[]
  timeZone: string
}): AvailabilityDate[] => {
  return useMemo(() => {
    const matchingDatesMap = new Map<string, Set<string>>()

    for (const availability of matchingAvailabilities) {
      const zonedDate = utcToZonedTime(availability, timeZone)
      const dateParts = format(zonedDate, 'MM/dd/yyyy', { timeZone }).split('/')
      const dateStr = `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`
      if (!matchingDatesMap.has(dateStr)) {
        matchingDatesMap.set(dateStr, new Set())
      }
      matchingDatesMap.get(dateStr)?.add(availability)
    }

    // Organize slots based on date
    const matchingDates: AvailabilityDate[] = []
    for (const [date, datetimes] of matchingDatesMap) {
      matchingDates.push({
        date,
        // Only add the times in the time range that are within our appointment duration that exists in our list of earliest times
        times: Array.from(datetimes),
      })
    }

    return matchingDates
  }, [matchingAvailabilities, timeZone])
}
