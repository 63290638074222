import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { LegalFooterProps, openUrl } from '@lyrahealth-inc/ui-core-crossplatform'

import { getLegalNotice } from '../../data/customer/customerSelectors'
import { getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer } from '../../data/user/userSelectors'

export const useGetLegalFooterProps = (): LegalFooterProps => {
  const onLegalFooterLinkPress = (url: string) => {
    openUrl(url)
  }

  const isUserAssumedInternational = useSelector(getIsLoggedOutUserAssumedInternationalAndOfInternationalCustomer)

  const legalNotice = useSelector(getLegalNotice)

  return useMemo(() => {
    return {
      footerLinkPressed: onLegalFooterLinkPress,
      isInternational: isUserAssumedInternational,
      legalNotice,
    }
  }, [isUserAssumedInternational, legalNotice])
}
