import { useSelector } from 'react-redux'

import {
  COMMON_MIXPANEL_PAGE,
  ProgramCustomerPropertyId,
  useForkedTriageAvailability,
  WELLNESS_TOOLKIT_TYPE,
} from '@lyrahealth-inc/shared-app-logic'

import { useIsWellnessToolkitAvailable } from './useIsWellnessToolkitAvailable'
import { getCustomerPropertiesMap } from '../../data/customer/customerSelectors'

/**
 * Hook to be used for microsite multi cta availability
 * NOTE: only use in microsite homepage (i.e. do not use in homebase/logged in)
 */
export const useIsMultiCtaPathwaysAvailable = () => {
  const customerPropertiesMap = useSelector(getCustomerPropertiesMap)?.toJS()
  const essentialsDisabled = customerPropertiesMap?.[ProgramCustomerPropertyId.essentialsDisabled] === 'true'
  const {
    isToolkitAvailable: isIndividualCoachingAvailable,
    reasonsForAvailabilityStatus: reasonsForIndividualCoachingAvailabilityStatus,
  } = useIsWellnessToolkitAvailable(WELLNESS_TOOLKIT_TYPE.INDIVIDUAL_COACHING, COMMON_MIXPANEL_PAGE.FORKED_TRIAGE)
  const {
    isToolkitAvailable: isParentCoachingToolkitAvailable,
    reasonsForAvailabilityStatus: reasonsForParentCoachingAvailabilityStatus,
  } = useIsWellnessToolkitAvailable(WELLNESS_TOOLKIT_TYPE.PARENT_COACHING, COMMON_MIXPANEL_PAGE.FORKED_TRIAGE)

  const { availableOptions, reasons, forkedTriageAvailable } = useForkedTriageAvailability({
    customerPropertiesMap,
    coachingToolkitAvailability: {
      individual: {
        available: isIndividualCoachingAvailable,
        reasons: reasonsForIndividualCoachingAvailabilityStatus,
      },
      parent: {
        available: isParentCoachingToolkitAvailable,
        reasons: reasonsForParentCoachingAvailabilityStatus,
      },
    },
  })

  return {
    showMultiCTAPathways: !essentialsDisabled && forkedTriageAvailable,
    availableOptions,
    reasons,
  }
}
