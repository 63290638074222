import { useMemo } from 'react'

import { Assignment } from '../../../models/assignments/Assignments'
import { AssessmentStages, AssignmentStatuses } from '../types'

export const useGetFinalAssessments = (assignments: Assignment[]) => {
  const getFinalAssessment = (assignments: Assignment[]) => {
    const finalAssessments: Assignment[] = assignments?.filter(
      (assignment) => assignment?.content_meta_data?.stage === AssessmentStages.end,
    )

    // just return if there's no post episode or final assessment, or just one
    if (finalAssessments.length === 0) return []

    // if multiple post care assessments, filter out missed. sort it by create_date afterwards
    const activeFinalAssessments: Assignment[] = finalAssessments
      ?.filter(
        (assignment) =>
          assignment.status == AssignmentStatuses.new || assignment.status == AssignmentStatuses.in_progress,
      )
      .sort((a: Partial<Assignment>, b: Partial<Assignment>) => {
        if (a.create_date && b.create_date)
          return a.create_date > b.create_date ? -1 : a.create_date < b.create_date ? 1 : 0
        return 0
      })

    // return the latest
    return activeFinalAssessments
  }
  return useMemo(() => getFinalAssessment(assignments), [assignments])
}
