import { SpeculativeSearchThresholds, WELLNESS_TOOLKIT_TYPE } from './types'

export const DEFAULT_SPECULATIVE_FILTER_THRESHOLDS: SpeculativeSearchThresholds = {
  [WELLNESS_TOOLKIT_TYPE.FAMILY_HUB]: {
    filters: {
      check: [
        {
          numProviders: {
            gte: 2,
          },
        },
      ],
    },
  },
  [WELLNESS_TOOLKIT_TYPE.INDIVIDUAL_COACHING]: {
    filters: {
      check: [
        {
          numProviders: {
            gte: 2,
          },
        },
      ],
    },
  },
  [WELLNESS_TOOLKIT_TYPE.PARENT_COACHING]: {
    filters: {
      check: [
        {
          numProviders: {
            gte: 2,
          },
        },
      ],
    },
  },
}
