import React, { FunctionComponent, MutableRefObject, ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { Platform, TextStyle, View, ViewStyle } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { InputLabelBadgeTypes, TitleInfoPopover } from '@lyrahealth-inc/shared-app-logic'

import * as CustomMetadataElements from './customMetadataElements/index'
import { AccessibilityRolesNative } from '../../constants'
import { UICoreFormContext } from '../../organisms/formBody/types'
import { getFontStyles, getHighlightedText } from '../../styles/typeStyles'
import { tID } from '../../utils'
import { ThemeType } from '../../utils/themes/ThemeProvider'
import { BodyText, Size } from '../bodyText/BodyText'
import { AlertIconStroke, AlertLoudIcon } from '../icons'
import { InputLabelBadge } from '../inputLabelBadge/InputLabelBadge'
import { StyledMarkdown } from '../styledMarkdown/StyledMarkdown'
import { Tooltip as ToolTipComponent, TooltipPlacement, ToolTipTriggerConfig } from '../tooltip/Tooltip'

import type * as CSS from 'csstype'

type BaseInputProps = {
  labelAlignment?: CSS.Properties['alignItems']
  label?: string
  isOptionalLabel?: boolean // show "OPTIONAL" tag next to the label
  subLabel?: string
  subLabelComponent?: string
  labelSecondary?: string
  error?: boolean | string
  name?: string
  /** Array field label */
  largeLabel?: boolean
  largeSubLabel?: boolean
  inputValueLabel?: string
  readOnly?: boolean
  style?: ViewStyle
  labelStyle?: ViewStyle
  labelContainerStyle?: ViewStyle
  toolTipContent?: string | ReactElement
  toolTipTriggerConfig?: ToolTipTriggerConfig
  toolTipContentStyle?: { container?: Dict }
  toolTipPlacement?: TooltipPlacement
  showValue?: boolean
  labelRef?: MutableRefObject<any>
  hideErrorPadding?: boolean
  errorMessageId?: string
  hideErrorMessage?: boolean
  inputValueColor?: string
  labelInfoPopover?: TitleInfoPopover
  contextProps?: UICoreFormContext
  showErrorAsWarning?: boolean
  showDivider?: boolean
  percentageValue?: boolean
  customTextStyle?: TextStyle
  labelBadgeType?: InputLabelBadgeTypes
}

const LabelContainer = styled.View<{ largeLabelWithSublabel?: boolean; theme: ThemeType }>(
  ({ largeLabelWithSublabel, theme }) => ({
    marginBottom: largeLabelWithSublabel ? theme.spacing['16px'] : '0',
    flexDirection: 'row',
    justifyContent: 'space-between',
  }),
)

const CombinedLabelContainer = styled.View<{ hasMargin?: boolean; bottomMargin?: number }>(
  ({ theme, hasMargin, bottomMargin }) => ({
    marginBottom: hasMargin ? theme.spacing[`${bottomMargin}px`] : '0px',
  }),
)

const StyledMarkdownContainer = styled.View<{ labelAlignment?: CSS.Properties['alignItems']; theme: ThemeType }>(
  ({ labelAlignment }) => ({
    flexGrow: 3,
    flexShrink: 2,
    alignItems: labelAlignment ?? 'flex-start',
    textAlign: labelAlignment === 'center' ? 'center' : 'inherit',
  }),
)

const ToolTipLabelContainer = styled.View({
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
})

const BadgeContainer = styled.View<{ theme: ThemeType }>(({ theme: { spacing } }) => ({
  marginLeft: spacing['8px'],
}))

const ToolTip = styled(ToolTipComponent)(({ theme }) => ({
  marginLeft: theme.spacing['4px'],
}))

const Container = styled.View(({ theme }) => ({
  marginBottom: theme.spacing['40px'],
}))

const ErrorContainer = styled.View<{ theme: ThemeType; hideErrorPadding: boolean }>(({ theme, hideErrorPadding }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: hideErrorPadding ? 0 : Platform.OS === 'web' ? theme.spacing['4px'] : theme.spacing['8px'],
}))

const InputValueLabelContainer = styled.View<{ theme: ThemeType; percentageValue: boolean | undefined }>(
  ({ theme, percentageValue }) => ({
    backgroundColor: theme.colors.backgroundHighlightTeal,
    borderRadius: theme.spacing['4px'],
    alignItems: 'center',
    // number value text won't center in the container on Android without justifyContent. WEB & iOS do not need it
    justifyContent: Platform.OS === 'android' ? 'center' : '',
    height: '26px',
    flexBasis: percentageValue ? '48px' : '34px',
    alignSelf: 'flex-end',
  }),
)

const ErrorText = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  marginLeft: theme.spacing['8px'],
}))

const LabelRowContainer = styled(View)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: '100%',
})

const OptionalLabelContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundSection,
  paddingHorizontal: theme.spacing['8px'],
  borderRadius: '4px',
}))
const OptionalText = styled(BodyText)<{ theme: ThemeType }>(({ theme }) => ({
  color: theme.colors.textInactive,
  fontSize: 12,
}))

const Divider = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  width: '100%',
  height: '1px',
  backgroundColor: theme.colors.dividerSecondary,
}))

/**
 * A wrapper component for our form elements that provides styling
 * for labels and error handling.
 */

export const BaseInput: FunctionComponent<BaseInputProps> = ({
  labelAlignment,
  label,
  isOptionalLabel,
  subLabel,
  subLabelComponent: subLabelComponentName,
  children,
  error,
  name,
  largeLabel,
  largeSubLabel,
  inputValueLabel,
  labelSecondary,
  readOnly,
  style,
  labelStyle,
  labelContainerStyle,
  toolTipContent,
  toolTipTriggerConfig,
  toolTipContentStyle,
  toolTipPlacement,
  showValue,
  labelRef,
  hideErrorPadding = false,
  errorMessageId,
  hideErrorMessage = false,
  inputValueColor,
  labelInfoPopover,
  contextProps,
  showErrorAsWarning,
  showDivider,
  percentageValue,
  customTextStyle,
  labelBadgeType,
}) => {
  const { colors } = useTheme()
  // if content is a large label, append ### for h3 else fallback to p1
  const content = largeLabel && label && label.indexOf('#') === -1 ? `### ${label}` : label
  const errorText = typeof error === 'string' ? error : ''
  const isHeader = !!content?.startsWith('#')
  const hasSublabel = !!subLabel || !!subLabelComponentName
  const isLargeLabelWithSublabel = hasSublabel && isHeader
  const subLabelFontStyles = largeSubLabel
    ? { ...getFontStyles(colors).body.default, color: colors.textPrimary }
    : { ...getFontStyles(colors).body.small, color: colors.textSecondary }
  const labelBottomMargin =
    /^#{4,}/.test(content || '') || isLargeLabelWithSublabel ? 24 : content?.startsWith('#') ? 40 : 8
  const { formatMessage } = useIntl()

  let subLabelComponent = null
  if (subLabelComponentName) {
    const SubLabelComponent = CustomMetadataElements[subLabelComponentName]
    subLabelComponent = <SubLabelComponent contextProps={contextProps} />
  }

  const labelWithTooltip = (
    <ToolTipLabelContainer>
      <StyledMarkdown
        content={content}
        customStyles={{
          p2: { marginTop: 0, marginBottom: 0, ...customTextStyle },
          h4: { marginRight: labelSecondary ? 12 : 0, marginBottom: 0 },
          em: getHighlightedText(colors),
        }}
        center={labelAlignment === 'center'}
        linkInfoPopover={labelInfoPopover}
      />

      {labelSecondary && (
        <StyledMarkdown
          content={labelSecondary}
          customStyles={{
            p2: { color: colors.textSecondary, marginTop: 0, marginBottom: 0 },
          }}
        />
      )}
      {toolTipContent && (
        <ToolTip
          content={toolTipContent}
          triggerConfig={toolTipTriggerConfig}
          contentStyle={toolTipContentStyle}
          placement={toolTipPlacement}
        />
      )}

      {labelBadgeType && (
        <BadgeContainer>
          <InputLabelBadge badgeType={InputLabelBadgeTypes[labelBadgeType]} />
        </BadgeContainer>
      )}
    </ToolTipLabelContainer>
  )

  return (
    <Container style={style} testID={tID(`field-${name}`)}>
      <CombinedLabelContainer
        ref={labelRef}
        hasMargin={!!label || hasSublabel}
        nativeID={name}
        bottomMargin={labelBottomMargin}
        style={labelContainerStyle}
      >
        {label !== undefined && (
          <LabelContainer largeLabelWithSublabel={isLargeLabelWithSublabel} style={labelStyle}>
            <StyledMarkdownContainer labelAlignment={labelAlignment}>
              {isOptionalLabel ? (
                <LabelRowContainer>
                  {labelWithTooltip}
                  <OptionalLabelContainer>
                    <OptionalText
                      testID={tID(`field-${name}-optional-label`)}
                      text={formatMessage({
                        defaultMessage: 'OPTIONAL',
                        description: 'Label text to indicate an input field is optional',
                      })}
                      bold
                    />
                  </OptionalLabelContainer>
                </LabelRowContainer>
              ) : (
                labelWithTooltip
              )}
            </StyledMarkdownContainer>
            {inputValueLabel !== undefined && showValue && (
              <InputValueLabelContainer percentageValue={percentageValue}>
                <BodyText
                  size={Size.DEFAULT}
                  text={inputValueLabel}
                  color={readOnly ? colors.textInactive : inputValueColor ? inputValueColor : colors.textActive}
                />
              </InputValueLabelContainer>
            )}
          </LabelContainer>
        )}
        {!!subLabel && (
          <StyledMarkdownContainer labelAlignment={labelAlignment}>
            <StyledMarkdown
              content={subLabel}
              center={labelAlignment === 'center'}
              customStyles={{
                p2: { ...subLabelFontStyles, marginTop: 0, marginBottom: 0 },
              }}
            />
          </StyledMarkdownContainer>
        )}
        {subLabelComponent}
      </CombinedLabelContainer>
      {children}
      {Boolean(error) && !hideErrorMessage && (
        <ErrorContainer hideErrorPadding={hideErrorPadding}>
          {showErrorAsWarning ? (
            <AlertIconStroke fillColor={colors.iconWarning} />
          ) : (
            <AlertLoudIcon fillColor={colors.iconError} />
          )}
          <ErrorText
            size={Size.SMALL}
            text={errorText}
            color={showErrorAsWarning ? colors.textWarning : colors.textError}
            testID={tID(`field-${name}-error-message`)}
            accessibilityLabel={errorText}
            nativeID={errorMessageId}
            accessibilityRole={AccessibilityRolesNative.ALERT}
          />
        </ErrorContainer>
      )}
      {showDivider && <Divider testID={tID('input-divider')} />}
    </Container>
  )
}
