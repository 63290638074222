import React, { useEffect } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { useLocation } from 'react-router-dom'

import classnames from 'classnames'

import { COMMON_MIXPANEL_EVENTS, CustomerName, ERROR_BANNER_PROPERTIES } from '@lyrahealth-inc/shared-app-logic'
import { formatMessageHelper, getStringFromMessage } from '@lyrahealth-inc/shared-app-logic/src/features/intl'
import { Alert } from '@lyrahealth-inc/ui-core'

import { BANNER_TYPE, closeBanner, setBannerPropsAndStatus } from './banner-actions'
import styles from './banner.module.scss'
import {
  getApiErrorMessageProps,
  getBannerIncludeX,
  getBannerIsOpen,
  getBannerMessage,
  getBannerType,
} from '../../../data/banner/bannerSelectors'
import { getCustomerName } from '../../../data/customer/customerSelectors'
import { trackEventWithObj } from '../../../data/mixpanel'
import { isInLoginFlow } from '../../../features/login/loginUtils'
import { isInRegisterFlow } from '../../../features/register/data/registerUtils'
import { ELIGIBILITY_EXPIRATION__WALMART_IN_MINS } from '../../constants/registrationFormConstants'

type BannerViewProps = ConnectedProps<typeof connector>

const ERROR_MESSAGE_QUERY_PARAM = 'errormsg'

const BannerView = ({
  message,
  bannerType,
  customerName,
  isOpen,
  setBannerPropsAndStatus,
  includeX = false,
  closeBanner,
  errorMessageProps,
  trackEventWithObj,
}: BannerViewProps) => {
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const errormsg = queryParams.get(ERROR_MESSAGE_QUERY_PARAM)

  useEffect(() => {
    if (errormsg) {
      setBannerPropsAndStatus({
        bannerType: BANNER_TYPE.DANGER,
        message: errormsg,
        shouldAutomaticallyClose: false,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errormsg])

  useEffect(() => {
    if (isOpen && bannerType === BANNER_TYPE.DANGER) {
      const lyraErrorMessage = getStringFromMessage(message)

      const viewErrorBannerEventProp = {
        event: COMMON_MIXPANEL_EVENTS.ERROR_BANNER,
        ...(lyraErrorMessage && { [ERROR_BANNER_PROPERTIES.LYRA_ERROR_MESSAGE]: lyraErrorMessage }),
      }
      console.debug(
        `Sending ${COMMON_MIXPANEL_EVENTS.ERROR_BANNER} event to mixpanel with props : ${errorMessageProps}`,
      )
      trackEventWithObj(
        errorMessageProps
          ? {
              ...viewErrorBannerEventProp,
              [ERROR_BANNER_PROPERTIES.ENDPOINT_METHOD]: errorMessageProps?.requestMethod ?? '',
              [ERROR_BANNER_PROPERTIES.ENDPOINT_URL]: errorMessageProps?.requestUrl ?? '',
              [ERROR_BANNER_PROPERTIES.HTTP_ERROR_CODE]: errorMessageProps?.statusCode ?? '',
              [ERROR_BANNER_PROPERTIES.ERROR_MESSAGE]: errorMessageProps?.errorMessage ?? '',
            }
          : viewErrorBannerEventProp,
      )
    }
  }, [bannerType, errorMessageProps, isOpen, message, trackEventWithObj])

  if (!message) {
    return null
  }

  const isRegisterPage = isInRegisterFlow(location.pathname)
  const isLoginPage = isInLoginFlow(location.pathname)
  const isProfilePage = location.pathname.includes('profile')

  return (
    <Alert
      show={isOpen}
      style={bannerType}
      includeX={includeX}
      // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'object'.
      customClass={classnames({
        [styles['authentication-banner']]: isRegisterPage || isLoginPage,
        [styles['eligibility-banner']]: includeX,
        [styles['profile-banner']]: isProfilePage,
      })}
      dismissHandler={() =>
        closeBanner({
          message,
          shouldDismissEligibilityCheck: true,
          ...(customerName == CustomerName.WALMART && {
            eapEligibilityBannerExpiration: ELIGIBILITY_EXPIRATION__WALMART_IN_MINS,
          }),
        })
      }
    >
      {formatMessageHelper(message)}
    </Alert>
  )
}

const mapStateToProps = ($$state: $TSFixMe) => {
  return {
    message: getBannerMessage($$state),
    bannerType: getBannerType($$state),
    isOpen: getBannerIsOpen($$state),
    includeX: getBannerIncludeX($$state),
    customerName: getCustomerName($$state),
    errorMessageProps: getApiErrorMessageProps($$state),
  }
}

const connector = connect(mapStateToProps, { setBannerPropsAndStatus, closeBanner, trackEventWithObj })
export default connector(BannerView)
