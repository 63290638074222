import React from 'react'
import { MessageDescriptor } from 'react-intl'

import { isNil } from 'lodash-es'

/**
 * Gets default message string from the message param, logic to retrieve string depends on type of message param
 * @param message message descriptor, string, or a React Node type message
 */
export const getStringFromMessage = (message: MessageDescriptor | string | React.ReactNode) => {
  if (isNil(message)) {
    return
  }

  let messageAsString: string | undefined
  if (React.isValidElement(message)) {
    const { defaultMessage } = (message as React.ReactElement).props
    if (Array.isArray(defaultMessage) && defaultMessage.length > 0) {
      messageAsString = defaultMessage[0]?.value
    }
  } else if (typeof message === 'string') {
    messageAsString = message
  } else {
    const { defaultMessage } = message as MessageDescriptor
    messageAsString = typeof defaultMessage === 'string' ? defaultMessage : messageAsString
  }

  return messageAsString
}
