import React, { FunctionComponent, ReactElement } from 'react'
import { Text, View, ViewStyle } from 'react-native'

import styled, { useTheme } from 'styled-components/native'

import { Button, ButtonTypes } from '../../atoms/button/Button'
import { tID } from '../../utils'
import { ThemeType } from '../../utils/themes/ThemeProvider'

export type BannerProps = {
  show: boolean
  bannerContent: ReactElement<Text | View>
  buttonTitle?: string
  onPress?: () => void
  backgroundColor?: string
  style?: ViewStyle
}

const BannerContainer = styled.View<{ backgroundColor: string; theme: ThemeType }>(
  ({ backgroundColor, theme: { spacing } }) => ({
    backgroundColor,
    padding: spacing['24px'],
    marginTop: '-10px',
  }),
)

const OutlineButton = styled(Button)<{ theme: ThemeType }>(({ theme: { spacing, colors } }) => ({
  marginTop: spacing['16px'],
  borderWidth: '1px',
  borderColor: colors.components.banner.outlineButton.border,
  borderRadius: spacing['4px'],
  backgroundColor: 'transparent',
}))

/**
 * A banner that can show some text and also a button for the user to interact with
 */
export const Banner: FunctionComponent<BannerProps> = ({
  show,
  bannerContent,
  onPress,
  buttonTitle,
  backgroundColor,
  style,
}) => {
  const { colors } = useTheme()
  return show ? (
    <BannerContainer testID={tID('Banner')} backgroundColor={backgroundColor || colors.backgroundActive} style={style}>
      {bannerContent}
      {Boolean(buttonTitle) && onPress ? (
        <OutlineButton onPress={() => onPress()} title={buttonTitle ?? ''} buttonType={ButtonTypes.PRIMARY} />
      ) : null}
    </BannerContainer>
  ) : null
}
