import { List, Map } from 'immutable'
import { createSelector } from 'reselect'

import { Attestation, ELIGIBILITY_TYPES, PathwayOptions, RaceCategory } from '@lyrahealth-inc/shared-app-logic'

import { getShouldHideEthnicityField } from '../customer/customerSelectors'

const getRegister = (state: $TSFixMe) => state.get('register')

export const getRegisterDependentTypes = createSelector(getRegister, (register) => register?.get('dependentTypes'))

export const getRegisterFormValues = createSelector(getRegister, (register) => register?.get('formValues', Map()))

export const getEthnicityInformation = createSelector(getRegister, (register) =>
  register?.get('ethnicityInformation', Map()),
)

export const getRaceCategories: (state: any) => RaceCategory[] = createSelector(
  getEthnicityInformation,
  (ethnicityInformation) => ethnicityInformation?.get('raceCategories', List()),
)

export const getAttestationContents: (state: Map<string, any>) => Attestation[] = createSelector(
  getRegister,
  (register) => register?.get('attestations', List()),
)

export const getShowRegistrationTips = createSelector(getRegister, (register) => register?.get('showRegistrationTips'))

export const getRegistrationSelectedCountry = createSelector(getRegister, (register) =>
  register?.get('selectedCountry', Map()),
)

export const getRegistrationSelectedCountryName = createSelector(getRegistrationSelectedCountry, (selectedCountry) =>
  selectedCountry?.get('countryName'),
)

export const getRegistrationSelectedCountryIso = createSelector(getRegistrationSelectedCountry, (selectedCountry) =>
  selectedCountry?.get('countryIso'),
)

export const getRegistrationSelectedCountryId = createSelector(getRegistrationSelectedCountry, (selectedCountry) =>
  selectedCountry?.get('countryId'),
)

export const getCustomerCountryId = createSelector(getRegistrationSelectedCountry, (selectedCountry) => {
  return selectedCountry?.get('customerCountryId') as number | undefined
})

export const getIsRegistrationSelectedCountryDomestic = createSelector(
  getRegistrationSelectedCountryIso,
  (selectedCountryIso = 'US') => {
    return selectedCountryIso === 'US'
  },
)

export const isInternationalRegistrationExperience = createSelector(
  getRegistrationSelectedCountryIso,
  (selectedCountryIso) => {
    return selectedCountryIso && selectedCountryIso !== 'US'
  },
)

export const getEligibilityType = createSelector(getRegisterFormValues, (formValues) =>
  formValues?.get('eligibilityType'),
)

export const getDependentTypeId = createSelector(getRegisterFormValues, (formValues) =>
  formValues?.get('dependentTypeId'),
)

export const getRegistrationUsername = createSelector(getRegisterFormValues, (formValues): string | undefined =>
  formValues?.get('userEmailAddress'),
)

export const getIsDependent = createSelector(
  getEligibilityType,
  (eligibilityType) => eligibilityType === ELIGIBILITY_TYPES.DEPENDENT,
)

export const getEAPIneligibleAnalyticsPayload = createSelector(
  getRegisterFormValues,
  getIsDependent,
  (formValues, isDependent) => {
    const memberDOB = formValues?.get('userDOB')
    const nonDependentPayload = {
      memberFirstName: formValues?.get('userFirstName'),
      memberLastName: formValues?.get('userLastName'),
      memberDOB: memberDOB && `${memberDOB}T00:00:00`,
      isDependent,
    }
    if (!isDependent) {
      return nonDependentPayload
    }

    const employeeDOB = formValues?.get('employeeDOB')
    const dependentPayload = {
      ...nonDependentPayload,
      employeeFirstName: formValues?.get('employeeFirstName'),
      employeeLastName: formValues?.get('employeeLastName'),
      employeeDOB: employeeDOB && `${employeeDOB}T00:00:00`,
    }
    return dependentPayload
  },
)

export const getShouldShowEthnicityField = createSelector(
  (state: $TSFixMe) => getShouldHideEthnicityField(state),
  (state: $TSFixMe) => isInternationalRegistrationExperience(state),
  (shouldHideEthnicityField: $TSFixMe, isInInternationalRegistrationExperience: $TSFixMe) =>
    !shouldHideEthnicityField && !isInInternationalRegistrationExperience,
)

export const getSsoIdentityProvider = createSelector(getRegister, (register) => register?.get('ssoIdentityProvider'))

export const getSsoToken = createSelector(getRegister, (register) => register?.get('ssoToken'))

export const getSelectedPathwayOption = createSelector(
  getRegister,
  (register) => register?.get('selectedPathwayOption') as PathwayOptions,
)

export const getSelectedEssentialsTopicIds = createSelector(getRegister, (register) =>
  register?.get('selectedEssentialsTopicIds'),
)

export const getTriageEntryPoint = createSelector(getRegister, (register) => register?.get('triageEntrypoint'))

export const getRedirectUrlOnTriageExit = createSelector(getRegister, (register) =>
  register?.get('redirectUrlOnTriageExit'),
)
