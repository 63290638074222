import React, { FunctionComponent } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { StyleProp, ViewStyle } from 'react-native'

import { UICoreFieldProps } from 'organisms/formBody/types'
import styled, { useTheme } from 'styled-components/native'

import { useAccessibilityFocus } from '../../hooks/useAccessibilityFocus'
import { getHighlightedText } from '../../styles/typeStyles'
import { tID } from '../../utils'
import { StyledMarkdown } from '../styledMarkdown/StyledMarkdown'

/**
 * Renders markdown as native elements
 */

const ContentFieldContainer = styled.View<{ center?: boolean; maxWidth?: number; content?: string }>(
  ({ theme, center, maxWidth, content }) => ({
    ...(center && { alignSelf: 'center' }),
    ...(maxWidth && { maxWidth: `${maxWidth}px` }),
    ...(content ? { marginBottom: `${theme.spacing['16px']}` } : { display: 'none' }),
  }),
)

const StyledView = styled.View({
  flexGrow: 1,
  margin: '10px 0',
})

export const ContentField: FunctionComponent<
  Pick<UICoreFieldProps, 'schema' | 'uiSchema' | 'formContext'> & Pick<FieldRenderProps<string>, 'meta'>
> = ({ schema, uiSchema, meta: { active }, formContext: { onPopOverPress } }) => {
  const {
    content,
    name,
    titleInfoPopoverContent,
    titleInfoPopoverConfig,
    InfoPopoverFooterContent,
    InfoPopoverFooterContentConfig,
    ModalContentConfig,
    style,
  } = schema
  const { colors } = useTheme()
  const [focusRef] = useAccessibilityFocus({ active, delay: 200 })
  const uiOptions = uiSchema['ui:options']
  const customStyles = {
    ...(uiOptions?.customStyles as Dict),
    em: getHighlightedText(colors),
  }

  return (
    <ContentFieldContainer
      ref={focusRef}
      content={content}
      testID={tID(`field-${name}`)}
      center={Boolean(uiOptions?.center)}
      maxWidth={uiOptions?.maxWidth as number}
      nativeID={name}
    >
      {style !== undefined ? (
        <StyledView style={style as StyleProp<ViewStyle>}>{content}</StyledView>
      ) : (
        <StyledMarkdown
          content={content}
          center={!!uiOptions?.center}
          linkInfoPopover={
            titleInfoPopoverContent
              ? {
                  content: titleInfoPopoverContent || '',
                  ...titleInfoPopoverConfig,
                  footer: InfoPopoverFooterContent,
                  ...InfoPopoverFooterContentConfig,
                }
              : undefined
          }
          modalContentInfo={ModalContentConfig}
          customStyles={customStyles}
          onPopOverPress={onPopOverPress as () => void}
        />
      )}
    </ContentFieldContainer>
  )
}
