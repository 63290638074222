import { MemberPreferencesForUserV2 } from './types'

export const CARE_LANGUAGES_PREFERENCE_CATEGORY = 'careLanguages'

export enum S4C_PROGRAM_OPTION_FLOW {
  THERAPY = 'THERAPY',
  COACHING = 'COACHING',
  COACHING_WITH_LMS = 'COACHING_WITH_LMS', // coaching with live messaging support
  SELF_INITIATED_TEENS = 'SELF_INITIATED_TEENS',
  PARENT_INITIATED_CHILD = 'PARENT_INITIATED_CHILD',
  PARENT_INITIATED_TEEN = 'PARENT_INITIATED_TEEN',
  OTHER = 'OTHER',
}

export enum MEETING_STYLE_MODAL_OPTIONS {
  NONE = 'NONE',
  IN_PERSON = 'IN_PERSON',
  VIDEO_AND_LIVE_MESSAGING = 'VIDEO_AND_LIVE_MESSAGING',
}

export enum MEMBER_PREFERENCE_IDS {
  // Race/ethnicity
  ASIAN = 'asian',
  BLACK_OR_AFRICAN_AMERICAN = 'blackOrAfricanAmerican',
  HISPANIC_OR_LATINX = 'hispanicOrLatinx',
  NATIVE_AMERICAN_OR_ALASKA_NATIVE = 'nativeAmericanOrAlaskaNative',
  NATIVE_HAWAIIAN_OR_PACIFIC_ISLANDER = 'nativeHawaiianOrPacificIslander',
  WHITE = 'white',
  MIDDLE_EASTERN_OR_NORTH_AFRICAN = 'middleEasternOrNorthAfrican',
  // Gender
  MAN = 'man',
  WOMAN = 'woman',
  NON_BINARY_PERSON = 'nonBinaryPerson',
  // LGBTQIA+
  'LGBTQIA+' = 'LGBTQIA+',
  // Religion
  BUDDHIST = 'buddhist',
  CHRISTIAN = 'christian',
  HINDU = 'hindu',
  JEWISH = 'jewish',
  MORMON = 'mormon',
  MUSLIM = 'muslim',
  CLIENTS_OF_FAITH = 'clientsOfFaith',
  NON_RELIGIOUS_ATHEIST_AGNOSTIC = 'nonReligiousAtheistAgnostic',
  // Veterans
  EXPERIENCE_WORKING_WITH_VETERANS = 'experienceWorkingWithVeterans',
  // Meeting setting
  HIGHLIGHT_PROVIDERS_WHO_OFFER_IN_PERSON_SESSIONS = 'highlightProvidersWhoOfferInPersonSessions',
  VIDEO = 'video',
  LIVE_MESSAGING = 'live_messaging',
  // Availability
  FLEXIBLE = 'flexible',
  SPECIFY_DATE_TIME = 'specifyDateTime',
  AVAILABILITIES = 'availabilities',
}

// Values from BE we receive for member preferences
// TODO: BE should be sending any matching values in their ID form and not label form
export const MEMBER_PREFERENCE_BE_TO_ID_MAPPING = Object.freeze({
  // Race/ethnicity
  Asian: MEMBER_PREFERENCE_IDS.ASIAN,
  'Asian or Asian American': MEMBER_PREFERENCE_IDS.ASIAN,
  'Black or African American': MEMBER_PREFERENCE_IDS.BLACK_OR_AFRICAN_AMERICAN,
  'Hispanic or Latino': MEMBER_PREFERENCE_IDS.HISPANIC_OR_LATINX,
  'Hispanic, Latino, or Spanish Origin': MEMBER_PREFERENCE_IDS.HISPANIC_OR_LATINX,
  'Middle Eastern or North African': MEMBER_PREFERENCE_IDS.MIDDLE_EASTERN_OR_NORTH_AFRICAN,
  'Native American or Alaska Native': MEMBER_PREFERENCE_IDS.NATIVE_AMERICAN_OR_ALASKA_NATIVE,
  'Native American or Alaskan Native': MEMBER_PREFERENCE_IDS.NATIVE_AMERICAN_OR_ALASKA_NATIVE,
  'American Indian or Alaskan Native': MEMBER_PREFERENCE_IDS.NATIVE_AMERICAN_OR_ALASKA_NATIVE,
  'American Indian or Alaska Native': MEMBER_PREFERENCE_IDS.NATIVE_AMERICAN_OR_ALASKA_NATIVE,
  'Native Hawaiian or Pacific Islander': MEMBER_PREFERENCE_IDS.NATIVE_HAWAIIAN_OR_PACIFIC_ISLANDER,
  'Native Hawaiian or Other Pacific Islander': MEMBER_PREFERENCE_IDS.NATIVE_HAWAIIAN_OR_PACIFIC_ISLANDER,
  White: MEMBER_PREFERENCE_IDS.WHITE,
  // Gender
  Man: MEMBER_PREFERENCE_IDS.MAN,
  Woman: MEMBER_PREFERENCE_IDS.WOMAN,
  'Non-binary': MEMBER_PREFERENCE_IDS.NON_BINARY_PERSON,
  'Gender non-conforming': MEMBER_PREFERENCE_IDS.NON_BINARY_PERSON,
  Agender: MEMBER_PREFERENCE_IDS.NON_BINARY_PERSON,
  Genderqueer: MEMBER_PREFERENCE_IDS.NON_BINARY_PERSON,
  'Two-spirit': MEMBER_PREFERENCE_IDS.NON_BINARY_PERSON,
  // Religion
  Buddhist: MEMBER_PREFERENCE_IDS.BUDDHIST,
  Christian: MEMBER_PREFERENCE_IDS.CHRISTIAN,
  Hindu: MEMBER_PREFERENCE_IDS.HINDU,
  Jewish: MEMBER_PREFERENCE_IDS.JEWISH,
  Mormon: MEMBER_PREFERENCE_IDS.MORMON,
  'Mormon (Church of Jesus Christ/LDS)': MEMBER_PREFERENCE_IDS.MORMON,
  Muslim: MEMBER_PREFERENCE_IDS.MUSLIM,
  'Non-religious/atheist/agnostic': MEMBER_PREFERENCE_IDS.NON_RELIGIOUS_ATHEIST_AGNOSTIC,
})

export type ProviderPreferenceValues = {
  ethnicities: string[]
  gender: string[]
  isLgbtqia: string[]
  religions: string[]
  isExperiencedWithVeterans: string[]
  meetingSetting: string[]
  availabilities?: string[] | null
  timeZone?: string
}

// Convert MEMBER_PREFERENCE_ID into readable text for mixpanel events and simplified user preference mapping
export const MEMBER_PREFERENCES_CATEGORY_SIMPLIFIED_MAPPING = Object.freeze({
  ethnicities: 'race/ethnicity',
  gender: 'gender',
  isLgbtqia: 'lgbtqia',
  religions: 'religion',
  isExperiencedWithVeterans: 'veterans',
  meetingSetting: 'meeting style',
  availabilities: 'availability',
})

// Conversion for Availability Calendar component mapping with time of day in military format
export const AVAILABILITY_PREFERENCE_TIME_OF_DAY_MAPPING: any = Object.freeze({
  0: '7-12', // 7AM - 12PM
  1: '12-17', // 12PM - 5PM
  2: '17-22', // 5PM - 10PM
})

// Conversion for Availability Calendar component mapping with day of week starting from integer 1 as Monday
export const AVAILABILITY_PREFERENCE_DAY_OF_WEEK_MAPPING: any = Object.freeze({
  0: 1, // Monday
  1: 2, // Tuesday
  2: 3, // Wednesday
  3: 4, // Thursday
  4: 5, // Friday
  5: 6, // Saturday
  6: 7, // Sunday
})

// Represents the initial empty RFF form state on the V2 ProviderPreferenceSelection page
export const emptyProviderPreferenceState = {
  ethnicities: [],
  gender: [],
  isLgbtqia: [],
  religions: [],
  isExperiencedWithVeterans: [],
  meetingSetting: [],
}

// Represents the initial empty RFF form state on the V3 ProviderPreferenceSelection page
export const emptyProviderPreferenceStateWithAvailability = {
  ethnicities: [],
  gender: [],
  isLgbtqia: [],
  religions: [],
  isExperiencedWithVeterans: [],
  availabilities: null,
  meetingSetting: [],
}

// Default / empty state (none selected) representation of the user's v2 preferences.
export const defaultV2PreferencesForUser: MemberPreferencesForUserV2 = {
  ethnicities: [],
  gender: [],
  isLgbtqia: false,
  religions: [],
  isBipoc: false,
  isExperiencedWithVeterans: false,
  preferredSessionFormat: [],
  preferredAppointmentType: [],
}

// Default / empty state for new availability preference
export const defaultAvailabilityPreferenceValues = {
  availabilities: null,
  timeZone: '',
}

// Default / empty state (none selected) representation of the user's preferences with availability properties
export const defaultV3PreferencesForUser: MemberPreferencesForUserV2 = {
  ethnicities: [],
  gender: [],
  isLgbtqia: false,
  religions: [],
  isBipoc: false,
  isExperiencedWithVeterans: false,
  preferredSessionFormat: [],
  preferredAppointmentType: [],
  availabilities: null,
  timeZone: '',
}
