import { MessageDescriptor } from 'react-intl'

import { cloneDeep } from 'lodash-es'

import {
  SOCIAL_NEED_OPTIONS,
  WellbeingResponse,
  WellbeingResponseResult,
  WellbeingResponseResults,
  WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE,
  WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE_LIMIT,
  WellnessCheckInDomain,
  WellnessCheckInDomainToTitle,
  WellnessCheckInResultsEssentialsContent,
} from '@lyrahealth-inc/shared-app-logic'

import { WELLBEING_RESULT_TO_CONTENT_MAPPING } from './wellnessCheckInDomainEssentialsConstants'

/**
 * Translate number score to domain score
 * @param score
 */
export const translateWellnessCheckInNumberScoreToDomainScore = (
  score: number,
): WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE => {
  if (score <= WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE_LIMIT.LOW) {
    return WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE.LOW
  } else if (score <= WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE_LIMIT.MODERATE) {
    return WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE.MODERATE
  }

  return WELLNESS_CHECK_IN_RESULTS_DOMAIN_SCORE.HIGH
}

/**
 * Translate essential domain to display domain name
 * @param title
 */
export const getDomainDisplayName = (title: WellnessCheckInDomain): MessageDescriptor => {
  return WellnessCheckInDomainToTitle[title]
}

export const isWellbeingResponseInvalid = (domain: WellnessCheckInDomain, results: WellbeingResponseResults) => {
  return results === undefined || results.length === 0 || getDomainDisplayName(domain) === undefined
}

/**
 * Filter out domains that are no longer supported and response that are not empty
 * @param wellbeingResponse
 */
export const filterInvalidDomains = (wellbeingResponse: WellbeingResponse) => {
  const filterResponse = cloneDeep(wellbeingResponse)
  const emptyDomains = Object.keys(filterResponse).filter((checkInDomain: WellnessCheckInDomain) => {
    const result = filterResponse[checkInDomain]
    return isWellbeingResponseInvalid(checkInDomain, result.response)
  })

  // Filter out domains with no response
  emptyDomains.map((domain: WellnessCheckInDomain) => {
    delete filterResponse[domain]
  })

  return filterResponse
}

/**
 * Return the contents for low or high score
 * @param responseResults
 */
export const getWellnessCheckInEssentialsContents = (
  responseResults: WellbeingResponseResults,
): WellnessCheckInResultsEssentialsContent[] => {
  const contents: WellnessCheckInResultsEssentialsContent[] = []
  responseResults.forEach((result: WellbeingResponseResult) => {
    const facetGroup = result.facetGroup
    const wellbeingResult = result?.result
    if (facetGroup && wellbeingResult) {
      const faceGroupToContentMapping = WELLBEING_RESULT_TO_CONTENT_MAPPING[wellbeingResult]
      if (faceGroupToContentMapping) {
        const essentialContent: WellnessCheckInResultsEssentialsContent = faceGroupToContentMapping[facetGroup]
        if (essentialContent) {
          contents.push(essentialContent)
        }
      }
    }
  })

  return contents
}

export const showSocialCareSupport = (socialCareOptions?: SOCIAL_NEED_OPTIONS[]): boolean => {
  return !(
    socialCareOptions === undefined ||
    socialCareOptions.length === 0 ||
    (socialCareOptions.length === 1 && socialCareOptions[0] === SOCIAL_NEED_OPTIONS.NONE_OF_THE_ABOVE)
  )
}
