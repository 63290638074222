import React, { FunctionComponent } from 'react'
import { StyleSheet, View } from 'react-native'

import { useTheme } from 'styled-components/native'

import { tID } from '../../utils'
import { BodyText, Size } from '../bodyText/BodyText'

/**
 * Standard badge for displaying a rounded icon with text in it
 */
export const TextBadge: FunctionComponent<TextBadgeProps> = ({
  text,
  backgroundColor,
  color,
  autoWidth = false,
  bold = false,
}) => {
  const { colors } = useTheme()

  return (
    <View
      testID={tID('TextBadge')}
      style={{
        ...styles.badge,
        backgroundColor: backgroundColor || colors.backgroundError,
        width: autoWidth ? 'auto' : 106,
      }}
    >
      <BodyText text={text} color={color || colors.textInverse} textAlign='center' size={Size.CAPTION} bold={bold} />
    </View>
  )
}

export interface TextBadgeProps {
  text: string | React.ReactNode
  backgroundColor?: string
  color?: string
  autoWidth?: boolean
  bold?: boolean
}

const styles = StyleSheet.create({
  badge: {
    height: 24,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 4,
    justifyContent: 'center',
  },
})
