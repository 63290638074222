import { People } from 'mixpanel-browser'
/**
 * This class is a partial mock of `mixpanel-browser` library.
 * It provides the two basic methods that we use. This is for testing only.
 */
export class MixpanelMock {
  token: string

  superProps: any = {}

  constructor(token: string) {
    this.token = token
  }

  init(id: string, options: { loaded: () => void }): void {
    console.log('Mixpanel init with id: ', id, ' and options: ', options)
    options.loaded()
  }

  register(props: unknown): void {
    this.superProps = props
  }

  register_once(props: { [key: string]: string }): void {
    console.log('register_once props: ', props)
  }

  unregister(prop: string): void {
    console.log('Unregistered prop: ', prop)
  }

  track(name: string, props?: unknown): void {
    console.log('track called with: ', name, props)
  }

  identify(id: string): void {
    console.log('identify called with: ', id)
  }

  alias(id: string): void {
    console.log('alias id: ', id)
  }

  reset(): void {
    console.log('reset')
  }

  get_property(name: string): any {
    return this.superProps[name]
  }

  clear_opt_in_out_tracking(): void {
    console.log('clear_opt_in_out_tracking called')
  }

  opt_out_tracking(): void {
    console.log('opt_out_tracking called')
  }

  opt_in_tracking(): void {
    console.log('opt_in_tracking called')
  }

  people: People = {
    // @ts-expect-error TS(2322): Type '(props: Dict) => void' is not assignable to ... Remove this comment to see the full error message
    set: (props: Dict): void => {
      console.log('people.set called', props)
    },
    // @ts-expect-error TS(2322): Type '(prop: string | Dict, to?: any, callback?: (... Remove this comment to see the full error message
    set_once: function (prop: string | Dict, to?: any, callback?: () => void): void {
      console.log('set_once called with', prop, to, callback)
    },
    // @ts-expect-error TS(2322): Type '(prop: string | string[], callback?: (() => ... Remove this comment to see the full error message
    unset: function (prop: string | string[], callback?: () => void): void {
      console.log('unset called with', prop, callback)
    },
    // @ts-expect-error TS(2322): Type '(prop: string | Dict, by?: number | undefine... Remove this comment to see the full error message
    increment: function (prop: string | Dict, by?: number, callback?: () => void): void {
      console.log('increment called with', prop, by, callback)
    },
    // @ts-expect-error TS(2322): Type '(prop: string | Dict, value?: any, callback?... Remove this comment to see the full error message
    append: function (prop: string | Dict, value?: any, callback?: () => void): void {
      console.log('append called with', prop, value, callback)
    },
    // @ts-expect-error TS(2322): Type '(prop: string | Dict, value?: any, callback?... Remove this comment to see the full error message
    union: function (prop: string | Dict, value?: any, callback?: () => void): void {
      console.log('union called with', prop, value, callback)
    },
    // @ts-expect-error TS(2322): Type '(amount: number, properties?: Dict | undefin... Remove this comment to see the full error message
    track_charge: function (amount: number, properties?: Dict, callback?: () => void): void {
      console.log('track_charge called with', amount, properties, callback)
    },
    // @ts-expect-error TS(2322): Type '(callback?: (() => void) | undefined) => voi... Remove this comment to see the full error message
    clear_charges: function (callback?: () => void): void {
      console.log('clear_charges called with', callback)
    },
    delete_user: function (): void {
      console.log('delete_user called')
    },
  }
}
