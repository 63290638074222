import { noOpActionErrorHandler } from '../../common/http/actionErrorHandler'

export const SET_CUSTOMER_GOALS = 'SET_CUSTOMER_GOALS'

export const getCustomerGoalsByEpisode = (episodeId: string) => ({
  action: SET_CUSTOMER_GOALS,
  request: {
    method: 'get',
    url: 'lt/v1/goals?episode_id=' + episodeId,
  },
  errorHandler: noOpActionErrorHandler,
})
