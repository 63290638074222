import { isObject } from 'lodash-es'
import Cookies from 'universal-cookie'

export const removeParentDomainCookies = () => {
  const cookies = new Cookies()
  const allCookies = cookies.getAll({ doNotParse: true })
  const parentDomain = '.lyrahealth.com'

  /**
   * Allow list for cookies that belong to the parent ".lyrahealth.com" domain
   * which we want to continue existing on our sub-domain (ex: ebay.lyrahealth.com).
   *
   * OptanonConsent: Cookie set by our OneTrust solution
   */
  const PARENT_DOMAIN_COOKIE_ALLOW_LIST = ['OptanonConsent']

  if (isObject(allCookies)) {
    Object.entries(allCookies).forEach(([cookieName]) => {
      if (!PARENT_DOMAIN_COOKIE_ALLOW_LIST.includes(cookieName)) {
        // The cookie will only be deleted if the domain and path matches.
        // Our sub-domain specific cookies will remain intact.
        cookies.remove(`${cookieName}`, { path: '/', domain: parentDomain })
      }
    })
  }
}
