import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { useIntl } from 'react-intl'
import { Modal, NativeSyntheticEvent, TextInputKeyPressEventData, ViewStyle } from 'react-native'

import { isArray, isEmpty } from 'lodash-es'
import styled, { useTheme } from 'styled-components/native'

import { KEYS, matchFinder, postExternalMatchesType, usePrevious } from '@lyrahealth-inc/shared-app-logic'

import { BaseInput } from './../BaseInput'
import { DropdownMenu } from './DropdownMenu'
import { FullScreenTypeAhead } from './FullScreenTypeAhead'
import { SearchNoResults } from './SearchNoResults'
import { Item, TypeAheadCustomOptionsConfig } from './TypeAheadCustomOptionsConfig'
import { TypeAheadInput } from './TypeAheadInput'
import { AccessibilityRolesNative, IS_WEB } from '../../../constants'
import { useAccessibilityFocus } from '../../../hooks/useAccessibilityFocus'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'
import { TypeAheadValue } from '../../../organisms/formBody/types'
import { ThemeType, tID } from '../../../utils'
import { BodyText, Size } from '../../bodyText/BodyText'

export const TYPE_AHEAD_Z_INDEX = 10

const Container = styled.View({
  zIndex: TYPE_AHEAD_Z_INDEX,
})

const DropdownContainer = styled.View<{ theme: ThemeType; absolutePositioned: boolean }>(
  ({ theme, absolutePositioned }) => ({
    position: 'relative',
    ...(absolutePositioned && {
      position: 'absolute',
      left: 0,
      right: 0,
      top: '100%',
      zIndex: TYPE_AHEAD_Z_INDEX,
    }),
    backgroundColor: theme.colors.backgroundPrimary,
    maxHeight: `${40 * 6 + 8 * 2}px`, // 6 list items + vertical padding
    padding: '8px',
    borderRadius: '8px',
    boxShadow: `0 2px 16px ${theme.colors.shadowLow}`,
  }),
)

export const TypeAhead = <T,>({
  label,
  error,
  name,
  value,
  options = [],
  onChange,
  onFocus,
  isFocused,
  onBlur,
  placeholder = '',
  readOnly,
  modalBackgroundColor,
  dataSource,
  postExternalMatches,
  showNoResultsDialog = true,
  multiSelect = true,
  saveSingleSelectFieldsAsArray = false,
  customOptionsConfig,
  inputName = 'typeAhead-input',
  accessibilityLabel = 'Select an option',
  fixedDropdownMenuPosition = false,
  allowUserInput = false,
  baseInputStyle,
  inputContainerStyle,
  dropdownContainerStyle,
  initialScrollIndex,
}: TypeAheadProps<T>): JSX.Element => {
  const { locale } = useIntl()
  const [focusRef] = useAccessibilityFocus({ active: isFocused, delay: 200 })
  const [matches, setMatches] = useState<string[]>([])
  /** When in single select mode + a custom item has been selected, this is the element to render within the input bar. */
  const [singleSelectModeSelectedItemLabel, setSingleSelectModeSelectedItemLabel] = useState<JSX.Element | undefined>(
    undefined,
  )
  const [isLoading, setIsLoading] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isFullscreenMenuOpen, setIsFullscreenMenuOpen] = useState(false)
  const [highlightValue, setHighlightValue] = useState('')
  const [matchHighlighted, setMatchHighlighted] = useState(-1)
  const [searchValue, setSearchValue] = useState('')

  const dropdownContainerRef = `TypeAhead-DropdownContainer-${name}`
  const typeAheadInputFieldRef = `TypeAhead-InputField-${name}`
  const ref = useRef(null)

  const valueArrayValues = customOptionsConfig ? customOptionsConfig.getValue(value) : value
  /** Deal with the selected value as a string[] internally to avoid mismatched types. `onValueChange` will set the parent value to the expected type. */
  const valueArray = useMemo(
    () => (isArray(value) ? (valueArrayValues as string[]) : value ? [value] : []),
    [value, valueArrayValues],
  )
  const [selectedMatches, setSelectedMatches] = useState<Array<string>>(valueArray)
  const { breakpoints } = useTheme()
  const previousMatches = usePrevious(matches)
  const noResults = !isEmpty(searchValue) && !isLoading && !matches.length
  /** Complete list of options as strings */
  const optionsList = customOptionsConfig
    ? customOptionsConfig.options
        .filter((option) => customOptionsConfig.getIsPressable(option as Item))
        .map((option) => customOptionsConfig.getDisplayString(option))
    : options
  const [menuOptions, setMenuOptions] = useState(optionsList.filter((item: string) => !valueArray.includes(item)))
  const numMenuOptions = getFilteredOptions().length
  const scrollIndex =
    isEmpty(searchValue) && initialScrollIndex && optionsList.length > initialScrollIndex
      ? initialScrollIndex
      : undefined

  useOnClickOutside(
    dropdownContainerRef,
    ref,
    () => {
      setIsDropdownOpen(false)
      onBlur()
    },
    typeAheadInputFieldRef,
  )

  const updateSelectedItemLabel = useCallback(
    (item: T): void => {
      if (customOptionsConfig?.inputLabelRenderer) {
        const selectItemLabel = customOptionsConfig?.inputLabelRenderer(item)
        if (selectItemLabel) {
          setSingleSelectModeSelectedItemLabel(selectItemLabel)
        }
      }
    },
    [customOptionsConfig],
  )

  useEffect(() => {
    setIsDropdownOpen(!!isFocused && !breakpoints.isMobileSized && (showNoResultsDialog || numMenuOptions !== 0))
  }, [isFocused, breakpoints.isMobileSized, showNoResultsDialog, numMenuOptions])

  useEffect(() => {
    if (!breakpoints.isMobileSized && isFullscreenMenuOpen) {
      setIsFullscreenMenuOpen(false)
    }
  }, [breakpoints.isMobileSized, isFullscreenMenuOpen])

  useEffect(() => {
    setHighlightValue(searchValue.toLowerCase())
  }, [matches, previousMatches, searchValue])

  useEffect(() => {
    if (isEmpty(value)) {
      setSelectedMatches([])
    } else if (!isEmpty(valueArray) && customOptionsConfig && customOptionsConfig?.inputLabelRenderer) {
      // Initialize the input label if there's a selected item or initial value is passed in
      const selectedOption = customOptionsConfig.options.find(
        (item) => customOptionsConfig.getDisplayString(item) === valueArray[0],
      )
      if (selectedOption) {
        updateSelectedItemLabel(selectedOption)
      }
      setSelectedMatches(valueArray)
    }
  }, [customOptionsConfig, updateSelectedItemLabel, value, valueArray])

  const onValueChange = (newValue: string[]) => {
    onChange(multiSelect ? newValue : saveSingleSelectFieldsAsArray ? [newValue[0]] : newValue[0])
  }

  const handleOnChangeText = async (text: string) => {
    setSearchValue(text)
    setMatchHighlighted(-1)
    if (text) {
      if (!!dataSource && !!postExternalMatches) {
        setIsLoading(true)
        try {
          const matches = await postExternalMatches({ dataSource, input: text })
          setMatches(matches)
        } finally {
          setIsLoading(false)
        }
      } else {
        setMatches(matchFinder(optionsList, text, locale))
      }
    } else {
      setMatches([])
    }

    if (allowUserInput) {
      onValueChange(text ? [...selectedMatches, text] : selectedMatches)
    }
  }

  const handleMatchPress = (match: string, customMatch?: T) => {
    setSearchValue('')
    setMatches([])
    const newValue = multiSelect ? [...selectedMatches, match] : [match] // Replace with most recent match if multiselect is off
    onValueChange(newValue)
    setSelectedMatches(newValue)
    if (!multiSelect) {
      onBlur()
    }
    if (customMatch && !multiSelect) {
      updateSelectedItemLabel(customMatch)
    }
  }

  const handleOnFocus = () => {
    onFocus()
  }

  const handleInputPress = () => {
    if (breakpoints.isMobileSized) {
      setIsFullscreenMenuOpen(true)
    }
  }

  const closeFullScreenMenu = () => {
    setIsFullscreenMenuOpen(false)
    onBlur()
    // Wait for the menu to close, then remove focus. Affects mobile web only.
    // If modified, verify registration view in mobile web selects from country dropdown correctly.
    setTimeout(onBlur, 800)
  }

  const handleOnBlur = (e: any) => {
    const ignoreBlur = ['typeAheadInput-clear-button', 'typeAhead-menu-item', 'typeAheadInput-selection-delete']
    if (!ignoreBlur.includes(e?.nativeEvent?.relatedTarget?.dataset?.testid)) {
      onBlur()
      setMatchHighlighted(-1)
    }
  }

  const handleKeyPress = (e?: NativeSyntheticEvent<TextInputKeyPressEventData>, isSubmit = false) => {
    if (!(isDropdownOpen || isFullscreenMenuOpen)) {
      return
    }
    const hasSectionItems = customOptionsConfig?.options?.some(
      (option) => !customOptionsConfig.getIsPressable(option as Item),
    )
    const displayedList = hasSectionItems ? getFilteredCustomOptions() : menuOptions
    const firstOptionHighlighted = matchHighlighted === 0
    const lastOptionHighlighted = matchHighlighted === displayedList.length - 1
    const isInitial = matchHighlighted < 0
    const key = e?.nativeEvent.key

    let nextIndex = 0
    if (key === KEYS.ARROW_DOWN) {
      nextIndex = lastOptionHighlighted ? 0 : matchHighlighted + 1
      // Skip section headers and dividers
      while (hasSectionItems && !customOptionsConfig?.getIsPressable(displayedList[nextIndex])) {
        nextIndex = nextIndex >= displayedList.length - 1 ? 0 : nextIndex + 1
      }
      setMatchHighlighted(nextIndex)
    } else if (key === KEYS.ARROW_UP) {
      nextIndex = firstOptionHighlighted || isInitial ? displayedList.length - 1 : matchHighlighted - 1
      // Skip section headers and dividers
      while (hasSectionItems && !customOptionsConfig?.getIsPressable(displayedList[nextIndex])) {
        nextIndex = nextIndex <= 0 ? displayedList.length - 1 : nextIndex - 1
      }
      setMatchHighlighted(nextIndex)
    } else if (key === KEYS.ENTER || isSubmit) {
      e?.preventDefault()
      if (allowUserInput && isFullscreenMenuOpen && !isEmpty(valueArray)) {
        closeFullScreenMenu()
        return
      }
      if (matchHighlighted === -1) {
        return
      }
      if (!isEmpty(displayedList)) {
        const match = hasSectionItems
          ? customOptionsConfig?.getDisplayString(displayedList[matchHighlighted])
          : displayedList[matchHighlighted]
        let customMatch
        if (customOptionsConfig) {
          customMatch = customOptionsConfig.options.find(
            (option) => customOptionsConfig.getDisplayString(option) === match,
          )
        }
        handleMatchPress(match, customMatch)
        if (lastOptionHighlighted) {
          setMatchHighlighted(displayedList.length - 2)
        }
      }
    } else if (key === KEYS.ESC && !breakpoints.isMobileSized) {
      setIsDropdownOpen(false)
    }
  }

  const handleRemoveItem = (itemToRemove: string) => {
    onValueChange(valueArray.filter((item: string) => item !== itemToRemove))
    setSelectedMatches(selectedMatches.filter((item: string) => item !== itemToRemove))
    getFilteredOptions()
  }

  function getFilteredOptions() {
    let results = isEmpty(matches) ? (isEmpty(searchValue) ? optionsList : []) : matches
    // If multiselect, filter out the currently selected options
    results = !multiSelect ? [...results] : results.filter((item: string) => !valueArray.includes(item))

    if (results?.length != menuOptions?.length) {
      setMenuOptions(results)
    }
    return results
  }

  const getFilteredCustomOptions = useCallback(() => {
    if (!customOptionsConfig) {
      return []
    }
    const allOptions = customOptionsConfig.options
    const filteredMatchesIncludingSectionItems = allOptions.filter((option: T) => {
      // Keep headers and dividers displayed when there is a search value
      if (!customOptionsConfig.getIsPressable(option as Item)) return true

      // Keep items that match the user input
      if (matches.includes(customOptionsConfig.getDisplayString(option))) return true

      return false
    })
    const filteredMatches = filteredMatchesIncludingSectionItems.filter((option: T) =>
      customOptionsConfig.getIsPressable(option as Item),
    )
    const results = isEmpty(filteredMatches)
      ? isEmpty(searchValue)
        ? allOptions
        : []
      : filteredMatchesIncludingSectionItems
    return !multiSelect
      ? [...results]
      : results.filter((item: T) => !valueArray.includes(customOptionsConfig.getDisplayString(item)))
  }, [customOptionsConfig, matches, multiSelect, searchValue, valueArray])

  return (
    <Container>
      <BaseInput label={label} error={error} name={name} style={baseInputStyle}>
        {readOnly ? (
          isEmpty(valueArray) ? (
            <BodyText text={'––'} size={Size.DEFAULT} />
          ) : (
            valueArray.map((val: string) => <BodyText text={val} size={Size.DEFAULT} key={val} />)
          )
        ) : (
          <>
            <TypeAheadInput
              ref={focusRef}
              name={inputName}
              placeholder={placeholder}
              value={selectedMatches}
              inputValue={searchValue}
              onChange={handleOnChangeText}
              onBlur={handleOnBlur}
              onFocus={handleOnFocus}
              isLoading={isLoading}
              isFocused={isFocused}
              editable={!breakpoints.isMobileSized}
              onPress={handleInputPress}
              onKeyPress={handleKeyPress}
              onDeleteItem={handleRemoveItem}
              multiSelect={multiSelect}
              singleSelectModeSelectedItemLabel={singleSelectModeSelectedItemLabel}
              accessibilityLabel={label || accessibilityLabel}
              style={inputContainerStyle}
              nativeID={typeAheadInputFieldRef}
              id={typeAheadInputFieldRef}
            />

            {isDropdownOpen && (
              <DropdownContainer
                testID={tID('typeAhead-dropdown')}
                accessibilityRole={AccessibilityRolesNative.MENU}
                absolutePositioned={fixedDropdownMenuPosition}
                ref={ref}
                nativeID={dropdownContainerRef}
                style={dropdownContainerStyle}
              >
                <DropdownMenu
                  options={getFilteredOptions()}
                  customOptions={getFilteredCustomOptions()}
                  customOptionsConfig={customOptionsConfig}
                  onOptionPress={handleMatchPress}
                  highlightValue={highlightValue}
                  optionSelected={matchHighlighted}
                  value={valueArray}
                  initialScrollIndex={scrollIndex}
                />
                {showNoResultsDialog && noResults && <SearchNoResults />}
              </DropdownContainer>
            )}
          </>
        )}
      </BaseInput>

      <Modal animationType='slide' transparent visible={isFullscreenMenuOpen} onRequestClose={closeFullScreenMenu}>
        <FullScreenTypeAhead
          value={selectedMatches}
          inputValue={searchValue}
          title={label}
          onChange={handleOnChangeText}
          matches={matches}
          onClose={closeFullScreenMenu}
          isLoading={isLoading}
          highlightValue={highlightValue}
          keyboardDelay={IS_WEB ? 350 : 100}
          onKeyPress={handleKeyPress}
          onPress={handleInputPress}
          optionSelected={matchHighlighted}
          backgroundColor={modalBackgroundColor}
          onOptionSelected={handleMatchPress}
          customOptionsConfig={customOptionsConfig}
          singleSelectModeSelectedItemLabel={singleSelectModeSelectedItemLabel}
          multiSelect={multiSelect}
          onDeleteItem={handleRemoveItem}
          getFilteredOptions={getFilteredOptions}
          getFilteredCustomOptions={getFilteredCustomOptions}
          accessibilityLabel={label || accessibilityLabel}
          initialScrollIndex={scrollIndex}
        />
      </Modal>
    </Container>
  )
}

export const TypeAheadRFF: FunctionComponent<FieldRenderProps<TypeAheadValue>> = ({
  input: { value, onChange, name, onFocus, onBlur },
  meta: { touched, error, active },
  label,
  placeholder,
  options,
  readOnly,
  dataSource,
  postExternalMatches,
  showNoResultsDialog = false,
  multiSelect,
  saveSingleSelectFieldsAsArray,
  allowUserInput = false,
  customOptionsConfig,
  baseInputStyle,
  inputContainerStyle,
  dropdownContainerStyle,
}) => {
  return (
    <TypeAhead
      label={label}
      value={value}
      onChange={onChange}
      error={touched && error}
      name={name}
      onFocus={onFocus}
      isFocused={active}
      onBlur={onBlur}
      options={options}
      placeholder={placeholder}
      readOnly={readOnly}
      dataSource={dataSource}
      postExternalMatches={postExternalMatches}
      showNoResultsDialog={showNoResultsDialog}
      multiSelect={multiSelect}
      saveSingleSelectFieldsAsArray={saveSingleSelectFieldsAsArray}
      allowUserInput={allowUserInput}
      customOptionsConfig={customOptionsConfig}
      baseInputStyle={baseInputStyle}
      inputContainerStyle={inputContainerStyle}
      dropdownContainerStyle={dropdownContainerStyle}
    />
  )
}

export interface TypeAheadProps<T> {
  label?: string
  error?: string
  name?: string
  options?: string[]
  value: TypeAheadValue
  /** Do not call directly within this component, use `onValueChange` */
  onChange: (text: TypeAheadValue) => void
  onFocus: () => void
  onBlur: (e?: any) => void
  isFocused?: boolean
  placeholder?: string
  readOnly?: boolean
  modalBackgroundColor?: string
  dataSource?: string
  postExternalMatches?: postExternalMatchesType
  showNoResultsDialog?: boolean
  multiSelect?: boolean
  saveSingleSelectFieldsAsArray?: boolean
  /** Use this over `options` when you need to display something custom in the dropdown menu or input bar */
  customOptionsConfig?: TypeAheadCustomOptionsConfig<T>
  inputName?: string
  /** Only used if missing `label` */
  accessibilityLabel?: string
  /** In order for the dropdown menu to overlay other items, the parent container must set a z-index higher than other sibling elements. By default the menu will push down other items on the page. If setting this to true, make sure to check z-index on the parent containers. */
  fixedDropdownMenuPosition?: boolean
  /** Only works in single select mode */
  allowUserInput?: boolean
  baseInputStyle?: ViewStyle
  inputContainerStyle?: ViewStyle
  dropdownContainerStyle?: ViewStyle
  initialScrollIndex?: number
}
