import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import styled, { useTheme } from 'styled-components/native'

import {
  ArrowIcon,
  BulletedList,
  BulletIcon,
  PrimaryButton,
  Subhead,
  WellnessCheckInOverviewIllustration,
} from '../../atoms'
import { SubheadSize } from '../../styles/typeStyles'
import { WellnessCheckInBackgroundContainer } from '../../templates/wellnessCheckInBackgroundContainer/WellnessCheckInBackgroundContainer'
import { ThemeType, tID } from '../../utils'

export interface WellnessCheckInOverviewProps {
  onCTAPress: () => void
}

const STICKY_HEADER_MARGIN_TOP_OFFSET = 72

const ContentContainer = styled.View<{ theme: ThemeType }>(({ theme }) => ({
  backgroundColor: theme.colors.backgroundPrimary,
  borderRadius: theme.spacing['24px'],
  width: '544px',
  alignSelf: 'center',
  marginTop: `${40 + STICKY_HEADER_MARGIN_TOP_OFFSET}px`,
  padding: `${theme.spacing['48px']} ${theme.spacing['40px']} ${theme.spacing['40px']} ${theme.spacing['40px']}`,
  ...(theme.breakpoints.isMobileSized && {
    width: 'auto',
    marginHorizontal: theme.spacing['16px'],
    padding: `${theme.spacing['40px']} ${theme.spacing['16px']} ${theme.spacing['32px']} ${theme.spacing['16px']}`,
  }),
}))

const Content = styled.View<{ theme: ThemeType }>({
  alignItems: 'center',
})

const StyledSubhead = styled(Subhead)<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['24px'],
  marginBottom: theme.spacing['32px'],
}))

const BulletIconContainer = styled.View({
  marginTop: '10px',
})

const CTAButton = styled(PrimaryButton)<{ theme: ThemeType }>(({ theme }) => ({
  marginTop: theme.spacing['16px'],
  ...(theme.breakpoints.isMinWidthTablet && {
    alignSelf: 'flex-end',
  }),
}))

export const WellnessCheckInOverview: FunctionComponent<WellnessCheckInOverviewProps> = ({ onCTAPress }) => {
  const {
    breakpoints: { isMobileSized },
    colors,
  } = useTheme() as ThemeType
  const { fill: bulletColor } = colors.components.wellnessCheckInOverview.bulletPoint
  const bulletedListData = [
    {
      value: (
        <FormattedMessage
          defaultMessage='Answer a few questions about how you’re feeling emotionally, physically, socially, and spiritually'
          description='Bullet point item to explain the wellness check in form process'
        />
      ),
      bulletIcon: (
        <BulletIconContainer>
          <BulletIcon fillColor={bulletColor} />
        </BulletIconContainer>
      ),
    },
    {
      value: (
        <FormattedMessage
          defaultMessage='Get personalized results and wellness resources'
          description='Bullet point item to explain the wellness check in form results'
        />
      ),
      bulletIcon: (
        <BulletIconContainer>
          <BulletIcon fillColor={bulletColor} />
        </BulletIconContainer>
      ),
    },
  ]

  return (
    <WellnessCheckInBackgroundContainer>
      <ContentContainer testID={tID('WellnessCheckInOverview')}>
        <Content>
          <WellnessCheckInOverviewIllustration />
          <StyledSubhead
            text={
              <FormattedMessage
                defaultMessage='Check in on your wellbeing'
                description='Title for Wellness Check In Overview page'
              />
            }
            size={isMobileSized ? SubheadSize.MEDIUM : SubheadSize.LARGE}
            textAlign='center'
          />
          <BulletedList data={bulletedListData} spaceBetween={24} />
        </Content>
        <CTAButton
          text={
            <FormattedMessage
              defaultMessage='Let’s go'
              description='Button on the Wellness Check In Overview page that takes the user to start the questionnaire'
            />
          }
          onPress={onCTAPress}
          testID={tID('WellnessCheckInOverview-CTAButton')}
          rightIcon={<ArrowIcon />}
          fullWidth={isMobileSized}
        />
      </ContentContainer>
    </WellnessCheckInBackgroundContainer>
  )
}
