import { ProgramNames } from '../programs/Programs'

export enum EpisodeStates {
  COMPLETED = 'completed',
  DROP_OUT = 'drop_out',
  NOT_SUPPORTED = 'not_supported',
  CARE_TRANSITION = 'care_transition',
  NOT_INITIATED = 'not_initiated',
  NOT_ELIGIBLE = 'not_eligible',
  BOOSTER = 'booster',
  IN_PROGRESS = 'in_progress',
}
export interface Episode {
  assessment_set: string
  create_date: string
  curriculum: Curriculum[]
  curriculum_track?: any
  end_date: string | null
  end_episode_note?: string | null
  goals?: string[]
  id: string
  name?: string
  patient_id: string
  program_id: string
  program_name: ProgramNames
  provider_id: string
  provider_patient_id: string
  recording_consent?: unknown
  start_date: string
  state: EpisodeStates
  program: string
  assessment_set_id: string
  external_ids?: Dict
}

export interface Curriculum {
  contents: EpisodeContents[]
  session: number
  title?: string
  name?: string
}

export interface EpisodeContents {
  content_type: string
  group: string
  name: string
  title: string
  content_meta_data?: Dict
  instructions?: string
  id?: string
  content_id?: string
}

export interface EpisodeSummary {
  baseline: EpisodeBaseline
  client_id: string
  clinical_success: null | boolean
  episode_end_date: string
  episode_state: string
  first_name: string
  last_name: string
}

export enum EpisodeBaseline {
  MILD_MINIMAL = 'Mild/Minimal',
  MODERATE_SEVERE = 'Moderate/Severe',
}

export interface Goal {
  id: string
  goal_text: string
  goal_position: number
  episode_id: string
}
