import { createSelector } from 'reselect'

import { launchDarklyUserDeviceUUID } from '@lyrahealth-inc/shared-app-logic'

import { getUserCountryCode } from '../user/userSelectors'

const getAppGlobalsState = (state: $TSFixMe) => state.get('appGlobals')

export const getOktaBaseUrl = createSelector(getAppGlobalsState, (appGlobals) => appGlobals?.get('oktaBaseUrl'))

export const getOktaClientId = createSelector(getAppGlobalsState, (appGlobals) => appGlobals?.get('oktaClientId'))

export const getShouldShowCookieBannerSelector = createSelector(getAppGlobalsState, (appGlobals) =>
  appGlobals.get('shouldShowCookieBanner', false),
)

export const getShouldShowCookieSettings = createSelector(getAppGlobalsState, (appGlobals) =>
  appGlobals.get('shouldShowCookieSettings', false),
)

export const getGoogleSSOClientId = createSelector(getAppGlobalsState, (appGlobals) =>
  appGlobals?.get('googleSSOClientId', ''),
)

export const getAppleSSOClientId = createSelector(getAppGlobalsState, (appGlobals) =>
  appGlobals?.get('appleSSOClientId', ''),
)

export const getDetectedCountry = createSelector(getAppGlobalsState, (appGlobals) => appGlobals?.get('detectedCountry'))

export const getDetectedCountryISO = createSelector(getDetectedCountry, (detectedCountry) =>
  detectedCountry?.get('countryIso'),
)

export const getIsDetectedCountryInternational = createSelector(getDetectedCountryISO, (detectedCountryISO) =>
  Boolean(detectedCountryISO && detectedCountryISO !== 'US'),
)

export const getGlobalIsHydrated = createSelector(
  getAppGlobalsState,
  (appGlobals) => appGlobals?.get('isHydrated') ?? false,
)

export const getDetectedCountryFetchStatus = createSelector(getAppGlobalsState, (appGlobals) =>
  appGlobals?.get('detectedCountryFetchStatus'),
)

export const getShouldEmitMixpanelEventsForEnv = createSelector(getAppGlobalsState, (appGlobals) =>
  appGlobals?.get('shouldEmitMixpanelEventsForEnv'),
)

export const getEssentialsCmsUrl = createSelector(getAppGlobalsState, (appGlobals) =>
  appGlobals?.get('essentialsCmsUrl'),
)

export const getPendoHashedLyraId = createSelector(getAppGlobalsState, (appGlobals) =>
  appGlobals?.get('pendoHashedLyraId'),
)

// Returns a boolean to indicate whether or not we should show live chat for international users. We want to block certain countries from using Live Chat due to
// GDPR and other international concerns
export const getShouldShowLiveChat = createSelector(
  (state: any) => getUserCountryCode(state),
  getDetectedCountryISO,
  (userCountryCode, detectedCountryISO) => {
    return !['CN', 'RU'].includes(userCountryCode) && !['CN', 'RU'].includes(detectedCountryISO)
  },
)

export const getToastText = createSelector(getAppGlobalsState, (appGlobals) => appGlobals?.get('toastText'))

export const getToastType = createSelector(getAppGlobalsState, (appGlobals) => appGlobals?.get('toastType'))

export const getToastId = createSelector(getAppGlobalsState, (appGlobals) => appGlobals?.get('toastId'))

export const getToastTextSize = createSelector(getAppGlobalsState, (appGlobals) => appGlobals?.get('toastTextSize'))

export const getToastDuration = createSelector(getAppGlobalsState, (appGlobals) => appGlobals?.get('toastDuration'))

export const getIsCareNavigatorModalOpen = createSelector(getAppGlobalsState, (appGlobals) =>
  appGlobals?.get('isCareNavigatorModalOpen'),
)

/**
 * Gets the device UUID from redux store.
 * If appGlobals?.get('deviceUUID') is empty, that means that the user registered on the same device.
 * If this is defined, it means that the user finished registration on a different device, but we want to use
 * the one returned by the redirect link in registration
 */
export const getDeviceUUID = createSelector(
  getAppGlobalsState,
  (appGlobals) => appGlobals?.get('deviceUUID') ?? launchDarklyUserDeviceUUID,
)
