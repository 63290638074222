import React from 'react'

import ProviderCalendarCheckmarkIllustrationUrl from '../../assets/providerCalendarCheckmark.png'
import { Image } from '../image/Image'

interface ProviderCalendarCheckmarkIllustrationProps {
  width?: number
  height?: number
}

export function ProviderCalendarCheckmarkIllustration({
  width = 140,
  height = 122,
  ...props
}: ProviderCalendarCheckmarkIllustrationProps) {
  return (
    <Image
      source={ProviderCalendarCheckmarkIllustrationUrl}
      style={{ width, height }}
      contentFit='contain'
      {...props}
      accessibilityIgnoresInvertColors
    />
  )
}
