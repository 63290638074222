import React from 'react'
import { View } from 'react-native'

import styled from 'styled-components/native'

import { ThemeType, tID } from '../../utils'
import { LegalAndCopyrightFooter } from '../legalAndCopyrightFooter/LegalAndCopyrightFooter'
import { LegalFooterProps } from '../legalFooter/LegalFooter'

export type WellnessCheckInCopyrightFooter = {
  wellnessCheckInFooterBackgroundColor?: string
} & LegalFooterProps

const FooterContainer = styled(View)<{ theme: ThemeType; wellnessCheckInFooterBackgroundColor?: string }>(
  ({ theme, wellnessCheckInFooterBackgroundColor }) => ({
    padding: theme.breakpoints.isMobileSized
      ? `${theme.spacing['64px']} ${theme.spacing['16px']} ${theme.spacing['16px']} ${theme.spacing['16px']}`
      : `80px 0 ${theme.spacing['24px']} 0`,
    backgroundColor: wellnessCheckInFooterBackgroundColor || theme.colors.backgroundTransparent,
  }),
)

export const WellnessCheckInCopyrightFooter: React.FC<WellnessCheckInCopyrightFooter> = ({
  footerLinkPressed,
  legalNotice,
  isInternational,
  wellnessCheckInFooterBackgroundColor,
}) => {
  return (
    <FooterContainer
      testID={tID('WellnessCheckInCopyrightFooter')}
      wellnessCheckInFooterBackgroundColor={wellnessCheckInFooterBackgroundColor}
    >
      <LegalAndCopyrightFooter
        footerLinkPressed={footerLinkPressed}
        legalNotice={legalNotice}
        isInternational={isInternational}
      />
    </FooterContainer>
  )
}
