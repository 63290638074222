import { MessageDescriptor } from 'react-intl'

import { cloneDeep, isArray, isEmpty, isEqual, omit } from 'lodash-es'

import {
  AVAILABILITY_PREFERENCE_DAY_OF_WEEK_MAPPING,
  AVAILABILITY_PREFERENCE_TIME_OF_DAY_MAPPING,
  CARE_LANGUAGES_PREFERENCE_CATEGORY,
  defaultV2PreferencesForUser,
  defaultV3PreferencesForUser,
  emptyProviderPreferenceState,
  emptyProviderPreferenceStateWithAvailability,
  MEETING_STYLE_MODAL_OPTIONS,
  MEMBER_PREFERENCE_IDS,
  MEMBER_PREFERENCES_CATEGORY_SIMPLIFIED_MAPPING,
  ProviderPreferenceValues,
  S4C_PROGRAM_OPTION_FLOW,
} from './constants'
import { activeMemberPreferenceListOptions, PreferenceMetadataConfig } from './metadata'
import { MemberPreferencesForUserV2, UserPreferenceValuesSimplified } from './types'
import {
  ProviderInfo,
  ProviderMemberPreferenceV2,
  ProviderPreferenceMatchV2,
  ProviderPreferenceTypeV2,
} from '../../models/providers/Providers'
import { MEETING_FORMATS } from '../../models/session/Session'
import { DEFAULT_CARE_LANGUAGE } from '../common/constants/constants'
import { TREATMENT_OPTIONS, TREATMENT_OPTIONS_TYPE } from '../common/constants/treatmentOptions'
import {
  getProviderSupportedPreferenceValues,
  getProviderSupportsInPersonModality,
  getProviderSupportsLiveMessaging,
  getProviderSupportsVideo,
} from '../providers/utils'
import { MEMBER_PREFERENCE_LABELS } from '../searchForCare/messages'
import { isSearchingForTherapy } from '../searchForCare/utils'

export const setMeetingFormatForUpdatedCoachingPreferences = ({
  setMeetingFormatFn,
  updatedValues,
}: {
  setMeetingFormatFn: (value: string) => void
  updatedValues: {
    live_messaging: boolean
    video: boolean
    inPerson?: boolean
  }
}) => {
  if (updatedValues.live_messaging && !updatedValues.video) {
    setMeetingFormatFn(MEETING_FORMATS.LIVE_MESSAGING)
  } else if (updatedValues.video && !updatedValues.live_messaging) {
    setMeetingFormatFn(MEETING_FORMATS.VIDEO)
  } else if (updatedValues.video && updatedValues.live_messaging) {
    setMeetingFormatFn(MEETING_FORMATS.VIDEO_AND_LIVE_MESSAGING)
  } else {
    setMeetingFormatFn(MEETING_FORMATS.NONE_SPECIFIED)
  }
}

// Based on the current treatment option flow, fetch the visible preference categories that the user saw / had the option to select preferences for
export const getVisiblePreferenceCategories = ({
  searchForCareProgramOptionFlow,
  shouldShowAvailabilityPreference,
}: {
  searchForCareProgramOptionFlow: S4C_PROGRAM_OPTION_FLOW
  shouldShowAvailabilityPreference: boolean
}) => {
  // PROVREC-4134 : filter out availability in list of visible preferences when feature flag is on
  const filteredPreferences = shouldShowAvailabilityPreference
    ? activeMemberPreferenceListOptions
    : activeMemberPreferenceListOptions.filter((preferenceCategory) => preferenceCategory.name !== 'availabilities')
  const visiblePreferences = filteredPreferences
    .filter((preferenceCategory: PreferenceMetadataConfig) => {
      return preferenceCategory.visibleFor.includes(searchForCareProgramOptionFlow)
    })
    .map((preferenceCategory: PreferenceMetadataConfig) => preferenceCategory.name)

  // Although not shown on the member preferences accordion page, care language preference is treated as a
  // member preference by LW-API / Arbiter and should be included in the list of visiblePreferences, also for data collection
  if (shouldDisplayCareLanguagePreference({ searchForCareProgramOptionFlow })) {
    visiblePreferences.push(CARE_LANGUAGES_PREFERENCE_CATEGORY)
  }

  return visiblePreferences.join(',')
}

export const getNumSelectedPreferenceCategories = ({
  ethnicities = [],
  gender = [],
  isLgbtqia = false,
  religions = [],
  isExperiencedWithVeterans = false,
  preferredAppointmentType = [],
  preferredSessionFormat = [],
  availabilities = null,
  searchForCareProgramOptionFlow,
  isCoachingWithLiveMessagingSearch = false,
  shouldShowAvailabilityPreference = false,
}: {
  ethnicities?: string[]
  gender?: string[]
  isLgbtqia?: boolean
  religions?: string[]
  isExperiencedWithVeterans?: boolean
  preferredAppointmentType?: string[]
  preferredSessionFormat?: string[]
  availabilities?: string[] | null
  searchForCareProgramOptionFlow: S4C_PROGRAM_OPTION_FLOW
  isCoachingWithLiveMessagingSearch: boolean
  shouldShowAvailabilityPreference: boolean
}) => {
  let numSelectedPreferenceCategories = 0
  // Filter out entries to only read meeting setting option
  // Filter out availability option if feature flag is not on
  const preferenceCategories =
    searchForCareProgramOptionFlow === S4C_PROGRAM_OPTION_FLOW.PARENT_INITIATED_TEEN ||
    searchForCareProgramOptionFlow === S4C_PROGRAM_OPTION_FLOW.PARENT_INITIATED_CHILD ||
    searchForCareProgramOptionFlow === S4C_PROGRAM_OPTION_FLOW.SELF_INITIATED_TEENS
      ? Object.entries({
          preferredAppointmentType,
        })
      : Object.entries({
          ethnicities,
          gender,
          isLgbtqia,
          religions,
          isExperiencedWithVeterans,
          preferredAppointmentType,
          preferredSessionFormat,
          ...(shouldShowAvailabilityPreference && { availabilities }),
        })

  const isValidPreferredAppointmentTypeOption = getIsValidSearchForModalityPreferenceForMemberPreferences({
    searchForCareProgramOptionFlow,
  })

  // Check if user has selected any options through the list of preference categories
  preferenceCategories.forEach(([key, value]) => {
    if (key === 'availabilities') {
      // Only count availabilities array on a valid availability preference option
      if (!isEmptyAvailabilityPreferenceOption({ availabilities })) {
        numSelectedPreferenceCategories++
      }
    } else if (isArray(value) && value.length) {
      if (
        (key === 'preferredAppointmentType' && isValidPreferredAppointmentTypeOption) ||
        (key === 'preferredSessionFormat' && isCoachingWithLiveMessagingSearch) ||
        (key !== 'preferredAppointmentType' && key !== 'preferredSessionFormat' && key !== 'ethnicities')
      ) {
        numSelectedPreferenceCategories++
      } else if (key === 'ethnicities') {
        if (
          !(
            isEqual(value, [MEMBER_PREFERENCE_IDS.MIDDLE_EASTERN_OR_NORTH_AFRICAN]) &&
            [S4C_PROGRAM_OPTION_FLOW.COACHING, S4C_PROGRAM_OPTION_FLOW.COACHING_WITH_LMS].includes(
              searchForCareProgramOptionFlow,
            )
          )
        ) {
          numSelectedPreferenceCategories++
        }
      }
    } else if ((key === 'isLgbtqia' && value) || (key === 'isExperiencedWithVeterans' && value)) {
      numSelectedPreferenceCategories++
    }
  })
  // Return total number of preference categories, relevant to the current treatment option flow
  return numSelectedPreferenceCategories
}

const matchedProviderPreferenceLabelV2 = {
  [ProviderPreferenceTypeV2.CARE_LANGUAGE]: MEMBER_PREFERENCE_LABELS.CARE_LANGUAGE,
  [ProviderPreferenceTypeV2.SEXUAL_ORIENTATION]: {
    LGBTQIA_MATCH_IDENTITY: MEMBER_PREFERENCE_LABELS.LGBTQIA_MATCH_IDENTITY,
    LGBTQIA_MATCH_EXPERIENCE: MEMBER_PREFERENCE_LABELS.LGBTQIA_MATCH_EXPERIENCE,
  },
  [ProviderPreferenceTypeV2.EXPERIENCE_RELIGION]: {
    [MEMBER_PREFERENCE_IDS.BUDDHIST]: MEMBER_PREFERENCE_LABELS.EXPERIENCE_RELIGION_BUDDHIST,
    [MEMBER_PREFERENCE_IDS.JEWISH]: MEMBER_PREFERENCE_LABELS.EXPERIENCE_RELIGION_JEWISH,
    [MEMBER_PREFERENCE_IDS.CHRISTIAN]: MEMBER_PREFERENCE_LABELS.EXPERIENCE_RELIGION_CHRISTIAN,
    [MEMBER_PREFERENCE_IDS.HINDU]: MEMBER_PREFERENCE_LABELS.EXPERIENCE_RELIGION_HINDU,
    [MEMBER_PREFERENCE_IDS.MUSLIM]: MEMBER_PREFERENCE_LABELS.EXPERIENCE_RELIGION_MUSLIM,
    [MEMBER_PREFERENCE_IDS.NON_RELIGIOUS_ATHEIST_AGNOSTIC]: MEMBER_PREFERENCE_LABELS.EXPERIENCE_RELIGION_NON_RELIGIOUS,
    [MEMBER_PREFERENCE_IDS.CLIENTS_OF_FAITH]: MEMBER_PREFERENCE_LABELS.EXPERIENCE_RELIGION_CLIENTS_OF_FAITH,
  },
  [ProviderPreferenceTypeV2.EXPERIENCE_VETERAN]: MEMBER_PREFERENCE_LABELS.EXPERIENCE_VETERAN,
  [ProviderPreferenceTypeV2.AVAILABILITY]: {
    SPECIFIC_DAYS_AND_TIMES: MEMBER_PREFERENCE_LABELS.specificDaysAndTimes,
    SPECIFIC_DAY_AND_TIME: MEMBER_PREFERENCE_LABELS.specificDayAndTime,
  },
  [ProviderPreferenceTypeV2.SESSION_MODALITY]: {
    video: MEMBER_PREFERENCE_LABELS.video,
    live_messaging: MEMBER_PREFERENCE_LABELS.live_messaging,
    in_person: MEMBER_PREFERENCE_LABELS.inPerson,
  },
}

const providerPreferenceTypeList = [
  ProviderPreferenceTypeV2.GENDER,
  ProviderPreferenceTypeV2.GRANULAR_ETHNICITY,
  ProviderPreferenceTypeV2.SEXUAL_ORIENTATION,
  ProviderPreferenceTypeV2.EXPERIENCE_RELIGION,
  ProviderPreferenceTypeV2.EXPERIENCE_VETERAN,
  ProviderPreferenceTypeV2.AVAILABILITY,
  ProviderPreferenceTypeV2.SESSION_MODALITY,
  ProviderPreferenceTypeV2.ETHNICITY,
  ProviderPreferenceTypeV2.CARE_LANGUAGE,
]

export const getMatchedPreferences = ({
  provider,
  userMemberPreferences,
  searchForCareProgramOptionFlow,
}: {
  provider: ProviderInfo | undefined
  userMemberPreferences: MemberPreferencesForUserV2 | null
  searchForCareProgramOptionFlow: S4C_PROGRAM_OPTION_FLOW
}) => {
  const initialArr: MessageDescriptor[] = []

  // Sort the preference types in order of the provider preference selection page
  const sortedMemberPreferences = cloneDeep(provider?.memberPreferences || []).sort(
    (a, b) =>
      providerPreferenceTypeList.indexOf(a.type as ProviderPreferenceTypeV2) -
      providerPreferenceTypeList.indexOf(b.type as ProviderPreferenceTypeV2),
  )

  return (
    sortedMemberPreferences?.reduce((arr, memberPreference) => {
      if (memberPreference.match == ProviderPreferenceMatchV2.FULL) {
        // Member preferences V2 uses GRANULAR_ETHNICITY instead of ETHNICITY here since
        // the former utilizes the multi-select structure
        if (memberPreference.type == ProviderPreferenceTypeV2.ETHNICITY) {
          return arr
        }
        if (memberPreference.type == ProviderPreferenceTypeV2.SESSION_MODALITY) {
          const label = []
          if (
            searchForCareProgramOptionFlow === S4C_PROGRAM_OPTION_FLOW.THERAPY ||
            searchForCareProgramOptionFlow === S4C_PROGRAM_OPTION_FLOW.PARENT_INITIATED_CHILD ||
            searchForCareProgramOptionFlow === S4C_PROGRAM_OPTION_FLOW.PARENT_INITIATED_TEEN
          ) {
            // Therapy has the possibility of only in-person matches
            if (
              provider &&
              getProviderSupportsInPersonModality({ provider }) &&
              userMemberPreferences?.preferredAppointmentType.includes(MEETING_FORMATS.IN_PERSON)
            ) {
              label.push(matchedProviderPreferenceLabelV2[memberPreference.type].in_person)
            }
          } else {
            // Coaching has the possibility of video or live messaging matches
            if (
              provider &&
              getProviderSupportsVideo({ provider }) &&
              userMemberPreferences?.preferredSessionFormat.includes(MEETING_FORMATS.VIDEO)
            ) {
              label.push(matchedProviderPreferenceLabelV2[memberPreference.type].video)
            }
            if (
              provider &&
              getProviderSupportsLiveMessaging({ provider }) &&
              userMemberPreferences?.preferredSessionFormat.includes(MEETING_FORMATS.LIVE_MESSAGING)
            ) {
              label.push(matchedProviderPreferenceLabelV2[memberPreference.type].live_messaging)
            }
          }
          return [...arr, ...label]
        } else if (memberPreference.type == ProviderPreferenceTypeV2.SEXUAL_ORIENTATION) {
          return [...arr, matchedProviderPreferenceLabelV2[memberPreference.type].LGBTQIA_MATCH_IDENTITY]
        } else if (memberPreference.type == ProviderPreferenceTypeV2.EXPERIENCE_RELIGION) {
          // Match any Religion options the user and provider have both selected
          const labels: MessageDescriptor[] = []
          const matches = getProviderSupportedPreferenceValues(
            provider?.religions || [],
            userMemberPreferences?.religions || [],
          )
          matches.map((preference) => labels.push(matchedProviderPreferenceLabelV2[memberPreference.type][preference]))
          return [...arr, ...labels]
        } else if (memberPreference.type == ProviderPreferenceTypeV2.GRANULAR_ETHNICITY) {
          // Match any Ethnicity options the user and provider have both selected
          const labels: MessageDescriptor[] = []
          // PROSPECT-3796: Filter out MENA option if user is NOT searching for therapy
          const filteredUserRaceEthnicityMemberPreference = filterMemberPreferencesOptions({
            preferenceCategory: userMemberPreferences?.ethnicities || [],
            shouldFilterMemberPreferencesOptions: searchForCareProgramOptionFlow !== S4C_PROGRAM_OPTION_FLOW.THERAPY,
            filterOptions: [MEMBER_PREFERENCE_IDS.MIDDLE_EASTERN_OR_NORTH_AFRICAN],
          })
          const matches = getProviderSupportedPreferenceValues(
            provider?.provider_ethnicity || [],
            filteredUserRaceEthnicityMemberPreference,
          )
          matches.map((preference) => labels.push(MEMBER_PREFERENCE_LABELS[preference]))
          return [...arr, ...labels]
        } else if (memberPreference.type == ProviderPreferenceTypeV2.GENDER) {
          // Match any Gender options the user and provider have both selected
          const labels: MessageDescriptor[] = []
          const matches = getProviderSupportedPreferenceValues(
            provider?.genders || [],
            userMemberPreferences?.gender || [],
          )
          matches.map((preference) => labels.push(MEMBER_PREFERENCE_LABELS[preference]))
          return [...arr, ...labels]
        } else if (memberPreference.type == ProviderPreferenceTypeV2.AVAILABILITY) {
          if (!isEmptyAvailabilityPreferenceOption({ availabilities: userMemberPreferences?.availabilities })) {
            // For all valid preference options, we will determine which copy to use by the length of slots the users has selected
            const availabilityPreference = userMemberPreferences?.availabilities
            if (availabilityPreference && availabilityPreference.length > 1) {
              return [...arr, matchedProviderPreferenceLabelV2[memberPreference.type].SPECIFIC_DAYS_AND_TIMES]
            } else {
              return [...arr, matchedProviderPreferenceLabelV2[memberPreference.type].SPECIFIC_DAY_AND_TIME]
            }
          } else {
            return arr
          }
        }
        return [...arr, matchedProviderPreferenceLabelV2[memberPreference.type]]
      } else if (memberPreference.match == ProviderPreferenceMatchV2.PARTIAL) {
        // LGBTQIA+ and Religion have partial options
        if (memberPreference.type == ProviderPreferenceTypeV2.SEXUAL_ORIENTATION) {
          return [...arr, matchedProviderPreferenceLabelV2[memberPreference.type].LGBTQIA_MATCH_EXPERIENCE]
        } else if (memberPreference.type == ProviderPreferenceTypeV2.EXPERIENCE_RELIGION) {
          return [
            ...arr,
            matchedProviderPreferenceLabelV2[memberPreference.type][MEMBER_PREFERENCE_IDS.CLIENTS_OF_FAITH],
          ]
        }
        return arr
      } else {
        return arr
      }
    }, initialArr) || initialArr
  )
}

// Determines whether or not least one provider in the list of provider results, fulfills a preference specified by the user
export const doesProviderResultsFulfillMemberPreferences = ({ providers }: { providers: ProviderInfo[] }) => {
  return providers.some((provider: ProviderInfo) => {
    const providerMemberPreferences = provider?.memberPreferences as ProviderMemberPreferenceV2[] | undefined
    return providerMemberPreferences?.some((preference: ProviderMemberPreferenceV2) => {
      // Ignore "ETHNICITY" type matches -- "ETHNICITY" is a v1 preference category that remains in the obj
      // for backwards compatibility. "GRANULAR_ETHNICITY" represents the desired match for v2
      if (preference.match === ProviderPreferenceMatchV2.FULL && preference.type !== ProviderPreferenceTypeV2.ETHNICITY)
        return true
      else if (
        // Fallback preferences, i.e. "Experience with clients of faith" or "Experience with LGBTQIA+ clients"
        preference.match === ProviderPreferenceMatchV2.PARTIAL &&
        [ProviderPreferenceTypeV2.SEXUAL_ORIENTATION, ProviderPreferenceTypeV2.EXPERIENCE_RELIGION].includes(
          preference.type,
        )
      ) {
        return true
      }
      return false
    })
  })
}

// With the introduction of availability preference, we are not comparing the timeZone property since that
// value is not determine/touched by the user. Aditionally, for availability preference we need to check
// if the value is in an "empty" state as well
export const isMemberPreferencesEmpty = ({
  memberPreferences,
  shouldShowAvailabilityPreference,
}: {
  memberPreferences: MemberPreferencesForUserV2 | null
  shouldShowAvailabilityPreference: boolean
}) => {
  if (!memberPreferences) return true
  if (shouldShowAvailabilityPreference) {
    const memberPreferencesWithoutAvailabilityTimezone = omit(memberPreferences, ['timeZone', 'availabilities'])
    const defaultPreferencesWithoutAvailabilityTimezone = omit(defaultV3PreferencesForUser, [
      'timeZone',
      'availabilities',
    ])
    return (
      isEqual(memberPreferencesWithoutAvailabilityTimezone, defaultPreferencesWithoutAvailabilityTimezone) &&
      isEmptyAvailabilityPreferenceOption({ availabilities: memberPreferences?.availabilities })
    )
  } else {
    return isEqual(memberPreferences, defaultV2PreferencesForUser)
  }
}

// Returns true if the user did not specifiy ANY preference when searching for providers. The logic for this check varies based on treatment option
export const isMemberPreferencesUnspecifiedByUser = ({
  memberPreferences,
  selectedTreatmentOption,
  selectedCareLanguage,
  isUserInternational,
  shouldShowAvailabilityPreference = false,
}: {
  memberPreferences: MemberPreferencesForUserV2 | null
  selectedTreatmentOption: string
  selectedCareLanguage: string | null
  isUserInternational: boolean
  shouldShowAvailabilityPreference?: boolean
}) => {
  // We only consider care language a preference when its specified as domestic user + non-English, or international user
  const shouldConsiderCareLanguageAsPreference = isCareLanguageValidPreference({
    isSearchingForAdultTherapy: isSearchingForTherapy(selectedTreatmentOption as TREATMENT_OPTIONS_TYPE),
    isUserInternational,
    selectedCareLanguage,
  })
  const languagePreference = shouldConsiderCareLanguageAsPreference ? selectedCareLanguage : null
  const isDomesticUserWithPreferences = !isUserInternational && memberPreferences

  if (languagePreference) return false

  // Member preferences V2 contains a different set of values that will check all six category values
  if (isDomesticUserWithPreferences) {
    return isMemberPreferencesEmpty({ memberPreferences, shouldShowAvailabilityPreference })
  }
  return true
}

// The matched preferences header should show if either no preferences were specified by the user, or if at least one preference was fulfilled
// within the list of returned recommended providers
export const shouldShowMemberPreferencesMatchedHeader = ({
  memberPreferences,
  selectedTreatmentOption,
  providers,
  selectedCareLanguage,
  isUserInternational,
  shouldShowAvailabilityPreference,
}: {
  memberPreferences: MemberPreferencesForUserV2 | null
  selectedTreatmentOption: string
  providers: ProviderInfo[]
  selectedCareLanguage: string | null
  isUserInternational: boolean
  shouldShowAvailabilityPreference: boolean
}) => {
  return (
    isMemberPreferencesUnspecifiedByUser({
      memberPreferences,
      selectedTreatmentOption,
      selectedCareLanguage,
      isUserInternational,
      shouldShowAvailabilityPreference,
    }) || doesProviderResultsFulfillMemberPreferences({ providers })
  )
}

// Returns an object containing the list of selected preferences relevant to the current treatment option
// For creating the mixpanel preference_detail when clicking "Meet Providers"
export const getSelectedPreferencesForCurrentTreatmentOption = ({
  userMemberPreferences,
  eligibleTreatmentOptions,
  isTherapySearch,
  isSearchingForCoaching,
  isUserInternational,
  isOnsiteLocation,
  shouldShowAvailabilityPreference,
}: {
  userMemberPreferences: MemberPreferencesForUserV2 | null
  eligibleTreatmentOptions?: TREATMENT_OPTIONS_TYPE[]
  isTherapySearch: boolean
  isSearchingForCoaching: boolean
  isUserInternational: boolean
  isOnsiteLocation: boolean
  shouldShowAvailabilityPreference: boolean
}): UserPreferenceValuesSimplified => {
  if (!userMemberPreferences || isUserInternational || !(isTherapySearch || isSearchingForCoaching) || isOnsiteLocation)
    return {}

  const isCoachingWithLiveMessagingSearch =
    isSearchingForCoaching &&
    (eligibleTreatmentOptions ? eligibleTreatmentOptions.includes(TREATMENT_OPTIONS.LIVE_MESSAGING_COACHING) : false)

  if ('ethnicities' in userMemberPreferences) {
    const {
      ethnicities,
      gender,
      isLgbtqia,
      religions,
      isExperiencedWithVeterans,
      preferredAppointmentType,
      preferredSessionFormat,
      availabilities,
    } = userMemberPreferences

    // Map inputted availabilities into readable format
    const parsedAvailabilities =
      availabilities?.length && !availabilities.includes('flexible')
        ? convertAvailabilitiesDayOfWeek(availabilities)
        : availabilities
    return {
      [MEMBER_PREFERENCES_CATEGORY_SIMPLIFIED_MAPPING.ethnicities]: ethnicities,
      [MEMBER_PREFERENCES_CATEGORY_SIMPLIFIED_MAPPING.gender]: gender,
      [MEMBER_PREFERENCES_CATEGORY_SIMPLIFIED_MAPPING.isLgbtqia]: isLgbtqia,
      [MEMBER_PREFERENCES_CATEGORY_SIMPLIFIED_MAPPING.religions]: religions,
      [MEMBER_PREFERENCES_CATEGORY_SIMPLIFIED_MAPPING.isExperiencedWithVeterans]: isExperiencedWithVeterans,
      ...(isCoachingWithLiveMessagingSearch && {
        [MEMBER_PREFERENCES_CATEGORY_SIMPLIFIED_MAPPING.meetingSetting]: preferredSessionFormat,
      }),
      ...(isTherapySearch && {
        [MEMBER_PREFERENCES_CATEGORY_SIMPLIFIED_MAPPING.meetingSetting]: preferredAppointmentType,
      }),
      ...(shouldShowAvailabilityPreference && {
        [MEMBER_PREFERENCES_CATEGORY_SIMPLIFIED_MAPPING.availabilities]: parsedAvailabilities,
      }),
    }
  } else if ('isBipoc' in userMemberPreferences) {
    const { isBipoc, isLgbtqia, preferredAppointmentType, preferredSessionFormat } = userMemberPreferences
    return {
      isBipoc,
      isLgbtqia,
      ...(isCoachingWithLiveMessagingSearch && { preferredSessionFormat }),
      ...(isTherapySearch && { preferredAppointmentType }),
    }
  }
  return {}
}

// Use this function for member preferences in search request payload
export const getUserModalityPreferenceForMemberPreferences = ({
  userMemberPreferences,
  isValidSearchForModalityPreferenceForMemberPreferences,
}: {
  isValidSearchForModalityPreferenceForMemberPreferences: boolean
  userMemberPreferences: MemberPreferencesForUserV2 | null
}) => {
  if (!isValidSearchForModalityPreferenceForMemberPreferences) return '[]'
  if (!userMemberPreferences?.preferredAppointmentType || !userMemberPreferences?.preferredAppointmentType.length)
    return MEETING_FORMATS.NONE_SPECIFIED
  return userMemberPreferences?.preferredAppointmentType.join(',')
}

export const isCareLanguageValidPreference = ({
  isSearchingForAdultTherapy,
  isUserInternational,
  selectedCareLanguage,
}: {
  isSearchingForAdultTherapy: boolean
  isUserInternational: boolean
  selectedCareLanguage: string | null
}) => {
  return (
    isSearchingForAdultTherapy &&
    !!selectedCareLanguage &&
    (isUserInternational || (!isUserInternational && selectedCareLanguage !== DEFAULT_CARE_LANGUAGE))
  )
}

const matchedMeetingSettingFormatValues = {
  [MEMBER_PREFERENCE_IDS.HIGHLIGHT_PROVIDERS_WHO_OFFER_IN_PERSON_SESSIONS]: MEETING_FORMATS.IN_PERSON,
  [MEMBER_PREFERENCE_IDS.LIVE_MESSAGING]: MEETING_FORMATS.LIVE_MESSAGING,
  [MEMBER_PREFERENCE_IDS.VIDEO]: MEETING_FORMATS.VIDEO,
}

/// Returns provider preference selection checkbox ID values to meeting format values
export const matchMeetingSettingFormatToMemberPreferencesValues = ({
  meetingSetting,
}: {
  meetingSetting: string[]
}) => {
  return meetingSetting.map((name) => matchedMeetingSettingFormatValues[name])
}

// Returns saved member preference values to provider preference selection checkbox ID values
export const matchMemberPreferencesValuesToMeetingSettingFormat = ({
  preferenceValues,
}: {
  preferenceValues: string[]
}) => {
  return preferenceValues.map(
    (value) =>
      Object.keys(matchedMeetingSettingFormatValues).find(
        (key) => matchedMeetingSettingFormatValues[key] === value,
      ) as string,
  )
}

// Returns true if the user has made any selections under Race/Ethnicty section
// If the user only selects White, then it is not classified as BIPOC
export const getBipocUserPreferenceSelection = ({
  userPreferenceSelections,
}: {
  userPreferenceSelections: string[]
}) => {
  return userPreferenceSelections.length !== 0 && !isEqual(userPreferenceSelections, [MEMBER_PREFERENCE_IDS.WHITE])
}

// Business logic for determining which "Meeting Style" modal should be shown to the user. We currently have
// two formats with showing the in-person option and showing video/live messaging options.
export const getModalityPreferenceProgramOption = ({
  shouldShowMeetingStylePreferenceModal,
  isSelectedTreatmentOptionCoaching,
}: {
  shouldShowMeetingStylePreferenceModal: boolean
  isSelectedTreatmentOptionCoaching: boolean
}) => {
  if (!shouldShowMeetingStylePreferenceModal) {
    return MEETING_STYLE_MODAL_OPTIONS.NONE
  }
  return isSelectedTreatmentOptionCoaching
    ? MEETING_STYLE_MODAL_OPTIONS.VIDEO_AND_LIVE_MESSAGING
    : MEETING_STYLE_MODAL_OPTIONS.IN_PERSON
}

/**
 * Function that returns preference categories shown to the user under the provider preference selection page
 * based on the current search for care flow of the user
 */
export const getMemberPreferencesShown = ({
  searchForCareProgramOptionFlow,
  isCoachingWithLiveMessagingSearch,
  shouldShowAvailabilityPreference,
}: {
  searchForCareProgramOptionFlow: S4C_PROGRAM_OPTION_FLOW
  isCoachingWithLiveMessagingSearch: boolean
  shouldShowAvailabilityPreference: boolean
}): string[] => {
  const providerPreferenceState = shouldShowAvailabilityPreference
    ? emptyProviderPreferenceStateWithAvailability
    : emptyProviderPreferenceState
  let memberPreferencesShown: string[] = []

  switch (searchForCareProgramOptionFlow) {
    case S4C_PROGRAM_OPTION_FLOW.SELF_INITIATED_TEENS:
    case S4C_PROGRAM_OPTION_FLOW.PARENT_INITIATED_CHILD:
    case S4C_PROGRAM_OPTION_FLOW.PARENT_INITIATED_TEEN:
      memberPreferencesShown = Object.keys(providerPreferenceState).filter(
        (preference) => preference === 'meetingSetting',
      )
      break
    case S4C_PROGRAM_OPTION_FLOW.COACHING_WITH_LMS:
    case S4C_PROGRAM_OPTION_FLOW.THERAPY:
      memberPreferencesShown = Object.keys(providerPreferenceState)
      break
    case S4C_PROGRAM_OPTION_FLOW.COACHING:
      memberPreferencesShown = Object.keys(providerPreferenceState).filter(
        (preference) => preference !== 'meetingSetting',
      )
      break
    default:
      memberPreferencesShown = []
  }

  // only show availability preference when feature flag turned is turned on
  if (!shouldShowAvailabilityPreference) {
    memberPreferencesShown = Object.keys(memberPreferencesShown).filter((preference) => preference !== 'availabilities')
  }

  return memberPreferencesShown.map((value) => MEMBER_PREFERENCES_CATEGORY_SIMPLIFIED_MAPPING[value])
}

// Set default empty string arrays if preferences are empty. Otherwise, translate object
// into string arrays for checkbox values
export const getInitialProviderPreferenceValues = ({
  memberPreferencesV2,
  isValidSearchForModalityPreferenceForMemberPreferences,
  isCoachingWithLiveMessagingSearch,
  shouldShowAvailabilityPreference = false,
}: {
  memberPreferencesV2: MemberPreferencesForUserV2 | null
  isValidSearchForModalityPreferenceForMemberPreferences: boolean
  isCoachingWithLiveMessagingSearch: boolean
  shouldShowAvailabilityPreference?: boolean
}): ProviderPreferenceValues => {
  if (isEmpty(memberPreferencesV2)) {
    // Only add availability properties when feature flag is on
    if (shouldShowAvailabilityPreference) {
      return cloneDeep(emptyProviderPreferenceStateWithAvailability)
    } else {
      return cloneDeep(emptyProviderPreferenceState)
    }
  }

  // PROSPECT-3928: Temp logic - we'll need to filter out the Mormon option on the FE side for now
  // if the user has saved the option in their member preferences
  const filteredReligionPreference = filterMemberPreferencesOptions({
    preferenceCategory: memberPreferencesV2?.religions || [],
    shouldFilterMemberPreferencesOptions: true, // filter out option for all flows
    filterOptions: [MEMBER_PREFERENCE_IDS.MORMON],
  })

  return {
    ethnicities: memberPreferencesV2.ethnicities,
    gender: memberPreferencesV2.gender,
    isLgbtqia: memberPreferencesV2.isLgbtqia ? [MEMBER_PREFERENCE_IDS['LGBTQIA+']] : [],
    religions: filteredReligionPreference,
    isExperiencedWithVeterans: memberPreferencesV2.isExperiencedWithVeterans
      ? [MEMBER_PREFERENCE_IDS.EXPERIENCE_WORKING_WITH_VETERANS]
      : [],
    meetingSetting: isValidSearchForModalityPreferenceForMemberPreferences
      ? matchMemberPreferencesValuesToMeetingSettingFormat({
          preferenceValues: memberPreferencesV2.preferredAppointmentType,
        })
      : isCoachingWithLiveMessagingSearch
      ? matchMemberPreferencesValuesToMeetingSettingFormat({
          preferenceValues: memberPreferencesV2.preferredSessionFormat,
        })
      : [],
    ...(shouldShowAvailabilityPreference && {
      availabilities: memberPreferencesV2.availabilities,
    }),
  }
}

/** Function that filters member preference category options based on the type of treatment option the user selected */
export const filterMemberPreferencesOptions = ({
  preferenceCategory,
  shouldFilterMemberPreferencesOptions,
  filterOptions,
}: {
  preferenceCategory: string[]
  shouldFilterMemberPreferencesOptions: boolean
  filterOptions: string[]
}): string[] => {
  return shouldFilterMemberPreferencesOptions
    ? preferenceCategory.filter((ethnicity: string) => !filterOptions.includes(ethnicity))
    : preferenceCategory
}

/**
 * Returns check if using preferredAppointmentType value under member preferences
 */
export const getIsValidSearchForModalityPreferenceForMemberPreferences = ({
  searchForCareProgramOptionFlow,
}: {
  searchForCareProgramOptionFlow: S4C_PROGRAM_OPTION_FLOW
}): boolean => {
  return [
    S4C_PROGRAM_OPTION_FLOW.THERAPY,
    S4C_PROGRAM_OPTION_FLOW.PARENT_INITIATED_CHILD,
    S4C_PROGRAM_OPTION_FLOW.PARENT_INITIATED_TEEN,
    S4C_PROGRAM_OPTION_FLOW.SELF_INITIATED_TEENS,
  ].includes(searchForCareProgramOptionFlow)
}

export const getUserSelectedPreferencesCountTotal = ({
  userSelectedPreferences,
}: {
  userSelectedPreferences: UserPreferenceValuesSimplified | null
}) => {
  let countTotal = 0
  if (!userSelectedPreferences || isEmpty(userSelectedPreferences)) return countTotal
  Object.entries(userSelectedPreferences).forEach(([prefKey, prefValue]) => {
    const defaultPrefKey: string | undefined = Object.keys(MEMBER_PREFERENCES_CATEGORY_SIMPLIFIED_MAPPING).find(
      (key) => MEMBER_PREFERENCES_CATEGORY_SIMPLIFIED_MAPPING[key] === prefKey,
    )
    if (typeof prefValue === 'boolean' && defaultPrefKey) {
      if (prefValue !== false) {
        countTotal++
      }
    } else if (typeof prefValue === 'object' && Array.isArray(prefValue) && defaultPrefKey) {
      if (prefValue.length > 0) {
        if (prefKey === MEMBER_PREFERENCES_CATEGORY_SIMPLIFIED_MAPPING.availabilities) {
          if (!prefValue.includes(MEMBER_PREFERENCE_IDS.FLEXIBLE)) countTotal++
        } else countTotal += prefValue.length
      }
    }
  })
  return countTotal
}

export const shouldDisplayCareLanguagePreference = ({
  searchForCareProgramOptionFlow,
}: {
  searchForCareProgramOptionFlow: S4C_PROGRAM_OPTION_FLOW
}): boolean => {
  return searchForCareProgramOptionFlow === S4C_PROGRAM_OPTION_FLOW.THERAPY
}

/**
 * Function that returns a list of all the provider's matching availabilities from Arbiter
 * and if provider has a MatchLevel of FULL
 */
export const getAvailabilityPreferenceMatchLevelAndSlots = ({
  provider,
  shouldShowAvailabilityPreference,
}: {
  provider: ProviderInfo | undefined
  shouldShowAvailabilityPreference: boolean | undefined
}) => {
  if (!provider || !shouldShowAvailabilityPreference)
    return {
      hasFullAvailabilityMatch: false,
      matchingScheduleSlotsString: [],
    }
  else {
    const availabilityMatchLevel = provider.memberPreferences?.find(
      (matchLevel) => matchLevel.type == ProviderPreferenceTypeV2.AVAILABILITY,
    )
    return {
      hasFullAvailabilityMatch: availabilityMatchLevel?.match == ProviderPreferenceMatchV2.FULL || false,
      matchingScheduleSlotsString:
        availabilityMatchLevel && availabilityMatchLevel.matchingScheduleSlotsString
          ? availabilityMatchLevel.matchingScheduleSlotsString
          : [],
    }
  }
}

// If there is at least one datetime that matches the user's availability preference, display that as a bookable appointment to the user
// Otherwise, display the first available appointment as a bookable appointment to the user
export const getProviderFirstAvailabilityDate = ({
  shouldShowAvailabilityPreference = false,
  hasFullAvailabilityMatch,
  matchingScheduleSlotsString,
  providerFirstAvailability,
}: {
  shouldShowAvailabilityPreference: boolean | undefined
  hasFullAvailabilityMatch: boolean
  matchingScheduleSlotsString: string[]
  providerFirstAvailability: Date | undefined
}) => {
  const firstMatchingScheduleAvailability =
    matchingScheduleSlotsString.length > 0 ? new Date(matchingScheduleSlotsString[0]) : undefined
  return shouldShowAvailabilityPreference && hasFullAvailabilityMatch
    ? firstMatchingScheduleAvailability
    : providerFirstAvailability
}

export const convertAvailabilitiesDayOfWeek = (availabilities: string[]) => {
  return availabilities.map((key) => {
    const availabilitySplit = key.split('-')
    const dayOfWeek = availabilitySplit[0] as unknown as number
    const timeOfDay = availabilitySplit[1] as unknown as number
    return `${AVAILABILITY_PREFERENCE_DAY_OF_WEEK_MAPPING[dayOfWeek]}-${AVAILABILITY_PREFERENCE_TIME_OF_DAY_MAPPING[timeOfDay]}`
  })
}

/** Checks if the availability preference is in an "empty" state, which includes:
 *  - The user has not engaged in availability
 *  - The user has selected "I'm flexible option"
 *  - The user has selected "Specify date and time" with no time slots selected
 */
export const isEmptyAvailabilityPreferenceOption = ({
  availabilities,
}: {
  availabilities: string[] | null | undefined
}): boolean => {
  return availabilities == null || availabilities.length == 0 || availabilities.includes('flexible')
}

// Checks if at least one provider has a FULL availability match from provider results
export const hasAvailabilityProviderMatch = ({ providers }: { providers: ProviderInfo[] | undefined }) => {
  return providers?.some((provider) => {
    const availabilityMatchLevel = provider.memberPreferences?.find(
      (matchLevel) => matchLevel.type == ProviderPreferenceTypeV2.AVAILABILITY,
    )
    return availabilityMatchLevel?.match == ProviderPreferenceMatchV2.FULL
  })
}
