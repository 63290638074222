import React from 'react'
import type { FunctionComponent } from 'react'
import { ImageSourcePropType } from 'react-native'

import styled from 'styled-components/native'

import providerAndMemberScene from '../../assets/providerAndMemberScene.png'
import { tID } from '../../utils'
import { Image } from '../image/Image'

type ForkedTriageMainCareOptionIllustrationProps = {
  width?: number
  height?: number
}

const ImageContainer = styled(Image)<{ height: number; width: number }>(({ height, width }) => ({
  height: `${height}px`,
  width: `${width}px`,
}))

export const ForkedTriageMainCareOptionIllustration: FunctionComponent<ForkedTriageMainCareOptionIllustrationProps> = ({
  width = 241,
  height = 170,
}) => {
  return (
    <ImageContainer
      height={height}
      width={width}
      testID={`${tID('ForkedTriageMainCareOptionIllustration')}`}
      source={providerAndMemberScene as ImageSourcePropType}
    />
  )
}
