import { COMMON_MIXPANEL_PAGE } from './mixpanelConstants'

export enum UTM_CAMPAIGN {
  ELECTION = 'election2024',
  NEW_YEAR = 'newyear2025',
}

export enum UTM_MEDIUM {
  PRODUCT = 'product',
  ORGANIC = 'organic',
}

export enum UTM_SOURCE {
  HOMEBASE = COMMON_MIXPANEL_PAGE.HOME_BASE,
}
