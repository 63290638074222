import React, { FunctionComponent } from 'react'
import Svg, { G, Path } from 'react-native-svg'

/**
 * Illustration representing a target
 */
export const TargetIllustration: FunctionComponent = () => {
  return (
    <Svg width='90' height='65' viewBox='0 0 90 65' fill='none'>
      <G opacity='0.15'>
        <Path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M82.5338 28.1595C91.1117 40.3033 91.5096 59.9285 85.5804 73.2934H85.5774C81.3401 82.8463 71.1068 90.9291 62.204 94.1944C47.7759 99.4887 30.5837 96.0807 17.8719 87.9523C7.34699 81.2243 1.61525 68.5458 0.995608 56.1772C0.685784 49.9899 1.78535 43.7266 4.24268 38.0374C8.04865 29.2317 15.0136 22.0906 23.2513 17.3035C32.6249 11.8542 43.9942 10.9886 54.671 12.1276C64.8952 13.2181 76.5804 19.7304 82.5338 28.1595ZM51.413 84.3718C69.2217 81.7778 78.5498 66.5205 77.4078 51.7796C75.2511 23.9836 40.5084 14.0783 22.3017 34.4538C1.04843 58.2343 21.8066 88.679 51.413 84.3718Z'
          fill='#AE9580'
        />
        <Path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M65.7603 55.2026C65.7603 66.1839 56.8582 75.086 45.8769 75.086C34.8956 75.086 25.9935 66.1839 25.9935 55.2026C25.9935 44.2213 34.8956 35.3192 45.8769 35.3192C56.8582 35.3192 65.7603 44.2213 65.7603 55.2026ZM53.9539 55.2024C53.9539 59.663 50.3379 63.2791 45.8773 63.2791C41.4166 63.2791 37.8006 59.663 37.8006 55.2024C37.8006 50.7418 41.4166 47.1257 45.8773 47.1257C50.3379 47.1257 53.9539 50.7418 53.9539 55.2024Z'
          fill='#AE9580'
        />
        <Path
          d='M45.5375 53.6898L68.7607 18.3493C69.3098 17.5138 70.4322 17.2815 71.2678 17.8306L71.2703 17.8323C72.1059 18.3813 72.3382 19.5038 71.7891 20.3394L48.5659 55.6798C48.0168 56.5154 46.8944 56.7477 46.0588 56.1986L46.0563 56.1969C45.2207 55.6478 44.9885 54.5254 45.5375 53.6898Z'
          fill='#7F6246'
        />
        <Path
          d='M71.1503 8.55882e-06L59.9754 17.1709L64.0122 28.9017L64.0152 28.9078H64.0213L76.3808 27.8446L87.5527 10.6737L74.5735 12.6815L71.1503 8.55882e-06Z'
          fill='#7F6246'
        />
      </G>
    </Svg>
  )
}
