import React, { FunctionComponent, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import {
  COMMON_MIXPANEL_EVENTS,
  COMMON_MIXPANEL_PROPERTIES,
  WELLNESS_CHECK_IN_ENTRY_POINTS,
  WELLNESS_CHECK_IN_PAGES,
} from '@lyrahealth-inc/shared-app-logic'
import { WellnessCheckInCopyrightFooter, WellnessCheckInOverview } from '@lyrahealth-inc/ui-core-crossplatform'

import {
  WELLNESS_CHECK_IN_PAGE_TYPE,
  WellnessCheckInHeader,
} from '../../common/components/header/WellnessCheckInHeader'
import { LOCAL_STORAGE_KEY } from '../../common/constants/appConstants'
import { useDefaultMixpanelEntryPoint } from '../../common/hooks/useDefaultMixpanelEntryPoint'
import { useGetLegalFooterProps } from '../../common/hooks/useGetLegalFooterProps'
import { useShouldShowWellnessCheckIn } from '../../common/hooks/useShouldShowWellnessCheckIn'
import { useTrackViewPage } from '../../common/hooks/useTrackViewPage'
import { logToSumoLogic } from '../../common/utils/userUtils'
import { useAppDispatch } from '../../data/storeConfiguration/store'
import {
  clearWellnessCheckInForm,
  setWellnessCheckInEntryPoint,
} from '../../data/wellnessCheckIn/wellnessCheckInActions'

const WellnessCheckInOverviewContainer: FunctionComponent = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const defaultEntryPoint = useDefaultMixpanelEntryPoint()
  const [searchParams] = useSearchParams()
  const isEmailEntryPoint = Boolean(searchParams.get('utm_campaign'))
  if (isEmailEntryPoint) {
    dispatch(setWellnessCheckInEntryPoint(WELLNESS_CHECK_IN_ENTRY_POINTS.EMAIL))
  }

  useTrackViewPage(
    WELLNESS_CHECK_IN_PAGES.OVERVIEW,
    { [COMMON_MIXPANEL_PROPERTIES.ACTION]: COMMON_MIXPANEL_EVENTS.PAGE_LOAD },
    defaultEntryPoint,
  )

  const showWellnessCheckIn = useShouldShowWellnessCheckIn()
  const legalFooterProps = useGetLegalFooterProps()

  useEffect(() => {
    if (!showWellnessCheckIn) {
      navigate('/')
    }
  }, [showWellnessCheckIn, navigate])

  useEffect(() => {
    /**
     * Resetting wellness check in values if user starts at the beginning of the check in
     */
    const wellnessCheckInStorageValue = localStorage.getItem(LOCAL_STORAGE_KEY.WELLNESS_CHECK_IN)
    dispatch(clearWellnessCheckInForm())
    if (wellnessCheckInStorageValue) {
      const message = 'Detected existing wellness check in results - automatically deleting previous results'
      logToSumoLogic('wellnessCheckInPreview', undefined, {
        message,
        type: 'debug',
      })
      console.debug(message)
      localStorage.removeItem(LOCAL_STORAGE_KEY.WELLNESS_CHECK_IN)
    }
  }, [dispatch])

  return (
    <>
      <WellnessCheckInHeader pageType={WELLNESS_CHECK_IN_PAGE_TYPE.Overview} />
      <WellnessCheckInOverview onCTAPress={() => navigate(`/wellness-check-in/1`)} />
      <WellnessCheckInCopyrightFooter {...legalFooterProps} />
    </>
  )
}

export default WellnessCheckInOverviewContainer
