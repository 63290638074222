import { ProgramCategory, SessionCountByProgram, SessionLimitByProgram } from '../../../models/session/SessionUsage'
import { TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND, TREATMENT_OPTIONS_TYPE } from '../constants/treatmentOptions'

export function getSessionLimitProgramCategoryByTreatmentOption(
  treatmentOption: TREATMENT_OPTIONS_TYPE,
): ProgramCategory {
  switch (treatmentOption) {
    case TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND.COACHING:
    case TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND.LIVE_MESSAGING_COACHING:
    case TREATMENT_OPTIONS_FROM_TRIAGE_BACKEND.GUIDED_SELF_CARE:
      return 'coaching'
    default:
      // all other treatment options are considered therapy
      return 'therapy'
  }
}

export const createRemainingSessionCountByProgram = (
  EAPSessionLimitByProgram?: SessionLimitByProgram,
  usedEAPSessionCountByProgram?: SessionCountByProgram,
): SessionCountByProgram => {
  if (!EAPSessionLimitByProgram) {
    return {}
  }
  return Object.keys(EAPSessionLimitByProgram).reduce(
    (sessionsRemainingByProgram: SessionCountByProgram, program: string) => {
      const programCategory = program as ProgramCategory
      const remainingSessionCount =
        EAPSessionLimitByProgram[programCategory] - (usedEAPSessionCountByProgram?.[programCategory] ?? 0)
      sessionsRemainingByProgram[programCategory] = Math.max(remainingSessionCount, 0)
      return sessionsRemainingByProgram
    },
    {} as SessionCountByProgram,
  )
}

export const isProgramAvailableForMinor = (program: ProgramCategory): boolean => program === 'therapy'

export const UNLIMITED_SESSION_LIMIT = 999
export const isUnlimitedSessionLimit = (sessionLimit: number): boolean => sessionLimit === UNLIMITED_SESSION_LIMIT
